<footer class="site-footer flow" role="contentinfo">
  <nav aria-label="Footer navigation" tabindex="=-1" class="repel">
    <ul class="site-footer-nav cluster" data-type="pipes" role="list">
      <li>
        <a target="_blank" class="link link-underline"  href="https://clario.com/">
          About 
        </a>
      </li>
      <li>
        <a target="_blank" class="link link-underline"  href="https://clario.com/contact/customer-support/">
          Customer Care 
        </a>
      </li>
      <li>
        <a target="_blank" class="link link-underline" href="https://clario.com/about/legal-and-privacy/">
          Privacy Policy 
        </a>
      </li>
      <li>
        <a target="_blank" class="link link-underline" href="https://clario.com/about/legal-and-privacy/imprint/">
          Imprint
        </a>
      </li>
      <li>
        <a class="hand" class="link link-underline" (click) = "openDialog()">
          System Requirements
        </a>
      </li>
    </ul>
  </nav>
  <p class="site-footer__meta" style="font-size: 15px">&#169; Clario - {{year}} - Data Insights - {{version}}</p>
</footer>  
  
