import { Injectable } from '@angular/core';

@Injectable()
export class UtilsService {

  constructor() { }

  // given numeric score 1-100, returns hex color string
    // red bad, green good, etc TODO make this more intelligent
    static getColorFromScore(score: number): string {
      if( score == null ) {
        return Color.Grey;
      }
      if (score <= 25) {
        return Color.Red;
      }
      if (score <= 50) {
        return Color.Orange;
      }
      if (score <= 75) {
        return Color.Yellow;
      }
      return Color.Green;
    }

  //formating Session data according to application needed.
  static formatStudyDetails(userAccessStudies, navigatorOrgName) {
      let formatedStudyAccess = [];
      for(let organization in userAccessStudies) {
        if(organization == navigatorOrgName){
            for(let study of  userAccessStudies[organization]) {
              formatedStudyAccess.push(
                {
                  studyName : study.displayName,
                  uniqueId: study.studyId
                });
            }
        }
    }
    return formatedStudyAccess;
  }

  // Report URL format:
  //   eslint-disable-next-line max-len
  //   https://rpt-env2.ods.dev.ert.com/ODS_Reports/rdPage.aspx?rdReport=DCR_Query_Trending_Report.lgx&rptid=25&ptl_id=228&environment_index=0&siteid=12345
  static getBIReportURL(rptData: any) {
    const apiUrl = rptData.reportConfig.ReportUrl.BIReportBaseUrl + rptData.reportConfig.ReportUrl.BIReportPath;
    const rptUrl = apiUrl + '?rdReport=' + rptData.reportConfig.reportFile
      + '&rptid=' + rptData.reportConfig.reportId;
    const isSiteLevel = rptData.isSiteLevel;

    const urls = [];
    rptData.reportSources.forEach(item => {
      const itemUrl = rptUrl + '&ptl_id=' + item.systemOdsProjectId
        + '&environment_index=' + item.envId
        + '&siteid=' + item.ertSiteId;
      if (!isSiteLevel || (isSiteLevel && item.ertSiteId && item.ertSiteId !== 'ALL')) {
        urls.push(itemUrl);
      }
    });

    return urls;
  }

}

export const enum Color {
  Red = '#D92A26',
  Orange = '#EF801E',
  Yellow = '#F7C53F',
  Green = '#16A81B',
  Grey = '#dedede'
}
