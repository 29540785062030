<mat-card class="add-study-config-form_card">
  <mat-card-title>
    <h2>ADD DEFAULT CONFIG</h2>
  </mat-card-title>
<form [formGroup]="addStudyConfigForm" class="add-study-config-form" #addStudyConfigFormElem="ngForm" (ngSubmit)="saveDaultStudyConfig()"
  autocomplete="off">
    <div>
      <mat-form-field>
        <input matInput placeholder="Customer Name *" formControlName="clientId" [matAutocomplete]="auto" (ngModelChange)="updateStudies($event)">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let client of clientList" [value]="client.client_name" (onSelectionChange)="selectCustomer($event, client.client_id)">
            {{client.client_name}} </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-select placeholder="Select Study *" [(ngModel)]="selectedStudy"  formControlName="srcStudyId">
          <mat-option *ngFor="let study of studies" [value]="study.src_study_id"> {{ study.study_name }} </mat-option>
        </mat-select>
        <mat-error *ngIf="addStudyConfigForm.controls['clientId'].errors"> Please select a <b>Customer Name </b>first.</mat-error>
        <mat-error *ngIf="addStudyConfigForm.controls['srcStudyId'].errors &&  addStudyConfigForm.controls['srcStudyId'].errors.noStudies">
          Customer has no studies, please add one.</mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput placeholder="Number Sites *" formControlName="numSites" type="number">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput placeholder="Total Enroll *" formControlName="totalEnroll" type="number">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput placeholder="Total Subjects *" formControlName="totalCompleted" type="number">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput placeholder="Duration (Days) *" formControlName="length" type="number">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput placeholder="Visit Count *" formControlName="visitCount" type="number">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput placeholder="Visit Average (Days) *" formControlName="visitAvgDays" type="number">
      </mat-form-field>
    </div>
    <div class="add-form-container_button">
      <button class="es-button -es-primary" type="submit" [disabled]="addStudyConfigForm.invalid">Create</button>
    </div>
  </form>
</mat-card>
