export interface ToolbarState {
  // title, required for all views
  title: string;
  // additional info text on same line as title
  infoText?: string;

  // dropdown menu to select items, ie "select sites"
  dropdownMenu?: {
    label: string,
    propertyName: string,
    items: Array<any>,
    onItemSelected: Function,
    displayItem: Function,
  };
  bucket?: {
    complianceScore: number,
    qualityScore: number,
  };
  metric?: {
    metricId: number,
    metricScore: number,
    metricName: string,
    metricDescription?: string,
  };
  study?: {
    studyId: number,
    odsReportsEnabled: boolean,
  };
  site?: {
    siteId: string,
  };
}

export const initializeToolbarState = function() {
  return {
    title: 'Data Insights',
  };
};
