<mat-card class="add-data-connection-form_card">
  <mat-card-title>
    <h2>Add Data Connection</h2>
  </mat-card-title>
  <div class="row justify-content-start forms-container">
    <!-- ADD DATA CONNECTION FORM -->
    <form [formGroup]="addDataConnectionForm" class="add-data-connection-form col-sm-6 col-xs-6 col-lg-4"
      #addDataConnectionFormElem="ngForm" (ngSubmit)="saveDataConnection()" autocomplete="off">
      <div>
        <mat-form-field>
          <input matInput placeholder="Customer Name *" formControlName="clientId" [matAutocomplete]="auto"
            (ngModelChange)="updateStudies($event)">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let client of clientList" [value]="client.client_name"
              (onSelectionChange)="selectCustomer($event, client.client_id)">
              {{client.client_name}} </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-select placeholder="Data Subset *" formControlName="srcName" [(ngModel)]="subset"
            (selectionChange)="showSubsetFields()">
            <mat-option *ngFor="let subsetName of dataSubsets" [value]="subsetName"> {{ subsetName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <p class="form-subset-text" *ngIf="subset">Using {{subset}}</p>
      </div>
      <div>
        <mat-form-field>
          <mat-select placeholder="Select Study *" (selectionChange)="onStudySelected(selectedStudy)"
            [(ngModel)]="selectedStudy" formControlName="srcStudyId">
            <mat-option *ngFor="let study of studies" [value]="study.src_study_id"> {{ study.study_name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="addDataConnectionForm.controls['clientId'].errors"> Please select a <b>Customer Name
            </b>first. </mat-error>
          <mat-error
            *ngIf="addDataConnectionForm.controls['srcStudyId'].errors &&  addDataConnectionForm.controls['srcStudyId'].errors.noStudies">
            Customer has no studies, please add one.</mat-error>
        </mat-form-field>
      </div>
      <div class="studyoid-field-container">
        <mat-form-field>
          <input matInput placeholder="studyoid *" value="" formControlName="studyoid" type="text">
        </mat-form-field>
      </div>
      <div formArrayName="ods" *ngFor="let field of odsConnectionFields.controls; let i = index;">
        <div [formGroupName]="i">
          <!--  envID  -->
          <div *ngIf="field?.controls?.envID">
            <!-- <mat-form-field>
              <input matInput placeholder="environment ID (envID)" value="" formControlName="envID" type="number">
            </mat-form-field> -->
            <mat-form-field>
              <mat-select placeholder="Select envID (Environment ID) *">
                <mat-option *ngFor="let envNum of [0,1,2,3]" [value]="envNum"
                  (onSelectionChange)="selectedEnvID = envNum">{{envNum}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- Data Types -->
          <div *ngIf="field?.controls?.dataType">
            <!-- <mat-form-field>
              <mat-select placeholder="Data Type" formControlName="dataType">
                <mat-option *ngFor="let type of ods.dataTypes" [value]="type"> {{ type }} </mat-option>
              </mat-select>
            </mat-form-field> -->
            <div *ngIf="odsStudyConfigList.length && selectedStudy" class="data-types-list">
              <label>Data Type Config:</label>
              <mat-list *ngFor="let controlName of odsStudyConfigList ; let j = index;">
                  <mat-checkbox *ngIf="!ods.mustConfigDataTypes.includes(controlName.target_id)"
                    [checked]="controlName.active"
                    (change)="controlName.active=!controlName.active">
                    {{getOdsDataTypeText(controlName.target_id)}}
                  </mat-checkbox>
                </mat-list>
            </div>
          </div>

        </div>
      </div>

      <div class="add-form-container_button">
        <button class="es-button -es-primary" type="submit"
          [disabled]="!addDataConnectionForm.valid">Create</button>
      </div>
    </form>
  </div>
</mat-card>
