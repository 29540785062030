import { VariableState, defaultVariableState  } from './variable.state';
import * as  Actions  from './variable.actions';

const defaultValue: VariableState = defaultVariableState();

export function VariableReducer(state: VariableState = defaultValue, action: Actions.VariableActions) : VariableState  {

  switch (action.type) {

    case Actions.VARIABLECHANGED: {
      return {
        ...state,
        ...action.payload
      };
    }
    default: {
      return state;
    }
  }
}

