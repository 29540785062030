import { ObservationModalComponent } from './observation-modal/observation-modal.component';
import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'ert-observation',
  templateUrl: './observation.component.html',
  styleUrls: ['./observation.component.scss']
})
export class ObservationComponent implements OnInit {
  @Input() siteId: number;
  @Input() studyId: number;

  constructor(
    public dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  openDialog(observation): void {
    const dialogRef = this.dialog.open(ObservationModalComponent, {
      width: '250px',
      data: {
        siteId: this.siteId,
        studyId: this.studyId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
