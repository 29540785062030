import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DataService } from '@shared/services/data.service';
import { EndpointService } from '@shared/services/endpoint.service';
import { Observable, of } from 'rxjs';
import { TOAdminUser } from './user-management/user-management.component';
import { UserInvitation } from '@app/shared/models/user.model';
import { TestBed } from '@angular/core/testing';
import { CardSeriesComponent } from '@swimlane/ngx-charts';

@Injectable({
  providedIn: 'root'
})
export class ToAdminService {

  constructor(
    private endpointService: EndpointService,
    private dataService: DataService<any>
  ) { }

  getAllClients() {
    const endpoint = this.endpointService.getEndpoint('getCustomers');
    return this.dataService
      .getAll(endpoint).pipe(map((response: any) => response.result));
  }

  getClientStudies(clientId) {
    const endpoint = this.endpointService.getEndpoint('getCustomerStudies', clientId);
    return this.dataService
      .getAll(endpoint).pipe(map((response: any) => response.result));
  }

  getTOAdmins(): Observable<TOAdminUser[]> {
    const endpoint = this.endpointService.getEndpoint('getTOAdmins');
    return this.dataService
      .getAll(endpoint).pipe(map((response: any) => response.result));
  }

  getLevel0User(): Observable<TOAdminUser[]> {
    const endpoint = this.endpointService.getEndpoint('getLevel0User');
    return this.dataService
      .getAll(endpoint).pipe(map((response: any) => response.result));
  }

  inviteTOAdmin(toAdminInvitation: UserInvitation) {
    const endpoint = this.endpointService.getEndpoint('inviteTOAdmin');
    return this.dataService
      .post(endpoint, toAdminInvitation).pipe(map((response: any) => response.result));
  }

  makeTOAdmin(userId) {
    const endpoint = this.endpointService.getEndpoint('makeTOAdmin', userId);
    return this.dataService
      .post(endpoint, { userId: userId }).pipe(map((response: any) => response.result));
  }

  revokeTOAdmin(userId) {
    const endpoint = this.endpointService.getEndpoint('revokeTOAdmin', userId);
    return this.dataService
      .delete(endpoint, userId).pipe(map((response: any) => response.result));
  }

  addStudy(study) {
    const endpoint = this.endpointService.getEndpoint('addStudy', study);
    return this.dataService
      .post(endpoint, study).pipe(map((response: any) => response.result));
  }

  refreshStudy(study) {
    const endpoint = this.endpointService.getEndpoint('refreshStudy', study);
    return this.dataService
      .post(endpoint, study).pipe(map((response: any) => response.result));
  }

  addUser(user) {
    const endpoint = this.endpointService.getEndpoint('addUser', user);
    return this.dataService
      .post(endpoint, user).pipe(map((response: any) => response.result));
  }

  configDefault(config) {
    const endpoint = this.endpointService.getEndpoint('configDefault', config);
    return this.dataService
      .post(endpoint, config).pipe(map((response: any) => response.result));
  }

  getSourceConfigList(srcName: string, clientId) {
    const endpoint = this.endpointService.getEndpoint('sourceConfigList', srcName, clientId);
    return this.dataService
      .getAll(endpoint)
      .pipe(map((response: any) => response.result))
      .pipe(map(data => data.map(res => res.target_id)));

    function formatConfigList(res) {
      return res.table_name;
    }
  }

  getSourceStudyConfig(srcName, clientId, studyId) {
    const endpoint = this.endpointService.getEndpoint('singleSourceStudyConfig', srcName, clientId, studyId);
    return this.dataService
      .getAll(endpoint).pipe(map((response: any) => response.result));
  }

  addSourceStudyConfig(srcName, clientId, studyId, sourceConfig) {
    const endpoint = this.endpointService.getEndpoint('singleSourceStudyConfig', srcName, clientId, studyId);
    return this.dataService
      .post(endpoint, sourceConfig).pipe(map((response: any) => response.result));
  }

  getSingleStudySources(studyId: number) {
    const endpoint = this.endpointService.getEndpoint('singleStudySources', studyId);
    return this.dataService
      .getAll(endpoint).pipe(map((response: any) => response.result));
  }

  addConnection(params) {
    const endpoint = this.endpointService.getEndpoint('createStudySource', params);

    return this.dataService
      .post(endpoint, params)
      .pipe(map((response: any) => response.result));
  }

}

