import { AuthGuard } from '@core/guards/auth.guard';
import { DetailComponent } from './detail/detail.component';
import { StudyComponent } from './study/study.component';
import { SiteComponent } from './site/site.component';
import { DashboardComponent } from './dashboard.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SiteMetricComponent } from './site-metric/site-metric.component';
import { StudyMetricComponent } from './study-metric/study-metric.component';

import { taskRoutes } from '@app/tasks/tasks.routes';
import { alertRoutes } from '@app/alerts/alerts.routes';
import { CrossSitePortfolioComponent } from './cross-site-portfolio/cross-site-portfolio.component';
import { ViewSiteGuard, CrossSiteGuard } from '@app/core/guards/permguard.guard';
import { TableauComponent } from './tableau/tableau.component';
import { DataMonitoringComponent } from '@app/dashboard/data-monitoring/data-monitoring.component';


export const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      // routing to other modules
      { path: 'settings', loadChildren: () => import('../settings/settings.module').then(m => m.SettingsModule) },
      { path: 'metric-studio', loadChildren: () => import('../metric-studio/metric-studio.module').then(m => m.MetricStudioModule) },
      { path: '', redirectTo: 'portfolio', pathMatch: 'full', },
      { path: 'kpi', loadChildren: () => import('./tableau/tableau.module').then(m => m.TableauModule)},
      { path: 'data-monitoring', loadChildren: () => import('./tableau/tableau.module').then(m => m.TableauModule)},
      { path: 'sitebenchmark', component: CrossSitePortfolioComponent, canActivate: [ViewSiteGuard, CrossSiteGuard] },
      { path: 'study/:studyId', component: StudyComponent },
      { path: 'study/:studyId/site/:siteId',  component: SiteComponent },
      { path: 'study/:studyId/site/:siteId/metric/:metricId',  component: SiteMetricComponent },
      { path: 'study/:studyId/metric/:metricId',  component: StudyMetricComponent },

      // { path: 'portfolio/detail/:calcId', component: DetailComponent },
      { path: 'study/:studyId/detail/:calcId', component: DetailComponent },
      { path: 'study/:studyId/site/:siteId/detail/:calcId',  component: DetailComponent },

      // ...taskRoutes,
      // ...alertRoutes,
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
