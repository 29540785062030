<mat-sidenav-container autosize>
	<mat-sidenav class="app-container__side-nav" #sidenav mode="side" opened="true">
		<ert-sidebar class="es-sidebar"></ert-sidebar>
	</mat-sidenav>
	<mat-sidenav-content class="app-container">
		<main class="es-app-body">
			<ert-header class=""></ert-header>
			<header class="es-page-header">
				<ert-breadcrumb></ert-breadcrumb>
			</header>
			<div class="es-page-body container-fluid">
          <nav mat-tab-nav-bar [disableRipple]="false">
              <a mat-tab-link
                 *ngFor="let tabLink of tabLinks"
                 [routerLink]="tabLink.path"
                 routerLinkActive #rla="routerLinkActive"
                 [active]="rla.isActive">
                 <span>{{tabLink.text}}</span>
              </a>
            </nav>
				<router-outlet></router-outlet>
			</div>
		</main>
		<ert-footer></ert-footer>
	</mat-sidenav-content>
</mat-sidenav-container>

