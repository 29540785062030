import { SpinnerService } from '@core/services/spinner.service';
import { AuthService } from '@core/services/auth.service';
import { ErrorNotificationService } from './error-notification.service';
import { StatusCodes } from 'http-status-codes';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { DBLoggerService} from '@core/services/db-logger.service'

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

  constructor(
    private injector: Injector,
    private loaderService: SpinnerService,
    private DBLogger: DBLoggerService
  ) {
    super();
  }

  /**
  * Error Handler
  * 401 - call the auth logout method (logout and send to /login ) and show the notification bar error
  * 403 - send to error page with reset param for logout (/error)
  * 404 - send to error page (/error)
  * everything else- 400, 500, etc - show notification bar with message
  * @param HttpErrorResponse error
  */
  handleError(error: any | HttpErrorResponse ) {
    super.handleError(error);
    this.loaderService.hide();
    const authService = this.injector.get(AuthService);
    const errorNotification = this.injector.get(ErrorNotificationService);
    const defaultErrorMessage = `Something went wrong!`;

    if (error && error.url && error.url.search('/login/complete') > -1) {
     return  errorNotification.showErrorPage({
        title: 'CUSTOMER SELECTION',
        message: `There\'s an issue with your account, contact your administrator. (${error.message})`
      });
    }

    if (error instanceof HttpErrorResponse) { // HTTPP request errors
      switch (error.status) {
        case StatusCodes.UNAUTHORIZED: // 401
          // if unauthorized, means auth not valid / missing
          if (window.location.pathname.includes('/dashboard/tasks/detail/')) {
            // this.store.dispatch(new ActionAppSetUrl(window.location.pathname));
          }
          authService.logout(); //  on 401 logout notify the error and redirect to login page
          errorNotification.notifyError(`Session Expired! Please log back in.`, error);
          break;

        case StatusCodes.BAD_REQUEST: // 400
          errorNotification.notifyError('There was an error', {message: `${error.status} - ${error.url}`});
          break;

        case StatusCodes.FORBIDDEN: // 403
          errorNotification.notifyError(`You do not have sufficient permissions to view the resource!`, {message: `${error.status} - ${error.url}`});
          break;

        case StatusCodes.NOT_FOUND: // 404
          errorNotification.showErrorPage({
            title: 'Page Not Found',
            message: 'The page you are trying to access does not exist, please ensure to update your bookmarks.'
          });
          break;

        default:
          errorNotification.notifyError(defaultErrorMessage, {message: `${error.statusText} - ${error.url}`});
          break;
      }
    } else { // Client errors: code errors, js and template errors, etc
      this.DBLogger.saveError(error).subscribe(result => result);
      const fileLineErrorMessage = error.stack && error.stack.split(' at ', 2).join() || error.message;
      errorNotification.notifyError('Something went wrong!', {message: fileLineErrorMessage});
    }
  }
  }
