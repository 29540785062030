import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable()
export class DataService<T> {
  constructor(private _http: HttpClient) { }

  public getAll(endpoint: string): Observable<T[]> {
    return this._http.get(endpoint).pipe(map(resp => resp as T[]));
  }

  getOne(endpoint: string): Observable<T> {
    return this._http.get(endpoint).pipe(map(resp => resp as T));
  }

  public post(endpoint: string, data: T): Observable<T> {
    this.log(endpoint, data);
    return this._http.post(endpoint, data).pipe(map(resp => resp as T));
  }

  public update(endpoint: string, data: T): Observable<T> {
    this.log(endpoint, data);
    return this._http.put(endpoint, data).pipe(map(resp => resp as T));
  }

  public delete(endpoint: string, data: T): Observable<T> {
    this.log(endpoint, data);
    return this._http.delete(endpoint, data).pipe(map(resp => resp as T));
  }

  public deleteWithBody(endpoint: string, data:T): Observable<T>{
    const body = data;
    return this._http.request('delete',endpoint, {body}).pipe(map(resp => resp as T));
  }

  private log(endpoint: string, data?: T) {
    console.log(`Endpoint: ${endpoint}`);
  }
}
