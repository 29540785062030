<mat-card class="metric-info-container">
    <mat-card-content class="metric_content">
        <div class="metric_info_content">
            <div class="metric_info-block">
                <p class="metric_block_heading"> Study Name: </p>
                <p class="metric_block_content"> {{studyName}} </p>
            </div>
            <div class="metric_info-block_2nd">
                <p class="metric_block_heading"> Site #: </p>
                <p class="metric_block_content"> {{siteNo}} </p>
            </div>
            <div class="metric_info-block_3rd">
                <p class="metric_block_heading"> Metric: </p>
                <p class="metric_block_content"> {{metricName}} </p>
            </div>
            <div class="metric_info-block_4th">
                <p class="metric_block_heading"> Metric Threshold: </p>
                <p class="metric_block_content"> {{metricThreshold}} </p>
            </div>
            <div class="metric_info-block_right">
                <p class="metric_block_heading"> Value Min/Max: </p>
                <p class="metric_block_content"> {{valueMinMax}} </p>
            </div>
            <div class="metric_info-block">
                <p class="metric_block_heading"> Protocol #: </p>
                <p class="metric_block_content"> {{protocolNo}} </p>
            </div>
            <div class="metric_info-block_2nd">
                <p class="metric_block_heading"> PI Name: </p>
                <p class="metric_block_content"> {{piName}} </p>
            </div>
            <div class="metric_info-block_3rd">
                <p class="metric_block_heading"> Category: </p>
                <p class="metric_block_content"> {{category}} </p>
            </div>
            <div class="metric_info-block_4th">
                <p class="metric_block_heading"> Metric Weight: </p>
                <p class="metric_block_content"> {{metricWeight}} </p>
            </div>
            <div class="metric_info-block_right">
                <p class="metric_block_heading"> Metric Value: </p>
                <p class="metric_block_content"> {{metricValue}} </p>
            </div>
        </div>
    </mat-card-content>
</mat-card>