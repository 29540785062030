import * as Customer from './customer.state';
import { CustomerActionTypes, CustomerActions } from './customer.actions';

export const initialState: Customer.CustomerState = Customer.loadInitialAuthState();

export function CustomerReducer(state = initialState, action: CustomerActions): Customer.CustomerState {
  switch (action.type) {
    case CustomerActionTypes.LOAD_CUSTOMERS: {
      return {
        ...state, customers: action.payload.customers,
        multiSelect: action.payload.select,
        isCustomerSelected: action.payload.isCustomerSelected
      };
    }
    // case CustomerActionTypes.SELECT_CUSTOMER: {
    //   console.log(state.customers);
    //   return { ...state, isSelectCustomer: true};
    // }

    case CustomerActionTypes.SELECT_CUSTOMER_SUCCESS: {
      return {
        ...state,
        customers: state.customers.filter(customer => customer.clientId === action.payload.clientId),
        multiSelect: false,
        isCustomerSelected: true
      };
    }

    case CustomerActionTypes.SELECT_CUSTOMER_WITH_NO_DB: {
      return {
        ...state,
        customers: state.customers.filter(customer => customer.navigatorOrgName === action.payload.navigatorOrgName ),
        multiSelect: false,
        isCustomerSelected: false
      };
    }

    case CustomerActionTypes.SHOW_MULTI_CUSTOMER: {
      return { ...state,
        customers: JSON.parse(sessionStorage.getItem('RBM-AUTH')).custList,
         isCustomerSelected: false, multiSelect: true };
    }

    case CustomerActionTypes.HIDE_MULTI_CUSTOMER: {
      return { ...state, isCustomerSelected: false };
    }

    case CustomerActionTypes.RESET_CUSTOMER: {
      return {...state, ...Customer.defaultState};
    }

    case CustomerActionTypes.CREATE_CUSTOMER: {
      return { ...state, customers: action.payload.customers, isCustomerSelected: true};
    }

    default:
      return state;
  }
}
