import { ActionAppSetAlerts } from './../../../core/store/app.actions';
import { Store } from '@ngrx/store';
import { DataService } from '@shared/services';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EndpointService } from '@app/shared/services';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'ert-observation-modal',
  templateUrl: './observation-modal.component.html',
  styleUrls: ['./observation-modal.component.scss']
})
export class ObservationModalComponent implements OnInit {
  createAlertForm: UntypedFormGroup;
  buttonText = 'Create Alert';
  buttonActive = false;

  constructor(
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<ObservationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private endpointService: EndpointService,
    private dataService: DataService<any>,
    private store: Store<any>
  ) { }

  ngOnInit() {
    this.createAlertForm = new UntypedFormGroup({
      title: new UntypedFormControl('', Validators.required),
      description: new UntypedFormControl('', Validators.required),
    });
  }

  onFormSubmit() {
    if (this.createAlertForm.valid) {
      const observation = {
        title : this.createAlertForm.controls['title'].value,
        msg : this.createAlertForm.controls['description'].value,
      };
      if (this.data.siteId) {
        observation['srcSiteId'] = this.data.siteId;
      }
      if (this.data.studyId) {
        observation['srcStudyId'] = this.data.studyId;
      }

      const endpoint = this.endpointService.getEndpoint('observation');
      this.dataService.post(endpoint, observation).subscribe(response => {
        this.toastr.success('Alert generated', 'Success');
        this.checkAlerts();
        this.dialogRef.close();
      }, (err) => {
        if (err.status === 401) { this.dialogRef.close(); throw err; }
      });
    }
  }

  private checkAlerts() {
    const alertEndpoint = this.endpointService.getEndpoint('alerts');
      this.dataService.getAll(alertEndpoint).subscribe((alerts: any) => {
        const count = alerts.result.filter(items => items.read !== true).length;
        this.store.dispatch(new ActionAppSetAlerts(count));
      }, (err) => {
        if (err.status === 401) { this.dialogRef.close(); throw err; }
      });
  }


}
