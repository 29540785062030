<ert-stat-block *ngIf = "siteId" class="site__stat-block" ></ert-stat-block>

<mat-card class="dashboard__card detail__line-chart">
  <mat-card-title>
    <h4>{{metricName}}</h4>
  </mat-card-title>
  <div *ngIf="alertPermCheck">
<ert-observation [studyId]="studyId" [siteId]="siteId"></ert-observation>
  </div>
  <mat-spinner *ngIf="is_valid === 'unchecked'" diameter="50"></mat-spinner>
  <div *ngIf="(is_valid === 'invalid')" class="es-status-sign">
    <span class="es-icon es-icon-magnifier"></span>
    <span class="text">No Data to display</span>
  </div>
  <div *ngIf="(is_valid === 'valid')">
    <ert-ms-line-chart [data]="chartOptions" [height]="210" [yAxisLabel]="yAxisName"></ert-ms-line-chart>
  </div>
</mat-card>

<mat-card class="dashboard__card detail__line-chart">
  <mat-card-title>
    <h4>{{metricCategory}}</h4>
  </mat-card-title>
  <div *ngIf="alertPermCheck">
  <ert-observation [studyId]="studyId" [siteId]="siteId"></ert-observation>
  </div>
  <mat-spinner *ngIf="is_valid === 'unchecked'" diameter="50"></mat-spinner>
  <div *ngIf="(is_valid === 'valid')">
    <ert-ms-line-chart [data]="kriAggr" [height]="210" [yAxisLabel]="kriValType"></ert-ms-line-chart>
  </div>
  <div *ngIf="is_valid === 'invalid'" class="es-status-sign">
    <span class="es-icon es-icon-magnifier"></span>
    <span class="text">No Data to display</span>
  </div>
</mat-card>

<div *ngIf="permCheck">
  <mat-card>
    <h4>Site Comparison
        <span *ngIf="alertPermCheck">
        <ert-observation [studyId]="studyId" [siteId]="siteId"></ert-observation>
        </span>
    </h4>
    <div class="tree-container">
      <ert-treemap-chart *ngIf="sites" (onClick)="onTreemapSiteClick($event)" [data]="sites"
        [titleDataItems]="titleDataItems" [tooltipDataItems]="tooltipDataItems" [valueItem]="valueItem"
        [scoreItem]="scoreItem" [height]=200 [fontSize]=12>
      </ert-treemap-chart>
      <div *ngIf="!sites" class="es-status-sign">
        <span class="es-icon es-icon-magnifier"></span>
        <span class="text">No Data to display</span>
      </div>
    </div>
  </mat-card>
</div>

<mat-card class="dashboard__card">
  <mat-card-title>
    <h4>{{metricCategory}} Data</h4>
  </mat-card-title>
  <div style="margin-top: -10px;" class="details__table-control-container">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
    </mat-form-field>
    <mat-paginator #paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]" class="details__table-control">
    </mat-paginator>

  </div>
  <mat-table *ngIf="displayedData" #table [dataSource]="displayedData" class="ert-table" matSort>
    <ng-container *ngFor="let colName of displayedColumns; let colIndex = index" matColumnDef="{{colName}}">
      <mat-header-cell [style.flex]="getFlexStyle(colIndex)" *matHeaderCellDef mat-sort-header>
        {{formatHeading(colIndex)}}</mat-header-cell>
      <mat-cell [style.flex]="getFlexStyle(colIndex)" *matCellDef="let element ">
        <span [matTooltip]="showTooltip(element, colName).text"
          [matTooltipDisabled]="showTooltip(element, colName).isDisabled">{{formatData(element, colName) | removeUnderscore | titlecase}}</span>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

</mat-card>

<mat-card>
  <ert-event-list-table></ert-event-list-table>
</mat-card>
