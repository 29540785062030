import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { eventListTableData } from '@app/mockdata';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'ert-event-list-table',
  templateUrl: './event-list-table.component.html',
  styleUrls: ['./event-list-table.component.scss']
})
export class EventListTableComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = ['siteno', 'pi', 'metricValue', 'description', 'action'];
  displayedData;
  eventDataCheckFlag: string = 'unchecked';

  constructor() { }

  ngOnInit() {
    // TODO: add style to table when injecting real data
    if (eventListTableData) this.eventDataCheckFlag = 'valid';
    else this.eventDataCheckFlag = 'invalid';
    this.initEventTable();
  }

  initEventTable() {
    this.displayedData = new MatTableDataSource(eventListTableData);
    this.displayedData.paginator = this.paginator;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.displayedData.sort = this.sort;
      this.displayedData.sortingDataAccessor = (datas: any, header: string): any => {
        if (header === 'study') {
          return datas[header].trim().toLocaleLowerCase();
        } else {
          if (parseInt(datas[header]) === NaN) { return }
          else return datas[header];
        }
      };
    }, 2000);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.displayedData.filter = filterValue;
  }

}
