
import { ErrorHandlerService } from './core/services/error-handler.service';
import { DashboardModule } from './dashboard/dashboard.module';
import { CoreModule } from './core/core.module';
import { EndpointService } from '@shared/services';
import { HttpDataInterceptor } from './core/services/http-data-interceptor.service';
import { ErrorNotificationService } from './core/services/error-notification.service';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule, Inject } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './core/app.component';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { StoreRouterConnectingModule, RouterStateSerializer, FullRouterStateSerializer } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './core/store/auth/auth.effects';
import { StoreModule, Store } from '@ngrx/store';
import { reducers } from './core/store/main.reducer';

import { SharedModule } from '@app/shared/shared.module';
import { SelectSiteComponent } from './login/select-site/select-site.component';
import { ErrorComponent } from './error/error.component';
import { ToastrModule } from 'ngx-toastr';
import { AppHeaderComponent } from '@app/shared/app-header/app-header.component';
import { FooterDialogComponent } from './footer-dialog/footer-dialog.component';
import { SpinnerService } from './core/services/spinner.service';
import { TaskMessageListComponent } from './tasks/message-list.component';
import { TaskMessageDetailComponent } from './tasks/message-detail/message-detail.component';
import { TaskCompleteComponent } from './tasks/modal/task-complete/task-complete.component';
import { AlertMessageDetailComponent } from './alerts/message-detail/message-detail.component';
import { GenerateTaskComponent } from './alerts/modal/generate-task/generate-task.component';
import { AlertMessageListComponent } from './alerts/message-list.component';
import { clearState } from './core/store/auth/auth.reducer';
import { ToAdminModule } from './to-admin/to-admin.module';
import { AddUserModalComponent } from './settings/admin/user-management/add-user-modal/add-user-modal.component';
import { UserIdleModule } from 'angular-user-idle';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { TableauDownloadDialogComponent } from './shared/modals/tableau-download-dialog/tableau-download-dialog.component';
import { CrossTabDownloadDialogComponent } from './shared/modals/tableau-crosstab-download-dialog/tableau-crossstab-download-dialog.component';
import { ReportComponent } from './report/report.component';
import { CrTooltipModule } from '@crystal/ng-tooltip';
import { CrIconModule } from '@crystal/ng-icon';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        SelectSiteComponent,
        ErrorComponent,
        AppHeaderComponent,
        ErrorComponent,
        FooterDialogComponent,
        AddUserModalComponent,
        TaskMessageListComponent,
        TaskMessageDetailComponent,
        TaskCompleteComponent,
        AlertMessageDetailComponent,
        GenerateTaskComponent,
        AlertMessageListComponent,
        AuthCallbackComponent,
        TableauDownloadDialogComponent,
        CrossTabDownloadDialogComponent,
        ReportComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        SharedModule,
        CoreModule,
        DashboardModule,
        ToAdminModule,
        StoreModule.forRoot(reducers, { metaReducers: [clearState] }),
        StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer }),
        StoreDevtoolsModule.instrument({ maxAge: 50, logOnly: environment.production }),
        EffectsModule.forRoot([AuthEffects]),
        AppRoutingModule,
        UserIdleModule.forRoot({idle: 900, timeout: 900, ping: 120}),
        ToastrModule.forRoot({
            timeOut: 3000,
            preventDuplicates: true
        }),
        CrTooltipModule,
        CrIconModule
    ],
    exports: [
        BrowserAnimationsModule,
    ],
    providers: [
        EndpointService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpDataInterceptor,
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: ErrorHandlerService,
        },
        ErrorNotificationService,
        SpinnerService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
