import { KriCategoryTableService } from './../../shared/kri-category-table/kri-category-table.service';
import { UtilsService } from './../../shared/services/utils.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { SetBreadCrumbs } from '@core/store/breadcrumbs/breadcrumbs.actions';
import { UpdateToolbar } from '@core/store/toolbar/toolbar.actions';
import { StudyService } from '@app/shared/services/study.service';
import { KriViews } from '@app/shared/kri-category-table/enum.kri-categories';
import { forkJoin, of } from 'rxjs';
import { PermissionService } from '@app/shared/services/permisssion.service';
import { kriOverviewChart } from '@app/mockdata';
import { sitesByScoreSchart } from '@app/mockdata';
import { metricCategory, Category } from '@app/shared/models/metric-category.type';
import { BreadCrumbOption, GlobalHeaderService } from '@app/shared/services/global-header.service';
import { BreadcrumbConfigService } from '@app/shared/services/breadcrumb-config.service';
import { isEmptyObject } from '@app/shared/helpers/util';

export interface Site {
  name: string;
  value: number;
}

@Component({
  selector: 'ert-study',
  templateUrl: './study.component.html',
  styleUrls: ['./study.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class StudyComponent implements OnInit {
  siteCtrl: UntypedFormControl;
  views = KriViews;
  studyId: number;
  sites;
  sitesListing;
  bucketsScoreHistory: any;
  loadingSites = true;
  breakpoint;
  statData: any;
  hasPermission: any;
  studiesTableSource: any[] = [];
  displayedColumns = [];
  detailsDataColumns = {};
  calcTypes = [];
  studyStartDate;
  lastUpdatedDate;
  alertPermCheck = false;
  bucketName = ['compliance', 'quality'];
  complianceLength = 0;
  qualityLength = 0;
  dataCheck = 'unchecked';
  studyViewPerm = false;
  chartData;
  sitesByScoreData;
  selectedCategory = "all";
  studyInfoData;
  metricCategories = [
    new Category('compliance', 'Compliance'),
    new Category('quality', 'Quality')
  ];
  metricbyScoreData: any;

  metricsGridHeight: string;
  metricsConfig = {
    compliance: { data: [], barColor: '#003865', title: 'Compliance Metrics by Score', showData: true },
    quality: { data: [], barColor: '#93328E', title: 'Quality Metrics by Score', showData: true },
  };

  cOptions: any = {
    quality: {
      legend: 'Quality Score',
      units: 'percentage',
      lineWidth: 3,
      curveType: 1,
      dotRadius: 6,
      color: '#90368C',
      showProjection: true,
    },
    compliance: {
      legend: 'Compliance Score',
      units: 'percentage',
      lineWidth: 3,
      curveType: 1,
      color: '#1692BA',
      showProjection: true,
    },
    projection: {
      lineWidth: 3,
      units: 'percentage',
      curveType: 1,
      color: '#494a4b',
      legend: 'Projected',
      dashPattern: '10,10',
    }
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<any>,
    private studyService: StudyService,
    private kriCategoryService: KriCategoryTableService,
    private permService: PermissionService,
    private globalHeaderService: GlobalHeaderService,
    private brdCrmbConfig: BreadcrumbConfigService
  ) {
    this.route.params.subscribe(params => {
      this.studyId = params.studyId;
    });
  }

  checkPermissions(permission) {
    // check if user doesn't have access to view observation component for studyView
    if (permission === 'editStudy') {
      return this.alertPermCheck = this.permService.hasPermission('editStudy', { studyId: this.studyId }) || this.permService.hasRole('vp')
        || this.permService.hasRole('studyAdmin') || this.permService.hasRole('studyView');
    }
    // check is user has permissions to view the KRI/Milestone info
    if (permission === 'viewStudy') {
      return this.alertPermCheck = this.permService.hasPermission('viewStudy', { studyId: this.studyId })
        || this.permService.hasRole('studyAdmin') || this.permService.hasRole('studyView');
    }
    return false;
  }

  ngOnInit() {
    const { STUDY_VIEW } = this.views;

    forkJoin(
      this.studyService.getStudyBucketScores(this.studyId).toPromise(),
      this.studyService.getStudyById(this.studyId).toPromise(),
      this.studyService.getSitesListing(this.studyId).toPromise(),
      this.studyService.getStudyEventsByLocation(this.studyId).toPromise(),
      this.studyService.eventsListByStudy(this.studyId).toPromise(),
      this.studyService.getStudyBucketScoresHistory(this.studyId, true).toPromise(),
      // this.checkPermissions('viewStudy') && this.studyService.getMilestoneValues(this.studyId).toPromise() || of(null),
      // this.checkPermissions('viewStudy') && this.studyService.getKpiBucketCalcs(this.studyId, 'kpi').toPromise() || of(null),
    ).subscribe(([bucketScores, studyById, sitesListing, studyEventsByLocation, eventsByStudy, bucketsScoreHistory]) => {
      this.studyInfoData = studyById;
      this.setupBreadcrumbs(studyById);
      this.updateToolbarScores(bucketScores);
      this.sitesByScoreData = this.formatDataForScatterPlotChart(eventsByStudy);
      this.getMetricData(this.studyId, 'all');
      this.sites = studyEventsByLocation;
      this.setStudySiteListingTable(sitesListing);
      this.loadingSites = false;

      this.bucketsScoreHistory = this.setBucketScoresHistoryData(bucketsScoreHistory);

      // this.setupMetricsChartData(studyBechmark);

      this.breakpoint = (window.innerWidth <= 768) ? 1 : 2;
    });




    // function formatMilestone(milestone) {
    //   return {
    //     targetDate: getUTCDates(new Date(milestone.expectedDate)),
    //     // actual and projected dates might not be calculated yet
    //     actualDate: milestone.actualDate ? getUTCDates(new Date(milestone.actualDate)) : null,
    //     projectedDate: milestone.projectedDate ? getUTCDates(new Date(milestone.projectedDate)) : null,
    //     name: milestone.milestoneType,
    //     shortName: milestone.milestoneShortName,
    //     lastUpdatedDate: getUTCDates(new Date(milestone.lastUpdatedDate))
    //   };
    // }

    // function getUTCDates(date) {
    //   return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),12));
    // }

    // // sort in order of target date
    // function sortMilestones(m1, m2) {
    //   return m1.targetDate > m2.targetDate;
    // }

    // const orderedCalcTypes = ['Site Activation', 'Subjects Screened', 'Subjects Screen Failed', 'Screen Failure Rate',
    //   'Enrollment', 'Study Discontinuations', 'Query Response', 'Protocol Deviation Rate'];

    // // sort in order of target date
    // function sortKpis(calc1, calc2) {
    //   return orderedCalcTypes.indexOf(calc1['calcTypeName']) > orderedCalcTypes.indexOf(calc2['calcTypeName']);
    // }

    // function setupToolbar(study, sites) {
    //   let permissionItem = JSON.parse(sessionStorage.getItem('RBM-AUTH')) || {};
    //   permissionItem = permissionItem.userAccess.permissions;
    //   if (permissionItem) {
    //     const studyId = permissionItem.viewSite[0].studyId;
    //     if ((permissionItem.viewSite[0].sites) && (permissionItem.viewSite[0].sites.length === 1
    //       || permissionItem.viewSite[0].sites.length > 1)
    //       && !(permissionItem.viewStudy && permissionItem.viewStudy.length > 1)) {
    //       this.store.dispatch(new SetBreadCrumbs({
    //         crumbs: [{ text: 'Portfolio', url: sessionStorage.getItem('HOMEPAGE') },
    //           { text: study.protocolNumber || study.studyName, url: '/dashboard/study/' + this.studyId, tooltipText: study.studyName  }],
    //       }));
    //     } else {
    //       this.store.dispatch(new SetBreadCrumbs({
    //         crumbs: [{ text: 'Portfolio', url: sessionStorage.getItem('HOMEPAGE') },
    //           { text: study.protocolNumber || study.studyName, url: '/dashboard/study/' + this.studyId, tooltipText: study.studyName }],
    //       }));
    //     }
    //   }
    // }

    // this.studyService.getSiteStudiesBenchmark(this.studyId).subscribe(study => {
    // if (!study.length) { return; }
    // Adding Rank to sites
    // study[0].sites.sort((a,b) => {return a.srcSiteId - b.srcSiteId});
    // study[0].sites.sort((a,b) => {return b.compositeScore - a.compositeScore});
    // var rank = 1;
    // for(var i in study[0].sites) {
    //   study[0].sites[i].rank = rank;
    //   rank++;
    // }

  }

  setupBreadcrumbs(study) {
    this.globalHeaderService.getHeader().then((header) => {
      header.addBreadcrumbs([
        this.brdCrmbConfig.getHomeBrdCrmb(),
        this.brdCrmbConfig.getPortfolioBrdCrmb(),
        this.brdCrmbConfig.getStudyBrdCrmb(this.studyId, study.studyName)
      ]);
      header.subscribe('logout', this.globalHeaderService.logoutHandler());
      header.subscribe('session-expired', this.globalHeaderService.sessionExpHandler());
    })
  }

  setBucketScoresHistoryData(bucketsScoreHistory) {
    let bucketsScoreHistoryArr = [];
    if (!isEmptyObject(bucketsScoreHistory)) {
      bucketsScoreHistoryArr = [
        {
          ...bucketsScoreHistory.bucket_quality_score,
          ...this.cOptions.quality
        },
        {
          ...bucketsScoreHistory.bucket_compliance_score,
          ...this.cOptions.compliance
        },
        {
          ...this.cOptions.projection
        }
      ];
    }


    return bucketsScoreHistoryArr;
  }

  setStudySiteListingTable(sitesListing) {
    const { STUDY_VIEW } = this.views;

    this.sitesListing = sitesListing;
    this.studiesTableSource = this.sitesListing.map(site => this.kriCategoryService.formatDataTable(STUDY_VIEW, site));

    if (this.studiesTableSource.length > 0) this.dataCheck = 'valid';
    else this.dataCheck = 'invalid';

    this.displayedColumns.push(
      'site',
      'pi',
      // 'siteName',
      'subjects',
      'bucketComplianceScore',
      'bucketQualityScore',
      'activeEvents',
      'eventsPerSubject',
    );
  }

  updateToolbarScores(bucketScores) {
    let compScore = null, qualScore = null;
    for (var i in bucketScores) {
      if (bucketScores[i].internalName === 'bucket_compliance_score') {
        compScore = bucketScores[i].calcLongDisplayVal;
      } else if (bucketScores[i].internalName === 'bucket_quality_score') {
        qualScore = bucketScores[i].calcLongDisplayVal;
      }
    }
    this.store.dispatch(new UpdateToolbar({
      title: 'My Study',
      study: {
        studyId: this.studyId,
        odsReportsEnabled: this.studyInfoData.odsReportsEnabled,
      },
      site: {
        siteId: null,
      },
      bucket: {
        complianceScore: compScore ? parseInt(compScore) : null,
        qualityScore: qualScore ? parseInt(qualScore) : null,
      }
    }));
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 768) ? 1 : 2;
  }

  onSelectCategory($event){
    this.getMetricData(this.studyId,$event);
  }

  getMetricData(studyId: number, bucketName: metricCategory){
    this.studyService.getMetricsByScore(studyId, bucketName).subscribe(ms => {
      this.metricbyScoreData = ms;
    });
  }

  setupMetricsChartData(studiesMetrics) {
    const studyMetrics = studiesMetrics.find((metric) => metric.srcStudyId === +this.studyId) || {};

    studyMetrics.calcTypeBucketExtraData.forEach(metricData => {
      const metricConfigNames = Object.keys(this.metricsConfig);
      if (metricConfigNames.includes(metricData.calcTypeBucketName)) {
        metricData.calcTypes.sort((a, b) => (a.score > b.score) ? 1 : -1)
          .forEach(metricCalcData => {
            if (metricCalcData.isErtData && metricCalcData.score != null) {
              this.metricsConfig[metricData.calcTypeBucketName].data
                .push({ names: metricCalcData.calcTypeName, values: metricCalcData.score });
            }
          });
      }
    });

    this.metricsConfig.compliance.showData = !!this.metricsConfig.compliance.data.length;
    this.metricsConfig.quality.showData = !!this.metricsConfig.quality.data.length;
    this.metricsGridHeight = (this.metricsConfig.compliance.showData || this.metricsConfig.quality.showData) ? '340px' : '180px';
  }

  getKriLength(data) {
    if (data && data.calcTypeBucketExtraData) {
      data.calcTypeBucketExtraData.forEach(element => {
        // if(element && element.calcTypeBucketName === 'enrollment') {
        //   this.enrollmentLength = Math.max(this.enrollmentLength, element.calcTypes.length);
        // }
        if (element && element.calcTypeBucketName === 'compliance') {
          this.complianceLength = Math.max(this.complianceLength, element.calcTypes.length);
        }
        // if(element && element.calcTypeBucketName === 'safety') {
        //   this.safetyLength = Math.max(this.safetyLength, element.calcTypes.length);
        // }
        if (element && element.calcTypeBucketName === 'quality') {
          this.qualityLength = Math.max(this.qualityLength, element.calcTypes.length);
        }
      });
    }
  }

  formatDataForScatterPlotChart(eventsListByStudyAPIData) {
    //Reformat sites to group same compliance and quality values
    let formatedData = [];
    for (var i in eventsListByStudyAPIData) {
      //When there is no score for both compliance and quality then ignore the record
      if(!eventsListByStudyAPIData[i].bucket_quality_score && !eventsListByStudyAPIData[i].bucket_compliance_score) {
        continue;
      }
      //return true if the same compliance and quality score exists
      let existsScoreStudy = formatedData.find(site => {
        for (var j in formatedData) {
          if (formatedData[j].quality == eventsListByStudyAPIData[i].bucket_quality_score && formatedData[j].compliance == eventsListByStudyAPIData[i].bucket_compliance_score) {
            //if same score exists then update site array
            formatedData[j].site.push({
              study_id: eventsListByStudyAPIData[i].srcStudyId,
              no: eventsListByStudyAPIData[i].siteNo,
              id: eventsListByStudyAPIData[i].srcSiteId,
              pi: eventsListByStudyAPIData[i].investigator
            });
            return true;
          }
        }
        return false;
      });
      if (!existsScoreStudy) {
        formatedData.push({
          site: [
            {
              study_id: eventsListByStudyAPIData[i].srcStudyId,
              no: eventsListByStudyAPIData[i].siteNo,
              id: eventsListByStudyAPIData[i].srcSiteId,
              pi: eventsListByStudyAPIData[i].investigator
            }
          ],
          compliance: eventsListByStudyAPIData[i].bucket_compliance_score,
          quality: eventsListByStudyAPIData[i].bucket_quality_score
        });
      }
    }
    return formatedData;
  }

}
