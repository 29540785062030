// import { VisualInteractions } from '@app/shared/models/visual-interactions/interactions.model';

export interface SingleVisualInteraction {
  studyId: number;
  siteIds?: [];
  metricIds?: [];
}


export interface VisualInteractionsState {
  selectedStudiesData: SingleVisualInteraction[];
}


export const defaultState: VisualInteractionsState = {
  selectedStudiesData: []
}

export const initializeVisualInteractionState = function () {
  return defaultState;
};
