import { Component, OnInit, Input, AfterContentInit, ViewChild, ElementRef, OnDestroy, ViewEncapsulation } from '@angular/core';
import * as d3 from 'd3';
import { UtilsService } from '@app/shared/services/utils.service';

@Component({
  selector: 'ert-heatmap',
  templateUrl: './heatmap.component.html',
  styleUrls: ['./heatmap.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeatmapComponent implements OnInit, AfterContentInit {
  @ViewChild('heatmap', { static: true, read: ElementRef })
  private chartContainer: ElementRef;
  @Input() private mapData;
  @Input() private studyId;
  @Input() private siteId;
  @Input() private metricId;
  margin = { top: 10, right: 40, bottom: 10, left: 0 };
  padding = 30;
  firstDraw: boolean = true;

  constructor() { }

  ngOnInit() {
  }

  ngAfterContentInit() {
    if(this.mapData.siteData) {
      this.drawChart();
    }
    this.firstDraw = false;
    window.addEventListener('resize', () => {
      if(this.mapData.siteData) {
        this.drawChart.bind(this);
      }
    });
  }

  drawChart() {
    // Load data and initialization
    const mapData = this.mapData.siteData;
    const units = this.mapData.units;
    const xValues = this.mapData.xValues;
    const yValues = this.mapData.yValues;
    const element = this.chartContainer.nativeElement;

    // Redrawing for everytime the window is resized
    if (!this.firstDraw) {
      d3.select(element).selectAll('svg').remove();
    }

    // Initializing width and height
    const width = window.innerWidth - 100;
    let height;
    if (mapData.length < this.mapData.rows) height = 75 * this.mapData.columns;
    else height = 50 * this.mapData.columns;

    const contentWidth = width - this.margin.left - this.margin.right;
    const contentHeight = height - this.margin.top - this.margin.bottom;

    // Appending svg to main element
    var svg = d3.select(element).append('svg')
      .attr('width', width)
      .attr('height', height)

    var g = svg.append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')')
      .attr('class', 'plot');

    // Build X and Y scales
    var x = d3.scaleBand()
      .range([0, contentWidth])
      .domain(xValues)
      .padding(0.05);

    var y = d3.scaleBand()
      .range([contentHeight, 0])
      .domain(yValues)
      .padding(0.05);

    // Build the tooltip
    var tooltip = d3.select('body')
      .append('div')
      .attr('id', 'heatmap_tooltip')
      .attr('class', 'heatmap_tooltip')
      .style('position', 'absolute')
      .style('z-index', '10')
      .style('opacity', 0)
      .style('background', '#dedede')
      .style('font-size', '12px')
      .style('line-height', '1.6')
      .style('transform', 'translate(-50%, 1em)')
      .style('transition', 'all .15s ease-in-out')
      .style('padding', '2px 4px')
      .style('border-radius', '3px')
      .style('font-family', '"Noto Sans"');

    // Tooltip functions
    // If mouse is on tooltip, tooltip stays, and so does the highlight on box
    // If mouse leaves tooltip and container, everything is restored
    var mouseover = (d, i, n) => {
      tooltip
        .transition()
        .duration(200)
      tooltip.html(() => { return this.getTooltipHTML(d, units) })
        .style('left', (d3.event.pageX) + 'px')
        .style('top', (d3.event.pageY + 15) + 'px')
        .style('opacity', 0.9)
        .on('mouseleave', (d) => {
          tooltip.style('opacity', 0).html('');
          d3.select(n[i])
            .style('stroke', (d) => {
              if (d.srcSiteId === parseInt(this.siteId)) return 'black';
              else 'none';
            })
            .style('opacity', (d) => {
              if (d.srcSiteId === parseInt(this.siteId)) return 1;
              else return 0.5;
            })
        })
        .on('mouseover', () => {
          tooltip.style('opacity', 0.9)
          d3.select(n[i])
            .style('stroke', 'black')
            .style('opacity', 1)
        })

      d3.select(n[i])
        .style('stroke', 'black')
        .style('opacity', 1)
    }

    var mouseleave = (d, i, n) => {
      d3.select(n[i])
        .style('stroke', (d) => {
          if (d.srcSiteId === parseInt(this.siteId)) return 'black';
          else 'none';
        })
        .style('opacity', (d) => {
          if (d.srcSiteId === parseInt(this.siteId)) return 1;
          else return 0.5;
        });
      tooltip.style('opacity', 0);
    }


    // Build the map
    g.selectAll()
      .data(mapData, d => { return d.x + ':' + d.y })
      .enter()
      .append('rect')
      .attr('x', d => { return x(d.x) })
      .attr('y', d => { return y(d.y) })
      .attr('rx', 4)
      .attr('ry', 4)
      .attr('width', x.bandwidth())
      .attr('height', y.bandwidth())
      .style('fill', d => { return UtilsService.getColorFromScore(d.score); })
      .style('stroke-width', 4)
      .style('stroke', (d) => {
        if (d.srcSiteId === parseInt(this.siteId)) return 'black';
        else 'none';
      })
      .style('opacity', (d) => {
        if (d.srcSiteId === parseInt(this.siteId)) return 1;
        else return 0.5;
      })
      .on('mouseover', mouseover)
      .on('mouseleave', mouseleave)
      .on('mousemove', () => {
        tooltip.style('left', (d3.event.pageX) + 'px')
        .style('top', (d3.event.pageY + 15) + 'px')
      });

    // Annotate the heatmap boxes
    g.selectAll()
      .data(mapData, d => { return d.score })
      .enter()
      .append('text')
      .attr('x', d => {
        if (d.score === 100) return x(d.x) + 15;
        else if (d.score > 9) return x(d.x) + 20;
        else if (d.score !== null) return x(d.x) + 25;
        else return x(d.x) + 25;
      })
      .attr('y', d => { return y(d.y) + 25 })
      .style('text-align', 'center')
      .text(d => {
        if (d.score !== null) return d.score;
        else return '--';
      })
      .on('mouseover', () => { tooltip.style('opacity', 0.9); })
      .on('mouseleave', () => { tooltip.style('opacity', 0); });
  }

  getTooltipHTML(d, units) {
    let location = `/dashboard/study/${this.studyId}/site/${d.srcSiteId}/metric/${this.metricId}`;

    return `<div class="heatmap_tp"><span style="color:#1692ba"><a href="${location}"><b>Site#: </b>  ${d.siteNo}  </a></span><br>
    <span style="color:black"><b>Metric Score: </b>  ${d.score}  <br>
    <b>Metric Value: </b>  ${d.metricValue}</span> </div>`;
  }
}
