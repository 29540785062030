import { Injectable, NgZone } from '@angular/core';
import { BreadcrumbConfigService } from './breadcrumb-config.service';
import { environment } from '@env/environment';
import { Logout } from '@app/core/store/auth/auth.reducer';
import { Store } from '@ngrx/store';

export interface BreadCrumbOption {
  label: string;
  handler?: Function;
}

interface GlobalHeader {
  clientId?: string;
  publish(event: string, ...args: any[]): boolean;
  subscribe(event: string, fn: Function): boolean;
  addBreadcrumbs(options: BreadCrumbOption[]): void;
  registerClientRoot(handle: Function);
  redirect(clientId: string, useCase: string, data: object): Promise<any>;
}

declare global {
  interface Window {
    ertGlobalHeader: GlobalHeader;
    ertGlobalHeaderReady(): any;
  }
}

interface ERTGlobalHeaderWindow extends Window {
  ertGlobalHeader: GlobalHeader;
  ertGlobalHeaderReady(): any;
}

@Injectable({
  providedIn: 'root'
})
export class GlobalHeaderService {

  autoRedirectDisabledFlag: boolean = false;

  gw = window as ERTGlobalHeaderWindow;

  headerScriptURL = {
    'LOCAL': 'https://gsso-03.int.ert.com/usp/header.js',
    'DEV': 'https://gsso-03.dev.ert.com/usp/header.js',
    'INT': 'https://gsso-03.int.ert.com/usp/header.js',
    'VAL': 'https://gsso.val2.ert.com/usp/header.js',
    'PROD': 'https://gsso.ert.com/usp/header.js'
  };

  constructor(private brdCrmbConfig: BreadcrumbConfigService,
    private store: Store<any>,
    private ngZone: NgZone) { }

  public getHeader(): Promise<GlobalHeader> {
    if (environment.envName === 'LOCAL') this.autoRedirectDisabledFlag = true; // Set this flag to true for local development

    if (this.gw.ertGlobalHeader) {
      return Promise.resolve(this.gw.ertGlobalHeader);
    }

    return new Promise((resolve) => {
      this.gw.ertGlobalHeaderReady = () => {
        resolve(this.gw.ertGlobalHeader);
        return {
          productCode: 'datainsights',
          autoRedirectDisabled: this.autoRedirectDisabledFlag
        };
      }
      this.loadScript(this.headerScriptURL[environment.envName]);
    });
  }

  logoutHandler() {
    const handler = () => {
      this.ngZone.run(() => {
        sessionStorage.clear();
        console.log('Clearing session Storage');
      });
    };
    return handler;
  }

  sessionExpHandler() {
    const handler = () => {
      this.ngZone.run(() => {
        sessionStorage.clear();
      });
    };
    return handler;
  }

  private loadScript(scriptSource) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = scriptSource;
    script.id = 'ert-global-header-script';
    script.setAttribute('data-client-id', 'datainsights');
    document.body.appendChild(script);
  }
}
