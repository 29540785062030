import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy, ChangeDetectorRef} from '@angular/core';
import { GlobalHeaderService } from '@app/shared/services/global-header.service';
import { BreadcrumbConfigService } from '@app/shared/services/breadcrumb-config.service';
import { forkJoin, from, of, Subject } from 'rxjs';
import { takeUntil, mergeMap, catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';

@Component({
  selector: 'ert-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit, OnDestroy  {
  private unsubscribe$: Subject<void> = new Subject<void>();
  isTableau;

  constructor(
    private cdr: ChangeDetectorRef,
    private store: Store<any>
  ) { }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  
  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  public onRouterOutletActivate(event : any) {
    this.isTableau = (event.name == "Tableau") ? true : false;;
  }
}

