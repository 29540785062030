import { Injectable } from '@angular/core';
import { AuthService } from '@app/core/services/auth.service';
import { DataService, EndpointService } from '@app/shared/services';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  constructor(
    private authService: AuthService,
    private endpointService: EndpointService,
    private dataService: DataService<any>
  ) {}

  /**
   * Show if customer DB exists:
   *  if no clientId(customer doesn't exist) and there's a navigatorOrgName
   */
  customerDIStatus(customers) {
    let customerInfo;
    if (customers && customers.length == 1) {
      customerInfo = customers[0];
    } else {
      customerInfo = this.authService.getOrganization();
    }

    return {
      toBeEnabled: !!(customerInfo && !customerInfo.clientId), // customer does not exist
      navigatorOrgName: (customerInfo && customerInfo.navigatorOrgName) || null,
      customer: customerInfo || {},
      orgId: (customerInfo && customerInfo.orgId) || null,
    };
  }

  addCustomer(customer) {
    const endpoint = this.endpointService.getEndpoint(
      'createCustomer',
      customer
    );
    return this.dataService
      .post(endpoint, customer)
      .pipe(map((response: any) => response.result));
  }

  setCustomerMode(clientId, clientModeId) {
    const endpoint = this.endpointService.getEndpoint('setCustomerMode', clientId, clientModeId);
    return this.dataService
      .post(endpoint, { clientId, clientModeId })
      .pipe(map((response: any) => response.result));
  }

  runCalcEngineClient(clientId) {
    const endpoint = this.endpointService.getEndpoint(
      'clientCalcEngine',
      clientId
    );
    return this.dataService
      .post(endpoint, { clientId })
      .pipe(map((response: any) => response.result));
  }

  runCalcEngineStudy(clientId, studyId, runHistoricMode, isDebugModeOn) {
    const endpoint = this.endpointService.getEndpoint(
      'studyCalcEngine',
      clientId,
      studyId
    );
    return this.dataService
      .post(endpoint, { clientId, runHistoricMode, isDebugModeOn })
      .pipe(map((response: any) => response.result));
  }
  getClientParameters() {
    const endpoint = this.endpointService.getEndpoint('clientParameters');
    return this.dataService
      .getAll(endpoint)
      .pipe(map((response: any) => response.result));
  }

  saveClientParameter(paramsBody = {}) {
    const endpoint = this.endpointService.getEndpoint('clientParameters');
    return this.dataService
      .post(endpoint, paramsBody)
      .pipe(map((response: any) => response.result));
  }

  updateClientParam(paramsBody = {}) {
    const endpoint = this.endpointService.getEndpoint('clientParameters');
    return this.dataService
      .update(endpoint, paramsBody)
      .pipe(map((response: any) => response.result));
  }

  deleteClientParameter(paramKey: number, row:any){
    const endpoint = this.endpointService.getEndpoint('deleteClientParameter', paramKey);
    return this.dataService.deleteWithBody(endpoint, row);
  }

  getClientMetrics() {
    const endpoint = this.endpointService.getEndpoint('clientMetrics');
    return this.dataService
      .getAll(endpoint)
      .pipe(map((response: any) => response.result));
  }
  
  getLatestRefreshDetails(clientId) {
    const endpoint = this.endpointService.getEndpoint(
      'fetchRefreshInfo',
      clientId
    );
    return this.dataService
      .post(endpoint, { clientId })
      .pipe(map((response: any) => response.result));
  }

  getCustomerStudyFeature() {
    const endpoint = this.endpointService.getEndpoint('customerStudyFeatures');
    return this.dataService
      .getAll(endpoint)
      .pipe(map((response: any) => response.result));
  }

  clearCalcLock(clientId) {
    const endpoint = this.endpointService.getEndpoint(
      'clearcalclock',
      clientId
    );
    return this.dataService
      .post(endpoint, { clientId })
      .pipe(map((response: any) => response.result));
  }
}
