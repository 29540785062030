import { KriCategory } from './../models/kri-category.type';
import { Injectable } from '@angular/core';
import { EndpointService } from '@shared/services/endpoint.service';
import { DataService } from '@shared/services/data.service';
import { UtilsService } from '@shared/services/utils.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { metricCategory } from './../models/metric-category.type';


@Injectable()
export class SiteService {

  constructor(
    private dataService: DataService<any>,
    private endpointService: EndpointService
  ) { }

  getSite(studyId: number, siteId: number) {
    const endpoint = this.endpointService.getEndpoint('singleSite', studyId, siteId);

    return this.dataService.getOne(endpoint).pipe(map((response: any) => response.result));
  }

  getKpiCalcs(studyId: number, siteId) {
    const endpoint = this.endpointService.getEndpoint('siteKpiCalcs', studyId, siteId);

    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  // gets latest kri values for kri category (enrollment compliance, etc)
  getKriCalcs(studyId: number, siteId: number, kriCategory: KriCategory) {
    const endpoint = this.endpointService.getEndpoint('siteKriCalcs', studyId, siteId, kriCategory);

    return this.dataService.getAll(endpoint)
      .pipe(map((response: any) => response.result))
      .pipe(map(data => data.map(formatKriGroup)));

    function formatKriGroup(group) {
      group.name = group.calcTypeName;
      group.color = UtilsService.getColorFromScore(group.score);

      group.calcRawData = Math.round(+group.calcRawData);
      group.historical = group.historical.map(formatHistoricalData);
      function formatHistoricalData(h) {
        return [h[0], Math.round(h[1])];
      }

      return group;
    }
  }

  getKriBucketCalcs(studyId: number, siteId: number, bucketName: KriCategory) {
    const endpoint = this.endpointService.getEndpoint('siteHistoricalBucketCalcs', studyId, siteId, bucketName);

    return this.dataService.getAll(endpoint)
      .pipe(map((response: any) => response.result))
      .pipe(map(data => data.map(formatKriGroup)));

    function formatKriGroup(group) {
      group.name = group.calcTypeName;
      group.color = UtilsService.getColorFromScore(group.score);

      group.calcRawData = Math.round(+group.calcRawData);
      group.historical = group.historical.map(formatHistoricalData);
      function formatHistoricalData(h) {
        return [h[0], Math.round(h[1])];
      }

      return group;
    }
  }

  getSiteMetricScores(studyId: number, siteId: number, bucketName: metricCategory) {
    const endpoint = this.endpointService.getEndpoint('siteMetricScores', studyId, siteId, bucketName);

    return this.dataService.getAll(endpoint)
      .pipe(map((response: any) => response.result))
      .pipe(map(data => data.map(formatGroup)));

    function formatGroup(group) {
      group.color = UtilsService.getColorFromScore(group.score);
      return group;
    }
  }

  getSiteMetricDetails(studyId: number, siteId: number, metricId: number) {
    const endpoint = this.endpointService.getEndpoint('siteMetricDetails', studyId, siteId, metricId);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  siteEvents(studyId: number, siteId: number) {
    const endpoint = this.endpointService.getEndpoint('siteEvents', studyId, siteId);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  siteMetricEvents(studyId: number, siteId: number, metricId: number) {
    const endpoint = this.endpointService.getEndpoint('siteMetricEvents', studyId, siteId, metricId);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  getSiteReport(studyId: number, siteId: number, metricId: number) {
    const endpoint = this.endpointService.getEndpoint('siteReport', studyId, siteId, metricId);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  getKriHistorical(studyId: number, siteId: number, kriId: number) {
    const endpoint = this.endpointService.getEndpoint('siteKriHistorical', studyId, siteId, kriId);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  getKriHistoricalEvents(studyId: number, siteId: number, kriId: number) {
    const endpoint = this.endpointService.getEndpoint('siteKriHistoricalData', studyId, siteId, kriId);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  getAllSites(studyId: number): Observable<any> {
    const endpoint = this.endpointService.getEndpoint('sites', studyId);
    return this.dataService.getAll(endpoint);
  }

  getSiteCompositeTrend(studyId: number, siteId: number) {
    const endpoint = this.endpointService.getEndpoint('siteTrend', studyId, siteId);
    return this.dataService.getAll(endpoint).pipe(map((res: any) => res.result));
  }

  getRandomKriValue(variance) {
    let value = Math.floor(Math.random() * 100);
    value += variance; // + Math.random(); // add some decimals and increase overall value
    if (value > 100) { // dont let it go over 100%
      value = 100;
      value -= Math.floor(Math.random() * 10); // randomize it a little more.
    }

    if (value < 0) {
      value = 0;
      value += Math.floor(Math.random() * 5); // randomize it a little more.
    }

    return Number(value.toFixed(2));
  }

  updateKriData(entry) {
    entry.trend = [];
    const baseDate = new Date(2017, 4, 2);
    for (let i = 0; i < 3; i++) {
      entry.trend.push(
        [
          new Date(baseDate.getTime() + 30 * i * 24 * 60 * 60 * 1000),
          Math.floor(Math.random() * 100)
        ]
      );
    }
  }

  getSiteBucketScoresHistory(studyId: number, siteId: number, isChartData: boolean) {
    const endpoint = this.endpointService.getEndpoint('siteBucketScoresHistory', studyId, siteId, isChartData);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  getSiteMetricScoresHistory(studyId: number, siteId: number, metricId: number, isChartData: boolean) {
    const endpoint = this.endpointService.getEndpoint('siteMetricScoresHistory', studyId, siteId, metricId, isChartData);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }
  
  getSiteBucketScores(studyId: number, siteId: number) {
    const endpoint = this.endpointService.getEndpoint('siteBucketScores', studyId, siteId);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }
}
