<ert-app-header title='DATA INSIGHTS'></ert-app-header>
<div class="error__content">
  <mat-card class="error__card">
    <h2>{{ title || 'PAGE NOT FOUND'}}</h2>
    <mat-card-content>
      <div class="error__instructions"> {{ message || 'The Page you are trying to access does not exist. Please ensure
        to update your bookmarks.' }} </div>
    </mat-card-content>
    <div class="error__actions">
      <button class="es-button -es-primary" (click)="navigateBack()"> Back to Login</button>
    </div>
    <!-- removed until we have forgot password functionality -->
    <!-- <button mat-button>Forgot Password</button> -->
  </mat-card>
</div>
<div class="error__footer">
  <!-- <ert-footer></ert-footer> -->
</div>
