import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ert-no-value',
  templateUrl: './no-value.component.html',
  styleUrls: ['./no-value.component.scss']
})
export class NoValueComponent implements OnInit {
  // @Input() value: string;
  noValue = '--';

  constructor() { }

  ngOnInit() {
  }

}
