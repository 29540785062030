<mat-card class="study-info-container">
  <mat-card-content class="study_content">
    <div class="study_info_content">
      <div class="study_info-block">
        <p class="study_block_heading">Study Name: </p>
        <p class="study_block_content"> {{studyInfoData.studyName}} </p>
      </div>
      <div class="study_info-block-border">
        <p class="study_block_heading">Protocol #: </p>
        <p class="study_block_content">
          <span> {{studyInfoData.protocolNumber}}</span>
        </p>
      </div>
      <div class="study_info-block">
        <p class="study_block_heading">Sponsor: </p>
        <p class="study_block_content"><span *ngIf="studyInfoData.sponsorName" > {{studyInfoData.sponsorName}}</span></p>
        <ert-no-value *ngIf="studyInfoData.sponsorName === null"></ert-no-value>
      </div>
      <div class="study_info-block-border">
        <p class="study_block_heading">Therapeutic Area: </p>
        <p class="study_block_content">
          <span *ngIf="studyInfoData.therapeuticArea"> {{studyInfoData.therapeuticArea}}</span>
          <ert-no-value *ngIf="studyInfoData.therapeuticArea === null"></ert-no-value>
        </p>
      </div>
    </div>
  </mat-card-content>
</mat-card>