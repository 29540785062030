import { ErrorNotificationService } from '@app/core/services/error-notification.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ToAdminService } from './../to-admin.service';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { SetBreadCrumbs } from '@app/core/store/breadcrumbs/breadcrumbs.actions';

export interface AddStudy {
  clientId: number;
  studyOid: string;
  clinicalGovId: string;
  studyName: string;
  protocolNumber: string;
  protocolName: string;
  sponsorName: string;
  startDate: string;
  numSubjects: string;
  phase?: string;
  country?: string;
  description?: string;
  therapeuticArea?: string;
  indications?: string;
}
@Component({
  selector: 'ert-add-study',
  templateUrl: './add-study.component.html',
  styleUrls: ['./add-study.component.scss']
})
export class AddStudyComponent implements OnInit {

  addStudyForm: UntypedFormGroup;
  clientList: [];
  selectedClientId: number;

  @ViewChild('addStudyFormElem') addStudyFormElem;

  constructor(
    private store: Store<any>,
    private toAdminService: ToAdminService,
    private toastr: ToastrService,
    private errorNotificationService: ErrorNotificationService,
  ) {
    this.store.dispatch(new SetBreadCrumbs({
      crumbs: [
        {text: 'TO Admin'},
        {text: 'Study'}
      ]
    }));
  }

  ngOnInit  () {
    this.addStudyForm = new UntypedFormGroup({
      clientId: new UntypedFormControl('', Validators.required),
      studyOid: new UntypedFormControl('', Validators.required),
      clinicalGovId: new UntypedFormControl('', Validators.required),
      studyName: new UntypedFormControl('', Validators.required),
      protocolNumber: new UntypedFormControl('', Validators.required),
      protocolName: new UntypedFormControl('', Validators.required),
      sponsorName: new UntypedFormControl('', Validators.required),
      startDate: new UntypedFormControl('', Validators.required),
      numSubjects: new UntypedFormControl('', [Validators.required, Validators.min(0)]),
      phase: new UntypedFormControl(''),
      country: new UntypedFormControl(''),
      description: new UntypedFormControl(''),
      therapeuticArea: new UntypedFormControl(''),
      indications: new UntypedFormControl(''),
    });

    this.toAdminService.getAllClients().subscribe(clients => {
      this.clientList = clients;
    });
  }

  selectCustomer(_event: any, clientId) {
    if (_event.isUserInput) {
      this.selectedClientId = clientId;
    }
  }

  handleError(err?) {
    let errorMesssage = '';
    if (err.error) {
      if (err.error.errorMessage) { errorMesssage = err.error.errorMessage; }
      // if (err.error.result && err.error.result.body) { errorMesssage = err.error.result.body.errorMessage; }
      // if (err.error.result.details) { errorMesssage = err.error.result.details[0].name; }
    }
    this.errorNotificationService.notifyError(`Error adding study.`, err);
  }

  saveStudy() {
    if (this.addStudyForm.valid) {
      // optional fields
      const phase = this.addStudyFormElem.value.phase;
      const description = this.addStudyFormElem.value.description;
      const therapeuticArea = this.addStudyFormElem.value.therapeuticArea;
      const indications = this.addStudyFormElem.value.indications;
      const country = this.addStudyFormElem.value.country;

      const study: AddStudy = {
        clientId: this.selectedClientId,
        studyOid: this.addStudyFormElem.value.studyOid,
        clinicalGovId: this.addStudyFormElem.value.clinicalGovId,
        studyName: this.addStudyFormElem.value.studyName,
        sponsorName: this.addStudyFormElem.value.sponsorName,
        protocolNumber: this.addStudyFormElem.value.protocolNumber,
        protocolName: this.addStudyFormElem.value.protocolName,
        startDate: this.addStudyFormElem.value.startDate,
        numSubjects: this.addStudyFormElem.value.numSubjects,
        ...(phase ? { phase } : undefined),
        ...(description ? { description } : undefined),
        ...(therapeuticArea ? { therapeuticArea } : undefined),
        ...(indications ? { indications } : undefined),
        ...(country ? { country } : undefined),
      };

      this.toAdminService.addStudy(study).subscribe(data => {
        this.toastr.success('Study added successfully.');
        this.addStudyFormElem.resetForm();
      }, err => {
        this.handleError(err);
      });
    }
  }
}
