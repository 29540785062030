<div class="side-navigation__header"></div>
<div class="side-navigation static --close-menu"  id="side-navigation" [ngClass]="{'--close-menu': isToggleOpen}" style="display: flex; height: 100%">
  <nav class="side-navigation__nav">
    <ul class="side-navigation__ul">
      <li class="side-navigation__li">
        <div>
          <a [ngClass]="{'cluster': true, '--selected': isLinkActive(link.location)}" mat-list-item *ngFor="let link of (sidebar$ | async)?.sidebarLinks" routerLink="{{link.location}}"
            routerLinkActive="active" [cr-tooltip] = "link.text">
            <span class="side-navigation__icon" data-tippy-content="{{link.text}}" >
              <cr-icon name="{{link.icon}}"></cr-icon>
            </span>
            <span class="side-navigation__item-name">{{link.text}}</span>
          </a>
        </div>
      </li>
    </ul>
  </nav>
  <button class="side-navigation__toggle-button" id="di-menu-toggle" (click) = "toggleMenu()" #diMenuToggle>
    <span class="side-navigation__icon" data-tippy-content="Menu Toggle">
      <svg class="icon" height="50px" viewBox="0 0 448 512"><path d="M7.6 238.5C2.7 243.1 0 249.4 0 256s2.7 12.9 7.6 17.5l136 128c9.7 9.1 24.8 8.6 33.9-1s8.6-24.8-1-33.9L84.5 280l83.5 0 128 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-128 0-83.5 0 91.9-86.5c9.7-9.1 10.1-24.3 1-33.9s-24.3-10.1-33.9-1l-136 128zM400 424c0 13.3 10.7 24 24 24s24-10.7 24-24l0-336c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 336z"/>
      </svg>
    </span>
    <span class="side-navigation__item-name">Collapse menu</span>
</button>
</div>
