import { isFloat } from './../helpers/util';
import { UtilsService } from '@shared/services/utils.service';
import { Component, OnInit, Input, ViewChild, OnChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { KriViews } from './enum.kri-categories';
import { type } from 'os';
@Component({
  selector: 'ert-kri-category-table',
  templateUrl: './kri-category-table.component.html',
  styleUrls: ['./kri-category-table.component.scss']
})
export class KriCategoryTableComponent implements OnInit, OnChanges {
  tableTopColumns = [];
  activeCategoryColumn: string = null;
  initialColumns: string[];
  inactive = 'N/A';

  @Input() dataSource;
  @Input() displayedColumns: string[];
  @Input() detailsDataColumns = {};
  @Input() view: string;
  @Input() inactiveKriList;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) pagnitor: MatPaginator;
  expand: Boolean = false;
  alertPermCheck: Boolean = false;
  studyId: number;
  siteId: number;

  scoreTooltipMessage: string;
  constructor(private router: Router,
    private route: ActivatedRoute) {
      this.route.params.subscribe(params => {
        this.studyId = +params.studyId;
        this.siteId = +params.siteId;
      });
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.initialColumns = this.displayedColumns;
    // this.tableTopColumns = this.setTopHeaderCatColumns();
    this.dataSource = new MatTableDataSource(this.dataSource);
    this.dataSource.paginator = this.pagnitor;

    this.dataSource.filterPredicate =  (data, filter: string): boolean => {
      return data.site.toString().toLowerCase().includes(filter) || data.pi.toLowerCase().includes(filter);
    };

    setTimeout(() => {
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (data: any, header: string): any => {
        if (typeof (data[header]) === 'string') {
          return data[header].trim().toLocaleLowerCase();
        }
        if (typeof (data[header]) === 'number') {
          return parseFloat(data[header]);
        }
        if (parseInt(data[header]) === NaN) { return; }
        else {
          return data[header];
        }
      };
    }, 1000);
  }

  checkAvailableScore(data, column) {
    if(this.hasScore(column)) {
      if(data[column] !== undefined && data[column].score !== null && (data[column].active === true)) return true;
      return false;
    }
    return false;
  }

  checkNoData(data, column) {
    if(this.hasScore(column)) {
      if(data[column] === undefined && this.checkInactive(data, column)) return false;
      if(data[column] === undefined && !this.checkInactive(data, column)) return true;
      if(data[column] !== undefined && data[column].score === null && (data[column].active === true)) return true;
      return false;
    } else if(!this.hasScore(column) ) {
      if(data[column] === undefined && !this.checkInactive(data, column)) return true;
    } else return false;
  }

  checkInactive(data, column) {
    if(this.view === 'PORTFOLIO') {
      var flag = false;
      if(this.hasScore(column) && data[column] !== undefined) {
        if(data[column].active === false) return true;
      }
      else if(data[column] === undefined && this.inactiveKriList[data.id].length>0) {
        this.inactiveKriList[data.id].forEach(element => {
          if(element && element.calcTypeName === column) {
            flag = true;
          }
        });
        return flag;
      }
      else return false;
    } else {
      if(this.hasScore(column) && data[column] !== undefined) {
        if(data[column].active === false) return true;
        else return false;
      } else return false;
    }
  }

  getColRowVal(value) {
    const val = (typeof value === 'string') ? value.toLowerCase() : value;

    if ((val !== null) && (typeof val === 'number' || typeof val === 'boolean')) {
      return value;
    } else if (val && typeof val === 'string') {
      if (val === 'n/d' || val === '') {
        return null;
      }
      return value;
    } else return null;
  }

  getScoreColor(score) {
    return UtilsService.getColorFromScore(score);
  }

  // tableWidth() {

  //   switch(this.activeCategoryColumn + '') {
  //     case 'enrollment' : return `${(this.enrollmentLength * 5) + 100}%`;
  //     case 'compliance' : return `${(this.complianceLength * 10) + 100}%`;
  //     case 'safety' : return `${(this.safetyLength * 5) + 100}%`;
  //     case 'quality' : return `${(this.qualityLength * 5) + 100}%`;
  //     default: return '100%';
  //   }
  // }

  getScoreTooltip(value) {
    if(value === undefined) return 'No Data to Display'
    var threshold;
    if(value) {
      if (value.units && value.units === '%') {
      threshold = value.thresholdValue * 100 + (value.units ? value.units : '');
      } else threshold = value.thresholdValue + ' ' + (value.units ? value.units : '');
      return `Value: ${value.value ? value.value.toLowerCase() : 'No Data'}
    Threshold: ${threshold  ? threshold.toLowerCase() : 'No Data'}
    Threshold Operator: ${value.thresholdOperator ? (value.thresholdOperator==='gte'? 'Greater than or Equal to': 'Less than or Equal to') : 'No Data'} `;
    }
  }

  isNumber(num) {
    return typeof num === 'number';
  }

  setTopHeaderCatColumns() {
    return this.displayedColumns.map(col => `${col}-top-header`);
  }

  setActiveColumn(activateColumn) {
    const activeCategoryColumnPos = this.initialColumns.indexOf(activateColumn);

    if (this.activeCategoryColumn === activateColumn) {
      this.displayedColumns = this.initialColumns;
      this.tableTopColumns = this.setTopHeaderCatColumns();
      this.activeCategoryColumn = null;
      this.expand = false;
    } else {
      this.expand = true;
      this.activeCategoryColumn = activateColumn;
      this.displayedColumns = [
        ...this.initialColumns.slice(0, activeCategoryColumnPos + 1),
        ...this.detailsDataColumns[activateColumn],
        ...this.initialColumns.slice(activeCategoryColumnPos + 1),
      ];
      this.tableTopColumns = this.setTopHeaderCatColumns();
    }
  }

  hasScore(name: string): boolean {
    return name && this.findCalcTypeBy(name) && this.findCalcTypeBy(name).hasOwnProperty('score');
  }

  getScore(name: string) {
    return this.hasScore(name) && this.findCalcTypeBy(name).score || null;
  }

  ertLogoDisplay (calcName) {
    let val;
    if (!this.detailsDataColumns['calcTypes']) { return false; }
    const calcCategory = this.detailsDataColumns['calcTypes'][this.activeCategoryColumn] || [];
    calcCategory.filter(element => {
      if(element.name.toLowerCase() === calcName.toLowerCase()) {
        val = element.isErtData;
      }
    });
    return val;
  }

  findCalcTypeBy(name: string) {
    let values: { id?: number, score?: number } = {} ;
    if (!this.detailsDataColumns['calcTypes']) { return values; }
    const detailsDataCategory = this.detailsDataColumns['calcTypes'][this.activeCategoryColumn] || [];
    detailsDataCategory.find(type => {
      if (name.toLowerCase() === type.name.toLowerCase()) {
        values = { id: type.id, score: type.score };
      }
    });

    return values;
  }

  navigateToPage(name, row) {
    const { PORTFOLIO_VIEW, CROSSITE_VIEW, STUDY_VIEW } = KriViews;
    const calcTypeId = this.findCalcTypeBy(name).id;

    if (!calcTypeId && this.view === STUDY_VIEW || this.view === CROSSITE_VIEW) {
      return  this.router.navigate([`/dashboard/study/${row.id}/site/${row.siteId}`]);
    }
    if (!calcTypeId && (this.view === PORTFOLIO_VIEW)) {
      return  this.router.navigate([`/dashboard/study/${row.id}`]);
    }
    if (calcTypeId && (this.view === PORTFOLIO_VIEW)) {
      return this.router.navigate([`/dashboard/study/${row.id}/detail/${calcTypeId}`]);
    } else {
      return this.router.navigate([`/dashboard/study/${row.id}/site/${row.siteId}/detail/${calcTypeId}`]);
    }
  }

  applyFilter(filterValue: string) {
    // remove white space and lowercase filter value
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  scoreTooltip(category) {
  //   var view;
  //  if(this.view === 'PORTFOLIO') {
  //   view = 'study';
  //  } else {
  //   view = 'site';
  //  }

   return 'The score indicates the composite score of all the KRIs within the category.';
  }
}
