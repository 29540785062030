import { Component, OnInit, ViewChild, Injector, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ToAdminService } from '../to-admin.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorNotificationService } from '@app/core/services/error-notification.service';
import { SetBreadCrumbs } from '@app/core/store/breadcrumbs/breadcrumbs.actions';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { AddUserModalComponent } from './../../settings/admin/user-management/add-user-modal/add-user-modal.component';

export class TOAdminUser {
  user_id: number;
  first_name: string;
  last_name: string;
  active: boolean;
  admin_level: number;
  fullName: string;
}

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit, AfterViewInit {
  displayedData: MatTableDataSource<TOAdminUser>;
  displayedColumns = ['fullName', 'email', 'modify'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  users: TOAdminUser[];
  toAdmins;
  level0Admins;
  constructor(
    private store: Store<any>,
    private toAdminService: ToAdminService,
    private toastr: ToastrService,
    private injector: Injector,
    public dialog: MatDialog,
  ) {
    this.store.dispatch(new SetBreadCrumbs({
      crumbs: [
        { text: 'TO Admin' },
        { text: 'User Management' }
      ]
    }));
  }

  ngOnInit() {
    this.setupTableData();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.displayedData.sort = this.sort;
    }, 1500);
  }

  setupTableData() {
    this.toAdminService.getTOAdmins().subscribe(dm => {
      this.toAdmins = dm;
      this.toAdminService.getLevel0User().subscribe(dm => {
        this.level0Admins = dm;
        this.users = [...this.toAdmins, ...this.level0Admins]
        this.users.sort((a, b) => (a['admin_level'] > b['admin_level']) ? -1 : 1);
        this.users.forEach(element => {
          element.fullName = element.first_name + ' ' + element.last_name;
        });
        this.initUserTable(this.users);
      });
    });
  }

  initUserTable(allUsers) {
    this.displayedData = new MatTableDataSource(allUsers);
    this.displayedData.sort = this.sort;
    this.displayedData.paginator = this.paginator;
    this.displayedData.sortingDataAccessor = ((data: any, header: string): any => {
      return data[header].trim().toLocaleLowerCase();
    });
    this.displayedData.filterPredicate = (user, filterString) => {
      // only filter based on fullName / email, not all fields (RBM-276)
      const fieldsToFilter = ['fullName', 'email'];

      return fieldsToFilter.some(field => {
        return user[field].toLowerCase().includes(filterString);
      });
    };
  }

  applyFilter(filterValue: string) {
    // remove white space and lowercase filter value
    this.displayedData.filter = filterValue.trim().toLowerCase();
  }

  modifyUserPermission(user) {
    if (user.admin_level === 1) {
      this.toAdminService.revokeTOAdmin(user.user_id).subscribe(data => {
        this.toastr.success('Permission removed successfully.');
        this.setupTableData();
      }, err => {
        if (err.status === 403) {
          this.injector.get(ErrorNotificationService).notifyError('You do not have permission to perform this task.', err);
        } else {
          this.injector.get(ErrorNotificationService).notifyError('Error:\n' + err.error.result.error, err);
        }
      });
    }
    if (user.admin_level === 0) {
      this.toAdminService.makeTOAdmin(user.user_id).subscribe(data => {
        this.toastr.success('Permission added successfully.');
        this.setupTableData();
      }, err => {
        if (err.status === 403) {
          this.injector.get(ErrorNotificationService).notifyError('You do not have permission to perform this task.', err);
        } else {
          this.injector.get(ErrorNotificationService).notifyError('Error:\n' + err.error.result.error, err);
        }
      });
    }

  }

  addToAdminDialog() {
    const dialogRef = this.dialog.open(AddUserModalComponent, {
      data: {
        titleText: 'Invite TO Admin',
        addUserFunc: this.toAdminService.inviteTOAdmin.bind(this.toAdminService),
        next: this.onAddToAdminNext.bind(this),
        error: this.onAddToAdminError.bind(this),
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // no furture actions needed
    });
  }

  onAddToAdminNext(res) {
    if (res.isNewUser) {
      this.injector.get(ErrorNotificationService).notifySuccess(res.message);
    } else {
      if (res.isNewlyAssigned) {
        this.injector.get(ErrorNotificationService).notifyInfo(res.message);
        this.setupTableData();  // need refresh for existing user being reactivated
      } else {
        this.injector.get(ErrorNotificationService).notifyWarning(res.message);
      }
    }
  }

  onAddToAdminError(err) {
    this.injector.get(ErrorNotificationService).notifyError('Error occurred when inviting TO Admin.', err);
  }

}
