import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class D3UtilsService {

  constructor() { }

  /**
   * Will get an Array with the next numDays for x Axis
   * @param numDays
   * @param xCurrentValues - actuall current values of x
   */
  static projectedDatesArray(numDays = 31, xCurrentValues) {
    let projectedDateArr = [];
    const day = 24 * 60 * 60 * 1000;
    const lastDataDate = Math.max(...xCurrentValues.map(dateValue => dateValue));
    let projectedDate = lastDataDate; // first projected date is the last from data

    projectedDateArr = [...Array(numDays - 1)].map(() => projectedDate += 1 * day);

    return projectedDateArr;
  }

  /**
   * Will generate the projection data
   * @param data - Actual present data
   * @param projectedDates - next projected Dates array
   */
  static getProjectionData(data, projectedDates) {
    const listYDataArray = data.map((d: any, i) => [i, d.y]);
    const listYDataArrayLength = listYDataArray.length - 1;
    let projectedData = [];
    // Get predict projected data to make up a 30 day worth of projection
    projectedData = projectedDates.map((d, i) => {
      return {
        x: projectedDates[i],
        y: D3UtilsService.predictYValue(listYDataArray, listYDataArrayLength + i),
      };
    });

    projectedData.unshift(data[data.length - 1]);

    return projectedData;

  }

  /**
  * Will generate the Y values for a given X(date)
  * Data  array of dates(x) and predicted Y
  * newX A new date in the future that we want to predict
  */
  static predictYValue(data, newX) {
    let yValue;
    const round = n => Math.round(n * 100) / 100;

    const sum = data.reduce((acc, pair) => {
      const x = pair[0];
      const y = pair[1];

      if (y !== null) {
        acc.x += x;
        acc.y += y;
        acc.squareX += x * x;
        acc.product += x * y;
        acc.len += 1;
      }

      return acc;
    }, { x: 0, y: 0, squareX: 0, product: 0, len: 0 });
    const run = ((sum.len * sum.squareX) - (sum.x * sum.x));
    const rise = ((sum.len * sum.product) - (sum.x * sum.y));
    const gradient = run === 0 ? 0 : round(rise / run);
    const intercept = round((sum.y / sum.len) - ((gradient * sum.x) / sum.len));
    yValue = round((gradient * newX) + intercept);
    
    if (yValue > 100) {
      yValue = 100;
    } else if (yValue < 0) {
      yValue = 0;
    }
    return yValue;
  }
}
