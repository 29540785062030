import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
// import { LoaderState } from '../loader/loader';

export interface SpinnerState {
  show: boolean;
}

/**
 * Show and Hide Loading indicator service
 */
@Injectable()
export class SpinnerService {
  private isSpinnerVisible: boolean;
  private spinnerSubject = new Subject<SpinnerState>();
  loaderState = this.spinnerSubject.asObservable();

  constructor() { }

  isSpinnerActive() {
    return this.isSpinnerVisible;
  }

  show() {
    this.isSpinnerVisible = true;
    this.spinnerSubject.next(<SpinnerState>{ show: this.isSpinnerVisible });
  }

  hide() {
    this.isSpinnerVisible = false;
    this.spinnerSubject.next(<SpinnerState>{ show: this.isSpinnerVisible });
  }
}
