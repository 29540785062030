<mat-card class="add-study-form_card">
  <mat-card-title>
    <h2>Add Study</h2>
  </mat-card-title>
<form [formGroup]="addStudyForm" class="add-study-form" #addStudyFormElem="ngForm" (ngSubmit)="saveStudy()"
  autocomplete="off">
    <div>
      <mat-form-field>
        <input matInput placeholder="Customer Name *" formControlName="clientId" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let client of clientList" [value]="client.client_name" (onSelectionChange)="selectCustomer($event, client.client_id)">
            {{client.client_name}} </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput placeholder="studyOid *" formControlName="studyOid" type="string">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput placeholder="clinicaltrial.gov ID *" formControlName="clinicalGovId" type="string">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput placeholder="Study Name *" formControlName="studyName">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput placeholder="Protocol # *" formControlName="protocolNumber">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput placeholder="Protocol Name *" formControlName="protocolName">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput placeholder="Sponsor Name *" formControlName="sponsorName">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput (click)="picker.open()" [matDatepicker]="picker" placeholder="Start Date *" formControlName="startDate">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput placeholder="Target Subjects *" formControlName="numSubjects" type="number">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput placeholder="Phase" formControlName="phase">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput placeholder="Therapeutic Area" formControlName="therapeuticArea">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput placeholder="Indications" formControlName="indications">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput placeholder="Synopsis" formControlName="description">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput placeholder="Country" formControlName="country">
      </mat-form-field>
    </div>
    <div class="add-form-container_button">
      <button class="es-button -es-primary" [disabled]="addStudyFormElem.invalid" type="submit">Create</button>
    </div>
  </form>
</mat-card>
