import { UtilsService } from '@shared/services/utils.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SiteService } from '../../services/site.service';

@Component({
  selector: 'ert-stat-block-top',
  templateUrl: './stat-block-top.component.html',
  styleUrls: ['./stat-block-top.component.scss']
})
export class StatBlockTopComponent implements OnInit {
  studyId: number;
  siteId: number;
  currentScoreColor: string;

  dataModel: any = {};

  constructor(private route: ActivatedRoute,
    private siteService: SiteService) {
      this.route.params.subscribe(params => {
        this.studyId = +params.studyId;
        this.siteId = +params.siteId;
    });
   }

  ngOnInit() {
     this.siteService.getSite(this.studyId, this.siteId).subscribe(site => {
        this.siteService.getSiteCompositeTrend(this.studyId, this.siteId).subscribe(trend  => {
          this.dataModel.site = site;
          this.dataModel.trend = trend.trend;
          this.dataModel.ranking = trend.ranking;
          this.dataModel.score = trend.score;
        });
      });
    }

    getScoreColor(score) {
      return UtilsService.getColorFromScore(score);
    }

    getScoreTextColor(score) {
      return UtilsService.getColorFromScore(score) === '#f9ea3b' ? '#000000' : '#ffffff';
    }
}
