
<ert-site-metric-info class="metric__stat-block" [studyName]="studyName" [metricName]="metricName" [category]="category"
    [protocolNo]="protocolNo" [siteNo]="siteNo" [metricThreshold]="metricThreshold" [piName]="piName"
    [valueMinMax]="valueMinMax" [metricWeight]="metricWeight" [metricValue]="metricValue"></ert-site-metric-info>

<mat-card>
  <ert-score-overview class="study-comp" kritype="" [data]="metricScoresHistory"
    [type]="chartType.LineColorChart" yAxisLabel="Score">
  </ert-score-overview>
</mat-card>

<mat-card>
    <h4 class="site-metric_chart-title">Site Comparative: Sites by {{metricName}}</h4>
    <div class="site-metric_heatmap">
        <ert-heatmap *ngIf="sites" [mapData]="sites" [studyId]="studyId" [siteId]="siteId" [metricId]="metricId"></ert-heatmap>
        <ert-no-data *ngIf="!sites"></ert-no-data>
    </div>
</mat-card>

<div class="site-metric-info">
    <ert-metric-site-event-table></ert-metric-site-event-table>
</div>
