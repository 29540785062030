import { SidebarService } from '@app/dashboard/sidebar/services/sidebar-permissions.service';
import { StudyService } from '@shared/services/study.service';
import { ToolbarComponent } from '@shared/toolbar/toolbar.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { SharedModule } from '@shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderDropdownComponent } from './header/header-dropdown/header-dropdown.component';
import { StudyComponent } from './study/study.component';
import { SiteComponent } from './site/site.component';
import { DetailComponent } from './detail/detail.component';
import { tasksEntryComponents } from '@app/tasks';
import { alertsEntryComponents } from '@app/alerts';
import { CrossSitePortfolioComponent } from './cross-site-portfolio/cross-site-portfolio.component';
import { SiteMetricComponent } from './site-metric/site-metric.component';
import { StudyMetricComponent } from './study-metric/study-metric.component';
import { TableauComponent } from './tableau/tableau.component';
import { DataMonitoringComponent } from './data-monitoring/data-monitoring.component';
import { CrIconModule } from '@crystal/ng-icon';
import { CrTooltipModule } from '@crystal/ng-tooltip';

@NgModule({
    declarations: [
        DashboardComponent,
        BreadcrumbComponent,
        SidebarComponent,
        HeaderComponent,
        FooterComponent,
        HeaderDropdownComponent,
        ToolbarComponent,
        StudyComponent,
        SiteComponent,
        DetailComponent,
        CrossSitePortfolioComponent,
        SiteMetricComponent,
        StudyMetricComponent,
        DataMonitoringComponent,
        // TableauComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        DashboardRoutingModule,
        SharedModule,
        CrIconModule,
        CrTooltipModule
    ],
    providers: [
        StudyService,
        SidebarService,
        DatePipe,
    ],
    exports: [
        BreadcrumbComponent,
        SidebarComponent,
        HeaderComponent,
        FooterComponent,
        ToolbarComponent
    ]
})
export class DashboardModule { }
