
import { Injectable } from '@angular/core';
import { EndpointService, DataService } from '../../shared/services';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class DBLoggerService {
  constructor(
    private endpointService: EndpointService,
    private dataService: DataService<any>
  ) { }

  saveError(error) {
    error = JSON.stringify(error, Object.getOwnPropertyNames(error));
    const endpoint = this.endpointService.getEndpoint('logError', error);
    return this.dataService.post(endpoint, {error}).pipe(map((response: any) => response.result));
  }
  saveCriticalError(error) {
    error = JSON.stringify(error, Object.getOwnPropertyNames(error));
    const endpoint = this.endpointService.getEndpoint('logCritical', error);
    return this.dataService.post(endpoint, {error}).pipe(map((response: any) => response.result));
  }
  saveWarning(warning) {
    const endpoint = this.endpointService.getEndpoint('logWarning', warning);
    return this.dataService.post(endpoint, {warning}).pipe(map((response: any) => response.result));
  }
  saveInfo() {
    const endpoint = this.endpointService.getEndpoint('logInfo');
    return this.dataService.post(endpoint, {}).pipe(map((response: any) => response.result));
  }

}
