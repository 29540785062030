import { Action } from '@ngrx/store';

export enum AppActionTypes {
    CHANGE_PAGE = '[APP] Change Page',
    ALERTS      = '[APP] Set Alerts Count',
    TASKS       = '[APP] Set Tasks Count',
    GETSTATE    = '[APP] Get current state',
    SET_URL     = '[APP] Set url',
    SET_PRELOGIN_URL  = '[APP] Set Prelogin URL',
  }

  // TODO: can remove, not being used
  export class ActionAppPageChange implements Action {
    readonly type = AppActionTypes.CHANGE_PAGE;
    constructor(public payload: any) { }
  }

  export class ActionAppSetAlerts implements Action {
    readonly type = AppActionTypes.ALERTS;
    constructor(public payload: any) { }
  }

  export class ActionAppSetTasks implements Action {
    readonly type = AppActionTypes.TASKS;
    constructor(public payload: any) { }
  }

  export class ActionAppSetUrl implements Action {
    readonly type = AppActionTypes.SET_URL;
    constructor(public payload: any) { }
  }

  export class ActionAppCurrentState implements Action {
    readonly type = AppActionTypes.GETSTATE;
  }
  export class ActionAppPreloginUrl implements Action {
    readonly type = AppActionTypes.SET_PRELOGIN_URL;
    constructor(public payload: any) { }
  }

  
  export type AppActions
  = ActionAppPageChange
  | ActionAppSetAlerts
  | ActionAppSetTasks
  | ActionAppCurrentState
  | ActionAppSetUrl
  | ActionAppPreloginUrl
  ;
