import { Study } from './../models/study.model';
import { KriCategory } from './../models/kri-category.type';
import { UtilsService } from './utils.service';
import { Injectable } from '@angular/core';
import { EndpointService, DataService } from '.';
import { map } from 'rxjs/operators';
import { Threshold } from '../models/threshold.model';
import { SubMetricForm } from '../models/subMetricForm.model';
import { Observable } from 'rxjs';
import { metricCategory } from './../models/metric-category.type';
import { isPercentageDataType } from '../helpers/util';

@Injectable()
export class StudyService {
  constructor(
    private endpointService: EndpointService,
    private dataService: DataService<any>
  ) {}

  public getStudies(): Observable<Study[]> {
    const endpoint = this.endpointService.getEndpoint('allStudies');
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  public getStudyServiceStudies(): Observable<Study[]> {
    const endpoint = this.endpointService.getEndpoint('allStudyServiceStudies');
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  public getStudyIds(therapeutic: string, indication: string, studyName: string, country: string,  selectedLoadStartDate: Date, selectedLoadEndDate: Date): Observable<any> {
    const endpoint = this.endpointService.getEndpoint('studyIds', therapeutic, indication, studyName, country,  selectedLoadStartDate, selectedLoadEndDate);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  public getHeatMapData(studyId, metricId): Observable<any> {
    const endpoint = this.endpointService.getEndpoint('studyHeatMap', studyId, metricId);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  getStudiesBenchmark(): Observable<any> {
    const endpoint = this.endpointService.getEndpoint('studyBenchmark');
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result.data));
  }

  getStudyReport(studyId: number, metricId: number) {
    const endpoint = this.endpointService.getEndpoint('studyReport', studyId, metricId);
    return this.dataService.getOne(endpoint).pipe(map((response: any) => response.result));
  }

  getSiteStudiesBenchmark(studyId: number) {
    const endpoint = this.endpointService.getEndpoint('studySitesBenchmark', studyId);
    return this.dataService.getOne(endpoint).pipe(map((response: any) => response.result.data));
  }

  getStudyBucketScores(studyId: number) {
    const endpoint = this.endpointService.getEndpoint('studyBucketScores', studyId);
    return this.dataService.getOne(endpoint).pipe(map((response: any) => response.result));
  }

  getCrossSiteBenchmark(): Observable<any> {
    const endpoint = this.endpointService.getEndpoint('crossSite');
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result.data));
  }

  getStudyById(studyId: number) {
    const endpoint = this.endpointService.getEndpoint('singleStudy', studyId);
    return this.dataService.getOne(endpoint).pipe(map((response: any) => response.result));
  }

  public getStudiesData() : Observable<any> {
    const endpoint = this.endpointService.getEndpoint('studiesData');
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  getStudyForAdmin(studyId: number) {
    const endpoint = this.endpointService.getEndpoint('singleStudyForAdmin', studyId);
    return this.dataService.getOne(endpoint).pipe(map((response: any) => response.result));
  }

  getStudyFeaturesForAdmin(studyId: number) {
    const endpoint = this.endpointService.getEndpoint('studyFeaturesForAdmin', studyId);
    return this.dataService.getOne(endpoint).pipe(map((response: any) => response.result));
  }

  getSites(studyId: number) {
    const endpoint = this.endpointService.getEndpoint('sites', studyId);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  getSitesListing(studyId: number) {
    const endpoint = this.endpointService.getEndpoint('studySitesListing', studyId);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  eventsListByStudy(studyId: number) {
      const endpoint = this.endpointService.getEndpoint('sitesByScore', studyId);
      return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  getMetricsByScore(studyId: number, bucketName: metricCategory) {
    const endpoint = this.endpointService.getEndpoint('studyMetricsByScore', studyId, bucketName);

    return this.dataService.getAll(endpoint)
      .pipe(map((response: any) => response.result))
      .pipe(map(data => data.map(formatGroup)));

    function formatGroup(group) {
      group.color = UtilsService.getColorFromScore(group.score);
      return group;
    }
  }

  getSitesByEvents(studyId: number) {
    const endpoint = this.endpointService.getEndpoint('sitesByEvents', studyId);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  getStudyEventsByLocation(studyId: number) {
    const endpoint = this.endpointService.getEndpoint('studyEventsByLocation', studyId);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  getStudiesByScore(therapeutic: string, indication: string, studyName: string, country: string, selectedLoadStartDate: Date, selectedLoadEndDate: Date): Observable<any> {
    const endpoint = this.endpointService.getEndpoint('studiesByScore', therapeutic, indication, studyName, country, selectedLoadStartDate, selectedLoadEndDate);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  getMilestoneList(studyId: number) {
    const endpoint = this.endpointService.getEndpoint('studyMilestoneList', studyId);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }
  getMilestoneValues(studyId: number) {
    const endpoint = this.endpointService.getEndpoint('studyMilestoneValues', studyId);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  createMilestone(studyId: number, milestone) {
    const endpoint = this.endpointService.getEndpoint('studyMilestoneValues', studyId);
    return this.dataService.post(endpoint, milestone).pipe(map((response: any) => response.result));
  }

  updateDataInsightStatus(studyId: number, dataInsightStatus: boolean, clientId){
    const endpoint = this.endpointService.getEndpoint('updateDataInsightStatus', studyId, clientId);
    return this.dataService.post(endpoint, {dataInsightStatus}).pipe(map((response: any) => response.result));
  }

  updateStudyFeature(studyId: number, key: string, value: boolean){
    const endpoint = this.endpointService.getEndpoint('updateStudyFeature', studyId);
    return this.dataService.post(endpoint, {key, value}).pipe(map((response: any) => response.result));
  }

  getKriThresholdList(studyId: number) {
    const endpoint = this.endpointService.getEndpoint('studyKriThresholdList', studyId);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }
  getKriThresholdValues(studyId: number) {
    const endpoint = this.endpointService.getEndpoint('studyKriThresholdValues', studyId);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => {
      const thresholdValues = response.result.map(this.formatThresholdValue);
      return thresholdValues;
    }));
  }

  getKpiThresholdList(studyId: number) {
    const endpoint = this.endpointService.getEndpoint('studyKpiThresholdList', studyId);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }
  getKpiThresholdValues(studyId: number) {
    const endpoint = this.endpointService.getEndpoint('studyKpiThresholdValues', studyId);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => {
      const thresholdValues = response.result.map(this.formatThresholdValue);
      return thresholdValues;
    }));
  }


  createThreshold(studyId, threshold: Threshold , isStudyLevel?) {
    const endpoint = this.endpointService.getEndpoint('studyMetricThresholdValue', studyId, isStudyLevel);
    return this.dataService.post(endpoint, threshold).pipe(map((response: any) => response.result));
  }

  createSubMetric(studyId, subMetricForms: SubMetricForm[], parentCalcTypeId, parentThreshold: Threshold, reasonForChange: string) {
    const endpoint = this.endpointService.getEndpoint('subMetricCreate', studyId, parentCalcTypeId);
    return this.dataService.post(endpoint, {subMetricForms: subMetricForms, parentThreshold: parentThreshold, reason: reasonForChange}).pipe(map((response: any) => response.result));
  }

  updateSubMetric(studyId, subMetricForms: SubMetricForm[], parentCalcTypeId, parentThreshold: Threshold, reasonForChange: string) {
    const endpoint = this.endpointService.getEndpoint('subMetricUpdate', studyId, parentCalcTypeId);
    return this.dataService.update(endpoint, {subMetricForms: subMetricForms, parentThreshold: parentThreshold, reason: reasonForChange}).pipe(map((response: any) => response.result));
  }

  getKpiCalcs(studyId: number) {
    const endpoint = this.endpointService.getEndpoint('studyKpiCalcs', studyId);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  studyEvents(studyId: number) {
    const endpoint = this.endpointService.getEndpoint('studyEvent',studyId);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  studyMetricEvents(studyId: number, metricId: number) {
    const endpoint = this.endpointService.getEndpoint('studyMetricEvent',studyId, metricId);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  getSiteEventsWithScores(studyId: number, country: string,  selectedLoadStartDate: Date, selectedLoadEndDate: Date) {
    const endpoint = this.endpointService.getEndpoint('siteEventsWithScores', studyId, country,  selectedLoadStartDate, selectedLoadEndDate);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  getStudyLoadStatus(studyId: number) {
    const endpoint = this.endpointService.getEndpoint('studyLoadStatus', studyId);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  getKpiBucketCalcs(studyId: number, bucketName) {
    const endpoint = this.endpointService.getEndpoint('studyLatestBucketCalcs', studyId, bucketName);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  getFullScorecardData(studyId: number) {
    const endpoint = this.endpointService.getEndpoint('studyScoreCard', studyId);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  // gets latest kri values for kri category (enrollment compliance, etc)
  getKriCalcs(studyId: number, kriCategory: KriCategory) {
    const endpoint = this.endpointService.getEndpoint('studyKriCalcs', studyId, kriCategory);

    return this.dataService.getAll(endpoint)
      .pipe(map((response: any) => response.result))
      .pipe(map(data => data.map(formatKriGroup)));

    function formatKriGroup(group) {
      group.name = group.calcTypeName;
      group.color = UtilsService.getColorFromScore(group.score);
      group.calcRawData = Math.round(+group.calcRawData);
      group.historical = group.historical.map(formatHistoricalData);
      function formatHistoricalData(h) {
        return [h[0], Math.round(h[1])];
      }
    return group;
    }

  }
  getSingleMetric(studyId: number, metricId: number) {
    const endpoint = this.endpointService.getEndpoint('studySingleMetric', studyId, metricId);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  getKriHistorical(studyId: number, kriId: number) {
    const endpoint = this.endpointService.getEndpoint('studyKriHistorical', studyId, kriId);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  getKriHistoricalEvents(studyId: number, kriId: number) {
    const endpoint = this.endpointService.getEndpoint('studyKriHistoricalData', studyId, kriId);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  updateKriCalcState(studyId: number, kriId: number, calcState: boolean) {
    const endpoint = this.endpointService.getEndpoint('studyKriCalcState', studyId, kriId, calcState);
    return this.dataService.update(endpoint, {}).pipe(map((response: any) => response.result));
  }

  // updateKpiCalcState(studyId: number, kpiId: number, calcState: boolean) {
  //   const endpoint = this.endpointService.getEndpoint('studyKpiCalcState', studyId, kpiId, calcState);
  //   return this.dataService.update(endpoint, {}).pipe(map((response: any) => response.result));
  // }

  editStudies(): Observable<Study[]> {
    const endpoint = this.endpointService.getEndpoint('editStudy');
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  getStudiesForAdmin(): Observable<Study[]> {
    const endpoint = this.endpointService.getEndpoint('studiesForAdmin');
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  importStudy(studyId: number, importBody) {
    const endpoint = this.endpointService.getEndpoint('importStudy', studyId);
    return this.dataService.post(endpoint, importBody).pipe(map((response: any) => response.result));
  }

  exportStudy(studyId: number) {
    const endpoint = this.endpointService.getEndpoint('exportStudy', studyId);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  // add units as appropriate, convert to percentage
  formatThresholdValue(threshold) {
    const studyThresholdValue = Number(threshold.studyThresholdValue);
    const siteThresholdValue = Number(threshold.siteThresholdValue);
    const dataType = threshold.calcValueDataType.toLowerCase();
    threshold.studyThresholdDisplayValue = formatThresholdDisplay(studyThresholdValue);
    threshold.siteThresholdDisplayValue = formatThresholdDisplay(siteThresholdValue);
    return threshold;

    function formatThresholdDisplay(val) {
      const formattedVal =  (isPercentageDataType(threshold.calcValueDataType) || threshold.units === '%') ? (parseFloat(val) * 100): val;
      return val ? `${formattedVal} ${threshold.units}` : null;
    }
  }

  updateKriData(entry) {
    entry.trend = [];
    const baseDate = new Date(2017, 4, 2);
    for (let i = 0; i < 3; i++) {
      entry.trend.push(
        [
          new Date(baseDate.getTime() + 30 * i * 24 * 60 * 60 * 1000),
          Math.floor(Math.random() * 100)
        ]
      );
    }
    // entry.score = entry.trend[2][1];
    // entry.color = UtilsService.getColorFromScore(entry.score);
  }

  getRandomKriValue(variance) {
    let value = Math.floor(Math.random() * 100);
    value += variance; // + Math.random(); // add some decimals and increase overall value
    if (value > 100) { // dont let it go over 100%
      value = 100;
      value -= Math.floor(Math.random() * 10); // randomize it a little more.
    }

    if (value < 0) {
      value = 0;
      value += Math.floor(Math.random() * 5); // randomize it a little more.
    }

    return Number(value.toFixed(2));
  }

  getScoreFromKriList(kriList) {
    let sum = 0;
    for (const kri of kriList) {
      sum += kri.value;
    }
    sum /= kriList.length;

    return sum;
  }

  getStudyMetricDetails(studyId: number, metricId: number) {
    const endpoint = this.endpointService.getEndpoint('studyMetricDetails', studyId, metricId);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  getStudyCalcParameters(studyId: number) {
    const endpoint = this.endpointService.getEndpoint('studyCalcParameters', studyId);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  saveStudyCalcParameters(studyId: number, studyParams, reasonForChange, attributeCreateDate) {
    const endpoint = this.endpointService.getEndpoint('studyCalcParameters', studyId);
    return this.dataService.post(endpoint, { config: studyParams, reason: reasonForChange, attributeCreateDate }).pipe(map((response: any) => response.result));
  }

  getStudyBucketScoresHistory(studyId: number, isChartData: boolean) {
    const endpoint = this.endpointService.getEndpoint('studyBucketScoresHistory', studyId, isChartData);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  getStudyMetricScoresHistory(studyId: number, metricId: number, isChartData: boolean) {
    const endpoint = this.endpointService.getEndpoint('studyMetricScoresHistory', studyId, metricId, isChartData);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  applyMetricsToStudies(studies:number[], metrics:any[]){
    const endpoint = this.endpointService.getEndpoint('applyMetricsToStudies');
    return this.dataService.update(endpoint, {studies,metrics});
  }
}
