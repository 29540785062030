import { Customer } from '@app/shared/models/customer.model';

export interface CustomerState {
  customers: Customer[];
  multiSelect: boolean;
  isCustomerSelected?: boolean;
}

export const defaultState: CustomerState = {
  customers: [],
  multiSelect: false,
  isCustomerSelected: false,
};

export function loadInitialAuthState() {
  let state: any;
  const storedString: string = sessionStorage.getItem('RBM-AUTH');
  try {
    const sessionStorageObject = JSON.parse(storedString);

    if (sessionStorageObject) {
      let { custList: customers } = sessionStorageObject;
      const { select: multiSelect, isCustomerSelected, clientId, clientName, navigatorOrgName } = sessionStorageObject;
      if (!customers) {
        customers = [{ clientId, clientName, navigatorOrgName }];
      }
      state = { customers, multiSelect, isCustomerSelected };

    } else {
      state = {...defaultState};
    }

  } catch (error) {
    sessionStorage.clear();
  }
  return state;
}


export const saveCustomerState = (state) => {
  const {clientId, clientName, clientMode, orgId} = state;
  try {
    const storedString: string = sessionStorage.getItem('RBM-AUTH');
    let sessionStorageObject: any = JSON.parse(storedString);
    const updatedCustList = sessionStorageObject.custList.map((cust) => {
        return (cust.orgId === orgId) ? { ...cust, clientId, clientName, clientMode } : cust;
    });

    sessionStorageObject = { ...sessionStorageObject, ...{ clientId }, ...{ clientName }, ...{ clientMode }, custList: updatedCustList};

    sessionStorage.setItem('RBM-AUTH', JSON.stringify(sessionStorageObject));
  } catch (error) {

  }
};
