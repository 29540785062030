<div class = 'site__info-banner pull-card-above'>
  <h1 class="site__info-banner-heading">{{pageTitle}}</h1>
  <div class="site__info-banner-scores" *ngIf="showBucket">
    <h2 *ngIf="complianceScore || complianceScore === 0; else noCompScoreValue" class="site__info-banner-bucket"><span class="site__info-banner_score"
        [ngStyle]="{'background': getScoreColor(complianceScore)}">
        {{complianceScore}}
      </span> COMPLIANCE </h2>
    <ng-template #noCompScoreValue >
      <h2 class="site__info-banner-bucket"><span class="site__info-banner_score" [ngStyle]="{'background': getScoreColor(null), 'color': '#000'}">
          <ert-no-value></ert-no-value>
        </span> COMPLIANCE </h2>
    </ng-template>
    <h2 *ngIf="qualityScore || qualityScore === 0; else noQuaScoreValue" class="site__info-banner-bucket"><span class="site__info-banner_score"
        [ngStyle]="{'background': getScoreColor(qualityScore)}">{{qualityScore}}</span> Quality </h2>
    <ng-template #noQuaScoreValue >
      <h2 class="site__info-banner-bucket"><span class="site__info-banner_score"
          [ngStyle]="{'background': getScoreColor(null), 'color': '#000'}">
          <ert-no-value></ert-no-value>
        </span> QUALITY </h2>
    </ng-template>
  </div>

  <div class="metric__info-banner-scores" *ngIf="showMetric">
     <h4 *ngIf="showReport" class="metric__info-banner-report">
       <button (click)="viewReport()" class="es-button -es-primary">
         <span class="es-icon"><mat-icon svgIcon="pie-white"></mat-icon></span><span>View Report</span>
       </button>
     </h4>
     <h2 *ngIf="metricScore || metricScore == 0" class="metric__info-banner-bucket"><span class="metric__info-banner_score"
         [ngStyle]="{'background': getScoreColor(metricScore)}">{{metricScore}}</span>
       {{metricName}} <span (click)="openDialog()" id="metric-help-info" class="es-icon es-icon-help"></span> </h2>
     <h2 *ngIf="!metricScore && metricScore != 0" class="metric__info-banner-bucket"><span class="metric__info-banner_score"
         [ngStyle]="{'background': getScoreColor(null), 'color': '#000'}">
         <ert-no-value></ert-no-value>
       </span> {{metricName}} <span (click)="openDialog()" id="metric-help-info" class="es-icon es-icon-help"></span>
     </h2>
  </div>
  <div class="metric__info-banner-scores" *ngIf="showOrganizationSettings && isERTAdmin">
    <span class="block-title">Internal Only Mode :</span>
    <label class="switch gap"  [style.color]="(clientMode == 'ERT-Only') ? '#bbb' : '#fff'">OFF</label>
    <label for="switch-example-with-label" class="switch">
      <input type="checkbox" role="switch" id="switch-example-with-label" [checked]="clientMode == 'ERT-Only'" (change)="toggleClientMode()">
      <span [style.color]="(clientMode == 'ERT-Only') ? '#fff' : '#bbb'">ON</span>
    </label>
  </div>
</div>
