export let eventListTableData = [
  {
    siteno: '001',
    pi: 'Jackson, Micheal',
    metricValue: '22 days',
    description: 'Query Rate > 2 STDs than expected',
    action: 'Notify Site(s) for immediate action'
  },
  {
    siteno: '002',
    pi: 'Bryant, Kobe',
    metricValue: '14 days',
    description: 'Query Rate > 3 STDs than expected',
    action: 'Notify Site(s) for immediate action'
  },
  {
    siteno: '003',
    pi: 'Doe, Cookie',
    metricValue: '12 days',
    description: 'Query Rate > 2 STDs than expected',
    action: 'Notify Site(s) for immediate action'
  },
  {
    siteno: '004',
    pi: 'Johnson, Dwayne',
    metricValue: '18 days',
    description: 'Query Rate > 5 STDs than expected',
    action: 'Notify Site(s) for immediate action'
  },
  {
    siteno: '005',
    pi: 'Dhoni, MS',
    metricValue: '21 days',
    description: 'Query Rate > 2 STDs than expected',
    action: 'Notify Site(s) for immediate action'
  },
  
];

export let kriOverviewChart = [
  {
    "lineWidth": 4,
    "curveType": 0,
    "dotRadius": 6,
    "color": "#003865",
    "data": [
      {
        "x": 1536206400000,
        "y": 0.35714285714285715
      },
      {
        "x": 1536292800000,
        "y": 0.35714285714285715
      },
      {
        "x": 1536379200000,
        "y": 0.35714285714285715
      },
      {
        "x": 1536465600000,
        "y": 0.35714285714285715
      },
      {
        "x": 1536552000000,
        "y": 0.35714285714285715
      },
      {
        "x": 1536638400000,
        "y": 0.35714285714285715
      },
      {
        "x": 1536724800000,
        "y": 0.42857142857142855
      },
      {
        "x": 1536811200000,
        "y": 0.42857142857142855
      },
      {
        "x": 1536897600000,
        "y": 0.42857142857142855
      },
      {
        "x": 1536984000000,
        "y": 0.42857142857142855
      },
      {
        "x": 1537070400000,
        "y": 0.42857142857142855
      },
      {
        "x": 1537156800000,
        "y": 0.42857142857142855
      },
      {
        "x": 1537243200000,
        "y": 0.42857142857142855
      },
      {
        "x": 1537329600000,
        "y": 0.42857142857142855
      },
      {
        "x": 1537416000000,
        "y": 0.42857142857142855
      },
      {
        "x": 1537502400000,
        "y": 0.42857142857142855
      },
      {
        "x": 1537588800000,
        "y": 0.42857142857142855
      },
      {
        "x": 1537675200000,
        "y": 0.42857142857142855
      },
      {
        "x": 1537761600000,
        "y": 0.42857142857142855
      },
      {
        "x": 1537848000000,
        "y": 0.42857142857142855
      },
      {
        "x": 1537934400000,
        "y": 0.42857142857142855
      },
      {
        "x": 1538020800000,
        "y": 0.42857142857142855
      },
      {
        "x": 1538107200000,
        "y": 0.42857142857142855
      },
      {
        "x": 1538193600000,
        "y": 0.42857142857142855
      },
      {
        "x": 1538280000000,
        "y": 0.42857142857142855
      },
      {
        "x": 1538366400000,
        "y": 0.42857142857142855
      },
      {
        "x": 1538452800000,
        "y": 0.42857142857142855
      },
      {
        "x": 1538539200000,
        "y": 0.42857142857142855
      },
      {
        "x": 1538625600000,
        "y": 0.42857142857142855
      },
      {
        "x": 1538712000000,
        "y": 0.42857142857142855
      },
      {
        "x": 1538798400000,
        "y": 0.42857142857142855
      },
      {
        "x": 1538884800000,
        "y": 0.42857142857142855
      },
      {
        "x": 1538971200000,
        "y": 0.42857142857142855
      },
      {
        "x": 1539057600000,
        "y": 0.42857142857142855
      },
      {
        "x": 1539144000000,
        "y": 0.42857142857142855
      },
      {
        "x": 1539230400000,
        "y": 0.42857142857142855
      },
      {
        "x": 1539316800000,
        "y": 0.42857142857142855
      },
      {
        "x": 1539403200000,
        "y": 0.42857142857142855
      },
      {
        "x": 1539489600000,
        "y": 0.42857142857142855
      },
      {
        "x": 1539576000000,
        "y": 0.42857142857142855
      },
      {
        "x": 1539662400000,
        "y": 0.5
      },
      {
        "x": 1539748800000,
        "y": 0.5
      },
      {
        "x": 1539835200000,
        "y": 0.5
      },
      {
        "x": 1539921600000,
        "y": 0.5
      },
      {
        "x": 1540008000000,
        "y": 0.5
      },
      {
        "x": 1540094400000,
        "y": 0.5
      },
      {
        "x": 1540180800000,
        "y": 0.5
      },
      {
        "x": 1540267200000,
        "y": 0.5
      },
      {
        "x": 1540353600000,
        "y": 0.5
      },
      {
        "x": 1540440000000,
        "y": 0.5
      },
      {
        "x": 1540526400000,
        "y": 0.5
      },
      {
        "x": 1540612800000,
        "y": 0.5
      },
      {
        "x": 1540699200000,
        "y": 0.5
      },
      {
        "x": 1540785600000,
        "y": 0.5
      },
      {
        "x": 1540872000000,
        "y": 0.4666666666666667
      },
      {
        "x": 1540958400000,
        "y": 0.4666666666666667
      },
      {
        "x": 1541044800000,
        "y": 0.5333333333333333
      },
      {
        "x": 1541131200000,
        "y": 0.5333333333333333
      },
      {
        "x": 1541217600000,
        "y": 0.5333333333333333
      },
      {
        "x": 1541304000000,
        "y": 0.5333333333333333
      },
      {
        "x": 1541394000000,
        "y": 0.5333333333333333
      },
      {
        "x": 1541480400000,
        "y": 0.5333333333333333
      },
      {
        "x": 1541566800000,
        "y": 0.5333333333333333
      },
      {
        "x": 1541653200000,
        "y": 0.5333333333333333
      },
      {
        "x": 1541739600000,
        "y": 0.5333333333333333
      },
      {
        "x": 1541826000000,
        "y": 0.5333333333333333
      },
      {
        "x": 1541912400000,
        "y": 0.5333333333333333
      },
      {
        "x": 1541998800000,
        "y": 0.5333333333333333
      },
      {
        "x": 1542085200000,
        "y": 0.5333333333333333
      },
      {
        "x": 1542171600000,
        "y": 0.5333333333333333
      },
      {
        "x": 1542258000000,
        "y": 0.5333333333333333
      },
      {
        "x": 1542344400000,
        "y": 0.5333333333333333
      },
      {
        "x": 1542430800000,
        "y": 0.5333333333333333
      },
      {
        "x": 1542517200000,
        "y": 0.5333333333333333
      },
      {
        "x": 1542603600000,
        "y": 0.5333333333333333
      },
      {
        "x": 1542690000000,
        "y": 0.5333333333333333
      },
      {
        "x": 1542776400000,
        "y": 0.5333333333333333
      },
      {
        "x": 1542862800000,
        "y": 0.5333333333333333
      },
      {
        "x": 1542949200000,
        "y": 0.5333333333333333
      },
      {
        "x": 1543035600000,
        "y": 0.5333333333333333
      },
      {
        "x": 1543122000000,
        "y": 0.5333333333333333
      },
      {
        "x": 1543208400000,
        "y": 0.5333333333333333
      },
      {
        "x": 1543294800000,
        "y": 0.5333333333333333
      },
      {
        "x": 1543381200000,
        "y": 0.5333333333333333
      },
      {
        "x": 1543467600000,
        "y": 0.5333333333333333
      },
      {
        "x": 1543554000000,
        "y": 0.5333333333333333
      },
      {
        "x": 1543640400000,
        "y": 0.5333333333333333
      },
      {
        "x": 1543726800000,
        "y": 0.5333333333333333
      },
      {
        "x": 1543813200000,
        "y": 0.5333333333333333
      },
      {
        "x": 1543899600000,
        "y": 0.5333333333333333
      },
      {
        "x": 1543986000000,
        "y": 0.5333333333333333
      },
      {
        "x": 1544072400000,
        "y": 0.5333333333333333
      },
      {
        "x": 1544158800000,
        "y": 0.5333333333333333
      },
      {
        "x": 1544245200000,
        "y": 0.5333333333333333
      },
      {
        "x": 1544331600000,
        "y": 0.5333333333333333
      },
      {
        "x": 1544418000000,
        "y": 0.5333333333333333
      },
      {
        "x": 1544504400000,
        "y": 0.5333333333333333
      },
      {
        "x": 1544590800000,
        "y": 0.5333333333333333
      },
      {
        "x": 1544677200000,
        "y": 0.5333333333333333
      },
      {
        "x": 1544763600000,
        "y": 0.5333333333333333
      },
      {
        "x": 1544850000000,
        "y": 0.5333333333333333
      },
      {
        "x": 1544936400000,
        "y": 0.5333333333333333
      },
      {
        "x": 1545022800000,
        "y": 0.5333333333333333
      },
      {
        "x": 1545109200000,
        "y": 0.5333333333333333
      },
      {
        "x": 1545195600000,
        "y": 0.5333333333333333
      },
      {
        "x": 1545282000000,
        "y": 0.5333333333333333
      },
      {
        "x": 1545368400000,
        "y": 0.5333333333333333
      },
      {
        "x": 1545454800000,
        "y": 0.5333333333333333
      },
      {
        "x": 1545541200000,
        "y": 0.5333333333333333
      },
      {
        "x": 1545627600000,
        "y": 0.5333333333333333
      },
      {
        "x": 1545714000000,
        "y": 0.5333333333333333
      },
      {
        "x": 1545800400000,
        "y": 0.5333333333333333
      },
      {
        "x": 1545886800000,
        "y": 0.5333333333333333
      },
      {
        "x": 1545973200000,
        "y": 0.5333333333333333
      },
      {
        "x": 1546059600000,
        "y": 0.5333333333333333
      },
      {
        "x": 1546146000000,
        "y": 0.5333333333333333
      },
      {
        "x": 1546232400000,
        "y": 0.5333333333333333
      },
      {
        "x": 1546318800000,
        "y": 0.5333333333333333
      },
      {
        "x": 1546405200000,
        "y": 0.5333333333333333
      },
      {
        "x": 1546491600000,
        "y": 0.5333333333333333
      },
      {
        "x": 1546578000000,
        "y": 0.5333333333333333
      },
      {
        "x": 1546664400000,
        "y": 0.5333333333333333
      },
      {
        "x": 1546750800000,
        "y": 0.5333333333333333
      },
      {
        "x": 1546837200000,
        "y": 0.5333333333333333
      },
      {
        "x": 1546923600000,
        "y": 0.5333333333333333
      },
      {
        "x": 1547010000000,
        "y": 0.5333333333333333
      },
      {
        "x": 1547096400000,
        "y": 0.5333333333333333
      },
      {
        "x": 1547182800000,
        "y": 0.5333333333333333
      },
      {
        "x": 1547269200000,
        "y": 0.5333333333333333
      },
      {
        "x": 1547355600000,
        "y": 0.5333333333333333
      },
      {
        "x": 1547442000000,
        "y": 0.5333333333333333
      },
      {
        "x": 1547528400000,
        "y": 0.5333333333333333
      },
      {
        "x": 1547614800000,
        "y": 0.5333333333333333
      },
      {
        "x": 1547701200000,
        "y": 0.5333333333333333
      },
      {
        "x": 1547787600000,
        "y": 0.5333333333333333
      },
      {
        "x": 1547874000000,
        "y": 0.5333333333333333
      },
      {
        "x": 1547960400000,
        "y": 0.5333333333333333
      },
      {
        "x": 1548046800000,
        "y": 0.5333333333333333
      },
      {
        "x": 1548133200000,
        "y": 0.5333333333333333
      },
      {
        "x": 1548219600000,
        "y": 0.5333333333333333
      },
      {
        "x": 1548306000000,
        "y": 0.5333333333333333
      },
      {
        "x": 1548392400000,
        "y": 0.5333333333333333
      },
      {
        "x": 1548478800000,
        "y": 0.5333333333333333
      },
      {
        "x": 1548565200000,
        "y": 0.5333333333333333
      },
      {
        "x": 1548651600000,
        "y": 0.6
      },
      {
        "x": 1548738000000,
        "y": 0.6
      },
      {
        "x": 1548824400000,
        "y": 0.6
      },
      {
        "x": 1548910800000,
        "y": 0.6
      },
      {
        "x": 1548997200000,
        "y": 0.6
      },
      {
        "x": 1549083600000,
        "y": 0.6
      },
      {
        "x": 1549170000000,
        "y": 0.6
      },
      {
        "x": 1549256400000,
        "y": 0.6
      },
      {
        "x": 1549342800000,
        "y": 0.6
      },
      {
        "x": 1549429200000,
        "y": 0.6
      },
      {
        "x": 1549515600000,
        "y": 0.6
      },
      {
        "x": 1549602000000,
        "y": 0.6
      },
      {
        "x": 1549688400000,
        "y": 0.6
      },
      {
        "x": 1549774800000,
        "y": 0.6
      },
      {
        "x": 1549861200000,
        "y": 0.6
      },
      {
        "x": 1549947600000,
        "y": 0.6
      },
      {
        "x": 1550034000000,
        "y": 0.6
      },
      {
        "x": 1550120400000,
        "y": 0.6
      },
      {
        "x": 1550206800000,
        "y": 0.6
      },
      {
        "x": 1550293200000,
        "y": 0.6
      },
      {
        "x": 1550379600000,
        "y": 0.6
      },
      {
        "x": 1550466000000,
        "y": 0.6
      },
      {
        "x": 1550552400000,
        "y": 0.6
      },
      {
        "x": 1550638800000,
        "y": 0.6
      },
      {
        "x": 1550725200000,
        "y": 0.6
      },
      {
        "x": 1550811600000,
        "y": 0.6
      },
      {
        "x": 1550898000000,
        "y": 0.6
      },
      {
        "x": 1550984400000,
        "y": 0.6
      },
      {
        "x": 1551070800000,
        "y": 0.6
      },
      {
        "x": 1551157200000,
        "y": 0.6
      },
      {
        "x": 1551243600000,
        "y": 0.6
      },
      {
        "x": 1551330000000,
        "y": 0.6
      },
      {
        "x": 1551416400000,
        "y": 0.6
      },
      {
        "x": 1551502800000,
        "y": 0.6
      },
      {
        "x": 1551589200000,
        "y": 0.6
      },
      {
        "x": 1551675600000,
        "y": 0.6
      }
    ],
    "legend": "Score",
    "units": "",
    "dashPattern": ""
  },
  {
    "lineWidth": 4,
    "curveType": 0,
    "dashPattern": "10,10",
    "color": "#c4d600",
    "data": [
      {
        "x": 1551675600000,
        "y": 0.6
      },
      {
        "x": 1552539600000,
        "y": 0.6357095520367366
      },
      {
        "x": 1553403600000,
        "y": 0.6516341578407925
      },
      {
        "x": 1554267600000,
        "y": 0.6675587636448519
      }
    ],
    "units": "Major PDs / Subject",
    "legend": "Projection",
    "dotRadius": 0
  }
];

export let studiesByScoreSchart = [
  {
    "study": [
      {
        "name": "study1",
        "id": 1
      }
    ],
    "compliance": 3,
    "quality": 2,
    "overall": 6
  },
  {
    "study": [
      {
        "name": "study2",
        "id": 2
      }
    ],
    "compliance": 7,
    "quality": 27,
    "overall": 20
  },
  {
    "study": [
      {
        "name": "study3",
        "id": 3
      }
    ],
    "compliance": 30,
    "quality": 20,
    "overall": 22
  },
  {
    "study": [
      {
        "name": "study4",
        "id": 4
      }
    ],
    "compliance": 80,
    "quality": 90,
    "overall": 30
  },
  {
    "study": [
      {
        "name": "study5",
        "id": 5
      }
    ],
    "compliance": 100,
    "quality": 100,
    "overall": 35
  },
  {
    "study": [
      {
        "name": "study6",
        "id": 6
      }
    ],
    "compliance": 12,
    "quality": 24,
    "overall": 40
  },
  {
    "study": [
      {
        "name": "study7",
        "id": 7
      }
    ],
    "compliance": 36,
    "quality": 76,
    "overall": 45
  },
  {
    "study": [
      {
        "name": "study8",
        "id": 8
      }
    ],
    "compliance": 90,
    "quality": 20,
    "overall": 55
  },
  {
    "study": [
      {
        "name": "study9",
        "id": 9
      }
    ],
    "compliance": 32,
    "quality": 26,
    "overall": 60
  },
  {
    "study": [
      {
        "name": "study10",
        "id": 10
      }
    ],
    "compliance": 40,
    "quality": 40,
    "overall": 62
  },
  {
    "study": [
      {
        "name": "study11",
        "id": 11
      }
    ],
    "compliance": 20,
    "quality": 60,
    "overall": 65
  },
  {
    "study": [
      {
        "name": "study12",
        "id": 12
      }
    ],
    "compliance": 60,
    "quality": 70,
    "overall": 69
  },
  {
    "study": [
      {
        "name": "study13",
        "id": 13
      }
    ],
    "compliance": 33,
    "quality": 80,
    "overall": 50
  },
  {
    "study": [
      {
        "name": "study14",
        "id": 14
      }
    ],
    "compliance": 37,
    "quality": 90,
    "overall": 55
  },
  {
    "study": [
      {
        "name": "study15",
        "id": 15
      }
    ],
    "compliance": 28,
    "quality": 100,
    "overall": 59
  },
  {
    "study": [
      {
        "name": "study16",
        "id": 16
      }
    ],
    "compliance": 48,
    "quality": 84,
    "overall": 27
  },
  {
    "study": [
      {
        "name": "study17",
        "id": 17
      }
    ],
    "compliance": 29,
    "quality": 68,
    "overall": 29
  },
  {
    "study": [
      {
        "name": "study18",
        "id": 18
      }
    ],
    "compliance": 50,
    "quality": 28,
    "overall": 17
  },
  {
    "study": [
      {
        "name": "study19",
        "id": 19
      },
      {
        "name": "study20",
        "id": 20
      },
      {
        "name": "study21",
        "id": 21
      },
      {
        "name": "study22",
        "id": 22
      },
      {
        "name": "study23",
        "id": 23
      },
      {
        "name": "study24",
        "id": 24
      }
    ],
    "compliance": 48,
    "quality": 82,
    "overall": 21
  }
];

export let sitesByScoreSchart = [
  {
    "site": [
      {
        "study_id": 1,
        "no": "site1",
        "id": 1,
        "pi": "Lastname1, Firstname1"
      }
    ],
    "compliance": 3,
    "quality": 2,
    "overall": 6
  },
  {
    "site": [
      {
        "study_id": 1,
        "no": "site2",
        "id": 2,
        "pi": "Lastname2, Firstname2"
      }
    ],
    "compliance": 7,
    "quality": 27,
    "overall": 60
  },
  {
    "site": [
      {
        "study_id": 1,
        "no": "site3",
        "id": 3,
        "pi": "Lastname3, Firstname3"
      }
    ],
    "compliance": 30,
    "quality": 20,
    "overall": 62
  },
  {
    "site": [
      {
        "study_id": 1,
        "no": "site4",
        "id": 4,
        "pi": "Lastname4, Firstname4"
      }
    ],
    "compliance": 80,
    "quality": 90,
    "overall": 50
  },
  {
    "site": [
      {
        "study_id": 1,
        "no": "site5",
        "id": 5,
        "pi": "Lastname5, Firstname5"
      }
    ],
    "compliance": 100,
    "quality": 100,
    "overall": 52
  },
  {
    "site": [
      {
        "study_id": 1,
        "no": "site6",
        "id": 6,
        "pi": "Lastname6, Firstname6"
      }
    ],
    "compliance": 12,
    "quality": 24,
    "overall": 54
  },
  {
    "site": [
      {
        "study_id": 1,
        "no": "site7",
        "id": 7,
        "pi": "Lastname7, Firstname7"
      }
    ],
    "compliance": 36,
    "quality": 76,
    "overall": 56
  },
  {
    "site": [
      {
        "study_id": 1,
        "no": "site8",
        "id": 8,
        "pi": "Lastname8, Firstname8"
      }
    ],
    "compliance": 90,
    "quality": 20,
    "overall": 57
  },
  {
    "site": [
      {
        "study_id": 1,
        "no": "site9",
        "id": 9,
        "pi": "Lastname9, Firstname9"
      }
    ],
    "compliance": 32,
    "quality": 26,
    "overall": 58
  },
  {
    "site": [
      {
        "study_id": 1,
        "no": "site10",
        "id": 10,
        "pi": "Lastname10, Firstname10"
      }
    ],
    "compliance": 40,
    "quality": 40,
    "overall": 59
  },
  {
    "site": [
      {
        "study_id": 1,
        "no": "site11",
        "id": 11,
        "pi": "Lastname11, Firstname11"
      }
    ],
    "compliance": 20,
    "quality": 60,
    "overall": 50
  },
  {
    "site": [
      {
        "study_id": 1,
        "no": "site12",
        "id": 12,
        "pi": "Lastname12, Firstname12"
      }
    ],
    "compliance": 60,
    "quality": 70,
    "overall": 40
  },
  {
    "site": [
      {
        "study_id": 1,
        "no": "site13",
        "id": 13,
        "pi": "Lastname13, Firstname13"
      }
    ],
    "compliance": 33,
    "quality": 80,
    "overall": 44
  },
  {
    "site": [
      {
        "study_id": 1,
        "no": "site14",
        "id": 14,
        "pi": "Lastname14, Firstname14"
      }
    ],
    "compliance": 37,
    "quality": 90,
    "overall": 45
  },
  {
    "site": [
      {
        "study_id": 1,
        "no": "site15",
        "id": 15,
        "pi": "Lastname15, Firstname15"
      }
    ],
    "compliance": 28,
    "quality": 100,
    "overall": 49
  },
  {
    "site": [
      {
        "study_id": 1,
        "no": "site16",
        "id": 16,
        "pi": "Lastname16, Firstname16"
      }
    ],
    "compliance": 48,
    "quality": 84,
    "overall": 30
  },
  {
    "site": [
      {
        "study_id": 1,
        "no": "site17",
        "id": 17,
        "pi": "Lastname17, Firstname17"
      }
    ],
    "compliance": 29,
    "quality": 68,
    "overall": 33
  },
  {
    "site": [
      {
        "study_id": 1,
        "no": "site18",
        "id": 18,
        "pi": "Lastname18, Firstname18"
      }
    ],
    "compliance": 50,
    "quality": 28,
    "overall": 36
  },
  {
    "site": [
      {
        "study_id": 1,
        "no": "site19",
        "id": 19,
        "pi": "Lastname19, Firstname19"
      },
      {
        "study_id": 1,
        "no": "site20",
        "id": 20,
        "pi": "Lastname20, Firstname20"
      },
      {
        "study_id": 1,
        "no": "site21",
        "id": 21,
        "pi": "Lastname21, Firstname21"
      },
      {
        "study_id": 1,
        "no": "site22",
        "id": 22,
        "pi": "Lastname22, Firstname22"
      },
      {
        "study_id": 1,
        "no": "site23",
        "id": 23,
        "pi": "Lastname23, Firstname23"
      },
      {
        "study_id": 1,
        "no": "site24",
        "id": 24,
        "pi": "Lastname24, Firstname24"
      }
    ],
    "compliance": 48,
    "quality": 82,
    "overall": 60
  }
];

export let lowestPerformingchart = [
  {
    "study": {
      "name": "study1",
      "id": 1
    },
    "site": {
      "no": "006",
      "id": 6
    },
    "compliance": 3,
    "quality": 2,
    "overall": 5
  },
  {
    "study": {
      "name": "study1",
      "id": 1
    },
    "site": {
      "no": "020",
      "id": 20
    },
    "compliance": 3,
    "quality": 3,
    "overall": 6
  },
  {
    "study": {
      "name": "study1",
      "id": 1
    },
    "site": {
      "no": "009",
      "id": 9
    },
    "compliance": 4,
    "quality": 4,
    "overall": 8
  },
  {
    "study": {
      "name": "study2",
      "id": 1
    },
    "site": {
      "no": "001",
      "id": 1
    },
    "compliance": 5,
    "quality": 5,
    "overall": 10
  },
  {
    "study": {
      "name": "study6",
      "id": 1
    },
    "site": {
      "no": "002",
      "id": 6
    },
    "compliance": 6,
    "quality": 6,
    "overall": 12
  },
  {
    "study": {
      "name": "study4",
      "id": 1
    },
    "site": {
      "no": "004",
      "id": 4
    },
    "compliance": 7,
    "quality": 7,
    "overall": 14
  },
  {
    "study": {
      "name": "study8",
      "id": 1
    },
    "site": {
      "no": "002",
      "id": 2
    },
    "compliance": 8,
    "quality": 8,
    "overall": 16
  },
  {
    "study": {
      "name": "study9",
      "id": 1
    },
    "site": {
      "no": "002",
      "id": 19
    },
    "compliance": 9,
    "quality": 9,
    "overall": 18
  },
  {
    "study": {
      "name": "study11",
      "id": 1
    },
    "site": {
      "no": "013",
      "id": 13
    },
    "compliance": 10,
    "quality": 10,
    "overall": 20
  },
  {
    "study": {
      "name": "study12",
      "id": 1
    },
    "site": {
      "no": "116",
      "id": 116
    },
    "compliance": 15,
    "quality": 15,
    "overall": 30
  }
];