import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from '@app/core/services/auth.service';
import { Subject, of } from 'rxjs';
import { takeUntil, catchError } from 'rxjs/operators';
import { GssoLoginModel } from '@app/login/models/gsso-login.model';
import { Login, Authenticated } from '@app/core/store/auth/auth.reducer';
import { ShowMultiCustomer, ResetCustomer } from '@app/core/store/customer/customer.actions';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { GlobalHeaderService, BreadCrumbOption } from '@app/shared/services/global-header.service';
import { BreadcrumbConfigService } from '@app/shared/services/breadcrumb-config.service';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss']
})
export class AuthCallbackComponent implements OnInit, OnDestroy {
  code: string;
  state: string;
  loading: Boolean = false;
  //@HostBinding('class.hideGssoIframe-host')  hideGssoIframe: Boolean = true;
 // hideGSSOIframe = Observable.of(true).delay(2000)
  // public isSelectCustomer: Boolean = false;
  public multiSelect: Boolean = false;

  private unsubscribe$: Subject<void> = new Subject<void>();
  private customerList: [];

  readonly NOT_AUTHENTICATED = 'NOT_AUTHENTICATED';
  readonly MULTI_LOGIN = 'MULTI_LOGIN';
  readonly AUTHENTICATED = 'AUTHENTICATED';

  constructor( private route: ActivatedRoute,
    private store: Store<any>,
    private authService: AuthService,
    private sessionStorageService: SessionStorageService,
    private globalHeaderService: GlobalHeaderService,
    private brdCrmbConfig: BreadcrumbConfigService
    ) {
    this.route.params.subscribe(params => {
      this.code = params.code;
      this.state = params.state;
    })
  }

  ngOnInit() {
    var authData = {
      code : this.code,
      state: this.state
    }
    sessionStorage.setItem('RBM-AUTH-CONFIG', JSON.stringify(authData));
    
    this.authService.setDidHandleLogin(false);
    const handleLoginCallback = this.handleLoginCallback.bind(this);

    if(!this.authService.getDidHandleLogin()) {
      handleLoginCallback(authData);
    }

    this.store.select('customerState')
    .pipe(takeUntil(this.unsubscribe$), catchError(error => of(`Bad Promise: ${error}`)))
    .subscribe(data => {
      this.customerList = data.customers;
      // this.isSelectCustomer = data.isSelectCustomer;
      this.multiSelect = data.multiSelect;
    });
    this.globalHeaderService.getHeader().then((header) => {
      if(this.customerList.length > 1){                          //Select Org only when user has access to more than one Org
        header.addBreadcrumbs([{label: 'Select Org'}]);
      }
      header.subscribe('logout', this.globalHeaderService.logoutHandler());
      header.subscribe('session-expired', this.globalHeaderService.sessionExpHandler());
    });
  }

  private loginStatus(): string {
    const isAuthenticated = this.authService.isAuthenticated();
    const authKey = this.sessionStorageService.getItem('RBM-AUTH');
    if(authKey) {
      authKey.multiSelect = true;
      this.sessionStorageService.setItem('RBM-AUTH', authKey);
    }

    if (!isAuthenticated) { return this.NOT_AUTHENTICATED; }
    if (isAuthenticated && authKey.multiSelect) { return this.MULTI_LOGIN; }
    if (isAuthenticated && !authKey.multiSelect) { return this.AUTHENTICATED; }
  }

/**
*  Handle Authentication:
*  1 - dispatch login event if not authenticated
*  2 - dispatch Show multiple customer selection for multiple logins
*  3 - dispatch Authenticated when a user is already Authenticated
*/
  public handleLoginCallback(event: GssoLoginModel) {
    const LOGIN_STATUS = this.loginStatus();

    switch (LOGIN_STATUS) {
      case this.NOT_AUTHENTICATED:
        this.store.dispatch(new Login(event));
        break;

      case this.AUTHENTICATED:
        this.authService.setDidHandleLogin(true);
        this.store.dispatch(new Authenticated());
        break;

      case this.MULTI_LOGIN:
        this.store.dispatch(new ShowMultiCustomer());
        break;
    }
  }

   ngOnDestroy() {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
   }

}
