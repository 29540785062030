import { UtilsService } from '@app/shared/services/utils.service';
import { Component, OnInit, OnDestroy, Input, AfterViewInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'ert-mini-bar-chart',
  templateUrl: './mini-bar-chart.component.html',
  styleUrls: ['./mini-bar-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MiniBarChartComponent implements OnInit, OnDestroy {
  static idGen = 0;
  @ViewChild('chart', {static: true}) chartContainer: ElementRef;
  @Input() private data: Array<any>;
  @Input() private height: number;

  private svg: any;
  private xScale: any;
  private yScale: any;

  private margin: any = { top: 1, bottom: 1, left: 1, right: 1};
  private chart: any;
  private width: number;
  private firstDraw: boolean;
  private xDomain: any;
  private yDomain: any;
  private tip: any;
  private id: string;

  constructor() {
    this.firstDraw = true;
    this.id = (MiniBarChartComponent.idGen++).toString();
  }

  ngOnInit() {
    if (! this.data) {
      this.data = [];
      const baseDate = new Date(2017, 4, 2);
      for (let i = 0; i < 3; i++) {
        this.data.push(
          [
            new Date(baseDate.getTime() + 30 * i * 24 * 60 * 60 * 1000),
            Math.floor(Math.random() * 100)
          ]
        );
      }
    }
    if (this.data) {
      if (! this.height) {
        this.height = 100;
      }
      // adjust margins
      // this.height -= (this.margin.top + this.margin.bottom);
      this.tip = d3.select('body').append('div')
        .attr('id', 't' + this.id)
        .attr('class', 'chartToolTip')
        .style('opacity', 0);
      this.drawChart();
      this.firstDraw = false;
    }
    window.addEventListener('resize', this.drawChart.bind(this));
  }

  ngOnDestroy () {
    window.removeEventListener('resize', this.drawChart.bind(this));
    this.tooltipOff();
    d3.select('#t' + this.id).remove();
  }

  drawChart() {
    const element = this.chartContainer.nativeElement;
    this.width = element.offsetWidth - this.margin.left - this.margin.right;
    this.width = this.width < 45 ?  80 : this.width;
    if (! this.firstDraw) {
      d3.select(element).selectAll('svg').remove();
      this.svg = null;
    }
    // define X & Y domains
    this.xDomain = this.data.map(d => d[0]);
    this.yDomain = [0, 100]; // d3.max(this.data, d => d[1])];
    // create scales
    this.xScale = d3.scaleBand()
      .padding(0.1)
      .domain(this.xDomain)
      .rangeRound([0, this.width - 40]);
    this.yScale = d3.scaleLinear()
      .domain(this.yDomain)
      .range([this.height - 5, 0]);



    this.svg = d3.select(element).append('svg')
      .attr('class', 'mini-chart')
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('transform', `translate(0,0)`);

       this.svg.selectAll('.bar')
      .data(this.data)
      .enter().append('rect')
      .attr('class', 'bar')
      .attr('y', d => this.yScale(d[1]))
      .attr('x', d => this.xScale(d[0]))
      .attr('width', () => this.xScale.bandwidth())
      .attr('height', 0)
      .style('fill', d => UtilsService.getColorFromScore(d[1]))

      .on('click', d => this.tooltipOff())
      .on('mouseover', (d, i, n)  => this.tooltipShow(d, i, n))
      .on('mouseout', d => this.tooltipOff())
      //
      .transition()
      .delay((d, i) => i * 5)
      .attr('y', d => this.yScale(d[1] + 5))
      .attr('height', d => this.height - this.yScale(d[1]));
  }

  tooltipShow(d, i, n) {
    this.tip.transition()
      .duration(300)
      .style('opacity', .9);
    this.tip
      .html(
      () => {
        const d0 = d3.timeFormat('%d-%b-%Y')(d[0]);
        return `${d0}<br>Score: ${d[1]}`;
      })
      .style('display', 'inline-block')
      .style('left', (d3.event.pageX) + 'px')
      .style('top', (d3.event.pageY - 30) + 'px');
    }

  tooltipOff() {
    this.tip.transition()
      .duration(300)
      .style('opacity', 0);
  }

}
