import { ErrorNotificationService } from './../../core/services/error-notification.service';
import { StudyService } from '@app/shared/services/study.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, FormControl, Validators, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ToAdminService } from './../to-admin.service';
import { ToastrService } from 'ngx-toastr';
import { SetBreadCrumbs } from '@app/core/store/breadcrumbs/breadcrumbs.actions';
import { forkJoin, iif, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';


interface OdsStudyConfig {
  target_id: string;
  seq: number;
  source_study_oid: string;
  study_oid: string;
  active: boolean;
}
@Component({
  selector: 'ert-add-data-connection',
  templateUrl: './add-data-connection.component.html',
  styleUrls: ['./add-data-connection.component.scss']
})
export class AddDataConnectionComponent implements OnInit {
  addDataConnectionForm: UntypedFormGroup;
  dataSubsets = [
    'ODS',
    // 'XClinical',
  ];
  ods = {
    fields: [
      'dataType',
      'sourceUrl',
      'envID'
    ],
    mustConfigDataTypes: ['site', 'subject'],
    dataTypesMapper: {
      dcr: 'DCR & Query Trending',
      src_customercare: 'Help Desk Stats',
      diary: 'Diary Compliance',
      visitcomp: 'Visit Compliance',
      ecgquality: 'ECG Analysis',
      assessment: 'Assessment'
    }
  };

  odsFields: UntypedFormArray;
  odsStudyConfigList = [];

  // behavior for slider
  sliderConfig = {
    min: 0,
    max: 24,
    step: 1,
  };

  subset: string;
  clientList: [];
  selectedClientId: number;
  selectedEnvID: number;
  studies: any[];
  selectedStudy;

  @ViewChild('addDataConnectionFormElem') addDataConnectionFormElem;

  constructor(
    private store: Store<any>,
    private toAdminService: ToAdminService,
    private studyService: StudyService,
    private toastr: ToastrService,
    private fb: UntypedFormBuilder,
    private errorNotificationService: ErrorNotificationService,
  ) {
    this.store.dispatch(new SetBreadCrumbs({
      crumbs: [
        { text: 'TO Admin' },
        { text: 'Data Connection' }
      ]
    }));
  }

  ngOnInit() {
    this.addDataConnectionForm = this.fb.group({
      clientId: ['', Validators.required],
      srcName: ['', Validators.required],
      srcStudyId: ['', Validators.required],
      studyoid: ['', Validators.required],
      ods: this.fb.array([]),
    });

    this.toAdminService.getAllClients().subscribe((allClients) => {
      this.clientList = allClients;
    });

  }

  onStudySelected(studyId) {
    if (this.subset.toLocaleLowerCase() === 'ods') { return this.sourceConfigList(studyId) };
  }

  getOdsDataTypeText(name) {
    const cleanName =  name.split('_')[1] || name;
    const dataTypes = Object.keys(this.ods.dataTypesMapper);
    const matchType = dataTypes.find((type) => type.includes(cleanName || name));
    return this.ods.dataTypesMapper[matchType] || name;
  }

  /**
   * Build a list of configs from  ODs master list
   * and merge it with data for the ones configured from study config
   */
  sourceConfigList(studyId) {
    this.toAdminService.getSourceConfigList('ods', this.selectedClientId)
      .subscribe((configMasterList) => {
        this.toAdminService.getSourceStudyConfig('ods', this.selectedClientId, studyId)
          .subscribe((studyConfigList) => {
            this.odsStudyConfigList = [];
            const uniqueConfigNameList = []; // site an subject are always configured
            const study = this.getStudy(this.selectedStudy) || {};
            // ODS Master List of configs
            configMasterList.map((configName: string) => {
              const defaultConfig = {
                target_id: configName, seq: 0, source_study_oid: study['study_oid'] || '', active: false,
                study_oid: study['study_oid']
              };
              // studyCurrentConfig List of current configs for a study
              studyConfigList.forEach(studyCurrentConfig => {
                let { target_id, source_study_oid = defaultConfig.source_study_oid, active, study_oid } = studyCurrentConfig;

                if (studyCurrentConfig.target_id === configName && !uniqueConfigNameList.includes(configName)) {
                  // find  matched targetId config in study Config
                  const studyFullConfig = { ...defaultConfig, target_id, source_study_oid, active, study_oid };
                  this.odsStudyConfigList.push(studyFullConfig);
                  uniqueConfigNameList.push(configName);
                }
              });

              if (!uniqueConfigNameList.includes(configName)) {
                this.odsStudyConfigList.push(defaultConfig);
              }
            });
          });
      });
  }

  createOdsConnectionFields(): UntypedFormGroup {
    return this.fb.group({
      envID: [''],
      dataType: [''],
      apiUrl: [''],
    });
  }

  get odsConnectionFields() {
    return this.addDataConnectionForm.get('ods') as UntypedFormArray;
  }

  addODSConnectionFields(): void {
    if(!this.odsConnectionFields.length) {
      this.odsConnectionFields.push(this.createOdsConnectionFields());
    };
  }

  removeODSConnectionFields(index) {
    while (this.odsConnectionFields.length) {
      this.odsConnectionFields.removeAt(index);
      this.selectedEnvID = null;
    }
  }

  showSubsetFields() {
    if (this.subset && this.subset.toLowerCase() === this.dataSubsets[0].toLowerCase()) {
      this.addODSConnectionFields();
    } else {
      this.removeODSConnectionFields(0);
    }
  }

  selectCustomer(_event: any, clientId) {
    if (_event.isUserInput) {
      this.selectedClientId = clientId;
      this.selectedStudy = null;
    }
  }

  getStudy(studyId) {
    return this.studies.filter(study => study.src_study_id === studyId)[0];
  }

  updateStudies(event) {
    this.addDataConnectionForm.controls['srcStudyId'].setErrors({ 'noStudies': false });
    if (event && this.selectedClientId) {
      return this.toAdminService.getClientStudies(this.selectedClientId).subscribe((allStudies) => {
        this.studies = allStudies;
        if (!this.studies.length) {
          this.addDataConnectionForm.controls['srcStudyId'].setErrors({ 'noStudies': true });
        }
      });
    }
    return this.studies = [];
  }

  /**
  *  site an subject are always configured
  *  They allways get SEQ 1 and 2
   */
  setOdsConfigSequenceOrder() {
    let configSeq = 2; // site an subject are seq  1 and 2
    this.odsStudyConfigList.forEach(config => {
      if (this.ods.mustConfigDataTypes.includes(config.target_id)) {
        config.seq = this.ods.mustConfigDataTypes.indexOf(config.target_id) + 1;
        config.active = true;
      } else if (config.active) {  // only set seq for active configs
        config.seq = ++configSeq;
      }
    });
  }

  resetForm() {
    this.removeODSConnectionFields(0);
    this.addDataConnectionFormElem.resetForm();
  }

  saveDataConnection() {
    let odsFields: { apiUrl?: string} = { };
    let dataSourceConfig;
    if (this.addDataConnectionForm.valid) {
      const isOds = this.subset.toLocaleLowerCase().includes('ods');

      if (isOds) {
        odsFields['apiUrl'] = this.addDataConnectionForm.value.ods[0].apiUrl;
        this.setOdsConfigSequenceOrder();
        dataSourceConfig = { config: this.odsStudyConfigList };
      }

      const connection = {
        clientId: this.selectedClientId,
        srcStudyId: this.addDataConnectionForm.value.srcStudyId,
        studyoid: this.addDataConnectionForm.value.studyoid,
        srcName: this.addDataConnectionForm.value.srcName,
        ...(isOds ? { envID: this.selectedEnvID } : undefined),
        ...(isOds ? { config: JSON.stringify(odsFields) } : undefined)
        // version: this.addDataConnectionForm.value.version,
      };
      this.toAdminService.addConnection(connection).pipe(
        mergeMap((data) =>
          iif(() => isOds,
            this.toAdminService.addSourceStudyConfig(this.subset.toLocaleLowerCase(), this.selectedClientId, this.selectedStudy, dataSourceConfig),
            of(data)
          ))
      ).subscribe(data => {
        this.toastr.success('Data connection added successfully.');
        this.resetForm();
      }, err => {
        this.errorNotificationService.notifyError('Error adding data connection.', err);
      });
    }
  }
}
