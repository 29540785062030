import { Component, OnInit, ViewChild, Input, Injector, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AddUserFunc } from '@shared/models/user.model';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorNotificationService } from './../../../../core/services/error-notification.service';

interface AddUserDialogData {
  titleText: string;
  addUserFunc: AddUserFunc;
  next: (res) => void;
  error: (err) => void;
}

@Component({
  selector: 'ert-add-user-modal',
  templateUrl: './add-user-modal.component.html',
  styleUrls: ['./add-user-modal.component.scss']
})
export class AddUserModalComponent implements OnInit {

  @ViewChild('inviteUserForm') inviteUserForm;

  titleText;
  buttonActive = false;
  buttonText = 'Send Invite';

  userForm: UntypedFormGroup = new UntypedFormGroup({
    suffix: new UntypedFormControl('', [Validators.max(80)]),
    prefix: new UntypedFormControl('', Validators.max(80)),
    firstName: new UntypedFormControl('', [Validators.required, Validators.max(80)]),
    lastName: new UntypedFormControl('', [Validators.required, Validators.max(80)]),
    email: new UntypedFormControl('', [Validators.required, Validators.email, Validators.max(80)]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddUserDialogData,
    private dialogRef: MatDialogRef<AddUserModalComponent>,
  ) {
    this.titleText = this.data.titleText;
  }

  ngOnInit() {
  }

  resetForm() {
    this.inviteUserForm.resetForm();
  }

  sendInvite() {
    this.buttonActive = true;
    this.buttonText = 'Sending..';

    const formValue = this.userForm.value;

    // filter out blank values of user form
    const userInvitation: any = Object.keys(this.userForm.value)
      .reduce((accum, prop) => {
        if (formValue[prop]) {
          accum[prop] = formValue[prop].trim();
        }
        return accum;
      }, {});
    userInvitation.srcUrl = location.origin;
    this.data.addUserFunc(userInvitation).toPromise()
      .then((res) => {
        this.data.next(res);
        this.buttonActive = false;
        this.buttonText = 'Send Invite';
        this.resetForm();
        this.dialogRef.close();
      }).catch(err => {
        this.data.error(err);
        this.buttonActive = false;
        this.buttonText = 'Send Invite';
      });
  }

}
