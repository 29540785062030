<mat-card class="add-customer-form_card">
  <mat-card-title>
    <h2>Add Customer</h2>
  </mat-card-title>
<form [formGroup]="addCustomerForm" class="add-customer-form" #addCustomerFormElem="ngForm" (ngSubmit)="saveCustomer()"
  autocomplete="off">
    <div>
      <mat-form-field>
        <input matInput placeholder="Client Name *" formControlName="clientName">
      </mat-form-field>
    </div>
    <div>
    <mat-form-field>
        <mat-select formControlName="clientType" placeholder="Client Type *">
          <mat-option *ngFor="let clientTypeName of clientTypeList" [value]="clientTypeName">{{clientTypeName}}</mat-option>
        </mat-select>
    </mat-form-field>
    </div>
    <mat-form-field>
      <input matInput placeholder="Client Admin Email *" formControlName="clientAdminEmail" type="email">
      <mat-error *ngIf="addCustomerForm.controls['clientAdminEmail'].errors && addCustomerForm.controls['clientAdminEmail'].errors.emailError">
        {{addCustomerForm.controls['clientAdminEmail'].errors.emailError}}</mat-error>
    </mat-form-field>
    <div class="add-form-container_button">
      <button class="es-button -es-primary" type="submit" [disabled]="addCustomerForm.invalid">Create</button>
    </div>
  </form>
</mat-card>
