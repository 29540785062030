import { Action } from '@ngrx/store';

export enum TabswitchActionTypes {
  SWITCH_TAB = '[Tableau] TAB_SWITCH',
}

export class ActionTabSwitch implements Action {
  readonly type = TabswitchActionTypes.SWITCH_TAB;
  constructor(public payload: any) { }
}

export type TabswitchActions = ActionTabSwitch;
