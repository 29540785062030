<mat-card class="treemap-card">
    <mat-spinner *ngIf="dataCheck === 'unchecked'" diameter="50"></mat-spinner>
  <div class="header" *ngIf="alertPermCheck">
    <!-- <ert-observation></ert-observation> -->
  </div>
  <div class = "kri-category__card" *ngIf="dataCheck === 'valid'">
  <ert-kri-category-table
   [dataSource]="dataTableStudies"
   [displayedColumns]="displayedColumns"
   [detailsDataColumns]="detailsDataColumns"
   [view]="views.CROSSITE_VIEW">
  </ert-kri-category-table>
</div>
<div *ngIf="dataCheck === 'invalid'" class="es-status-sign">
    <span class="es-icon es-icon-magnifier"></span>
    <span class="text">No data to display</span>
  </div>
</mat-card>
