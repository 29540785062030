import { DataService } from './data.service';
import { User, formatUser, UserInvitation } from '@shared/models/user.model';
import { EndpointService } from '@shared/services/endpoint.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class UserService {
  constructor(
    private endpointService: EndpointService,
    private dataService: DataService<any>
  ) {}

  getUsers(): Observable<User[]> {
    const endpoint = this.endpointService.getEndpoint('allUsers');

    return this.dataService
      .getAll(endpoint)
      .pipe(map((response: any) => response.result))
      .pipe(map(users => users.map(formatUser)));
  }

  addUserToCustomer(userId: number) {
    const endpoint = this.endpointService.getEndpoint('singleUser', userId);

    return this.dataService
      .post(endpoint, {})
      .pipe(map((response: any) => response.result));
  }

  removeUserFromCustomer(userId: number) {
    const endpoint = this.endpointService.getEndpoint('singleUser', userId);

    return this.dataService
      .delete(endpoint, {})
      .pipe(map((response: any) => response.result));
  }

  getUserRoles(userId: number) {
    const endpoint = this.endpointService.getEndpoint('userRoles', userId);

    return this.dataService
      .getAll(endpoint)
      .pipe(map((response: any) => response.result));
  }

  removeUserRole(userId: number, roleId: number, params) {
    const endpoint = this.endpointService.getEndpoint(
      'singleUserRole',
      userId,
      roleId
    );

    return this.dataService
      .delete(endpoint, params)
      .pipe(map((response: any) => response.result));
  }

  addUserRole(userId: number, roleId: number, params) {
    const endpoint = this.endpointService.getEndpoint(
      'singleUserRole',
      userId,
      roleId
    );

    return this.dataService
      .post(endpoint, params)
      .pipe(map((response: any) => response.result));
  }

  inviteUser(userInvitation: UserInvitation) {
    const endpoint = this.endpointService.getEndpoint('inviteUser');

    return this.dataService
      .post(endpoint, userInvitation)
      .pipe(map((response: any) => response.result));
  }
}
