import { AuthGuard } from '@core/guards/auth.guard';
import { AddDefaultStudyConfigComponent } from './add-default-study-config/add-default-study-config.component';
import { AddDataConnectionComponent } from './add-data-connection/add-data-connection.component';
import { AddCustomerComponent } from './add-customer/add-customer.component';
import { AddStudyComponent } from './add-study/add-study.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ToAdminComponent } from './to-admin.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { TOAdminRootGuard, AdminLevelCheckGuard } from '@app/core/guards/permguard.guard';

const routes: Routes = [
  {
    path: 'dashboard/settings/toadmin',
    component: ToAdminComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AdminLevelCheckGuard],
    children: [
      {path: '', redirectTo: 'customer', pathMatch: 'full'},
      {path: 'customer', component: AddCustomerComponent, canActivate: [AuthGuard, AdminLevelCheckGuard]},
      {path: 'study', component: AddStudyComponent, canActivate: [AuthGuard, AdminLevelCheckGuard]},
      {path: 'user-management', component: UserManagementComponent, canActivate: [AuthGuard, TOAdminRootGuard] },
      { path: 'data-connection', component: AddDataConnectionComponent, canActivate: [AuthGuard, AdminLevelCheckGuard] },
      { path: 'study-config', component: AddDefaultStudyConfigComponent, canActivate: [AuthGuard, AdminLevelCheckGuard] }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class ToAdminRoutingModule { }
