import { AuthService } from '@core/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'ert-app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})

export class ErrorComponent implements OnInit {
  title: string;
  message: string;
  reset: boolean;
  previousRoute: any; // Variable to store the previous route

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private location: Location // Inject the Location service
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.title = params['title'];
      this.message = params['message'];
      this.reset = params['reset'];
    });

    // Store the previous route on component initialization
    this.logRoute();
  }

  // Function to log the previous route
  logRoute() {
    this.previousRoute = this.location.path();
    console.log('Previous Route:', this.previousRoute);
  }

  navigateBack() {
    if (this.reset) {
      this.authService.logout();
    }

    // Navigate to the login page
    this.router.navigateByUrl('/login');
  }
}
