import { SpinnerService } from './spinner.service';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';
import { ErrorNotificationService } from './error-notification.service';
import { Injectable, OnDestroy, Injector } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { tap, delay } from 'rxjs/operators';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { AuthService } from '@core/services/auth.service';
// import { ActionAppSetUrl } from '@app/core/store/app.actions';

@Injectable()
export class HttpDataInterceptor implements HttpInterceptor, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private token: string;
  public urlWithTaskDetails: any = {};
  private totalRequests = 0; // To handler multiple requests from the same page at the same time.

  constructor(
    private authService: AuthService,
    private loaderService: SpinnerService,
    private errorNotification: ErrorNotificationService

  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.totalRequests++;
    // this.loaderService.show(); // Show loading indicator

    // Check if token is valid (isAuthenticated) otherwise set it to null
    this.token = this.authService.isAuthenticated() ? this.authService.geToken() : null;

    // include authorization token with every request
    const authReq = this.token ? this.addToken(req, this.token) : req;

    return next.handle(authReq).pipe(
      delay(150),
      tap(event => {
        if (event instanceof HttpResponse) {
          //this.canHideLoader(); // Hide loading indicator
          // this.loaderService.hide();
        }
      }, (error) => {
          //this.canHideLoader();
          this.loaderService.hide();
        if (error && error.url && error.url.search('/login/complete') > -1) {
          return this.errorNotification.showErrorPage({
            title: 'CUSTOMER SELECTION',
            message: 'There\'s an issue with your account, contact your administrator.',
            reset: true
          });
        }
      })
    );
  }

  /*
  handle multiple requests at the same time from a page.
  only hide loader after the last request
  */
  private canHideLoader() {
    this.totalRequests--;
    if (this.totalRequests === 0) {
      this.loaderService.hide();
    }
  }
  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
