import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Injectable, NgZone } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { NotificationComponent } from '@app/shared/notification/notification.component';

interface ErrorType {
  title: string;
  message: string;
  reset?: boolean;
}
@Injectable()
export class ErrorNotificationService {
  public config: MatSnackBarConfig = {
    panelClass: ['mat-notification-backgroud', 'mat-error-background-red'],
    // duration: 9000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private zone: NgZone
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
          // clear snackBar messages
        this.snackBar.dismiss();
      }
    });
  }

  public clear() {
    this.snackBar.dismiss();
  }

  /**
   * Show Snackbar error notification bar
   *
   * @param string errorMesssge
   */
  public notifyError(customMessage: string, error: any): void {
    this.zone.run(() => {
      const snackBar = this.snackBar.openFromComponent(NotificationComponent, {
        data: { message: `${customMessage} (${error.message})`, icon: 'es-icon es-icon-alert-danger', }, ...this.config
      });
      if (error.status === 401) {
        throw error;
      }
    });
  }

  /**
   * Show Snackbar error notification bar
   *
   * @param string errorMesssge
   */
  public notifyErrorMessageOnly(customMessage: string): void {
    this.zone.run(() => {
      const snackBar = this.snackBar.openFromComponent(NotificationComponent, {
        data: { message: `${customMessage}`, icon: 'es-icon es-icon-alert-danger', }, ...this.config
      });
    });
  }

  /**
   * Show Snackbar warning notification bar
   *
   * @param string warningMesssge
   */
  public notifyWarning(warningMesssge: string): void {
    this.zone.run(() => {
      const config = { ...this.config, panelClass: ['mat-notification-backgroud', 'mat-alert-warning-background'] };
      const snackBar = this.snackBar.openFromComponent(NotificationComponent, {
        data: { message: warningMesssge, icon: 'es-icon es-icon-alert-warning', }, ...config
      });
    });
  }
  /**
   * Show Snackbar info notification bar
   *
   * @param string infoMesssge
   */
  public notifyInfo(infoMesssge: string): void {
    this.zone.run(() => {
      const config = { ...this.config, panelClass: ['mat-notification-backgroud', 'mat-alert-info-background'] };
      const snackBar = this.snackBar.openFromComponent(NotificationComponent, {
        data: { message: infoMesssge, icon: 'es-icon es-icon-alert-info', }, ...config
      });
    });
  }
  /**
   * Show Snackbar success notification bar
   *
   * @param string successMesssge
   */
  public notifySuccess(successMesssge: string): void {
    this.zone.run(() => {
      const config = { ...this.config, panelClass: ['mat-notification-backgroud', 'mat-alert-success-background'] };
      const snackBar = this.snackBar.openFromComponent(NotificationComponent, {
        data: { message: successMesssge, icon: 'es-icon es-icon-alert-success', }, ...config
      });
    });
  }
  /**
   * Navigates to the ERROR page
   *  must pass : title and message
   * @param * error= {title, message}}
   */

  public showErrorPage(error: ErrorType) {
    this.router.navigate(['/error'], { queryParams: { title: error.title, message: error.message, reset: error.reset} });
  }
}
