import { Action } from '@ngrx/store';

export enum ToolbarActionTypes {
  UPDATE_TOOLBAR = '[Toolbar] UPDATE_TOOLBAR',
}

export class UpdateToolbar implements Action {
  readonly type = ToolbarActionTypes.UPDATE_TOOLBAR;
  constructor(public payload: any) { }
}


export type ToolbarActions = UpdateToolbar;
