/// <reference types="@types/googlemaps"/>
import { UtilsService } from './../services/utils.service';
// import { } from '@types/googlemaps';
import { Component, OnInit, ViewChild, Input, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommunicationEventsService } from '../services/communication/communication-events.service';

function min(x, y) {
  return x > y ? y : x;
}
function max(x, y) {
  return x > y ? x : y;
}

const DegreeToMeter = 111000;
const minRadiusFactor = 10.0;
const radiusFactor = 5.0;
const zoomLevelWorld = 900000;

@Component({
  selector: 'ert-sitelocations',
  templateUrl: './site-locations.component.html',
  styleUrls: ['./site-locations.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SitelocationsComponent implements OnInit {
  @ViewChild('gmap', { static: true }) gmapElement: any;
  @Input() sites: any = null;
  @Input() selectedStudiesData = [];

  map: google.maps.Map;
  infoWindow: google.maps.InfoWindow;
  flag: boolean;
  studyId: number;
  circles: Array<any> = [];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private communicationEvents: CommunicationEventsService,
  ) {
    this.route.params.subscribe(params => {
      this.studyId = params.studyId;
    });

  }

  ngOnChanges(changes) {  
    if(this.sites){
      this.drawMap();
    }
  }

  ngOnInit() {
    this.drawMap();
  }

  drawMap() {
    for (const key in this.sites) {
      if (this.sites.hasOwnProperty(key))
        this.flag = true;
      else this.flag = false
    }
    if (this.flag && this.sites.length > 0) {
      // find center
      let minLat = this.sites[0].latitude;
      let minLong = this.sites[0].longitude;
      let maxLat = this.sites[0].latitude;
      let maxLong = this.sites[0].longitude;

      this.sites.forEach(site => {
        minLat = min(site.latitude, minLat);
        minLong = min(site.longitude, minLong);
        maxLat = max(site.latitude, maxLat);
        maxLong = max(site.longitude, maxLong);
      });


      const center = {
        lat: minLat + (maxLat - minLat) / 2.0,
        lng: minLong + (maxLong - minLong) / 2.0
      };

      const extent = maxLat === minLat ? (maxLat) * DegreeToMeter : (maxLat - minLat) * DegreeToMeter;
      const minRadius = extent / minRadiusFactor;
      let zoomLevel = this.sites.length >= 2 ? 2 : 3;

      const mapProp: any = {
        center: new google.maps.LatLng(center.lat, center.lng),
        zoom: zoomLevel,
        mapTypeId: google.maps.MapTypeId.TERRAIN,
        gestureHandling: 'cooperative',
        scaleControl: true,
      };
      this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
      this.map.mapTypes.set('styled_map', this.getMapStyle());
      this.map.setMapTypeId('styled_map');
      this.infoWindow = new google.maps.InfoWindow({});

      let selectedSitesNo = [];
      let selectedStudyIds = [];
      if (this.selectedStudiesData) {
        selectedStudyIds = this.selectedStudiesData.map(study => {
          selectedSitesNo = study.siteIds || [];
          return study.studyId;
        });
      }

      // markers
      this.sites.forEach(site => {
        const marker = new google.maps.Circle({
          strokeColor: '#003865',
          strokeOpacity: .3,
          strokeWeight: 4,
          fillColor: '#003865',
          fillOpacity: 0.2,
          map: this.map,
          center: { lat: site.latitude, lng: site.longitude },
          radius: minRadius + radiusFactor * minRadius * site.activeEvents / 1000.0,
          //clickable: true
        });
        marker.set('srcStudyIds', site.eventsSrcStudyIds);
        marker.set('srcSiteIds', site.eventsSrcSiteIds);

        if(selectedSitesNo.length) {
          const isSelectedSiteIdInList = selectedSitesNo.some(siteId => site.eventsSrcSiteIds.indexOf(+siteId) !== -1);

          if (selectedSitesNo && isSelectedSiteIdInList) {
            this.selectCircle(marker);
          }
        } else if (selectedStudyIds.length && site.eventsSrcStudyIds.includes(selectedStudyIds[0])) {
          this.selectCircle(marker);
        } else if (!selectedStudyIds.length) {
          marker.setOptions({fillOpacity: 0.6, strokeOpacity: 1});
          marker.set('selectedCircle', true);
        }
        // hovering support
        marker.addListener('mouseover', () => {
          const content = `<div class="location-tooltip">
          <p><b>Country: </b>${site.country}
            <br><b>Active Events: </b>${site.activeEvents} </p> </div>`;
          this.infoWindow.setContent(content);
          this.infoWindow.setPosition(marker.getCenter());
          this.infoWindow.open(this.map, marker);
          this.infoWindow.set('site', site.country);
        });

        marker.addListener('mouseout', () => {
          if (this.infoWindow.get('site') === site.country) {
            this.infoWindow.close();
            this.infoWindow.set('site', -1);
          }
        });

        marker.addListener( 'click', this.changeColor.bind(this, marker));


        this.circles.push(marker);
      });

      google.maps.event.addListener(this.map, 'zoom_changed', function () {
        for (let i = 0; i < this.circles.length; i++) {
          let p = Math.pow(2, (21 - this.map.getZoom()));
          let radius = (p * 1128.497220 * 0.0027) / 5;
          radius = radius < 500 ? 500 : radius;
          this.circles[i].setRadius(radius);
        }
      }.bind(this));
    } else {
      const mapProp: any = {
        center: new google.maps.LatLng(26.229034, 24.933101999999998),
        zoom: 3,
        mapTypeId: google.maps.MapTypeId.TERRAIN,
        gestureHandling: 'cooperative',
      };
      this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
      this.infoWindow = new google.maps.InfoWindow({});
    }
  }
  // onSiteClick(site: Site) {
  //   const siteId = site.srcSiteId;
  //   const url = `/dashboard/study/${this.studyId}/site/${siteId}`;
  //   this.router.navigateByUrl(url);
  // }
  changeColor(selectedCircle) {
    event.stopPropagation();
    event.preventDefault();
    event.stopImmediatePropagation();

    this.selectCircle(selectedCircle, true);

    this.communicationEvents.singleClickEventListener( {
        studyId: selectedCircle.get('srcStudyIds')[0],
        siteIds: selectedCircle.get('srcSiteIds')
    } );
  }

  selectCircle(selectedCircle, mapEvent = false) {
    if (mapEvent) {
      this.circles.forEach(function(circle) {
          circle.setOptions({fillOpacity: .2, strokeOpacity: .35});
          circle.set('selectedCircle', false);
      });
    }
    selectedCircle.setOptions({fillOpacity: 0.6, strokeOpacity: 1});
    selectedCircle.set('selectedCircle', true);
  }

  getMapStyle() {
    return new google.maps.StyledMapType([
      {
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#f5f5f5"
          }
        ]
      },
      {
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#616161"
          }
        ]
      },
      {
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#f5f5f5"
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#bdbdbd"
          }
        ]
      },
      {
        "featureType": "poi",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#eeeeee"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#e5e5e5"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      },
      {
        "featureType": "road",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#ffffff"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#dadada"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#616161"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      },
      {
        "featureType": "transit",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#e5e5e5"
          }
        ]
      },
      {
        "featureType": "transit.station",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#eeeeee"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#c9c9c9"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      }
    ],
      { name: 'Styeled Map' });
  }

}
