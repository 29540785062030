import { HideMultiCustomer, SelectCustomer, SelectCustomerSuccess } from '@core/store/customer/customer.actions';
import { Customer } from '@app/shared/models/customer.model';
import { Observable, Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { EndpointService, DataService } from '@shared/services';
import { LoginSuccess } from '@core/store/auth/auth.reducer'
import { DataSourceRefresh } from '@core/store/datasource/datasource.actions';
import { TableauService } from '../../shared/services/tableau.service';
import { ErrorNotificationService } from '@app/core/services/error-notification.service';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { UntypedFormControl } from '@angular/forms';
import { map, startWith } from "rxjs/operators";

@Component({
  selector: 'ert-select-site',
  templateUrl: './select-site.component.html',
  styleUrls: ['./../login.component.scss'],
  providers: [TableauService]
})

export class SelectSiteComponent implements OnInit, OnDestroy {
  customerList: any[];
  // isSelectCustomer: boolean;
  multiSelect: boolean;
  selectedCustomer: any;
  selected = false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  isRefreshStarted = false;
  isRefreshSuccess = false;
  progress: string;
  isTimeLimitExceeded = false;
  isJobFailed = false;
  isComponentDestroyed = false;
  subscription: Subscription;
  customerControl: UntypedFormControl = new UntypedFormControl('');
  filteredCustomerList: Observable<string[]> ;
  
  constructor(
    private store: Store<any>,
    private authService: AuthService,
    private endpointService: EndpointService,
    private dataService: DataService<any>,
    private tableauService: TableauService,
    private errorNotificationService: ErrorNotificationService,
    private sessionStorageService: SessionStorageService,
  ) { }

  ngOnInit() {
    //initial load event should be reset to null
    this.store.dispatch(new DataSourceRefresh({
    selectedCustomerId: null,
    selectedCustomer: null
    }));
    this.store.select('customerState').subscribe(data => {
      this.customerList = [...data.customers];
      this.customerList.sort(function(a,b) {
        let x = '';
        let y = '';
        if(a['navigatorOrgName']) {
          x = a['navigatorOrgName'].toLowerCase();
        }
        if(b['navigatorOrgName']) {
          y = b['navigatorOrgName'].toLowerCase();
        }        
        if (x < y) {return -1;}
        if (x > y) {return 1;}
        return 0;
      });;
      this.multiSelect = data.multiSelect;
    });
    this.filteredCustomerList = this.customerControl.valueChanges
                                .pipe(startWith(''),
                                 map(value => this._filter(value)
                                ));
    //Subscribed to data source refresh event and performance data source refresh for every 10 seconds till it success
    this.subscription = this.store.select('dataSourceState').subscribe(data => {
      if (data &&
        ((data.selectedCustomerId && data.selectedCustomerId.clientId) || (data.selectedCustomer && data.selectedCustomer.clientId))
        ) {
        this.refreshDataSource(data);
      } else if(data.selectedCustomer)
      this.store.dispatch(new LoginSuccess(data.selectedCustomer));
    });
  }

  private _filter(value: any): any[] {
    this.selectedCustomer = '';
    if(typeof value != 'object'){
    const filterValue = value.toLowerCase();
    return this.customerList.filter((option:any) => option.navigatorOrgName && option.navigatorOrgName.toLowerCase().includes(filterValue));
    }
  }
  onFilterOptionSelected(optionSelected) {
    this.selectedCustomer = null;
    this.customerList.forEach((org:any) =>{
      if(org.navigatorOrgName === optionSelected.target.value){
            this.selectedCustomer = org;
      }
    })
  }
  refreshDataSource(data) {

    this.tableauService.refreshDataSource().subscribe(
      (result) => {
        (async () => { 
            //To display percentage and to calculate elapsed time of 10 minutes before timeout msg
            if(result && result[0]) {
              const jobDetails = result[0];
              this.progress = jobDetails.progress;
              this.isJobFailed = jobDetails.isJobFailed;
              this.isTimeLimitExceeded = jobDetails.isTimeLimitExceeded;
              if(jobDetails.isRefreshCompleted ) {
                this.isRefreshSuccess = true;
                if(data.selectedCustomerId)
                  this.store.dispatch(new SelectCustomerSuccess(data.selectedCustomerId));
                if(data.selectedCustomer)
                  this.store.dispatch(new LoginSuccess(data.selectedCustomer));
              } else {
                this.isRefreshStarted = true;
                if(!this.isRefreshSuccess && !this.isTimeLimitExceeded && !this.isJobFailed && !this.isComponentDestroyed) {
                  await new Promise(f => setTimeout(f, 15000));
                  this.refreshDataSource(data);
                }
              }
            } else {
              this.isTimeLimitExceeded = true;
            }  
        })();      
      },
        (err) => {
          let adminLevel = JSON.parse(sessionStorage.getItem('RBM-AUTH')).userAccess.toAdminLevel;
          if(adminLevel >= 1) { //Allow admins to app with error message even on datasource refresh failure.
            if(data.selectedCustomerId)
              this.store.dispatch(new SelectCustomerSuccess(data.selectedCustomerId));
            if(data.selectedCustomer)
              this.store.dispatch(new LoginSuccess(data.selectedCustomer));
          } else {
            this.isTimeLimitExceeded = true;
          }
          this.errorNotificationService.notifyError('Data Source refresh failure', err);
          }
    );
  }

  onSelectClick(): void {
    if (this.selectedCustomer) {
      this.selected = true;
      this.store.dispatch(new SelectCustomer(
        { clientId: this.selectedCustomer.clientId,
          orgId: this.selectedCustomer.orgId,
          navigatorOrgName: this.selectedCustomer.navigatorOrgName//,
          //studyAccess: JSON.parse(sessionStorage.getItem('RBM-AUTH')).studyAccess
        }
      ));
    }
  }


  ngOnDestroy() {
    this.isComponentDestroyed = true;
    this.subscription.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
