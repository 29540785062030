<mat-sidenav-container autosize>
  <mat-sidenav class="app-container__side-nav" #sidenav mode="side" opened="true">
    <ert-sidebar class="es-sidebar"></ert-sidebar>
  </mat-sidenav>

  <mat-sidenav-content class="app-container">
    <ert-header class=""></ert-header>
    <header class="es-page-header">
        <!-- <ert-breadcrumb></ert-breadcrumb> -->
        <span *ngIf="!isTableau">
          <ert-info-banner></ert-info-banner>
        </span>
    </header>
    <div>
      <main class="es-app-body">
        <div [ngClass] = "!isTableau ? 'pull-up-mainpage container-fluid' : ''">
          <ng-container *ngTemplateOutlet="router"></ng-container>
        </div>
      </main>
      <ert-footer></ert-footer>
      </div>
  </mat-sidenav-content>
  <ng-template #router>
    <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
  </ng-template>
</mat-sidenav-container>
