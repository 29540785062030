import { Component, Input } from '@angular/core';

@Component({
  selector: 'ert-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})

export class AppHeaderComponent {
  @Input() title: string;
}
