export interface DataSourceRefreshState {
    selectedCustomerId: any,
    selectedCustomer: any
  }

  export const initializeDataSourceRefresh = function() {
      let defaultValue: DataSourceRefreshState = {
        selectedCustomerId: null,
        selectedCustomer: null
      }
    return defaultValue;
  }