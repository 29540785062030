<div class="score_overview_chart-container">
  <div class="chart-title content">
    <h4> {{type ? 'Score Over Time' : 'Scores Over Time'}}</h4>
  </div>
  <div *ngIf="data">
    <div *ngIf="chartDataFlag === 'unchecked'">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
    <div *ngIf="chartDataFlag === 'valid'">
      <div *ngIf="type===chartTypes.LineColorChart ;else MsLineChart">
        <ert-line-color-chart *ngIf="data" height="255" [data]="data" [yAxisLabel]="yAxisLabel"> </ert-line-color-chart>
      </div>
      <ng-template #MsLineChart>
        <ert-ms-line-chart *ngIf="data" [data]="data" height="255" [yAxisLabel]="yAxisLabel"></ert-ms-line-chart>
      </ng-template>
    </div>
    <div *ngIf="chartDataFlag === 'invalid'" class="es-status-sign">
      <span class="es-icon es-icon-magnifier"></span>
      <span class="text">No data to display</span>
    </div>
  </div>
</div>
