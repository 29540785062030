
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { shim } from 'promise.prototype.finally';

// import 'codemirror/mode/markdown/markdown';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// load shim to allow Promise.finally()
shim();

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
