<!-- <div class="overlay" *ngIf="loading && !dataService.multiUserLoginData.select"> -->
<div class="overlay" *ngIf="loading">
  <div class="mat-spinner-container">
    <mat-spinner></mat-spinner>
  </div>
</div>
<router-outlet></router-outlet>
<div class="login__footer">
  <ert-footer color="white"></ert-footer>
</div>
