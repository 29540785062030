<div class="es-content-box">
  <ol class="breadcrumb__list">
    <li class="breadcrumb__item" *ngFor="let crumb of crumbs.crumbs; let last = last">
      <span class="breadcrumb__link" *ngIf="!crumb.url">{{ crumb.text }}</span>
      <a class="breadcrumb__link" [routerLink]="crumb.url" *ngIf="crumb.url" [matTooltip]="crumb.tooltipText || crumb.text"
        [matTooltipDisabled]="(!crumb.tooltipText && crumb.shortText.length<=30)"
          matTooltipPosition="above"
        matTooltipShowDelay="100">{{ crumb.shortText }}</a>
      <mat-icon *ngIf="!last" class="breadcrumb__item-connector">chevron_right</mat-icon>
    </li>
  </ol>
</div>
