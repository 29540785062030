import { initializeSidebarState, SidebarState } from './sidebar.state';
import { SidebarActions, SidebarActionTypes } from './sidebar.actions';

const defaultState: SidebarState = initializeSidebarState();


export function SidebarReducer(state: SidebarState = defaultState, action: SidebarActions): SidebarState {

  switch (action.type) {

    case SidebarActionTypes.SHOW_SIDEBAR: {
      return {
        ...state,
        opened: true,
      };
    }

    case SidebarActionTypes.HIDE_SIDEBAR: {
      return {
        ...state,
        opened: false,
      };
    }

    default: {
      return state;
    }
  }
}
