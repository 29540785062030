import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef, ViewEncapsulation } from "@angular/core";
import * as d3 from "d3";
import { ActivatedRoute } from '@angular/router';
import { MetricHelpInfoComponent } from '@app/shared/modals/metric-help-info/metric-help-info.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ert-metric-by-score',
  templateUrl: './metric-by-score.component.html',
  styleUrls: ['./metric-by-score.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MetricByScoreComponent implements OnInit {
  @ViewChild("metricbyscore", { static: true, read: ElementRef }) private chartContainer: ElementRef;
  @ViewChild("metricbyscoreXaxis", { static: true, read: ElementRef }) private chartContainerXaxis: ElementRef;
  dialogRef: MatDialogRef<MetricHelpInfoComponent>;
  firstDraw: boolean = true;
  @Input() data: any;
  @Input() private xAxisLabel: string;
  @Input() private yAxisLabel: string;
  @Input() private titleLabel: string;
  private svg: any = null;
  private svgBottom: any = null;
  private margin: any = { top: 10, right: 60, bottom: 0, left: 135 };
  private width: number;
  private height: number;
  private studyId: number;
  private siteId: number;
  private tooltip:any = null;
  private isOnTooltip: boolean = false;
  private xScale: any;
  private xScaleBottom: any;
  private yScale: any;
  thresholdOpCategory = {
    'gte': '>=',
    'lte': '<='
  };

  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
  ) {
    this.route.params.subscribe(params => {
      this.studyId = +params.studyId;
      this.siteId = +params.siteId;
    });
  }

  ngOnInit() {
    this.firstDraw = false;
    if(this.data){
      this.drawChart();
    }
  }

  ngOnChanges(changes) {
    if(this.data){
      this.drawChart();
    }
    window.addEventListener("resize", this.drawChart.bind(this));
  }

  drawChart() {
    if(!this.data || this.data.length == 0)
    return;

    const element = this.chartContainer.nativeElement;
    const elementXaxis = this.chartContainerXaxis.nativeElement;
    this.width = element.offsetWidth - this.margin.left - this.margin.right;
    this.height = 30;

    if (!this.firstDraw) {
      d3.select(element)
        .selectAll("svg")
        .remove();
      d3.select(elementXaxis)
        .selectAll("svg")
        .remove();
      d3.select(element)
        .selectAll(".metric_chart_tooltip")
        .remove();
      this.svg = null;
      this.svgBottom = null;
    }

    this.svg = d3
    .select(element)
    .append("svg")
    .attr("class", "bar-chart")
    .style("overflow-y","scroll")
    .attr("width", this.width + this.margin.left + this.margin.right)
    .attr("height", this.height*this.data.length)
    .append("g")
    .attr("transform",
          "translate(" + this.margin.left + "," + this.margin.top + ")");

    this.svgBottom = d3
      .select(elementXaxis).append("svg")
      .attr("width", this.width + this.margin.left + this.margin.right)
      .attr("height", 10)
      .append("g")
      .attr("transform",
            "translate(" + this.margin.left + "," + this.margin.bottom + ")");

    //To remove tooltip when mouse moved out of the metric chart
    d3.select(element).on("mouseleave", function(e) {
      this.isOnTooltip = false;
      if(d3.event.relatedTarget && d3.event.relatedTarget != null && (d3.event.relatedTarget.id == "metric_chart_tooltip"
      || d3.event.relatedTarget.class == "metric_tooltip_open") ){
         this.isOnTooltip =true;
      }

      if(!this.isOnTooltip){
        d3.select("body")
          .selectAll(".metric_chart_tooltip")
          .remove();
        this.tooltip = null;
      }
      }).on("wheel", function(e) {
          d3.select("body")
            .selectAll(".metric_chart_tooltip")
            .remove();
          this.tooltip = null;
      });

    // create scales
    this.xScale = d3
    .scaleLinear()
    .domain([-1, 100])
    .range([0, this.width]);

    var xAxis = d3.axisBottom(this.xScale).ticks(0).tickSize(0);
    this.xScaleBottom = d3
    .scaleLinear()
    .domain([0, 100])
    .range([0, this.width]);

    var xAxisBottom = d3.axisBottom(this.xScaleBottom).ticks(1).tickSize(0);

    //let yItems = this.data.length;

    this.yScale = d3
      .scaleBand()
      .padding(0.3)
      .domain(this.data.map(function (d) {
        return d.metricName;
    }))
      .range([0, this.height*this.data.length]);

    var yAxis = d3.axisLeft(this.yScale).tickSize(0);


    var chart = this.svg.selectAll(".bar")
      .data(this.data)
      .enter();

    // add the y Axis
/*     chart.append("g")
      .call(yAxis).call(g => g.select(".domain").remove());   */

    //Create score rectangles
    chart
      .append("rect")
      //.style("padding-bottom", "0.3")
      .attr("fill", d => d.color)
      .attr("width", d => this.xScale(d.score))
      .attr("height", this.height-10)
      .attr("x", d => 0)
      .attr("y", d => this.yScale(d.metricName))
      .on("mouseover", d => this.tooltipDisplay(d));


    //Create ERT logo colored rect
    chart
      .append("rect")
     // .style("padding-bottom", "0.3")
      .attr("class", "ert-rect")
      .attr("width", d => this.xScale(100) - this.xScale(d.score))
      .attr("height", this.height-10)//this.yScale.bandwidth())
      .attr("x", (d, i) => this.xScale(d.score))
      .attr("y", (d, i) => this.yScale(d.metricName))
      .on("mouseover", d => this.tooltipDisplay(d));


    //Create score text
      chart
      .append("text")
      .text(function(d) {
        return d.score;
      })
      .attr("class", "score-label")
      .attr("text-anchor", "middle")
      .attr("x", d => d.score < 94 ? this.xScale(d.score) + 10 : this.xScale(d.score) - 15)
      .attr("y", (d, i) => this.yScale(d.metricName) + this.yScale.bandwidth() / 2 + 2);

  // text label for the x axis
  this.svgBottom
  .append("text")
  .attr("class", "axis-label")
  .attr("alignment-baseline", "baseline")
  .attr(
    "transform",
    "translate(" + this.width/2 + "," + 10 + ")"
  )
  .style("text-anchor", "middle")
  .text("Metric Score");

  // x-axis
  this.svgBottom
    .append("g")
    .attr("class", "ms-axis")
    .call(xAxisBottom).call(g => g.select(".domain").remove());

  // y-axis
  this.svg
    .append("g")
    .attr("class", "ms-axis")
    .call(yAxis).call(g => g.select(".domain").remove());
  }

  openDialog(data): void {
    this.dialog.open(MetricHelpInfoComponent, {
        width: '600px',
        data
    });
  }

  getThreshold(threshold, units) {
    if(units === '%') {
      return +(parseFloat(threshold) * 100).toFixed(2) + units;
    } else {
      return threshold + ' ' + units;
    }
  }

  ngOnDestroy() {
    window.removeEventListener("resize", this.drawChart.bind(this));
  }

  tooltipDisplay(d) {
    d3.select("body").selectAll(".metric_chart_tooltip").remove();
    //add the tooltip area to the svg
    this.tooltip = d3.select("body").append("div")
                .attr("id", "metric_chart_tooltip")
                .attr("class", "metric_chart_tooltip")
                .on("mouseover", function(d) {
                  this.isOnTooltip = true;
                })
                .on("mouseleave", function(d) {
                      d3.select("body").selectAll(".metric_chart_tooltip").remove();
                      this.tooltip = null;
                      this.isOnTooltip = false;
                  });

    //var mouseCoords = d3.mouse(tooltip.node().parentElement);
    let href = '';
    if(this.siteId) {
      href = `dashboard/study/${this.studyId}/site/${this.siteId}/metric/${d.id}`;
    } else {
      href = `dashboard/study/${this.studyId}/metric/${d.id}`;
    }


    this.tooltip
          .html(
              "<span class='study-style'>" +
              `<a class='metric_tooltip_open' href='${href}'` + "'>" +
                d.metricName +
              "</a></span>" +
              ' <span id="metric-help-info" class="es-icon es-icon-help"></span><span></span>' +
              "<br><b>Metric Value: </b>" + d.metricValue +
              "<br><b>Threshold: </b>" + this.thresholdOpCategory[d.thresholdOperator] + " " + this.getThreshold(d.thresholdValue, d.units) +
              "<br><b>Score: </b>" + d.score

          )
          .style('left', (d3.event.pageX) + 15 + 'px')
          .style('top', (d3.event.pageY) - 15 + 'px');

          d3.selectAll('#metric-help-info').on('click', function() {
            this.openDialog(d);

          }.bind(this));
      }
}
