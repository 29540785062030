export enum KriCategories {
  Enrollment = 'enrollment',
  Compliance = 'compliance',
  Safety = 'safety',
  Quality = 'quality',
}

export enum KriViews {
  CROSSITE_VIEW = 'CROSSITE',
  STUDY_VIEW = 'STUDY',
  PORTFOLIO_VIEW = 'PORTFOLIO'
}
