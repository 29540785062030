import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { UtilsService } from '../services/utils.service';

export enum lineChartType {
  MsLineChart,
  LineColorChart,
};

@Component({
  selector: 'ert-score-overview',
  templateUrl: './score-overview.component.html',
  styleUrls: ['./score-overview.component.scss']
})
export class ScoreOverviewComponent implements OnInit, OnChanges {

  @Input() kritype: string;
  @Input() type: number;
  @Input() data: any;
  @Input() yAxisLabel: string;
  chartDataFlag: string = 'unchecked';
  chartTypes = lineChartType;
  
  constructor() { }
  
  ngOnInit() {

  }

  ngOnChanges(changes) {
    if (this.data && this.data.length) {
      this.chartDataFlag = 'valid';
    } else {
      this.chartDataFlag = 'invalid';
    }
  }


  getScoreColor(score) {
    return UtilsService.getColorFromScore(score);
  }


}
