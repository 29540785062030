import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StudyService } from '@app/shared/services/study.service';
import { SiteService } from '@app/shared/services/site.service';
import {UtilsService} from '@app/shared/services/utils.service';
import { ErrorNotificationService } from '@app/core/services/error-notification.service';

@Component({
  selector: 'ert-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  studyId;
  siteId;
  metricId
  odsReportsEnabled: boolean = false;
  reportData: any = {};
  showReport: boolean; 

  constructor( private route: ActivatedRoute,
    private studyService: StudyService,
    private siteService: SiteService,
    private errorNotificationService: ErrorNotificationService,
    ) {
    this.route.params.subscribe(params => {
      this.studyId = params.studyId;
      this.siteId = params.siteId;
      this.metricId = params.metricId;
    })
  }

  ngOnInit() {
    this.BIReportAction();
  }

  BIReportAction(){
    this.odsReportsEnabled = true;
          if (this.odsReportsEnabled && this.studyId && this.siteId && this.metricId) {
            this.siteService.getSiteReport(this.studyId, this.siteId, this.metricId).subscribe(rptData => {
              this.reportData = {
                ...rptData,
                isSiteLevel: true
              };
              const rptConfig = this.reportData.reportConfig;
              this.showReport = ( rptConfig && rptConfig.reportFile && rptConfig.reportId );
              this.displayReport(this.reportData)
            });
          } else if (this.odsReportsEnabled && this.studyId && this.metricId) {
            this.studyService.getStudyReport(this.studyId, this.metricId).subscribe(rptData => {
              this.reportData = {
                ...rptData,
                isSiteLevel: false
              };
              const rptConfig = this.reportData.reportConfig;
              this.showReport = ( rptConfig && rptConfig.reportFile && rptConfig.reportId );
              this.displayReport(this.reportData)
            });
            
          }else{
              return this.errorNotificationService.notifyError('Metric Selection Error', { message: 'Could not fetch the Study/Site details for selected metrics from database' });
          }
  
  }

  displayReport(rpt){
    if (this.showReport) {
      document.cookie = `di_auth_token=${rpt.di_auth_token}; path=/; domain=.ert.com`;
      const rptUrls = UtilsService.getBIReportURL(rpt);
      let loopCount = 1;
      let totalReports = rptUrls.length;
      rptUrls.forEach(url => {
        if(loopCount >= totalReports)
          window.open(url, '_self');
        else
          window.open(url, '_blank');
          loopCount++;
      });
  
    }
  }

}
