import { AddDataConnectionComponent } from './add-data-connection/add-data-connection.component';
import { AddStudyComponent } from './add-study/add-study.component';
import { AddCustomerComponent } from './add-customer/add-customer.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { ToAdminRoutingModule } from './to-admin-routing.module';
import { ToAdminComponent } from './to-admin.component';
import { SidebarComponent } from '@app/dashboard/sidebar/sidebar.component';
import { HeaderComponent } from '@app/dashboard/header/header.component';
import { FooterComponent } from '@app/dashboard/footer/footer.component';
import { BreadcrumbComponent } from '@app/dashboard/breadcrumb/breadcrumb.component';
import { DashboardModule } from '@app/dashboard/dashboard.module';
import { UserManagementComponent } from './user-management/user-management.component';
import { AddDefaultStudyConfigComponent } from './add-default-study-config/add-default-study-config.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        ToAdminRoutingModule,
        DashboardModule,
    ],
    declarations: [
        ToAdminComponent,
        AddCustomerComponent,
        AddStudyComponent,
        AddDataConnectionComponent,
        UserManagementComponent,
        AddDefaultStudyConfigComponent
    ]
})
export class ToAdminModule {}
