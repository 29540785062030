import { SidebarService } from './services/sidebar-permissions.service';
import { HideSidebar, ShowSidebar } from './../../core/store/sidebar/sidebar.actions';
import { Observable } from 'rxjs';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef  } from '@angular/core';
import { Store } from '@ngrx/store';
import { Sidebar } from './model/sidebar-link.model';
import { MatAccordion } from '@angular/material/expansion';
import { Router } from '@angular/router';

const tableauViewConfig = {
  'PORTFOLIO' : 'Portfolio',
  'STUDIES' : 'Studies',
  'STUDY EVENTS' : 'Events',
  'STUDY METRICS' : 'Studies',
  'SITES' : 'Sites',
  'SITE EVENTS' : 'Events',
  'SITE METRICS' : 'Sites',
  //'METRICS OVERVIEW' : 'Metrics Overview', uncomment this code when metric overview adds back
  'EVENTS' : 'Events',
}

@Component({
  selector: 'ert-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit  {
  @ViewChild('accordionSubMenu',{static:true}) accordionSubMenu: MatAccordion;
  panelOpenState: boolean;
  subPanelState1: boolean;
  subPanelState2: boolean;
  panelSubMenuOpenState: boolean;
  menuOption: string;
  active: string;
  linkSelected: string;
  @ViewChild('accordionMenu',{static:true}) accordionMenu: MatAccordion;
  @ViewChild('diMenuToggle') diMenuToggle: ElementRef | undefined;

  sidebar$: Observable<Sidebar>;
  isToggleOpen:boolean = true;

  constructor(
    private store: Store<any>,
    private sidebarService: SidebarService,
    public router: Router,
  ) {
  }

  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    if(sessionStorage.getItem("isToggleOpen")) {
      this.isToggleOpen = JSON.parse(sessionStorage.getItem("isToggleOpen").toLowerCase());
    }
    this.sidebar$ = this.sidebarService.getAuthorizedSidebarLinks();
    this.store.select('tabSwitchState').subscribe(data => {
      if(data.activeTab) {
        this.menuOption = tableauViewConfig[data.activeTab.trim()];
      }
    });
  }


  isLinkActive(linkLocation: string): boolean {
    return this.router.isActive(linkLocation, true) || this.router.url.startsWith(linkLocation);
  }
  
  toggleMenu(): void {
    this.isToggleOpen = !this.isToggleOpen;
    sessionStorage.setItem("isToggleOpen", this.isToggleOpen.toString());
  }
}
