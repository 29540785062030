import { DataSourceRefreshState, initializeDataSourceRefresh  } from './datasource.state';
import * as  Actions  from './datasource.actions';

const defaultState: DataSourceRefreshState = initializeDataSourceRefresh();
export function DataSourceRefreshReducer(state: DataSourceRefreshState = defaultState, action: Actions.DataSourceActions) : DataSourceRefreshState   {

    switch (action.type) {
  
      case Actions.REFRESH: {
        return {
          ...state,
          ...action.payload
        };
      }
  
      default: {
        return state;
      }
    }
  }