import { initializeBreadCrumbState, BreadCrumbState } from './breadcrumbs.state';
import { BreadCrumbsActions, BreadCrumbsActionTypes } from './breadcrumbs.actions';

const defaultState: BreadCrumbState = initializeBreadCrumbState();


export function BreadcrumbsReducer(state: BreadCrumbState = defaultState, action: BreadCrumbsActions): BreadCrumbState {

  switch (action.type) {

    case BreadCrumbsActionTypes.UPDATE_ADD: {
      return add(state, action.payload)
    }

    case BreadCrumbsActionTypes.UPDATE_REMOVE: {
      return remove(defaultState, action.payload)
    }

    case BreadCrumbsActionTypes.UPDATE_SET: {
      return set(defaultState, action.payload)
    }

    default: {
      return state;
    }
  }

}

export function add(State, payload) {
  var newState = { ...State };
  payload.crumbs.forEach(element => {
    newState.crumbs.push({text: element.text, url: ''})
  });

  return newState;
}

export function remove(State, payload) {
  var newState = { ...State };
  for (var i = newState.length - 1; i >= 0; i--) {
    if (newState[i] === payload) {
      newState.splice(i, 1);
    }
  }
  return newState;
}

export function set(State, payload) {
  var newState = { ...State };
  newState = payload;
  return newState;
}
