import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import { Component, OnInit, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SetBreadCrumbs } from './../../core/store/breadcrumbs/breadcrumbs.actions';
import { zip } from 'rxjs';
import { DatePipe } from '@angular/common';
import { LineChartOptions } from '../../shared/d3/ms-line-chart/line-chart.options';
import { UpdateToolbar } from '@app/core/store/toolbar/toolbar.actions';
import { StudyService } from '@app/shared/services/study.service';
import { SiteService } from '@app/shared/services/site.service';
import { PermissionService } from '@shared/services/permisssion.service';
import dateFormat from 'dateformat';

const brandColors = {
    light_blue: '#0092bc',
    picton_blue: '#4ec3e0',
    dark_blue: '#003865',
    pistachio: '#84bd00',
    lime: '#c4d600',
    dark_green: '#007a33',
    amethyst: '#c069c2',
    plum: '#93328e',
    orange: '#ff5c39',
    dark_grey: '#747678',
};

const mOptions: LineChartOptions[] = [
    {
        lineWidth: 4,
        curveType: 0,
        dotRadius: 6,
        color: brandColors.dark_blue,
        data: null
    },
    {
        lineWidth: 4,
        curveType: 0,
        dashPattern: '10,10',
        color: brandColors.lime,
        data: null
    },
    {
        lineWidth: 3,
        curveType: 2,
        color: brandColors.orange,
        data: null
    }
];

const cOptions: LineChartOptions[] = [
    {
        lineWidth: 3,
        curveType: 0,
        dotRadius: 6,
        color: brandColors.plum,
        data: null
    },
    {
        lineWidth: 3,
        curveType: 0,
        color: brandColors.dark_grey,
        data: null
    },
    {
        lineWidth: 3,
        curveType: 0,
        color: brandColors.picton_blue,
        data: null
    },
    {
        lineWidth: 3,
        curveType: 0,
        color: brandColors.pistachio,
        data: null
    }
];

@Component({
    selector: 'ert-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.scss'],
    providers: [SiteService, DatePipe]
})
export class DetailComponent implements OnInit {
    yAxisName = '';
    metricName = '';
    metricCategory = '';
    kriValType = '';
    @ViewChild(MatPaginator) paginator: MatPaginator;
    displayedColumns = null;
    allData = null;
    filteredData = null;
    displayedData = null;
    displayedHeadings = null;
    displayedColWidth = null;
    sort;
    studyId: number;
    sites;
    studyName: string;
    siteId: number;
    detailsDataColumns = [];
    // dateformat = require('dateformat');
    is_valid: String = 'unchecked';
    loading: Boolean = true;
    siteCheck: Boolean =  false;

    private titleDataItems = [
        { key: 'site' }
    ];

    private tooltipDataItems = [];

    // field that determines block size
    private valueItem = 'size';
    // field that determines block color
    private scoreItem = '';
    permCheck: boolean = false;


    @ViewChild(MatSort) set content(content: ElementRef) {
        this.sort = content;
        if (this.sort) {
            this.displayedData.sort = this.sort;
        }
    }
    pageEvent: PageEvent;
    chartOptions: LineChartOptions[] = null;
    kriAggr: LineChartOptions[];
    calcId;
    // dateformat = require('dateformat');
    alertPermCheck = false;

    constructor(
        private store: Store<any>,
        private route: ActivatedRoute,
        private router: Router,
        private studyService: StudyService,
        private siteService: SiteService,
        private datePipe: DatePipe,
        private permService: PermissionService
    ) {
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };

        this.route.params.subscribe(params => {
            this.studyId = params.studyId;
            this.siteId = params.siteId;
            this.calcId = params.calcId;
        });

        if (sessionStorage.getItem('HOMEPAGE') !== '/dashboard/sitebenchmark') {
            this.permCheck = true;
        }
    }

    getData(studyId, siteId, calcId) {
        if (studyId && siteId && calcId) {
            return this.siteService.getKriHistorical(studyId, siteId, calcId);
        } else if (studyId && calcId) {
            return this.studyService.getKriHistorical(studyId, calcId);
        }
    }

    getEventData(studyId, siteId, calcId) {
        if (studyId && siteId && calcId) {
            return this.siteService.getKriHistoricalEvents(studyId, siteId, calcId);
        } else if (studyId && calcId) {
            return this.studyService.getKriHistoricalEvents(studyId, calcId);
        }
    }

    setupChartData(data) {
        this.yAxisName = data.metric.units;
        this.metricName = data.metric.calcTypeName;
        this.metricCategory = data.category;
        this.kriValType = 'Count';
        // main options
        const options: LineChartOptions[] = [];
        // find main series
        const main = data.series.find(s => s.id === data.main);
        if (main) {
          // threshold first
          if (main.threshold.length > 0) {
            const s = mOptions[2];
            s.data = main.threshold;
            s.units = this.yAxisName;
            s.legend = 'Threshold';
            options.push(s);
          }
          // main series
          const m = mOptions[0];
          m.legend = data.metric.calcTypeName;
          m.units = this.yAxisName;
          m.data = main.data;
          options.push(m);
          // projection
          if (main.projection.length > 0) {
            const s = mOptions[1];
            s.data = main.projection;
            s.units = this.yAxisName;
            s.legend = 'Projection';
            // s.units = data.units;
            options.push(s);
          }
        }
        // context series
        const contextOpt: LineChartOptions[] = [];
        let i = 0;
        data.series.forEach(e => {

            if (e.id !== data.main) {
                const s = cOptions[i];
                s.legend = e.caption;
                s.data = e.data;
                contextOpt.push(s);
                i++;
            }
        });
        this.chartOptions = options;


        //Adding 30 days to compensate for projection
        const day = 24 * 60 * 60 * 1000;
        var point =[  {
            'x' :  main.data[main.data.length-1].x + 30*day,
            'y' : 0
        }
        ];
        var extenison = {
            lineWidth : 0,
            curveType : 0,
            color : '#F8F8F8',
            data : point
        };
        contextOpt.push(extenison)
        this.kriAggr = contextOpt;
    }

    setupTable(data) {
        this.displayedColumns = data.columns;
        this.displayedHeadings = data.display;
        this.displayedColWidth = data.colWidth;
        this.allData = data.data;
        this.filteredData = new MatTableDataSource(data.data);
        this.displayedData = new MatTableDataSource(data.data);
        this.displayedData.paginator = this.paginator;
        this.displayedData.sort = this.sort;
        this.displayedData.filterPredicate = (item, filter: String) => {
            const lowerFilter = filter.trim().toLowerCase();
            return this.checkEventName(item.event_name, lowerFilter) || this.checkDate(item.entry_date, lowerFilter) ||
                this.checkDate(item.event_date, lowerFilter) || this.checkEventName(item.study_name, lowerFilter)
                || this.checkEventName(item.subject_no, lowerFilter) || this.checkEventName(item.site_no, lowerFilter)
                || this.checkEventName(item.pd_severity, lowerFilter) || this.checkEventName(item.visit_id, lowerFilter) || this.checkEventName(item.data_point_count, lowerFilter) || this.checkDate(item.entered_date, lowerFilter)
                || this.checkDate(item.onset_date, lowerFilter) || this.checkEventName(item.severity, lowerFilter)
                || this.checkDate(item.from_date, lowerFilter) || this.checkDate(item.to_date, lowerFilter)
                || this.checkDate(item.visit_date, lowerFilter) || this.checkDate(item.visit_entered_date, lowerFilter) 
                || this.checkEventName(item.protocol_number, lowerFilter);
        };
    }

    checkEventName(item, lowerFilter) {
        if (item) {
            //item = item.includes('_') ? item.replace(/_/g, ' ') : item;
            if (typeof (item) === 'string') {
                return item.trim().toLowerCase().includes(lowerFilter);
            } else {
                return item.toString().trim().toLowerCase().includes(lowerFilter);
            }
        }
    }

    checkDate(item, lowerFilter) {
        if (item !== null) {
            return dateFormat(item, 'dd-mmm-yyyy HH:MM:ss').toString().trim().toLowerCase().includes(lowerFilter);
        } else return false;
    }


    ngOnInit() {
      this.setupPage();
      const editStudyPermission = this.permService.hasPermission('editStudy', { studyId: this.studyId.toString() });
      this.alertPermCheck = (editStudyPermission && editStudyPermission === 'all') || editStudyPermission || this.permService.hasRole('vp') || this.permService.hasRole('studyAdmin');
    }

    setupPage() {
        // load data
        this.route.params.subscribe(params => {
            if (params.studyId && params.siteId) {
                zip(
                    this.studyService.getStudyById(params.studyId),
                    this.siteService.getSite(params.studyId, params.siteId),
                    this.getData(params.studyId, params.siteId, params.calcId),
                    this.getEventData(params.studyId, params.siteId, params.calcId)
                ).subscribe(([study, site, data, eventData]) => {
                    this.studyName = study.studyName;
                    this.getSiteData(data.metric.calcRbmCategoryName, data.metric.calcTypeName);
                    for (var key in data) {
                        if (!data.hasOwnProperty(key))
                            return;
                    }
                    this.store.dispatch(new SetBreadCrumbs({
                      crumbs: [
                        { text: 'Portfolio', url: sessionStorage.getItem('HOMEPAGE') },
                        { text: study.protocolNumber || study.studyName, url: `/dashboard/study/${study.srcStudyId}`, tooltipText: study.studyName },
                        { text: `${site.institutionName}`, url: `/dashboard/study/${study.srcStudyId}/site/${site.srcSiteId}` },
                        { text: data.metric.calcTypeName, url: '' }
                      ],
                    }));
                    this.setupChartData(data);
                    this.setupTable(eventData);
                });
            } else if (params.studyId) {
                zip(
                    this.studyService.getStudyById(params.studyId),
                    this.getData(params.studyId, params.siteId, params.calcId),
                    this.getEventData(params.studyId, params.siteId, params.calcId)
                  ).subscribe(([study, data, eventData]) => {
                    this.studyName = study.studyName;
                    this.getSiteData(data.metric.calcRbmCategoryName, data.metric.calcTypeName);
                    for (var key in data) {
                        if (!data.hasOwnProperty(key))
                            return;
                    }
                  this.store.dispatch(new SetBreadCrumbs({
                    crumbs: [
                      { text: 'Portfolio', url: '/dashboard' },
                      { text: study.protocolNumber || study.studyName, url: `/dashboard/study/${study.srcStudyId}`, tooltipText: study.studyName },
                      { text: data.metric.calcTypeName, url: '' }
                    ],
                  }));
                    this.setupChartData(data);
                    this.setupTable(eventData);
                });
            } else {
                zip(
                    this.getData(params.studyId, params.siteId, params.calcId),
                    this.getEventData(params.studyId, params.siteId, params.calcId)
                ).subscribe(([data, eventData]) => {
                    for (var key in data) {
                        if (!data.hasOwnProperty(key))
                            return;
                    }
                    this.store.dispatch(new SetBreadCrumbs({
                        crumbs: [
                            { text: 'Portfolio', url: '/dashboard' },
                            { text: data.metric.calcTypeName, url: '' }
                        ],
                    }));
                    this.setupChartData(data);
                    this.setupTable(eventData);
                });
            }
        });
        // sending an empty toolbar so that site and scorecard are not available
        this.store.dispatch(new UpdateToolbar({
        }));
    }

    validateStudyData(site, rbmCatName, calcTypeName) {
      site.calcTypeBucketExtraData.forEach(ele => {
        if (ele.calcTypeBucketName === rbmCatName) {
          if (ele.calcTypes) {
            ele.calcTypes.forEach(calc => {
              if (calc.calcTypeName === calcTypeName && calc.score === null) {
                return this.is_valid = 'invalid';
              } else {
                return this.is_valid = 'valid';
              }
            });
          }
        }
      });
      this.is_valid = (this.is_valid === 'unchecked') ? 'invalid' : this.is_valid;
    }

    getSiteData(rbmCatName: string, calcTypeName: string) {
        this.studyService.getSiteStudiesBenchmark(this.studyId).subscribe(dm => {
            if (!dm.length) { return; }
            this.sites = dm[0].sites.map(site => {
              this.validateStudyData(site, rbmCatName, calcTypeName);
              return this.formatSingleStudySiteTable(site);
            });
            this.sites.forEach(element => {
                element.size = 100;
            });
            this.scoreItem = calcTypeName.replace(/\s/g, '');
            this.tooltipDataItems = [
                { key: 'pi' },
                { label: 'Site', key: 'site' },
                { label: 'Enrolled', key: 'subjects' },
                { label: 'KRI Score', key: `${this.scoreItem}` }
            ];
            this.sites.sort((a, b) => {
                var va, vb;
                if (a[this.scoreItem] === null) {
                    va = -1;
                } else {
                    va = a[this.scoreItem];
                }
                if (b[this.scoreItem] === null) {
                    vb = -1;
                } else {
                    vb = b[this.scoreItem];
                }
                return va > vb ? 1 : -1;
            });
            if (this.siteId) {
                this.sites.forEach(element => {
                    if ((element.siteId == this.siteId) && (element[this.scoreItem] !== null)) {
                        this.siteCheck = true;
                    }
                });
            }
        });
    }

    setDetailsDataColumns(catName: string, calc: any) {
        this.detailsDataColumns[catName] = this.detailsDataColumns[catName] || [];
        this.detailsDataColumns['calcTypes'] = this.detailsDataColumns['calcTypes'] || [];

        if (calc.calcTypeName && !this.detailsDataColumns[catName].includes(calc.calcTypeName)) {
            this.detailsDataColumns[catName].push(calc.calcTypeName);
            this.detailsDataColumns['calcTypes'].push({ name: calc.calcTypeName, id: calc.calcTypeId });
        }
    }

    formatSingleStudySiteTable(site) {
        const detailsData = {};
        const averageScores = (Object.assign({}, ...site.avgCalcTypeBucketScore));
        const { enrollment, compliance, safety, quality } = averageScores;

        site.calcTypeBucketExtraData.filter((data) => {
            if (!data) { return; }
            const catName = data.calcTypeBucketName;
            detailsData[catName] = {};

            data.calcTypes.filter((calc) => {
                if (!calc) { return; }
                this.setDetailsDataColumns(catName, calc);
                detailsData[catName][calc.calcTypeName.replace(/\s/g, '')] = (calc.score === null ? null : calc.score);
            });
        });

        return {
            site: site.siteNo,
            pi: site.investigator,
            subjects: site.enrollTarget,
            score: site.compositeScore,
            enrollment: isNaN(enrollment) ? null : parseInt(enrollment, 10),
            ...detailsData['enrollment'],
            compliance: isNaN(compliance) ? null : parseInt(compliance, 10),
            ...detailsData['compliance'],
            safety: isNaN(safety) ? null : parseInt(safety, 10),
            ...detailsData['safety'],
            quality: isNaN(quality) ? null : parseInt(quality, 10),
            ...detailsData['quality'],
            id: site.srcStudyId,
            siteId: site.srcSiteId
        };
    }

    applyFilter(filterValue: string) {
        //when applying a filter, always go back to the first page of the results
        this.displayedData.paginator.pageIndex = 0;
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.displayedData.filter = filterValue;
    }

    getFlexStyle(index) {
        return '0 0 ' + this.displayedColWidth[index];
    }

    formatData(element='', key) {
        if (key.includes('_date')) {
            return this.datePipe.transform(element[key], 'dd-MMM-yyyy hh:mm:ss');
        } else {
            if ((element[key]) && (typeof (element[key]) !== 'string')) {
                return element[key].toString();
            } else {
                return element[key] || '';
            }
        }
    }

    formatHeading(colIndex) {
      if (this.displayedHeadings[colIndex] === 'protocol_number') {
        return 'Protocol #';
      }
      return this.displayedHeadings[colIndex];
    }

    onTreemapSiteClick($event) {
        this.router.navigate([`/dashboard/study/${this.studyId}/site/${$event.siteId}/detail/${this.calcId}`]);
    }

    showTooltip(element, colName) {
      let text;
      const tooltipCols = ['protocol_number'];
      if (colName === 'protocol_number') { text = this.studyName; }

      return {
        isDisabled: !tooltipCols.includes(colName),
        text
      };
    }
}
