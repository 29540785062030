import { SpinnerService } from '@core/services/spinner.service';
import { Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { EndpointService } from '@shared/services';

@Component({
  selector: 'ert-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class LoginComponent implements OnInit {
  loading: Boolean = false;

  constructor( private router: Router,
     private loaderService: SpinnerService,
     private endpointService: EndpointService,) {
  }

  ngOnInit() {
    this.loaderService.show();
    const loginUrl = this.endpointService.getEndpoint('gssoLogin');
    this.router.navigate(["/"]).then(result=>{window.location.href = loginUrl;});
    setTimeout( () => {
      this.loaderService.hide();
    }, 1000);
  }
}
