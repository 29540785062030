import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsService } from '@app/shared/services/utils.service';

@Component({
  selector: 'ert-metric-help-info',
  templateUrl: './metric-help-info.component.html',
  styleUrls: ['./metric-help-info.component.scss']
})
export class MetricHelpInfoComponent implements OnInit {
   thresholdOpCategory = {
    'gte': '>=',
    'lte': '<='
  };

  formattedData = {
    thresholdMin: this.formatData().thresholdMin,
    thresholdMax: this.formatData().thresholdMax,
    thresholdValue: this.formatData().thresholdValue,
  };

  constructor(
    public dialogRef: MatDialogRef<MetricHelpInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

  ngOnInit() {
  }

  formatData() {
    return {
      thresholdMin: (this.data.units === '%') ? Number((this.data.thresholdMin * 100).toFixed(2)) : this.data.thresholdMin,
      thresholdMax: (this.data.units === '%') ? Number((this.data.thresholdMax * 100).toFixed(2)) : this.data.thresholdMax,
      thresholdValue: (this.data.units === '%') ? Number((this.data.thresholdValue * 100).toFixed(2)) : this.data.thresholdValue,
    };
  }

   closeModal(bool) {
    this.dialogRef.close(bool);
  }
   getScoreColor(score) {
    return UtilsService.getColorFromScore(score);
  }
}


