import { Action } from '@ngrx/store';

export enum VisualInteractionsTypes {
  SINGLE_SELECT_INTERACTION = '[Visual Interactions] SINGLE_SELECT_INTERACTION',
  MULTI_SELECT_INTERACTION = '[Visual Interactions] MULTI_SELECT_INTERACTION',
  RESET_ALL_INTERACTION = '[Visual Interactions] RESET_ALL_INTERACTION',
  // CREATE_INTERACTION = '[Visual Interactions] CREATE_INTERACTION',
}

export class SingleSelectInteraction implements Action {
  readonly type = VisualInteractionsTypes.SINGLE_SELECT_INTERACTION;
  constructor(public payload: any) { }
}

export class MultiSelectInteraction implements Action {
  readonly type = VisualInteractionsTypes.MULTI_SELECT_INTERACTION;
  constructor(public payload: any) { }
}

export class ResetAllInteraction implements Action {
  readonly type = VisualInteractionsTypes.RESET_ALL_INTERACTION;
}

export type VisualInteractions =
  SingleSelectInteraction |
  MultiSelectInteraction |
  ResetAllInteraction
  ;
