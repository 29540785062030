import { Injectable } from '@angular/core';
import { KriConfigAudit } from '@app/shared/models/study-config/kri-config-audit';
import { KpiConfigAudit } from '@app/shared/models/study-config/kpi-config-audit';
import { MilestoneConfigAudit } from '@app/shared/models/study-config/milestone-audit';
import { AlertConfigAudit } from '@app/shared/models/study-config/alert-audit';
import { UserAudit } from '@app/shared/models/study-config/user-audit';
import { TaskAudit } from '@app/shared/models/study-config/task-audit';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EndpointService, DataService } from '@app/shared/services';
// import 'rxjs/add/operator/map';
import { StudyAudit } from '@app/settings/admin/audit/study-audit/edit-study-audit/edit-study-audit.component';

@Injectable()
export class AuditComponentService {

  constructor(
    private endpointService: EndpointService,
    private dataService: DataService<any>,
  ) { }

  public getAuditLog(): Observable<any[]> {
    const endpoint = this.endpointService.getEndpoint('auditLogs');
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }
  public getAuditKpi(): Observable<KpiConfigAudit[]> {
    const endpoint = this.endpointService.getEndpoint('auditKpi');
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }
  public getAuditAlert(): Observable<AlertConfigAudit[]> {
    const endpoint = this.endpointService.getEndpoint('auditAlert');
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }
  public getAuditUser(): Observable<UserAudit[]> {
    const endpoint = this.endpointService.getEndpoint('auditUser');
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }
  public getAuditTask(): Observable<TaskAudit[]> {
    const endpoint = this.endpointService.getEndpoint('auditTask');
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }
  public getAuditMilestone(): Observable<MilestoneConfigAudit[]> {
    const endpoint = this.endpointService.getEndpoint('auditMilestone');
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }
  public getAuditStudy(): Observable<StudyAudit[]> {
    const endpoint = this.endpointService.getEndpoint('auditStudy');
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }
}
