
  <h2>{{titleText}}</h2>


<form class="add-user__form" [formGroup]="userForm" (ngSubmit)="sendInvite()" #inviteUserForm="ngForm">
  <div class="add-user__form-section">
    <mat-form-field>
      <input matInput placeholder="Prefix" formControlName="prefix" onkeyup="this.value = this.value[0].toUpperCase() + this.value.substr(1)">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Title" formControlName="suffix" onkeyup="this.value = this.value[0].toUpperCase() + this.value.substr(1)">
    </mat-form-field>
  </div>
  <div class="add-user__form-section">
    <mat-form-field>
      <input matInput placeholder="First Name *" formControlName="firstName"  onkeyup="this.value = this.value[0].toUpperCase() + this.value.substr(1)">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Last Name *" formControlName="lastName" onkeyup="this.value = this.value[0].toUpperCase() + this.value.substr(1)" >
    </mat-form-field>
  </div>
  <div class="add-user__form-section">
    <mat-form-field>
      <input matInput placeholder="Email *" formControlName="email" >
    </mat-form-field>
  </div>

  <div class="add-user__button-row">
    <button class="es-button" [mat-dialog-close]="false">Cancel</button>

    <ert-spinner-button
    [disabled]="userForm.invalid" [active]="buttonActive" [buttonText]="buttonText"
  ></ert-spinner-button>

  </div>
</form>
