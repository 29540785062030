import { Action } from '@ngrx/store';

export enum CustomerActionTypes {
  LOAD_CUSTOMERS = '[Customer] LOAD_CUSTOMERS',
  SHOW_MULTI_CUSTOMER = '[Customer] SHOW_MULTI_CUSTOMER',
  HIDE_MULTI_CUSTOMER = '[Customer] DESELECT_CUSTOMER',
  SELECT_CUSTOMER = '[Customer] SELECT_CUSTOMER',
  SELECT_CUSTOMER_SUCCESS = '[Customer] SELECT_CUSTOMER_SUCCESS',
  SELECT_CUSTOMER_FAIL = '[Customer] SELECT_CUSTOMER_FAIL',
  SELECT_CUSTOMER_WITH_NO_DB = '[Customer] SELECT_CUSTOMER_WITH_NO_DB',
  RESET_CUSTOMER = '[Customer] RESET_CUSTOMER',
  CREATE_CUSTOMER = '[Customer] CREATE_CUSTOMER',
}

export class LoadCustomers implements Action {
  readonly type = CustomerActionTypes.LOAD_CUSTOMERS;
  constructor(public payload: any) { }
}

export class HideMultiCustomer implements Action {
  readonly type = CustomerActionTypes.HIDE_MULTI_CUSTOMER;
}

export class ShowMultiCustomer implements Action {
  readonly type = CustomerActionTypes.SHOW_MULTI_CUSTOMER;
}

export class SelectCustomer implements Action {
  readonly type = CustomerActionTypes.SELECT_CUSTOMER;
  constructor(public payload: any) { }
}

export class SelectCustomerSuccess implements Action {
  readonly type = CustomerActionTypes.SELECT_CUSTOMER_SUCCESS;
  constructor(public payload: any) { }
}

export class SelectCustomerWithNoDB implements Action {
  readonly type = CustomerActionTypes.SELECT_CUSTOMER_WITH_NO_DB;
  constructor(public payload: any) { }
}

export class SelectCustomerFail implements Action {
  readonly type = CustomerActionTypes.SELECT_CUSTOMER_FAIL;
  constructor(public payload: any) { }
}

export class ResetCustomer implements Action {
  readonly type = CustomerActionTypes.RESET_CUSTOMER;
}

export class CreateCustomer implements Action {
  readonly type = CustomerActionTypes.CREATE_CUSTOMER;
  constructor(public payload: any) { }
}

export type CustomerActions =
  LoadCustomers|
  ShowMultiCustomer|
  HideMultiCustomer|
  SelectCustomer |
  SelectCustomerSuccess|
  SelectCustomerWithNoDB|
  SelectCustomerFail|
  ResetCustomer|
  CreateCustomer
  ;
