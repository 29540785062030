import { AUTH_KEY } from './../../core/store/auth/auth.reducer';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UpdateToolbar } from '@core/store/toolbar/toolbar.actions';
import { SetBreadCrumbs } from './../../core/store/breadcrumbs/breadcrumbs.actions';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { StudyService } from '@app/shared/services/study.service';
import { SiteService } from '@app/shared/services/site.service';
import { metricCategory, Category } from '@app/shared/models/metric-category.type';
import { kriOverviewChart } from '@app/mockdata';
import { forkJoin } from 'rxjs';
import { BreadcrumbConfigService } from '@app/shared/services/breadcrumb-config.service';
import { GlobalHeaderService } from '@app/shared/services/global-header.service';
import { isEmptyObject } from '@app/shared/helpers/util';

@Component({
  selector: 'ert-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss']
})
export class SiteComponent implements OnInit {
  metricbyScoreData: any;
  bucketName: metricCategory;
  breakpoint;
  noOfMetrics = 10;
  bestScoreOrder = 'desc';
  metricScores: any[];
  siteBucketsScoreHistory: any;
  selectedCategory = 'null';
  metricCategories = [
    new Category('compliance', 'Compliance'),
    new Category('quality', 'Quality')
  ];

  enrollmentKri: any[];
  complianceKri: any[];
  safetyKri: any[];
  qualityKri: any[];

  siteName: string;
  studyId: number;
  siteId: number;

  statData: any;
  scorecardWidth = 25;
  chartData;
  bucketScores;

  cOptions: any = {
    quality: {
      legend: 'Quality Score',
      units: 'percentage',
      lineWidth: 3,
      curveType: 1,
      dotRadius: 6,
      color: '#90368C',
      showProjection: true,
    },
    compliance: {
      legend: 'Compliance Score',
      units: 'percentage',
      lineWidth: 3,
      curveType: 1,
      color: '#1692BA ',
      showProjection: true,
    },
    projection: {
      lineWidth: 3,
      units: 'percentage',
      curveType: 1,
      color: '#494a4b',
      legend: 'Projected',
      dashPattern: '10,10',
    }
  };

  constructor(
    private route: ActivatedRoute,
    private store: Store<any>,
    private siteService: SiteService,
    private studyService: StudyService,
    private sessionStorageService: SessionStorageService,
    private globalHeaderService: GlobalHeaderService,
    private brdCrmbConfig: BreadcrumbConfigService
  ) {
    this.route.params.subscribe(params => {
      this.studyId = +params.studyId;
      this.siteId = +params.siteId;
    });
  }

  ngOnInit() {
    this.chartData = kriOverviewChart;

    forkJoin([
      this.siteService.getSiteBucketScores(this.studyId, this.siteId),
      this.siteService.getSite(this.studyId, this.siteId),
      this.studyService.getStudyById(this.studyId),
      this.siteService.getSiteBucketScoresHistory(this.studyId, this.siteId, true)
    ]).subscribe(([bucketScores, site, study, siteBucketsScoreHistory]) => {
      const siteName = `${site.siteNo} - ${site.institutionName}`;
      this.siteName = siteName;
      this.setupBreadcrumbs(site, study)


      let compScore = null, qualScore = null;
      for (var i in bucketScores) {
        if (bucketScores[i].internalName === 'bucket_compliance_score') {
          compScore = bucketScores[i].calcLongDisplayVal;
        } else if (bucketScores[i].internalName === 'bucket_quality_score') {
          qualScore = bucketScores[i].calcLongDisplayVal;
        }
      }

      this.store.dispatch(new UpdateToolbar({
        title: 'My Site',
        study: {
            studyId: this.studyId,
            odsReportsEnabled: study.odsReportsEnabled,
        },
        site: {
            siteId: this.siteId,
        },
        bucket: {
          complianceScore: compScore ? parseInt(compScore) : null,
          qualityScore: qualScore ? parseInt(qualScore) : null,
        }
      }));

      this.siteBucketsScoreHistory = this.setBucketScoresHistoryData(siteBucketsScoreHistory);
    });

    this.getSiteMetricScores(this.studyId, this.siteId, 'all');
  }

  setupBreadcrumbs(site, study) {
    this.globalHeaderService.getHeader().then((header) => {
      header.addBreadcrumbs([
        this.brdCrmbConfig.getHomeBrdCrmb(),
        this.brdCrmbConfig.getPortfolioBrdCrmb(),
        this.brdCrmbConfig.getStudyBrdCrmb(this.studyId, study.studyName),
        this.brdCrmbConfig.getSiteBrdCrmb(this.studyId, this.siteId, site.siteNo)
      ]);
      header.subscribe('logout', this.globalHeaderService.logoutHandler());
      header.subscribe('session-expired', this.globalHeaderService.sessionExpHandler());
    });
  }

 onSelectCategory($event){
    this.getSiteMetricScores(this.studyId, this.siteId, $event);
  }

  getSiteMetricScores(studyId: number,  siteId: number, bucketName: metricCategory){
    this.siteService.getSiteMetricScores(studyId, siteId, bucketName).subscribe(ms => {
      this.metricbyScoreData = ms;
    });
  }

  setBucketScoresHistoryData(bucketsScoreHistory) {
    let bucketsScoreHistoryArr = [];
    if (!isEmptyObject(bucketsScoreHistory)) {
      bucketsScoreHistoryArr = [
        {
          ...bucketsScoreHistory.bucket_quality_score,
          ...this.cOptions.quality
        },
        {
          ...bucketsScoreHistory.bucket_compliance_score,
          ...this.cOptions.compliance
        },
        {
          ...this.cOptions.projection
        }
      ];
    }

    return bucketsScoreHistoryArr;
  }


}
