import { UserService } from './services/user.service';
import { PermissionService } from './services/permisssion.service';
import { StatBlockTopComponent } from './stat-block/stat-block-top/stat-block-top.component';
import { UtilsService } from './services/utils.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminService } from './services/admin.service';
import {
  DataService,
  EndpointService
} from './services';

import { MaterialAllModule } from './material/material-all.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { SitelocationsComponent } from './site-locations/site-locations.component';
import { RouterModule } from '@angular/router';
import { ScorecardComponent } from './scorecard/scorecard.component';
import { BigCardComponent } from './scorecard/modals/big-card.component';
import { ObservationComponent } from './observation/observation.component';
import { ObservationModalComponent } from './observation/observation-modal/observation-modal.component';
import { SpinnerButtonComponent } from './spinner-button/spinner-button.component';
import { NotificationComponent } from './notification/notification.component';
import { SbTrendlineComponent } from './d3/sb-trendline/sb-trendline.component';
import { StatBlockComponent } from './stat-block/stat-block.component';
import { MsLineChartComponent } from './d3/ms-line-chart/ms-line-chart.component';
import { RemoveUnderscorePipe } from './pipes/remove-underscore.pipe';
import { MiniBarChartComponent } from './d3/mini-bar-chart/mini-bar-chart.component';
import { OrdinalPipe } from './pipes/ordinal.pipe';
import { StudyService } from './services/study.service';
import { SiteService } from './services/site.service';
import { DisplayDatePipe } from './pipes/display-date.pipe';
import { ThresholdConfigComponent } from './threshold-config/threshold-config.component';
import { ThresholdValuePipe } from './pipes/threshold-value.pipe';
import { StudiesComponent } from '../settings/admin/organization/studies/studies.component';
import { SearchPaginatorComponent } from './search-paginator/search-paginator/search-paginator.component';
import { PermissionDirective } from './permission.directive';
import { KriCategoryTableComponent } from './kri-category-table/kri-category-table.component';
import { HorizontalStackedBarComponent } from './d3/horizontal-stacked-bar/horizontal-stacked-bar.component';
import { TreemapChartComponent } from './d3/treemap-chart/treemap-chart.component';
import { DisableControlDirective } from './pipes/disabled-control.pipe';
import { DynamicFormComponent } from './form-generator/dynamic-form/dynamic-form.component';
import { SparklineComponent } from './d3/sparkline/sparkline.component';
import { FormPreviewComponent } from './form-generator/dynamic-form/form-preview/form-preview.component';
import { BarChartComponent } from './d3/bar-chart/bar-chart.component';
import { ScoreOverviewComponent } from './score-overview/score-overview.component';
import { ScatterPlotChartComponent } from './d3/scatter-plot-chart/scatter-plot-chart.component';
import { TornadoChartComponent } from './d3/tornado-chart/tornado-chart.component';
import { EventListTableComponent } from './event-list-table/event-list-table.component';
import { MetricChartComponent } from './d3/metric-chart/metric-chart.component';
import { InfoBannerComponent } from './info-banner/info-banner.component';
import { MyStudyInfoComponent } from './my-study-info/my-study-info.component';
import { MetricInfoBannerComponent } from './metric-info-banner/metric-info-banner.component';
import { SiteMetricInfoComponent } from './site-metric-info/site-metric-info.component';
import { NoDataComponent } from './data-generic/no-data/no-data.component';
import { NoValueComponent } from './data-generic/no-value/no-value.component';
import { PrettyPrintPipe } from './pipes/pretty-print.pipe';
import { StudyMetricInfoComponent } from './study-metric-info/study-metric-info.component';
import { AuditLogComponent } from './audit-log/audit-log.component';
import { AuditComponentService } from './services/audit';
import { MetricStudyEventTableComponent } from './metric-study-event-table/metric-study-event-table.component';
import { MetricSiteEventTableComponent } from './metric-site-event-table/metric-site-event-table.component';
import { DurationTimeDirectiveDirective } from './directives/duration-time.directive';
import { BoxPlotComponent } from './d3/box-plot/box-plot.component';
import { InlineTableEditComponent } from './table/inline-table-edit/inline-table-edit.component';
import { ConfirmationDialogComponent } from './modals/confirmation-dialog/confirmation-dialog.component';
import { MetricByScoreComponent } from './d3/metric-by-score/metric-by-score.component';
import { PerfectScrollbarModule, PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { HStackedBarChartComponent } from './d3/h-stacked-bar-chart/h-stacked-bar-chart.component';
import { LineColorChartComponent } from './d3/line-color-chart/line-color-chart.component';
import { HeatmapComponent } from './d3/heatmap/heatmap.component';
import { GlobalHeaderService } from './services/global-header.service';
import { DataLoadingChartComponent } from './data-generic/data-loading-chart/data-loading-chart.component';
import { MetricHelpInfoComponent } from './modals/metric-help-info/metric-help-info.component';
import { DialogReasonForChangeComponent } from '@app/settings/admin/study-parameters-config/dialog-reason-for-change/dialog-reason-for-change.component';
import { TableFilterComponent } from './table-filter/table-filter.component';
import { MouseleaveCloseDirective } from './directives/multi-select/mouseleave-close.directive';
import { CaseConverterPipe } from './pipes/case-convereter.pipe';
import { CodeMirrorComponent } from './code-mirror/code-mirror.component';
import { CodeMirrorService } from './code-mirror/code-mirror.service';
import { SubMetricsConfigComponent } from '@app/settings/admin/study-parameters-config/sub-metrics-config/sub-metrics-config.component';
import { CrTooltipModule } from '@crystal/ng-tooltip';
import { CrInputModule } from '@crystal/ng-input'
import { CrSelectModule } from '@crystal/ng-select'

@NgModule({
    imports: [
        CommonModule,
        MaterialAllModule,
        NgxChartsModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        MatDialogModule,
        PerfectScrollbarModule,
        CrTooltipModule,
        CrInputModule,
        CrSelectModule
    ],
    declarations: [
        ObservationModalComponent,
        ScorecardComponent,
        BigCardComponent,
        ObservationComponent,
        ObservationModalComponent,
        SpinnerButtonComponent,
        NotificationComponent,
        SbTrendlineComponent,
        StatBlockComponent,
        StatBlockTopComponent,
        SitelocationsComponent,
        MsLineChartComponent,
        RemoveUnderscorePipe,
        MiniBarChartComponent,
        OrdinalPipe,
        DisplayDatePipe,
        ThresholdConfigComponent,
        ThresholdValuePipe,
        StudiesComponent,
        SearchPaginatorComponent,
        PermissionDirective,
        DisableControlDirective,
        KriCategoryTableComponent,
        HorizontalStackedBarComponent,
        TreemapChartComponent,
        DynamicFormComponent,
        SparklineComponent,
        FormPreviewComponent,
        BarChartComponent,
        ScoreOverviewComponent,
        ScatterPlotChartComponent,
        TornadoChartComponent,
        EventListTableComponent,
        MetricChartComponent,
        InfoBannerComponent,
        MyStudyInfoComponent,
        MetricInfoBannerComponent,
        SiteMetricInfoComponent,
        NoDataComponent,
        NoValueComponent,
        PrettyPrintPipe,
        StudyMetricInfoComponent,
        AuditLogComponent,
        MetricStudyEventTableComponent,
        MetricSiteEventTableComponent,
        DurationTimeDirectiveDirective,
        BoxPlotComponent,
        InlineTableEditComponent,
        ConfirmationDialogComponent,
        MetricByScoreComponent,
        HStackedBarChartComponent,
        LineColorChartComponent,
        HeatmapComponent,
        DataLoadingChartComponent,
        MetricHelpInfoComponent,
        DialogReasonForChangeComponent,
        TableFilterComponent,
        MouseleaveCloseDirective,
        CaseConverterPipe,
        CodeMirrorComponent,
        SubMetricsConfigComponent
    ],
    exports: [
        MaterialAllModule,
        StatBlockComponent,
        FormsModule,
        ReactiveFormsModule,
        SitelocationsComponent,
        MiniBarChartComponent,
        ThresholdConfigComponent,
        StudiesComponent,
        SearchPaginatorComponent,
        RemoveUnderscorePipe,
        MsLineChartComponent,
        DisplayDatePipe,
        ScorecardComponent,
        ObservationComponent,
        SpinnerButtonComponent,
        SbTrendlineComponent,
        StatBlockTopComponent,
        OrdinalPipe,
        ThresholdValuePipe,
        PermissionDirective,
        DisableControlDirective,
        KriCategoryTableComponent,
        HorizontalStackedBarComponent,
        TreemapChartComponent,
        DynamicFormComponent,
        SparklineComponent,
        FormPreviewComponent,
        BarChartComponent,
        ScoreOverviewComponent,
        ScatterPlotChartComponent,
        TornadoChartComponent,
        EventListTableComponent,
        MetricChartComponent,
        EventListTableComponent,
        InfoBannerComponent,
        MyStudyInfoComponent,
        MetricStudyEventTableComponent,
        MetricSiteEventTableComponent,
        MetricInfoBannerComponent,
        SiteMetricInfoComponent,
        NoDataComponent,
        NoValueComponent,
        PrettyPrintPipe,
        StudyMetricInfoComponent,
        AuditLogComponent,
        DurationTimeDirectiveDirective,
        BoxPlotComponent,
        InlineTableEditComponent,
        MetricByScoreComponent,
        HStackedBarChartComponent,
        LineColorChartComponent,
        HeatmapComponent,
        DataLoadingChartComponent,
        MetricHelpInfoComponent,
        DialogReasonForChangeComponent,
        TableFilterComponent,
        MouseleaveCloseDirective,
        CaseConverterPipe,
        CodeMirrorComponent,
        SubMetricsConfigComponent
    ],
    providers: [
        DisplayDatePipe,
        DataService,
        UserService,
        StudyService,
        SiteService,
        AdminService,
        EndpointService,
        UtilsService,
        PermissionService,
        AuditComponentService,
        GlobalHeaderService,
        CodeMirrorService,
    ]
})
export class SharedModule {
}
