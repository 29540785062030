import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

const endpointMap = {
  // auth
  gssoLogin: 'auth/gsso/login',
  gssoLoginComplete: 'auth/gsso/login/complete',
  logoff: `auth/logoff`,
  inviteUser: `auth/user/invite`,
  select: 'auth/select',

  // log (critical, error, warning, info) to DB
  logCritical: 'api/log/critical',
  logError: 'api/log/error',
  logWarning: 'api/log/warning',
  logInfo: 'api/log/info',
  logDetails: (limit, pageNo, filterQueryString) => `api/log/limit/${limit}/page/${pageNo}${filterQueryString}`,
  singleLogActivity: (activityLogId) => `api/log/activity/${activityLogId}`,
  logCount: (filterQueryString) => `api/log/count${filterQueryString}`,

  // roles, permissions
  roles: 'api/role',
  permissions: 'api/permission',
  admins: 'api/admins',
  editAdmin: 'api/admins/admin',

  // Customer (client)
  createCustomer: 'api/client/create',
  getCustomers: 'api/load/client/',
  getCustomer: (clientId) => `api/load/client/${clientId}`,
  getCustomerStudies: (clientId) => `api/load/client/${clientId}/study`,
  setCustomerMode: (clientId, clientModeId) => `api/load/client/${clientId}/mode/${clientModeId}`,
  clientCalcEngine: (clientId) => `api/load/client/calc/${clientId}`,
  studyCalcEngine: (clientId, studyId) => `api/load/client/calc/${clientId}/study/${studyId}`,
  clientParameters: 'api/client/parameters',
  deleteClientParameter: (paramKey) => `api/client/parameter/${paramKey}`,
  clientMetrics: 'api/client/metrics',
  //Refresh Extract
  fetchRefreshInfo: (clientId) => `api/tableau/getLatestRefresh/${clientId}`,
  dataSourceStatus: 'api/tableau/getUserDataRefreshStatus',
  getTableauToken: 'api/tableau/generate/token',
  renewSession: 'api/tableau/renewSession',
  customerStudyFeatures: 'api/client/customerStudyFeatures',

  // Calc
  clearcalclock: (clientId) => `api/calc/clearcalclock/${clientId}`,
  testCalcFormula: 'api/load/client/testFormula',
  saveCalcFormula: (calcTypeId) => `api/metric/saveformula/${calcTypeId}`,

  // Metric
  saveMetric: 'api/metric/create',
  copyMetric: 'api/metric/copy',
  getMetric: (metricId) => `api/metric/${metricId}`,
  variablesList: 'api/metric/aggrVar/list',
  calcFormula: (calcTypeId) => `api/metric/formula/${calcTypeId}`,
  updateMetric: (metricId) => `api/metric/${metricId}`,
  testAggVariablesSql: 'api/load/client/testSql',
  saveAggrVariables: 'api/metric/aggrVar/create',
  updateAggrVariables: (metricId) => `api/metric/${metricId}/aggrVar/update`,
  saveMetricAggrVariables: (metricId) => `api/metric/${metricId}/aggrVar/create`,
  getMetricAssociatedStudies: (metricId) => `api/metric/${metricId}/associatedStudies`,
  getAggregateVariables: 'api/metric/aggrVar/aggregateVariableList',
  getVariableSQL: (variableId) => `api/metric/aggrVar/sqlDetails/${variableId}`,
  getAggrConfigVarsParams: (variableId) => `api/metric/aggrVar/${variableId}/getSqlVarParam`,
  updateAggrConfigVarsParams: (variableId) => `api/metric/aggrVar/${variableId}/updateSqlVarParam`,
  updateVariableStatus: 'api/metric/aggrVar/updateStatus',
  deleteAggrVar: (aggrVarId) => `api/metric/aggrVar/${aggrVarId}/delete`,
  deleteMetric: (calcTypeId) => `api/metric/${calcTypeId}/delete`,
  categoriesList: 'api/metric/categories/list',
  systemSourceName: 'api/metric/systemSourceName/list',

  // study
  studySystemSourceName: (studyId) => `api/study/${studyId}/studySystemSourceName/list`,
  studyMilestones: (studyId) => `api/study/${studyId}/milestone`,
  singleSite: (studyId, siteId) => `api/study/${studyId}/site/${siteId}`,
  siteKpiCalcs: (studyId, siteId) => `api/study/${studyId}/site/${siteId}/kpi`,
  siteKriCalcs: (studyId, siteId, kriCategory) => `api/study/${studyId}/site/${siteId}/kri/category/${kriCategory}`,
  siteKriHistorical: (studyId, siteId, kriId) => `api/study/${studyId}/site/${siteId}/kri/${kriId}/calc`,
  siteKriHistoricalData: (studyId, siteId, kriId) => `api/study/${studyId}/site/${siteId}/kri/${kriId}/data`,
  siteTrend: (studyId, siteId) =>  `api/study/${studyId}/site/${siteId}/composite/trend`,
  siteLatestBucketCalcs: (studyId, siteId, bucketName) => `api/study/${studyId}/site/${siteId}/bucket/${bucketName}`,
  siteHistoricalBucketCalcs: (studyId, siteId, bucketName) => `api/study/${studyId}/site/${siteId}/bucket/${bucketName}/hist`,
  siteMetricScores: (studyId, siteId, bucketName) => `api/study/${studyId}/site/${siteId}/bucket/${bucketName}/metricsscore`,
  siteMetricDetails: (studyId, siteId, metricId) => `api/study/${studyId}/site/${siteId}/metricId/${metricId}`,
  studyMetricDetails: (studyId, metricId) => `api/study/${studyId}/metricId/${metricId}`,
  studyCalcParameters: (studyId) => `api/study/${studyId}/calc/parameters`,
  studyBucketScoresHistory: (studyId, isChartData = false) =>`api/study/${studyId}/bucketScores/history?isChartData=${isChartData}`,
  siteBucketScoresHistory: (studyId, siteId, isChartData = false) => `api/study/${studyId}/site/${siteId}/bucketScores/history?isChartData=${isChartData}`,
  studyMetricScoresHistory: (studyId, metricId, isChartData = false) => `api/study/${studyId}/metric/${metricId}/metricScores/history?isChartData=${isChartData}`,
  siteMetricScoresHistory: (studyId, siteId, metricId, isChartData = false) => `api/study/${studyId}/site/${siteId}/metric/${metricId}/metricScores/history?isChartData=${isChartData}`,
  applyMetricsToStudies: 'api/study/apply/metrics',
  // Source
  sourceConfigList: (sourceName, clientId) => `api/load/source/${sourceName}/client/${clientId}`,
  singleSourceStudyConfig: (sourceName, clientId, studyId) => `api/load/source/${sourceName}/client/${clientId}/study/${studyId}`,

  // study
  allStudies: 'api/study',
  allStudyServiceStudies: 'api/study/studyservicestudies',
  studyIds: (therapeutic, indication, studyName, country, selectedLoadStartDate, selectedLoadEndDate) => `api/study/studyIds/${therapeutic}/${indication}/${studyName}/${country}/${selectedLoadStartDate}/${selectedLoadEndDate}`,
  editStudy: 'api/study/edit',
  studiesForAdmin: 'api/study/studiesforadmin',
  configDefault: 'api/load/config/default',
  // create study might need to remove
  createStudy: 'api/load/study',
  createStudySource: 'api/load/source',
  addStudy: 'api/study/createStudy',
  refreshStudy: 'api/study/refreshStudy',
  singleStudySources: (studyId) => `api/study/${studyId}/source`,
  studyBenchmark: 'api/study/benchmark',
  studyReport: (studyId, metricId) => `api/study/${studyId}/metric/${metricId}/report`,
  studySitesBenchmark: (studyId) => `api/study/${studyId}/site/benchmark`,
  crossSite: 'api/study/site/benchmark',
  singleStudy: (studyId) => `api/study/${studyId}`,
  singleStudyForAdmin: (studyId) => `api/study/${studyId}/adminstudy`,
  studiesByScore: (therapeutic, indication, studyName, country, selectedLoadStartDate, selectedLoadEndDate) => `api/study/studiesbyscore/${therapeutic}/${indication}/${studyName}/${country}/${selectedLoadStartDate}/${selectedLoadEndDate}`,
  sites: (studyId) => `api/study/${studyId}/site`,
  studiesData: 'api/study/studiesdata',
  studyLoadStatus: (studyId) => `api/study/${studyId}/studyloadstatus`,
  studyFeaturesForAdmin: (studyId) => `api/study/${studyId}/studyfeatures`,
  updateStudyFeature: (studyId) => `api/study/${studyId}/updatestudyfeature`,

  studyBucketScores : (studyId) =>`api/study/${studyId}/bucketScores`,
  studyKriCalcs: (studyId, kriCategory) => `api/study/${studyId}/kri/category/${kriCategory}`,
  studyKpiCalcs: (studyId) => `api/study/${studyId}/kpi`,
  studyMilestoneList: (studyId) => `api/study/${studyId}/milestone/list`,
  studyMilestoneValues: (studyId) => `api/study/${studyId}/milestone/value`,
  studyKriThresholdList: (studyId) => `api/study/${studyId}/kri/threshold/list`,
  studyKriThresholdValues: (studyId) => `api/study/${studyId}/kri/threshold/value`,
  studyKpiThresholdList: (studyId) => `api/study/${studyId}/kpi/threshold/list`,
  studyKpiThresholdValues: (studyId) => `api/study/${studyId}/kpi/threshold/value`,
  updateDataInsightStatus: (studyId, clientId) => `api/study/${clientId}/${studyId}/updateDataInsightStatus`,

  studyMetricThresholdValue: (studyId, isStudyLevel = true) => `api/study/${studyId}/metric/threshold/value?isStudyLevel=${isStudyLevel}`,
  studyKpiThresholdValue: (studyId) => `api/study/${studyId}/kpi/threshold/value`,
  studyKriHistorical: (studyId, kriId) => `api/study/${studyId}/kri/${kriId}/calc`,
  studyKriHistoricalData: (studyId, kriId) => `api/study/${studyId}/kri/${kriId}/data`,
  studySingleMetric: (studyId, metricId) => `api/study/${studyId}/metric/${metricId}`,

  subMetricCreate: (studyId, metricId) => `api/study/${studyId}/submetric/${metricId}/create`,
  subMetricUpdate: (studyId, metricId) => `api/study/${studyId}/submetric/${metricId}/update`,

  studyScoreCard : (studyId) => `api/study/${studyId}/scorecard`,
  studyLatestBucketCalcs: (studyId, bucketName) => `api/study/${studyId}/bucket/${bucketName}`,
  studyHistoricalBucketCalcs: (studyId, bucketName) => `api/study/${studyId}/bucket/${bucketName}/hist`,
  studyEvent: (studyId) => `api/study/${studyId}/events`,
  studyMetricEvent: (studyId, metricId) => `api/study/${studyId}/metric/${metricId}/events`,
  siteEvents: (studyId, siteId) => `api/study/${studyId}/site/${siteId}/events`,
  siteMetricEvents: (studyId, siteId, metricId) => `api/study/${studyId}/site/${siteId}/metric/${metricId}/events`,
  siteReport: (studyId, siteId, metricId) => `api/study/${studyId}/site/${siteId}/metric/${metricId}/report`,
  studySitesListing: (studyId) => `api/study/${studyId}/sites/listing`,
  studyHeatMap: (studyId, metricId) => `api/study/${studyId}/metric/${metricId}/heatmap`,
  studyMetricsByScore: (studyId, category) => `api/study/${studyId}/category/${category}/metricsbyscore`,
  subMetricFormIds: (studyId, metricId) => `api/study/${studyId}/metric/${metricId}/formIds`,
  getSubMetric: (studyId, parentMetricId) => `api/study/${studyId}/submetric/${parentMetricId}`,
  sitesByScore: (studyId) => `api/study/${studyId}/sitescores`,
  siteBucketScores : (studyId, siteId) =>`api/study/${studyId}/site/${siteId}/bucketScores`,
  getSubMetricDefaults: 'api/study/submetric/defaults',

  // user endpoints
  allUsers: 'api/user',
  addUser: 'api/user/adduser',
  singleUser: (userId) => `api/user/${userId}`,
  userRoles: (userId) => `api/user/${userId}/role`,
  singleUserRole: (userId, roleId) => `api/user/${userId}/role/${roleId}`,
  userPermissions: 'api/user/access',

  // TO Admin User Management
  getTOAdmins: 'api/load/admin/toadmin',
  getLevel0User: 'api/load/admin/user',
  inviteTOAdmin: 'api/load/admin/invite',
  makeTOAdmin: (userId) => `api/load/admin/${userId}`,
  revokeTOAdmin: (userId) => `api/load/admin/${userId}`,


  // portfolio
  portfolioKriCalcs: (kriCategory) => `api/portfolio/kri/category/${kriCategory}`,
  portfolioKriHistorical: (kriId) => `api/kri/${kriId}/calc`,
  portfolioKriHistoricalData: (kriId) => `api/kri/${kriId}/data`,
  portfolioHistoricalCalcsBucket: (siteId, bucketName) => `api/${siteId}/bucket/${bucketName}/hist`,
  getScoreChangeByPecentage: (therapeutic, indication, studyName, country, selectedLoadStartDate, selectedLoadEndDate) => `api/portfolio/score/boxplot/${therapeutic}/${indication}/${studyName}/${country}/${selectedLoadStartDate}/${selectedLoadEndDate}`,
  portfolioStudyOverview: (therapeutic, indication, studyName, country, selectedLoadStartDate, selectedLoadEndDate) => `api/portfolio/studies/overview/${therapeutic}/${indication}/${studyName}/${country}/${selectedLoadStartDate}/${selectedLoadEndDate}`,

  // alerts
  alerts: 'api/alert/all', // GET    alert/all   - gets all alerts
  readAlert: `api/unread`, // GET   alert/unread   - gets unread alerts
  singleAlert: (alertId) => `api/alert/${alertId}`, // GET  alert/:alertId   - get single alert
  observation: 'api/alert',
  sitesByEvents: (studyId) => `api/events/${studyId}`,
  eventsByLocation: (therapeutic, indication, studyName, country, selectedLoadStartDate, selectedLoadEndDate) => `api/events/location/${therapeutic}/${indication}/${studyName}/${country}/${selectedLoadStartDate}/${selectedLoadEndDate}`,
  studyEventsByLocation: (studyId) => `api/events/location/${studyId}`,

  // events
  siteEventsWithScores: (studyId, country, selectedLoadStartDate, selectedLoadEndDate) => `api/events/sitescores/${studyId}/${country}/${selectedLoadStartDate}/${selectedLoadEndDate}`,

  // load
  updateStudy: (studyId) => `api/load/study/${studyId}`,
  importStudy: (studyId) => `api/load/config/import/study/${studyId}`,
  exportStudy: (studyId) => `api/load/config/export/study/${studyId}`,

  // Notifications
  notificationsList: 'api/notifications/list',
  notificationsAdd: 'api/notifications/add',
  notificationsFilterAccess: 'api/notifications/filters-list',
  notificationUpdate: (notificationId) => `api/notifications/update/${notificationId}`,
  notificationDelete: (notificationId) => `api/notifications/delete/${notificationId}`,


  // Tasks
  tasks: 'api/task/all',
  task: 'api/task',
  singleTask: (taskId) => `api/task/${taskId}`,

  // client (routes, sidebar, etc)
  clientRoutes: 'api/client/routes',
  clientSidebar: 'api/client/sidebar',

  // Audit Trials
  auditLogs : 'api/audit/log',
  // auditKri : 'api/audit/kris',
  // auditKpi : 'api/audit/kpis',
  // auditMilestone : 'api/audit/milestones',
  // auditAlert : 'api/audit/alerts',
  // auditUser : 'api/audit/users',
  // auditTask : 'api/audit/tasks',
  // auditStudy: 'api/audit/study'
};


@Injectable()
export class EndpointService {
  constructor() { }

  getEndpoint(endpointName, ...params): string {
    const value = endpointMap[endpointName];
    const apiPath = (typeof value === 'string') ? value : value(...params);

    return `${environment.apiBase}/${apiPath}`;
  }

}
