import { Action } from '@ngrx/store';

export const REFRESH = '[DataSource] REFRESH';

export class DataSourceRefresh implements Action {
  readonly type = REFRESH;
  constructor(public payload: any) {
  }
}

export type DataSourceActions = DataSourceRefresh;