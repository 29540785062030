<div>
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" autocomplete="off">
    </mat-form-field>
    <mat-paginator #paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20, 30, 50]" class="paginator-alerts">
    </mat-paginator>
</div>
<div *ngIf="eventDataCheckFlag === 'unchecked'">
    <mat-spinner diameter="50"></mat-spinner>
</div>
<div *ngIf="eventDataCheckFlag === 'valid'">
    <!-- Creating the Table -->
    <mat-table #table [dataSource]="displayedData" class="ert-table" matSort matSortActive="status"
        matSortDirection="desc" matSortStart="desc">

        <!-- Site no column -->
        <ng-container matColumnDef="siteno">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Site Number </mat-header-cell>
            <mat-cell *matCellDef="let event"> {{event.siteno}} </mat-cell>
        </ng-container>

        <!-- PI column -->
        <ng-container matColumnDef="pi">
            <mat-header-cell *matHeaderCellDef mat-sort-header> PI </mat-header-cell>
            <mat-cell *matCellDef="let event"> {{event.pi}} </mat-cell>
        </ng-container>

        <!-- Metric Value column -->
        <ng-container matColumnDef="metricValue">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Metric Value </mat-header-cell>
            <mat-cell *matCellDef="let event"> {{event.metricValue}} </mat-cell>
        </ng-container>

        <!-- Description column -->
        <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Event Descrition </mat-header-cell>
            <mat-cell *matCellDef="let event"> {{event.description}} </mat-cell>
        </ng-container>

        <!-- Action column -->
        <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Action </mat-header-cell>
            <mat-cell *matCellDef="let event"> {{event.action}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>
    </mat-table>
</div>

<div *ngIf="eventDataCheckFlag === 'invalid'" class="es-status-sign">
    <span class="es-icon es-icon-magnifier"></span>
    <span class="text">No data to display</span>
</div>