<ng-container *ngIf="!siteId else site">
  <mat-card class="stats-container" *ngIf="statData?.topRowData">
    <mat-card-title>
      <h4>{{ statData.topRowTitle }}
      <ert-observation *ngIf="statData?.topRowData && !alertPermCheck" [studyId]="studyId" [siteId]="siteId">
        </ert-observation></h4>
    </mat-card-title>

    <div class="stats-item" [ngStyle]="{'min-width' : wrapWidth()}" *ngFor="let item of statData.topRowData">
      <p class="data" [matTooltip]="toolTipGoalData(item.threshold, item.calcContext.thresholdValue, item.units)" style="color: #003865;">
        {{ item.calcShortDisplayVal === "N/D" ? "-" : item.calcShortDisplayVal }}
      </p>
      <p class="title"><img *ngIf="item.isErtData" src="assets/ERT-Logo.svg" alt="">{{ item.calcTypeName }}</p>
    </div>
    <div class="stats-item es-status-sign" *ngIf="!statData.topRowData || !statData.topRowData.length">
      <span class="es-icon es-icon-magnifier"></span>
      <span class="text">No data to display</span>
    </div>
    <div *ngIf="!alertPermCheck">
    <!-- <ert-observation *ngIf="statData?.topRowData" [studyId]="studyId" [siteId]="siteId">
    </ert-observation> -->
    </div>
  </mat-card>
</ng-container>
<ng-template #site>
  <ert-stat-block-top></ert-stat-block-top>
</ng-template>

