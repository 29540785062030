import { SpinnerService } from './services/spinner.service';
import { ActivatedRoute, Router, NavigationEnd , NavigationStart, RouterEvent, NavigationError,} from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { filter, takeUntil, catchError, delay } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Subject, of } from 'rxjs';
import { UserIdleService } from 'angular-user-idle';
import { Logout } from './store/auth/auth.reducer';
import { AuthService } from './services/auth.service';
import { CommunicationEventsService } from '@app/shared/services/communication/communication-events.service';

// import { ActionAppSetUrl } from '@core/store/app.actions';
// import { StartupService } from './services/startup.service';

@Component({
  selector: 'ert-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit , OnDestroy {
  loading = false;
  maxLoadTime = 500;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private loaderService: SpinnerService,
    private idleService: UserIdleService,
    private authService: AuthService,
    private communicationEvents: CommunicationEventsService,
  ) {
    if (!environment.production) {
      console.log('You are currently working in: ' + environment.envName + ' Environment');
    }

    router.events.subscribe((event: RouterEvent) => {
      this.navigationLoaderInterceptor(event);
    });
  }

  ngOnInit() {
    this.initIcons();
    // this.handlePreviousUrl();
    this.idleService.startWatching();
    this.idleService.onTimerStart().subscribe(count => {
      const eventList = ['click', 'mouseover', 'keydown', 'DOMMouseScroll', 'mousewheel', 'mousedown',
      'touchstart', 'touchmove', 'scroll', 'keyup'];
      for (const event of eventList) {
        document.body.addEventListener(event, () => this.idleService.resetTimer());
      }
    });
    this.idleService.onTimeout().subscribe(() => {
      this.authService.logout();
    });

    this.loaderService.loaderState
      .pipe(takeUntil(this.unsubscribe$), catchError(error => of(`Bad Promise: ${error}`)), delay(0))
      .subscribe((state: any) => {
        this.loading = state.show;
      });
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationLoaderInterceptor(event: RouterEvent) {
    if (event instanceof NavigationStart) {
      this.loaderService.show();
    }

    if (event instanceof NavigationEnd) {
      setTimeout(() => {
        this.communicationEvents.resetAllInteractions();
        this.loaderService.hide();
      }, this.maxLoadTime);
    }

    // // Set loading state to false in both of the below events to hide the spinner in case a request fails
    // if (event instanceof NavigationCancel) {
    //   setTimeout(() => {
    //     this.loaderService.hide();
    //   }, 2000);
    // }
    if (event instanceof NavigationError) {
      setTimeout(() => {
        this.loaderService.hide();
      }, this.maxLoadTime);
    }
  }

  // handlePreviousUrl() {
  //   this.router.events
  //     .pipe(
  //       filter(event => event instanceof NavigationEnd)
  //     ).subscribe((routingEvent: NavigationEnd) => {
  //       // this.store.dispatch(new ActionAppSetUrl(routingEvent.url));
  //     });
  // }

  initIcons() {
    const sanitizer = this.sanitizer;
    this.iconRegistry
      .addSvgIcon('ERT-Portfolio', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/access-level/ERT-Portfolio.svg'))
      .addSvgIcon('ERT-Site', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/access-level/ERT-Site.svg'))
      .addSvgIcon('ERT-Study', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/access-level/ERT-Study.svg'))
      .addSvgIcon('ERT-Subject', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/access-level/ERT-Subject.svg'))
      .addSvgIcon('ERT-Menu', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ux/ERT-Menu.svg'))

      .addSvgIcon('ERT-Average', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/calculation/ERT-Average.svg'))
      .addSvgIcon('ERT-Decrease', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/calculation/ERT-Decrease.svg'))
      .addSvgIcon('ERT-Difference', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/calculation/ERT-Difference.svg'))
      .addSvgIcon('ERT-Gap', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/calculation/ERT-Gap.svg'))
      .addSvgIcon('ERT-Increase', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/calculation/ERT-Increase.svg'))
      .addSvgIcon('ERT-Proportion', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/calculation/ERT-Proportion.svg'))
      .addSvgIcon('ERT-Rate', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/calculation/ERT-Rate.svg'))
      .addSvgIcon('ERT-Sum', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/calculation/ERT-Sum.svg'))


      .addSvgIcon('people', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ux/people.svg'))
      .addSvgIcon('key1', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ux/key1.svg'))
      .addSvgIcon('key2', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ux/key2.svg'))
      .addSvgIcon('gear', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/clario/sliders.svg'))
      .addSvgIcon('bell', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/clario/bell-exclamation.svg'))
      .addSvgIcon('bigw', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ux/bigw.svg'))
      .addSvgIcon('magnifyingGlass', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ux/magnifyingGlass.svg'))
      .addSvgIcon('smallw', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ux/smallw.svg'))
      .addSvgIcon('admin', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ux/admin.svg'))

      .addSvgIcon('tasks', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ux/tasks.svg'))
      .addSvgIcon('alerts', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ux/alerts.svg'))
      .addSvgIcon('gear-bar', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ux/gear-bar.svg'))
      .addSvgIcon('pie-white', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ux/pie-white.svg'))
      .addSvgIcon('data-quality-monitoring', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ux/data-quality-monitoring.svg'))
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
