
<mat-card class="dashboard__card">
  <div class="user-management__table-control-container">
    <mat-form-field class="user-management__table-control">
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
    </mat-form-field>
    <mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[10, 20, 50, 100]" class="user-management__table-control">
    </mat-paginator>
    <div class="user-management__table-control center">
      <button (click)="addToAdminDialog()" class="user-management__add-to-admin-button center es-button -es-primary">Invite TO Admin</button>
    </div>
  </div>

  <div *ngIf="displayedData">
<mat-table #table [dataSource]="displayedData" matSort class="ert-table">

      <ng-container matColumnDef="fullName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
        <mat-cell *matCellDef="let user"> {{user.fullName}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
        <mat-cell *matCellDef="let user"> {{user.email}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="modify">
        <mat-header-cell *matHeaderCellDef>TO Admin Permissions</mat-header-cell>
        <mat-cell *matCellDef="let user">
          <button (click)="modifyUserPermission(user)"
            [ngClass]="user.admin_level === 1 ? 'es-button -es-primary' : 'user-management__modify-user-button es-button'">
            {{user.admin_level === 1 ? 'Disable TO Admin' : 'Enable TO Admin' }}
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

    </mat-table>
  </div>
  <div *ngIf="!displayedData" class="es-status-sign">
    <span class="es-icon es-icon-magnifier"></span>
    <span class="text">No Data to display</span>
  </div>
</mat-card>
