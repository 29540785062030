import { ActionAppSetAlerts, ActionAppSetTasks } from '@core/store/app.actions';
import { EndpointService } from '@app/shared/services/endpoint.service';
import { AuthService } from '@core/services/auth.service';
import { Store } from '@ngrx/store';
import { HeaderDropdownLink } from './model/header-dropdown.interface';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { DataService } from '@app/shared/services';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ert-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  private unsubsrcibe$: Subject<void> = new Subject<void>();
  private loginDropdownItem: HeaderDropdownLink[] = [];
  @Input() name;
  loginDropdownIcon = 'es-icon-user-name';
  email: string;
  alertCount = 0;
  taskCount = 0;
  url: string;

  constructor(
    private store: Store<any>,
    private authService: AuthService,
    private dataService: DataService<any>,
    private endpointService: EndpointService
    ) {
      this.loginDropdownItem.push({
        text: 'Welcome',
        location: 'login',
        icon: 'es-icon-application-mode'
      });
      this.url = sessionStorage.getItem('HOMEPAGE');
   }

  ngOnInit() {
    this.store.select('authState')
    .pipe(takeUntil(this.unsubsrcibe$))
      .subscribe(authState => {
        this.name = `${authState.firstName} ${authState.lastName}`;
    });

    this.store.select('appState')
    .pipe(takeUntil(this.unsubsrcibe$))
    .subscribe(appReducer => {
      this.alertCount = appReducer.alertCount;
      this.taskCount = appReducer.taskCount;
    });
  }

  onLogoutClick() {
    this.authService.logout();
  }

  ngOnDestroy() {
    this.unsubsrcibe$.next();
    this.unsubsrcibe$.complete();
  }

}
