import { DashboardComponent } from './dashboard/dashboard.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from '@app/login/login.component';
import { ErrorComponent } from './error/error.component';
import {AuthGuard} from '@core/guards/auth.guard';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { ReportComponent } from './report/report.component';


const routes: Routes = [
  {
    path: '',  redirectTo: 'login', pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'authcallback/code/:code/state/:state',
    component: AuthCallbackComponent,
  },
  {
    path: 'report/studyId/:studyId/metricId/:metricId',
    component: ReportComponent,
  },
  {
    path: 'report/studyId/:studyId/siteId/:siteId/metricId/:metricId',
    component: ReportComponent,
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: '**',
    component: ErrorComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', useHash: false, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [AuthGuard]
})

export class AppRoutingModule { }

