import { AppActions, AppActionTypes } from './app.actions';
import { NavigationEnd } from '@angular/router';
import { Action } from '@ngrx/store';


export interface AppState {
  currentPageTitle: string;
  authToken: string;
  alertCount: number;
  taskCount: number;
  // stack of previous urls navigated to
  urlStack: string[];
  preLoginUrl: string;
}

export const initialState: AppState = {
  currentPageTitle: 'Dashboard',
  authToken: null,
  alertCount: 0,
  taskCount: 0,
  urlStack: [],
  preLoginUrl: '',
};

export function appReducer(
  appState: AppState = initialState,
  action: AppActions
): AppState {
  switch (action.type) {

    case AppActionTypes.CHANGE_PAGE:
      return setPageTitle(appState, action.payload);
    case AppActionTypes.ALERTS:
      return setAlerts(appState, action.payload);
    case AppActionTypes.TASKS:
      return setTasks(appState, action.payload);
    case AppActionTypes.SET_URL:
      return setCurrentUrl(appState, action.payload);
    case AppActionTypes.SET_PRELOGIN_URL:
        return setPreloginUrl(appState, action.payload);
    case AppActionTypes.GETSTATE:
      return appState;

    default:
      return appState;
  }

  function setPageTitle(state, title) {
    return { ...state, currentPageTitle: title };
  }

  function setAlerts(state, alertCount) {
    return { ...state, alertCount };
  }

  function setTasks(state, taskCount) {
    return { ...state, taskCount };
  }
  
  function setPreloginUrl(state, preLoginUrl) {
    return { ...state, preLoginUrl };
  }

  function setCurrentUrl(state, url) {
    const { urlStack } = state;
    urlStack.push(url);

    // only store last 5 urls in history, arbitrary number
    return { ...state, urlStack: urlStack.slice(-5) };
  }
}
