import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'ert-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  private unsubscribe$: Subject<void> = new Subject<void>();
  crumbs: any = {};

  constructor(
    private store: Store<any>
  ) { }

  ngOnInit() {
    this.store
      .select('breadCrumbState')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(crumbs => {
        if (crumbs) {
          this.crumbs = crumbs;
          this.crumbs.crumbs.forEach((b) => {
            b.shortText = this.convertToShortText(b.text);
          });
        }
      });
  }

  convertToShortText(text: string): string {
    return text.length > 30 ? text.substring(0, 30) + '...' : text;
  }

}
