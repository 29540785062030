import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DataService } from '.';
import { EndpointService } from '@app/shared/services';

export enum UserPermissions {
  editStudy = 'editStudy',
  editUsers = 'editUsers',
  toAdminLevel = 'toAdminLevel',
  viewStudy = 'viewStudy',
  viewSite = 'viewSite',
  viewAuditTrail = 'viewAuditTrail',
  clientAdmin = 'clientAdmin',
  ertAdmin = 'ErtAdmin',
  ertDemoAccess = 'ertDemoAccess',
  internalUser = 'internalUser',
  hasDataMonitorAccess = 'hasDataMonitorAccess',
}

@Injectable()
export class PermissionService {
  private permissions; // Cache permission from sessionStorage
  constructor (
    private endpointService: EndpointService,
    private dataService: DataService<any>
  ) {
   // this.permissions = JSON.parse(sessionStorage.getItem('RBM-AUTH')) || {};
  }

  /** Get admins endpoint  */
  getAdmins() {
    const endpoint = this.endpointService.getEndpoint('admins');
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  /** Edit admin endpoint  */
  editAdmin(adminConfig) {
    const endpoint = this.endpointService.getEndpoint('editAdmin');
    return this.dataService.update(endpoint, adminConfig).pipe(map((response: any) => response.result));
  }

  hasRole(role: string) {
    this.permissions = JSON.parse(sessionStorage.getItem('RBM-AUTH')) || {};
    const { userAccess = {} } = this.permissions;
    if (userAccess.roles && userAccess.roles.length) {
      return JSON.stringify(this.permissions.userAccess.roles).includes(role);
    }
    return false;
  }

  /** Calls roles endpoint  */
  getClientApiRoles() {
    const endpoint = this.endpointService.getEndpoint('roles');
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  getUserRoles() {
    this.permissions = JSON.parse(sessionStorage.getItem('RBM-AUTH')) || {};
    const { userAccess = {} } = this.permissions;
    return userAccess && userAccess.roles || null;
  }

  getUserAccess() {
    this.permissions = JSON.parse(sessionStorage.getItem('RBM-AUTH')) || {};
    return this.permissions.userAccess;
  }

  /**
   *
   * @param permission
   * @param options
   * Returns boolean
   */
  hasPermission(permission: string, options?) {
    this.permissions = JSON.parse(sessionStorage.getItem('RBM-AUTH')) || {};
    const { userAccess = {} } = this.permissions;
    if (userAccess.permissions || userAccess) {
      switch (permission) {
        case UserPermissions.editStudy: {
          const permissionRes = userAccess.permissions[permission];
          return permissionRes && (permissionRes === 'all' || permissionRes.includes(options.studyId));
        }
        case UserPermissions.viewStudy: {
          const permissionRes = userAccess.permissions[permission];
          return permissionRes && (permissionRes === 'all' || permissionRes.includes(options.studyId));
        }

        case UserPermissions.viewSite: {
          return;
        }

        case UserPermissions.editUsers: {
          return;
        }

        case UserPermissions.viewAuditTrail: {
          return;
        }

        case UserPermissions.toAdminLevel: {
          return (userAccess.toAdminLevel > 0);
        }

        case UserPermissions.clientAdmin: {
          return (userAccess.toAdminLevel === 1);
        }

        case UserPermissions.ertAdmin: {
          return (userAccess.toAdminLevel === 2);
        }

        case UserPermissions.ertDemoAccess: {
          return this.permissions.isErtDemo;
        }

        case UserPermissions.internalUser: {
          const userEmail = this.permissions.email;
          if ((userEmail.includes('@ert.com')) || (userEmail.includes('@clario.com'))) {
            return true;
          }else{
            return false;
          }
        }

        case UserPermissions.hasDataMonitorAccess: {
          return this.permissions.userPermittedStudyFeatures.length > 0;
        }

        default: {
          return false;
        }
      }
    }
  }

  getPermission(permission: string) {
    this.permissions = JSON.parse(sessionStorage.getItem('RBM-AUTH') || '{}');
    const { userAccess = {} } = this.permissions;
    if (userAccess.permissions && userAccess.permissions[permission]) {
      return userAccess.permissions[permission];
    }

    return userAccess[permission];
  }
}
