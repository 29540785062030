<div  class="kri-category-table__filter">
  <mat-form-field class="user-management__table-control">
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
  </mat-form-field>
  <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  <ert-observation *ngIf="alertPermCheck" [studyId]="studyId" [siteId]="siteId"></ert-observation>
</div>

<mat-table  *ngIf="dataSource" [dataSource]="dataSource"
  class="ert-table es-table es-plain" matSort matSortActive="activeEvents" matSortDirection="desc"
  matSortStart="desc">
  <!--  TABLE HEADER COLUMNS AND ROWS-->
  <ng-container *ngFor="let column of displayedColumns; let colIndex = index" matColumnDef="{{column}}">
    <!-- trend column cells -->
    <ng-container *ngIf="column === 'trend'; else regularColumn">
      <mat-header-cell *matHeaderCellDef> {{ column }} </mat-header-cell>
      <mat-cell *matCellDef="let row">
       <div *ngIf="!row.trend.length" class="scorecard__trend">
         <ert-no-value></ert-no-value>
       </div>
        <ert-mini-bar-chart *ngIf="row.trend.length" [height]="36" [data]="row[column]"></ert-mini-bar-chart>
      </mat-cell>
    </ng-container>

    <ng-container *ngIf="column === 'overallScore'; else regularColumn" matColumnDef="overallScore">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Overall Score </mat-header-cell>
      <mat-cell matTooltipPosition="left" class="scorecard_cell rating_col" *matCellDef="let row">
         <div ngClass="scorecard__color-square{{isNumber(row['overallScore'])? '': '-nd'}}"
           [ngStyle]="{ 'background': getScoreColor(isNumber(row['overallScore']) ? row.overallScore: null) }">
           <span *ngIf="isNumber(row['overallScore'])">{{row[column]}}</span>
           <ert-no-value *ngIf="!isNumber(row['overallScore'])"></ert-no-value>
         </div>
      </mat-cell>
    </ng-container>

    <!-- Protocol # Cards column cells -->
    <ng-container *ngIf="column === 'protocolNumber'; else regularColumn" matColumnDef="protocolNumber">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Protocol # </mat-header-cell>
      <mat-cell class="protocolNumber" *matCellDef="let row" (click)="navigateToPage('', row)">
        <div class="protocolNumber">
          <span *ngIf="row.protocolNumber">{{row.protocolNumber}}</span>
          <ert-no-value *ngIf="!row.protocolNumber"></ert-no-value>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Study Name Cards column cells -->
    <ng-container *ngIf="column === 'studyName'; else regularColumn" matColumnDef="studyName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Study Name </mat-header-cell>
      <mat-cell class="studyName" *matCellDef="let row" (click)="navigateToPage('', row)">
        <div class="studyName">
          <span *ngIf="row.studyName">{{row.studyName}}</span>
          <ert-no-value *ngIf="!row.studyName"></ert-no-value>
        </div>
      </mat-cell>
    </ng-container>

     <!-- Site # Cards column cells -->
     <ng-container *ngIf="column === 'site'; else regularColumn" matColumnDef="site">
       <mat-header-cell *matHeaderCellDef mat-sort-header> <p>Site # </mat-header-cell>
       <mat-cell class="site" *matCellDef="let row" (click)="navigateToPage('', row)">
         <div class="site">
           <span *ngIf="row[column]"><p><a>{{row[column]}}</a></p></span>
            <ert-no-value *ngIf="!row[column]"></ert-no-value>
          </div>
       </mat-cell>
     </ng-container>

    <!-- Site Name Cards column cells -->
    <ng-container *ngIf="column === 'siteName'; else regularColumn" matColumnDef="siteName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Site Name
      </mat-header-cell>
      <mat-cell class="siteName" *matCellDef="let row" >
        <div class="siteName">
           <span *ngIf="row.siteName">{{row.siteName}}</span>
           <ert-no-value *ngIf="!row.siteName"></ert-no-value>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Subjects Cards column cells -->
    <ng-container *ngIf="column === 'subjects'; else regularColumn" matColumnDef="subjects">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Total Subjects
        <span class="es-icon es-icon-info-circle info-circle"
        [matTooltip]="'Total Subjects is derived from the total number of subjects, regardless of subject status, who have been entered into the source system. Where more than one source system is utilized on a given study, the source system with the maximum number of subjects is utilized.'"
        matTooltipPosition="above"></span>
        <!-- <span class="es-icon es-icon-alert-info"
          [matTooltip]="view === 'PORTFOLIO' ? 'Total Enrolled / Study Target' :'Total Enrolled / Site Target'"></span> -->
      </mat-header-cell>
      <mat-cell class="subjects_col" *matCellDef="let row">
        <div class="subjects">
           <span>{{row.subjects ? row.subjects: 0}}</span>
        </div>
      </mat-cell>
    </ng-container>

    <!-- PI Cards column cells -->
    <ng-container *ngIf="column === 'pi'; else regularColumn" matColumnDef="pi">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        <span>PI Name</span>
      </mat-header-cell>
      <mat-cell class="pi_col" *matCellDef="let row">
        <div class="pi">
           <span *ngIf="row.pi">{{row.pi}}</span>
           <ert-no-value *ngIf="!row.pi"></ert-no-value>
        </div>
      </mat-cell>
    </ng-container>

       <!-- Active Events Cards column cells -->
       <ng-container *ngIf="column === 'activeEvents'; else regularColumn" matColumnDef="activeEvents">
         <mat-header-cell *matHeaderCellDef mat-sort-header> Active Events </mat-header-cell>
         <mat-cell class="activeEvents" *matCellDef="let row">
           <div class="activeEvents">
             <span>{{row.activeEvents ? row.activeEvents: 0}}</span>
           </div>
         </mat-cell>
       </ng-container>

       <!-- Events Per Subject Cards column cells -->
       <ng-container *ngIf="column === 'eventsPerSubject'; else regularColumn" matColumnDef="eventsPerSubject">
         <mat-header-cell *matHeaderCellDef mat-sort-header> Active Events per Subject </mat-header-cell>
         <mat-cell class="eventsPerSubject" *matCellDef="let row">
           <div class="eventsPerSubject">
             <span>{{row.eventsPerSubject ? row.eventsPerSubject: 0}}</span>
           </div>
         </mat-cell>
       </ng-container>

    <!-- bucket Compliance Score column cells -->
      <ng-container *ngIf="column === 'bucketComplianceScore'; else regularColumn">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="category-score"
          [ngClass]="{'collapsed': activeCategoryColumn!= null }">Compliance Score
        </mat-header-cell>
        <mat-cell class="scorecard_cell rating_col" *matCellDef="let row" [ngClass]="{'collapsed': activeCategoryColumn!= null }">
          <div ngClass="scorecard__color-square{{isNumber(row['bucketComplianceScore'])? '': '-nd'}}"
            [ngStyle]="{ 'background': getScoreColor(isNumber(row['bucketComplianceScore']) ? row.bucketComplianceScore:
            null) }">
            <span *ngIf="isNumber(row['bucketComplianceScore'])">{{row[column]}}</span>
            <ert-no-value *ngIf="!isNumber(row['bucketComplianceScore'])"></ert-no-value>
          </div>
        </mat-cell>
      </ng-container>

    <!-- bucket Quality Score column cells -->
      <ng-container *ngIf="column === 'bucketQualityScore'; else regularColumn">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="category-score" [ngClass]="{'collapsed': activeCategoryColumn!= null }">
          Quality Score
        </mat-header-cell>
         <mat-cell class="scorecard_cell rating_col" *matCellDef="let row" [ngClass]="{'collapsed': activeCategoryColumn!= null }">
           <div ngClass="scorecard__color-square{{isNumber(row['bucketQualityScore'])? '': '-nd'}}"
             [ngStyle]="{ 'background': getScoreColor(isNumber(row['bucketQualityScore']) ? row.bucketQualityScore:
             null) }">
             <span *ngIf="isNumber(row['bucketQualityScore'])">{{row[column]}}</span>
             <ert-no-value *ngIf="!isNumber(row['bucketQualityScore'])"></ert-no-value>
           </div>
         </mat-cell>
      </ng-container>

    <!-- regular default text cell -->
    <ng-container *ngIf="column">
      <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
      <mat-cell *matCellDef="let row" #regularColumn >
        <div class="cell-value">
          <div class="scorecard__color-square-outline"
            *ngIf="checkAvailableScore(row, column)"
            [matTooltip]="getScoreTooltip(row[column])"
            [ngStyle]="{ 'border-color': getScoreColor(row[column].score) }"
            (click)="navigateToPage(column, row)">{{row[column].score}}</div>
          <div class="scorecard__color-square-nd"
            *ngIf="hasScore(column) && checkInactive(row, column)"
            [matTooltip]="'KRI not Active'" [ngStyle]="{ 'background': getScoreColor(null) }">{{inactive}}
          </div>
          <div class="scorecard__color-square-nd" *ngIf="checkNoData(row, column)" [ngStyle]="{ 'background': getScoreColor(null) }">
            <ert-no-value></ert-no-value>
          </div>
          <div *ngIf="!hasScore(column)" (click)="navigateToPage('', row)">{{getColRowVal(row[column])}} </div>
          <!-- <span *ngIf="hasScore(column)" class="score-value" [ngStyle]="{ 'background': getScoreColor(getScore(column))}"></span>{{getColRowVal(row[column])}}  -->
        </div>
      </mat-cell>
    </ng-container>
  </ng-container>
  <!-- TOP HEADER COLUMNS DISPLAYED-->
  <ng-container *ngFor="let column of tableTopColumns; let colIndex = index" matColumnDef="{{column}}">
    <!-- SITE TOP HEADER Column -->
    <ng-container *ngIf="column === 'site-top-header'; else otherColumn" matColumnDef="site-top-header">
      <mat-header-cell *matHeaderCellDef> SITE </mat-header-cell>
    </ng-container>
    <!-- STUDY TOP HEADER Column -->
    <ng-container *ngIf="column === 'protocolNumber-top-header'; else otherColumn "
      matColumnDef="protocolNumber-top-header">
      <mat-header-cell *matHeaderCellDef (click)="setActiveColumn('protocolNumber')"
        [ngClass]="{'collapsed': activeCategoryColumn!= null  }">
        STUDY <span class="es-icon es-icon-sorting column-expand"></span> </mat-header-cell>
    </ng-container>
    <!-- ENROLLMENT TOP HEADER  Column -->
    <ng-container *ngIf="column === 'enrollment-top-header'; else otherColumn" matColumnDef="enrollment-top-header">
      <mat-header-cell *matHeaderCellDef (click)="setActiveColumn('enrollment')"
        [ngClass]="{'collapsed': activeCategoryColumn!= null  }">
        ENROLLMENT <span class="es-icon es-icon-sorting column-expand"></span></mat-header-cell>
    </ng-container>
    <!-- COMPLIANCE TOP HEADER Column -->
    <ng-container *ngIf="column === 'compliance-top-header'; else otherColumn" matColumnDef="compliance-top-header">
      <mat-header-cell *matHeaderCellDef (click)="setActiveColumn('compliance')"
        [ngClass]="{'collapsed': activeCategoryColumn!= null }">
        COMPLIANCE <span class="es-icon es-icon-sorting column-expand"></span></mat-header-cell>
    </ng-container>
    <!-- SAFETY TOP HEADER  Column -->
    <ng-container *ngIf="column === 'safety-top-header'; else otherColumn" matColumnDef="safety-top-header">
      <mat-header-cell *matHeaderCellDef (click)="setActiveColumn('safety')"
        [ngClass]="{'collapsed': activeCategoryColumn!= null }">
        SAFETY <span class="es-icon es-icon-sorting column-expand"></span></mat-header-cell>
    </ng-container>
    <!-- QUALITY TOP HEADER  Column -->
    <ng-container *ngIf="column === 'quality-top-header'; else otherColumn" matColumnDef="quality-top-header">
      <mat-header-cell *matHeaderCellDef (click)="setActiveColumn('quality')"
        [ngClass]="{'collapsed': activeCategoryColumn!= null }">
        QUALITY <span class="es-icon es-icon-sorting column-expand"></span></mat-header-cell>
    </ng-container>
    <!-- HELPDESK TOP HEADER  Column -->
    <ng-container *ngIf="column === 'helpdesk-top-header'; else otherColumn" matColumnDef="helpdesk-top-header">
      <mat-header-cell *matHeaderCellDef (click)="setActiveColumn('helpdesk')"
        [ngClass]="{'collapsed': activeCategoryColumn!= null }">
        HELPDESK <span class="es-icon es-icon-sorting column-expand"></span></mat-header-cell>
    </ng-container>
    <ng-container *ngIf="column">
      <mat-header-cell *matHeaderCellDef #otherColumn></mat-header-cell>
    </ng-container>
  </ng-container>
  <!-- TOP HEADER COLUMNS -->
  <!-- <mat-header-row *matHeaderRowDef="tableTopColumns" class="top-header"></mat-header-row> -->
  <!-- TABLE HEADER COLUMNS -->
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
