import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { Store } from "@ngrx/store";
import {Component, OnInit, ViewChild, ElementRef, OnChanges } from "@angular/core";
import { PageEvent, MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { SetBreadCrumbs } from "./../../core/store/breadcrumbs/breadcrumbs.actions";
import { zip } from "rxjs";
import { DatePipe } from "@angular/common";
import { UpdateToolbar } from "@app/core/store/toolbar/toolbar.actions";
import { StudyService } from "@app/shared/services/study.service";
import { SiteService } from "@app/shared/services/site.service";
import { lineChartType } from "@app/shared/score-overview/score-overview.component"
import { BreadcrumbConfigService } from '@app/shared/services/breadcrumb-config.service';
import { GlobalHeaderService } from '@app/shared/services/global-header.service';
import { isEmptyObject } from '@app/shared/helpers/util';

const formatedMetricData: MetricElement[] = [
  {
    SiteId: "",
    PI: "",
    MetricValue: "",
    EventDescription: "",
    SuggestedAction: ""
  }
];

const cOptions: any = {
  metric: {
    legend: 'Score',
    units: 'percentage',
    lineWidth: 3,
    curveType: 1,
    dotRadius: 6,
    color: '#003865',
    showProjection: true,
  },
  projection: {
    lineWidth: 3,
    units: 'percentage',
    curveType: 1,
    color: '#003865',
    legend: 'Projected Score',
    dashPattern: '4,5',
  },
  threshold: {
    lineWidth: 3,
    units: 'percentage',
    curveType: 0,
    color: '#EF801E',
    legend: 'Threshold',
    dashPattern: '4,5',
  }
};

export interface MetricElement {
  SiteId: string;
  PI: string;
  MetricValue: string;
  EventDescription: string;
  SuggestedAction: string;
}

@Component({
  selector: 'ert-study-metric',
  templateUrl: './study-metric.component.html',
  styleUrls: ['./study-metric.component.scss']
})

export class StudyMetricComponent implements OnInit {
  private studyId: number;
  private siteId: number;
  private metricId: number;
  studyName: string;
  metricScore: string;
  metricScoresHistory: any;
  metricName: string;
  category: string;
  protocolNo: string;
  metricThreshold: string;
  valueMinMax: string;
  metricWeight: string;
  metricValue: string;
  metricDescription: string;

  displayedData: any;
  displayedColumns = [
    "SiteId",
    "PI",
    "MetricValue",
    "EventDescription",
    "SuggestedAction"
  ];
  dataSource = formatedMetricData;
  chartType = lineChartType;
  sites;

  constructor(
    private store: Store<any>,
    private route: ActivatedRoute,
    private router: Router,
    private studyService: StudyService,
    private globalHeaderService: GlobalHeaderService,
    private brdCrmbConfig: BreadcrumbConfigService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };

    this.route.params.subscribe(params => {
      this.studyId = params.studyId;
      this.metricId = params.metricId;
    });

    this.store.dispatch(new UpdateToolbar(
      {
        title: 'MY Study Metric',
        metric: {
          metricId: this.metricId,
        },
        study: {
          studyId: this.studyId,
          odsReportsEnabled: false,
        },
        site: {
          siteId: null,
        },
      }
    ));
  }

 ngOnInit() {
    this.setupPage();
  }

  setupPage() {
    // load data
    if (this.studyId && this.metricId) {
      zip(
        this.studyService.getStudyById(this.studyId),
        this.getStudyMetricData(this.studyId, this.metricId), // impacts heatmap for units
        this.studyService.getStudyMetricScoresHistory(this.studyId, this.metricId, true)
      ).subscribe(([study, data, metricScoresHistory]) => {
        if (data.length <= 0) return;

        this.setStudyMetricInfo(study, data);
        this.setBreadCrumbsInfo(study, data);
        this.metricScoresHistory = this.setMetricScoresChart(metricScoresHistory);
        this.setupHeatMap(data[0].units);
      });
    }
  }

  setStudyMetricInfo(study, data) {
    const thresholdOpCategory = {
      'gte': ' >= ',
      'lte': ' <= '
    };
    this.protocolNo = study.protocolNumber;
    this.studyName = study.studyName;
    this.metricName = data[0].metricName;
    this.metricScore = data[0].score;
    this.metricWeight = data[0].metricWeight;
    this.metricValue = data[0].metricLongDisplay;
    this.metricDescription = data[0].metricDescription;
    this.category = data[0].category.charAt(0).toUpperCase() + data[0].category.slice(1);
    if(data[0].units === '%') {
      var thresholdMin = parseFloat(data[0].thresholdMin) * 100;
      var thresholdMax = parseFloat(data[0].thresholdMax) * 100;
      this.metricThreshold = thresholdOpCategory[(data[0].operator || '').trim()] + (+(parseFloat(data[0].threshold) * 100).toFixed(2)) + data[0].units;
      this.valueMinMax = thresholdMin + " - " + thresholdMax + data[0].units;
    } else {
      this.metricThreshold = thresholdOpCategory[(data[0].operator || '').trim()] + data[0].threshold + ' ' + data[0].units;
      this.valueMinMax = data[0].thresholdMin + " - " + data[0].thresholdMax + ' ' + data[0].units;
    }

    this.store.dispatch(new UpdateToolbar({
      title: 'MY Study Metric',
      study: {
        studyId: this.studyId,
        odsReportsEnabled: study.odsReportsEnabled,
      },
      site: {
        siteId: null,
      },
      metric: {
        metricId: this.metricId,
        metricScore: this.metricScore,
        metricName: this.metricName,
        metricDescription: this.metricDescription
      }
    }));
  }

  setBreadCrumbsInfo(study, metricData) {
    this.globalHeaderService.getHeader().then((header) => {
      header.addBreadcrumbs([
        this.brdCrmbConfig.getHomeBrdCrmb(),
        this.brdCrmbConfig.getPortfolioBrdCrmb(),
        this.brdCrmbConfig.getStudyBrdCrmb(this.studyId, study.studyName),
        this.brdCrmbConfig.getStudySiteMetricBrdCrmb(this.studyId, null, this.metricId, metricData[0].metricName)
      ]);
      header.subscribe('logout', this.globalHeaderService.logoutHandler());
      header.subscribe('session-expired', this.globalHeaderService.sessionExpHandler());
    })
  }

  getStudyMetricData(studyId, metricId) {
    if (studyId && metricId) {
      return this.studyService.getStudyMetricDetails(studyId, metricId);
    }
  }


  setMetricScoresChart(metricScoresHistory) {
    let metricScoreHistoryChart = [];
    if (metricScoresHistory.data && metricScoresHistory.data.length) {
      metricScoreHistoryChart = [
       {
         ...metricScoresHistory,
         ...cOptions.metric
       },
       {
         ...cOptions.projection
       },
       {
         ...cOptions.threshold
       }
     ];
    }

   return metricScoreHistoryChart;
  }

  setupHeatMap(units) {
    this.studyService.getHeatMapData(this.studyId, this.metricId).subscribe(siteData => {
      this.setupHeatMapData(siteData, units);
    });
  }

  setupHeatMapData(siteData, units) {
    // Sort by score
    siteData.sort((a, b) => { return b.score > a.score ? -1 : 1 });

    // Number of sites in one row
    let numSites = 25;

    let j = 0;
    let k = (siteData.length % numSites) > 0 ? Math.floor(siteData.length / numSites) + 1 : Math.floor(siteData.length / numSites);
    let columns = k;
    let yValues = [];
    k--;
    for (var y = 0; y < columns; y++) {
      yValues.push('y' + y);
    }
    let xValues = [];
    for(var x = 0; x < numSites; x++) {
      xValues.push('x' + x);
    }
    for (var i in siteData) {
      if (j < numSites) {
        siteData[i].x = 'x' + j;
        siteData[i].y = 'y' + k;
        j++;
      } else {
        j = 0;
        k--;
        siteData[i].x = 'x' + j;
        siteData[i].y = 'y' + (k);
        j++;
      }
    }
    this.sites = {
      siteData: siteData,
      xValues: xValues,
      yValues: yValues,
      rows: numSites,
      columns: columns,
      units: units
    };
  }
}
