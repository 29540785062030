
<ert-study-metric-info class="metric__stat-block" [studyName]="studyName" [metricName]="metricName" [category]="category"
    [protocolNo]="protocolNo" [metricThreshold]="metricThreshold" [valueMinMax]="valueMinMax"
    [metricWeight]="metricWeight" [metricValue]="metricValue"></ert-study-metric-info>

<mat-card>
 <ert-score-overview class="study-comp" kritype="" [data]="metricScoresHistory"
    [type]="chartType.LineColorChart" yAxisLabel="Score">
    </ert-score-overview>
</mat-card>

<mat-card>
    <h4 class="site-metric_chart-title">Site Comparative: Sites by {{metricName}}</h4>
    <div class="site-metric_heatmap">
        <ert-heatmap *ngIf="sites" [mapData]="sites" [studyId]="studyId" [siteId]="" [metricId]="metricId"></ert-heatmap>
        <ert-no-data *ngIf="!sites"></ert-no-data>
    </div>
</mat-card>

<div>
    <ert-metric-study-event-table></ert-metric-study-event-table>
</div>
