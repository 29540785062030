import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild} from '@angular/router';
import {AuthService} from '@core/services/auth.service';
import { SessionStorageService } from '../services/session-storage.service';
import { Store } from '@ngrx/store';
import { ActionAppPreloginUrl } from '../store/app.actions';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  private defaultDashboardRoute = '/dashboard';
  private logInRoute = '/login';

  constructor(
    private router: Router,
    private store: Store<any>,
    private authService: AuthService,
    private sessionStorageService: SessionStorageService,
    ) {

     }

  /**
   * Allow/Deny route navigation based on Authentication:
   *  - AUTHENTICATED - Allows all route:
   *    - if route is to /dashboard just return true to allow it
   *    - if route is to  /login send to /dasboard, should navigate to /login while logged in
   *  - NOT AUTHENTICATED - Allow only navigation to /login
   *    - If route is /login just return true to allow it
   *
   * @param ActivatedRouteSnapshot [next]
   * @param RouterStateSnapshot [state]
   * @returns Boolean
   */
  canActivate(next?: ActivatedRouteSnapshot, state?: RouterStateSnapshot) {
    const authKey = this.sessionStorageService.getItem('RBM-AUTH');
    const isMultiCustomer = authKey && authKey.multiSelect;
    if (this.authService.isAuthenticated() && !isMultiCustomer) {
      if (state.url === this.logInRoute) {
        this.router.navigate([sessionStorage.getItem('HOMEPAGE')]);
        return false; // AUTHENTICATED should not navigate to /login while logged in
      }

      return true; // AUTHENTICATED return true
    }

    // NOT AUTHENTICATED check if route is /login, to prevent infinite navigation to /login
    if (state.url === this.logInRoute) {
      return true;
    }
    this.setRedirectUrl();
    this.router.navigate([this.logInRoute]); // NOT AUTHENTICATED, should only allow navigation /login
    return false;
  }

  setRedirectUrl() {
    if (location.pathname.includes('/dashboard/tasks/detail')) {
      this.store.dispatch(new ActionAppPreloginUrl(location.pathname));
    }
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(next, state);
  }
}
