import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ert-my-study-info',
  templateUrl: './my-study-info.component.html',
  styleUrls: ['./my-study-info.component.scss']
})
export class MyStudyInfoComponent implements OnInit {
  @Input() studyInfoData;
  studyTrendFlag: boolean = false;
  studyId: number;
  constructor() { }

  ngOnInit() {
  }
}
