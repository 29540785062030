import { Action } from '@ngrx/store';

export const VARIABLECHANGED = '[Variable] VARIABLECHANGED';

export class VariableChanged implements Action {
  readonly type = VARIABLECHANGED;
  constructor(public payload: any) {}
}

export type VariableActions = VariableChanged;
