import { Store } from "@ngrx/store";
import { ActivatedRoute, Router, Params } from "@angular/router";
import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { SiteService } from "@shared/services/site.service";
import { Study } from "@shared/models/study.model";
import { UpdateToolbar } from "@app/core/store/toolbar/toolbar.actions";
import dateFormat from 'dateformat';

@Component({
  selector: "ert-metric-site-event-table",
  templateUrl: "./metric-site-event-table.component.html",
  styleUrls: ["./metric-site-event-table.component.scss"],
})
export class MetricSiteEventTableComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  studyId;
  siteId;
  metricId;
  noData = true;
  // dateformat = require('dateformat');

  thresholdOpCategory = {
    gte: ">=",
    lte: "<=",
  };

  thresholdCategory = {
    'compliance': 'Compliance',
    'quality': 'Quality'
  };

  displayedColumns = [
    "metricName",
    "suggestedAction",
    "category",
    "beginDate",
  ];
  dataSource;
  constructor(
    private siteService: SiteService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<any>
  ) {
    this.route.params.subscribe((params) => {
      this.studyId = params.studyId;
      this.siteId = params.siteId;
      this.metricId = params.metricId;
    });
  }

  ngOnInit() {
    if(this.metricId){
      this.siteService.siteMetricEvents(this.studyId, this.siteId, this.metricId).subscribe((events) => {
        this.formatData(events);
      });
    } else {
      this.siteService.siteEvents(this.studyId, this.siteId).subscribe((events) => {
        this.formatData(events);
      });
    }
  }

  formatData(events) {
    this.noData = events.length > 0 ? false : true;
    this.dataSource = new MatTableDataSource(events);
    setTimeout(() => {
      this.dataSource.filterPredicate = (data, filter: String) => {
        const lowerFilter = filter.trim().toLowerCase();
        return this.checkDate(data.beginDate, lowerFilter)
        || this.checkFilter(this.displayDescription(data.metricName, data.thresholdOperator, data.thresholdValue, data.units), lowerFilter)
        || this.checkFilter(data.suggestedAction, lowerFilter) ||  this.checkFilter(data.category, lowerFilter);
      };
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (datas: any, header: string): any => {
        switch (header) {
          case 'beginDate': return new Date(datas.beginDate);
          case 'siteNo': return parseInt(datas.siteNo, 10) || null;
          default: return datas[header];
        }
      };
    });
  }

  applyFilter(filterValue: string) {
    // remove white space and lowercase filter value
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  checkFilter(item, lowerFilter) {
    if(item)
      return item.toString().trim().toLowerCase().indexOf(lowerFilter) !== -1;
    else
      return false;
  }

  checkDate(item, lowerFilter) {
    return dateFormat(item, 'dd-mmm-yyyy', true).toString().trim().toLowerCase().indexOf(lowerFilter) !== -1;
  }

  selectSiteMetric(metricId) {
    if(this.metricId) {
      return;
    }

    this.router.navigateByUrl(
      `dashboard/study/${this.studyId}/site/${this.siteId}/metric/${metricId}`
    );
  }

  displayDescription(metricName, thresholdOperator, thresholdValue, units) {
    var threshold;
    if (units === '%') {
      threshold = +(thresholdValue * 100).toFixed(2) + units;
    } else {
      threshold = thresholdValue + ' ' + units;
    }
    thresholdOperator = (thresholdOperator) ? thresholdOperator.toLowerCase() : thresholdOperator;
    return metricName + " " + this.thresholdOpCategory[thresholdOperator]  + " " + threshold;
  }
}
