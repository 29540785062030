import { HideSidebar } from './../core/store/sidebar/sidebar.actions';
import { ActivatedRoute } from '@angular/router';
import { TabLink } from '@app/settings/admin/models/tabLink.model';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'ert-to-admin',
  templateUrl: './to-admin.component.html',
  styleUrls: ['./to-admin.component.scss']
})
export class ToAdminComponent implements OnInit {

   tabLinks: TabLink[] = [
    {text: 'Add Customer', path: 'customer'},
    {text: 'Add Study', path: 'study'},
    {text: 'Add Data Connection', path: 'data-connection'},
    {text: 'Add Default Config', path: 'study-config'},
  ];

  constructor(private route: ActivatedRoute,
    private store: Store<any>) {  }

  ngOnInit() {
    const permCheck = JSON.parse(sessionStorage.getItem('RBM-AUTH')).userAccess.toAdminLevel;
    if (permCheck === 2) {
      this.tabLinks = [...this.tabLinks, {text: 'TO Administrators', path: 'user-management'}];
    }
  }

}
