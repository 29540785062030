import { Study } from './../models/study.model';
import { Injectable } from '@angular/core';
import { EndpointService, DataService } from '.';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class TableauService {
  constructor(
    private endpointService: EndpointService,
    private dataService: DataService<any>
  ) {}

  public getToken(): Observable<any> {
    const endpoint = this.endpointService.getEndpoint('getTableauToken');
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  public refreshDataSource(): Observable<any> {
    const endpoint = this.endpointService.getEndpoint('dataSourceStatus');
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  public renewSession(): Observable<any> {
    const endpoint = this.endpointService.getEndpoint('renewSession');
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }
}
