import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { SingleSelectInteraction, ResetAllInteraction } from '@app/core/store/visual-interactions/visual-interactions.actions';
import { fromEvent, of, Subject } from 'rxjs';
import { takeUntil, catchError, take, map } from 'rxjs/operators';
export enum EventsList {
  SINGLE_SELECT = 'singleSelect',
  MULTI_SELECT = 'multiSelect',
};

@Injectable({
  providedIn: 'root'
})

export class CommunicationEventsService implements OnDestroy {

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private store: Store<any>,
  ) {
    fromEvent(document, "click").subscribe(() => {
      this.store.dispatch(new ResetAllInteraction());
    });
  }

  singleClickEventListener(data: any = {}) {
    const self = this;
    const  studiesData = [{
      studyId: +data.studyId,
      siteIds: data.siteIds,
      metricIds: data.metricIds,
    }];

    self.store.dispatch(new SingleSelectInteraction( {studiesData} ));
  }


  resetAllInteractions() {
    this.store.dispatch(new ResetAllInteraction());
  }

  getSelectedStudiesData() {
    return this.store.select('visualInteractionsState')
      .pipe(takeUntil(this.unsubscribe$),  catchError(error => of(`Bad Promise: ${error}`)), map((data: any) => data.selectedStudiesData));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
