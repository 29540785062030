import { Injectable } from '@angular/core';
import { isEmptyObject } from '../helpers/util';
import { KriCategories, KriViews } from './enum.kri-categories';

@Injectable({
  providedIn: 'root'
})
export class KriCategoryTableService {

  constructor() { }

  /**
   * DATASOURCE table
   * @param view
   * @param studyData (Portfolio, crossite or study)
   * @param studyname
   */
  formatDataTable(view, studyData , studyname?) {
    if (isEmptyObject(studyData)) { return; }
    // const { enrollment, compliance, safety, quality} = (Object.assign({}, ...studyData.avgCalcTypeBucketScore));
    // const studyTrend = studyData.trend.map(d => [new Date(d.x), Math.round(d.y)]);

    // View columns data based on view: crossite, portfolio or study
    const viewBasedColumnsData = this.viewColumnsData(studyData, studyname)[view];
    // const detailsData = this.detailsCategoryData(studyData);

    return {
      ...viewBasedColumnsData,
      bucketQualityScore: studyData.bucketQualityScore ? parseInt(studyData.bucketQualityScore, 10) : null,
      bucketComplianceScore: studyData.bucketComplianceScore ?  parseInt(studyData.bucketComplianceScore, 10) : null,

      // compliance: isNaN(compliance) ? null : parseInt(compliance, 10),
      // ...detailsData[KriCategories.Compliance],

      // quality: isNaN(quality) ? null : parseInt(quality, 10),
      // ...detailsData[KriCategories.Quality],
      // trend: studyTrend,
      id: studyData.srcStudyId,
      // rank: studyData.rank
    };
  }
  /**
   * Set columns data based on VIEW
   */
  viewColumnsData(data, studyname?) {
    const { PORTFOLIO_VIEW, CROSSITE_VIEW, STUDY_VIEW } = KriViews;
    const studyDetailsData = this.getStudyDetails(data);
    return {
      [CROSSITE_VIEW]: {
        'studyName': studyname,
        'protocolNumber': data.protocolNumnber,
        site: parseInt(data.siteNo, 10),
        pi: data.investigator,
        subjects: this.getSubjects(data, 'CROSSITE_VIEW'),
        siteId: data.srcSiteId
      },
      [PORTFOLIO_VIEW]: {
        sponsor: data.sponsorName,
        'studyName': data.studyName,
        'protocolNumber': data.protocolNumber,
        ...studyDetailsData // Shown only for portfolio
      },
      [STUDY_VIEW]: {
        siteName: data.institutionName,
        site: data.siteNo || '',
        pi: data.investigator || '',
        // subjects: this.getSubjects(data, 'STUDY_VIEW'),
        siteId: data.srcSiteId,
        subjects: data.subjectsCount,
        activeEvents: data.activeEvents,
        eventsPerSubject: data.activeEventsPerSubject && +data.activeEventsPerSubject.toFixed(2) || null,
      }
    };
  }

  getStudyDetails(studyData) {
      return {
        sites: studyData.numSites,
        subjects: this.getSubjects(studyData, 'PORTFOLIO_VIEW'),
        country: studyData.country && studyData.country.trim(),
        'Therapeutic Area': studyData.therapeuticArea && studyData.therapeuticArea.trim(),
        'Therapeutic Indications': studyData.indications && studyData.indications.trim()
    };
  }

  getSubjects(data, view) {
    if(view === 'STUDY_VIEW' || view ==='CROSSITE_VIEW') {
      for(var i in data.calcTypeBucketExtraData) {
        if(data.calcTypeBucketExtraData[i].calcTypeBucketId === 1) {
          for(var j in data.calcTypeBucketExtraData[i].calcTypes) {
            if(data.calcTypeBucketExtraData[i].calcTypes[j].calcTypeId === 20) {
              return data.calcTypeBucketExtraData[i].calcTypes[j].calcRawData + '/' + data.enrollTarget;
            }
          }
        }
      }
    }

    if(view === 'PORTFOLIO_VIEW') {
      for(var i in data.calcTypeBucketExtraData) {
        if(data.calcTypeBucketExtraData[i].calcTypeBucketId === 1) {
          for(var j in data.calcTypeBucketExtraData[i].calcTypes) {
            if(data.calcTypeBucketExtraData[i].calcTypes[j].calcTypeId === 20) {
              return data.calcTypeBucketExtraData[i].calcTypes[j].calcShortDisplayVal;
            }
          }
        }
      }
    }
    return null;
  }

  detailsCategoryData(data) {
    const detailsData = {};
    if (!data.calcTypeBucketExtraData) return detailsData;

    data.calcTypeBucketExtraData.filter((entry) => {
      if (!entry) { return; }
      const catName = entry.calcTypeBucketName;
      detailsData[catName] = detailsData[catName] || {};

      entry.calcTypes.filter((calc) => {
        return calc ? detailsData[catName][calc.calcTypeName] = {
          score: calc.score === null || calc.score=== undefined ? null : calc.score,
          units: calc.units ? calc.units : null,
          isErtData: calc.isErtData === null || calc.isErtData=== undefined ? null : calc.isErtData,
          value: calc.calcLongDisplayVal ? calc.calcLongDisplayVal : null,
          thresholdMax: calc.calcContext.thresholdMax ? calc.calcContext.thresholdMax : null,
          thresholdMin: calc.calcContext.thresholdMin ? calc.calcContext.thresholdMin : null,
          thresholdValue: calc.calcContext.thresholdValue ? calc.calcContext.thresholdValue : null,
          thresholdOperator: calc.calcContext.thresholdOperator ? calc.calcContext.thresholdOperator : null,
          active: calc.active,
          } : undefined;
      });
    });
    return detailsData;
  }

  /**
   * Details Data columns
   * @param entries - study data
   * @param dataColumns
   * @param view (Portfolio, crossite or study)
   */
  setDetailsDataColumnsByCat(entries, dataColumns?, view?) {
    const { PORTFOLIO_VIEW } = KriViews;
    dataColumns = (dataColumns && !isEmptyObject(dataColumns)) ? dataColumns : undefined;

    const detailsDataColumns = dataColumns || {
      calcTypes: {}
    };

    // Add study details extra columns only for portfolio
    if (view === PORTFOLIO_VIEW) {
      detailsDataColumns['protocolNumber'] = detailsDataColumns['protocolNumber'] || [];
      detailsDataColumns['protocolNumber'].push(
        'sites',
        'subjects',
        'country',
        'Therapeutic Area',
        'Therapeutic Indications'
      );
    }

    entries.map(entry =>{
      entry.calcTypeBucketExtraData.filter((data) => {
        if (!data) { return; }
        const catName = data.calcTypeBucketName;

        data.calcTypes.filter((calc) => {
          if (!calc) { return; }
          detailsDataColumns[catName] = detailsDataColumns[catName] || [];

          if (calc.calcTypeName && !detailsDataColumns[catName].includes(calc.calcTypeName)) {
            detailsDataColumns[catName].push(calc.calcTypeName);
            detailsDataColumns['calcTypes'][catName] = detailsDataColumns['calcTypes'][catName] || [];
            detailsDataColumns['calcTypes'][catName].push({ name: calc.calcTypeName, id: calc.calcTypeId, score: calc.score, isErtData: calc.isErtData, active: calc.active });
          }
        });
      });
    })

    return detailsDataColumns;
  }
}

