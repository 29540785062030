import { PermissionService, UserPermissions } from '@shared/services/permisssion.service';
import { Sidebar } from '../model/sidebar-link.model';
import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { isEmptyObject } from '@app/shared/helpers/util';
import { AuthService } from '@core/services/auth.service';

@Injectable()
export class SidebarService {
  sidebar: Sidebar;

  constructor(
    private permService: PermissionService,
    private authService: AuthService,
  ) { }

  /**
   * Assigns sidebar links based on permissions
   * Update the sidebar object if permissions is changed
   */
  getAuthorizedSidebarLinks(): Observable<Sidebar> {
    const DIAdmin = this.permService.hasPermission(UserPermissions.toAdminLevel);
    const ERTAdmin = this.permService.hasPermission(UserPermissions.ertAdmin);
    const ERTDemoAccess = this.permService.hasPermission(UserPermissions.ertDemoAccess);
    const isClientEnabledInDI = !!this.authService.getOrganization().clientId;
    const internalUser = this.permService.hasPermission(UserPermissions.internalUser);
    const hasDataMonitorAccess = this.permService.hasPermission(UserPermissions.hasDataMonitorAccess);

    this.sidebar = {
      sidebarSections: [
      ].filter((sec) => !isEmptyObject(sec)),
      toAdminLink: [
        /* {
          ...(toAdmin && { text: 'TO Admin', location: '/dashboard/settings/toadmin', icon: 'admin'})
        } */
      ].filter((link) => !isEmptyObject(link)),
      sidebarLinks: [
        {// Metrics Dashboards
          text: 'Metrics Dashboards', location:'/dashboard/kpi/portfolio', icon: 'car-dashboard',
        },
        {// Data Monitoring
          ...( (isClientEnabledInDI && hasDataMonitorAccess) && { text: 'Data Monitoring', location: '/dashboard/kpi/data-monitoring', icon: 'chart', } )
        },
        {// Notifications
          ...( isClientEnabledInDI && { text: 'Notifications', location: '/dashboard/settings/admin/notifications', icon: 'bell', } )
        },

        { // editStudy has access tl settings
          ...((DIAdmin || ERTDemoAccess) && {text: 'Settings', location: '/dashboard/settings/admin/organization', icon: 'sliders-horizontal', })
        },
        { // Metric Studio
            ...(ERTAdmin && isClientEnabledInDI &&  {
            text: 'Metric Studio',
            location: '/dashboard/metric-studio',
            icon: 'settings',
          }),
        },
      ].filter((links) => !isEmptyObject(links)),
     /* sidebarSections: [
          { // editUsers and viewAuditTrail has access to admin
          ...(DIAdmin || ERTDemoAccess) && {
            links: [
              {
                ...(DIAdmin && {
                  links: [
                    {
                      ...(DIAdmin && { text: 'Study Events', location: '/dashboard/settings/admin/user-management', })
                    }
                  ].filter((links) => !isEmptyObject(links)),
                  dividerBottom: false,
                  text: 'Study', location: sessionStorage.getItem('HOMEPAGE'), icon: '',
                })
              },
              {
                ...(DIAdmin &&
                   {
                     text: 'Sites', location: sessionStorage.getItem('HOMEPAGE'), icon: '',
                     links: [
                       {
                         ...(DIAdmin && { text: 'Site Events', location: '/dashboard/settings/admin/user-management', })
                       }
                     ].filter((links) => !isEmptyObject(links)),
                   })
              }
            ].filter((links) => !isEmptyObject(links)),
            dividerBottom: false,
            icon: 'smallw',
            title: 'KPI',
          }
        }
      ].filter((sec) => !isEmptyObject(sec)),*/
    };
    return of(this.sidebar);
  }
}
