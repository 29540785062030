import {Store, select} from '@ngrx/store';
import {Component, OnInit, AfterContentInit, AfterViewInit, Input, OnDestroy, QueryList, ViewChildren,} from '@angular/core';
import {UtilsService} from '../services/utils.service';
import { ToastrService } from 'ngx-toastr';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { OrganizationService } from '@app/settings/admin/organization/organization.service';
import { StudyService } from '../services/study.service';
import { SiteService } from '../services/site.service';
import {takeUntil, catchError} from 'rxjs/operators';
import {Subject, of, zip} from 'rxjs';
import { MetricHelpInfoComponent } from '../modals/metric-help-info/metric-help-info.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import {FormControl} from '@angular/forms';
import { PermissionService } from '@app/shared/services/permisssion.service';
import { ErrorNotificationService } from '@app/core/services/error-notification.service';
import { AuthService } from '@app/core/services/auth.service';

const ClientModes: any[] = [
  { clientModeId: 0,
    clientMode: 'Default',
    clientModeDisplayText: 'Default',
    toggleButtonText: 'Internal Only Mode ON',
    toggleToModeId: 1
  },
  { clientModeId: 1,
    clientMode: 'ERT-Only',
    clientModeDisplayText: 'Internal Only',
    toggleButtonText: 'Internal Only Mode OFF',
    toggleToModeId: 0
  }
];

@Component({
  selector: 'ert-info-banner',
  templateUrl: './info-banner.component.html',
  styleUrls: ['./info-banner.component.scss']
})
export class InfoBannerComponent implements OnInit, AfterContentInit, AfterViewInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  dialogRef: MatDialogRef<MetricHelpInfoComponent>;
  pageTitle: string;
  showOrganizationSettings: boolean;
  clientModeToggleButtonText: string;
  clientId: number;
  clientName: string;
  clientMode: string;

  showBucket: boolean;
  complianceScore: number;
  qualityScore: number;
  showMetric: boolean;
  studyId: number;
  odsReportsEnabled: boolean = false;
  siteId: number;
  metricId: number;
  metricScore: number;
  metricName: string;
  metricDescription: string;
  showReport: boolean = false;
  reportData: any = {};
  therapeutic: any = [];
  indication: any = [];
  studyName: any = [];
  country: any = [];
  loadStartDate: Date;
  loadEndDate: Date;
  initialStartDate: Date;
  initialEndDate: Date;
  initalTherapeutic: any = [];
  initalIndication: any = [];
  initalStudyName: any = [];
  initalCountry: any = [];
  selectedLoadStartDate: Date;
  selectedLoadEndDate: Date;
  therapeuticAreas: any;
  indications: any;
  studyNames: any;
  countries: any;
  enableApply: boolean = false;
  optionCount = 0;
  value: number = 40;
  highValue: number = 60;
  isERTAdmin = false;
  finalOptionCount = 0;
  @ViewChildren(MatSelect) matSelect: QueryList<MatSelect>;

  constructor(
    private store: Store<any>,
    public dialog: MatDialog,
    public toastrService: ToastrService,
    private authService: AuthService,
    public sessionStorageService: SessionStorageService,
    public organizationService: OrganizationService,
    private studyService: StudyService,
    private siteService: SiteService,
    private permissionService: PermissionService,
    private errorNotificationService: ErrorNotificationService,
  ) {
  }

  ngOnInit() {
    this.isERTAdmin = this.permissionService.hasPermission('ErtAdmin');
    const hasClientEnabled = !!this.authService.getOrganization().clientId;

      this.store.select('toolbarState')
        .pipe(takeUntil(this.unsubscribe$), catchError(error => of(`Bad Promise: ${error}`)))
        .subscribe(toolbarData => {
          const portfolioPage = 'my portfolio';
          this.pageTitle = toolbarData.title;
          if (hasClientEnabled) {
            // Show organization setttings
            this.showOrganizationSettings = this.pageTitle ? this.pageTitle === 'Organization Settings' : false;
            if (this.showOrganizationSettings) {
              const rbmAuth = this.sessionStorageService.getItem('RBM-AUTH');
              this.clientId = rbmAuth.clientId;
              this.clientName = rbmAuth.clientName;
              this.clientMode = rbmAuth.clientMode;
              const clientModeEntry = ClientModes.find(item => item.clientMode === this.clientMode);
              this.clientModeToggleButtonText = ( clientModeEntry ? clientModeEntry.toggleButtonText : 'Not Set' );
            } else {
              this.clientId = null;
              this.clientName = null;
              this.clientMode = null;
              this.clientModeToggleButtonText = 'Not Set';
            }

            // Show bucket scores
            this.showBucket = !!toolbarData.bucket;
            this.complianceScore = this.showBucket ? toolbarData.bucket.complianceScore : null;
            this.qualityScore = this.showBucket ? toolbarData.bucket.qualityScore : null;

            // Show individual metric score
            this.showMetric = !!toolbarData.metric;
            this.metricId = this.showMetric ? toolbarData.metric.metricId : null;
            this.metricScore = this.showMetric ? toolbarData.metric.metricScore : null;
            this.metricName = this.showMetric ? toolbarData.metric.metricName : null;

            this.metricDescription = this.showMetric ? toolbarData.metric.metricDescription : null;
            this.studyId = toolbarData.study ? toolbarData.study.studyId : null;
            this.odsReportsEnabled = toolbarData.study ? toolbarData.study.odsReportsEnabled : false;
            this.siteId = toolbarData.site ? toolbarData.site.siteId : null;

            if (this.odsReportsEnabled && this.studyId && this.siteId && this.metricId) {
              this.siteService.getSiteReport(this.studyId, this.siteId, this.metricId).subscribe(rptData => {
                this.reportData = {
                  ...rptData,
                  isSiteLevel: true
                };
                const rptConfig = this.reportData.reportConfig;
                this.showReport = ( rptConfig && rptConfig.reportFile && rptConfig.reportId && rptConfig.active );
              });
            } else if (this.odsReportsEnabled && this.studyId && this.metricId) {
              this.studyService.getStudyReport(this.studyId, this.metricId).subscribe(rptData => {
                this.reportData = {
                  ...rptData,
                  isSiteLevel: false
                };
                const rptConfig = this.reportData.reportConfig;
                this.showReport = ( rptConfig && rptConfig.reportFile && rptConfig.reportId && rptConfig.active );
              });
            }
          }

        });

  }

  ngAfterContentInit() {
  }

  ngAfterViewInit() {
    //To close multi select elements when mouse leave
    this.matSelect.changes.subscribe(() => {
      this.matSelect.toArray().forEach(child =>
        {
          child.openedChange.subscribe(opened => {
            if (opened) {
              child.panel.nativeElement.addEventListener('mouseleave', () => {
              child.close();
              })
            }
          });
      });
    });
  }

  getScoreColor(score) {
    return UtilsService.getColorFromScore(score);
  }


  onChange(event: any) {
    this.countOptions();
    this.enableApply = true;
    //TODO: The below code is for future use, the code is to detect apply button enable/disable
                        // this.compareArrays(this.therapeutic , this.initalTherapeutic) ||
                        // this.compareArrays(this.indication , this.initalIndication) ||
                        // this.compareArrays(this.studyName , this.initalStudyName) ||
                        // this.compareArrays(this.country , this.initalCountry) ||
                        // (this.selectedLoadStartDate.valueOf() != this.initialStartDate.valueOf()) ||
                        // (this.selectedLoadEndDate.valueOf() != this.initialEndDate.valueOf());
  }

compareArrays(arr1,arr2) {
  for (var i = 0; i < arr1.length; i++)
    if (arr1[i] != arr2[i])
      return false;

  return i == arr2.length;
}

  countOptions() {
    this.optionCount = 0;
    if (this.therapeutic.length > 0)
      this.optionCount++;
    if (this.indication.length > 0)
      this.optionCount++;
    if (this.studyName.length > 0)
      this.optionCount++;
    if (this.country.length > 0)
      this.optionCount++;
    if(this.loadStartDate.valueOf() != this.selectedLoadStartDate.valueOf() && this.selectedLoadStartDate != null)
      this.optionCount++;
    if(this.loadEndDate.valueOf() != this.selectedLoadEndDate.valueOf() && this.selectedLoadEndDate != null)
      this.optionCount++;
  }

  viewReport(): void {
    if (this.showReport) {
      document.cookie = `di_auth_token=${this.reportData.di_auth_token}; path=/; domain=.ert.com`;

      const rptUrls = UtilsService.getBIReportURL(this.reportData);
      rptUrls.forEach(url => {
        window.open(url, '_blank');
      });
    }
  }

  openDialog(): void {
    const data = {metricName: this.metricName, metricDescription: this.metricDescription};
    this.dialog.open(MetricHelpInfoComponent, {
        width: '600px',
        data
    });
  }

  toggleClientMode() {
    const clientModeEntry = ClientModes.find(item => item.clientMode === this.clientMode);
    if (clientModeEntry) {
      const targetClientModeId = clientModeEntry.toggleToModeId;
      this.organizationService.setCustomerMode(this.clientId, targetClientModeId).subscribe((result) => {
        if (result && result.client_mode) {
          this.clientMode = result.client_mode;
          const rbmAuth = this.sessionStorageService.getItem('RBM-AUTH');
          if (rbmAuth) {
            rbmAuth.clientMode = this.clientMode;
            this.sessionStorageService.setItem('RBM-AUTH', rbmAuth);
          }

          const cmEntry = ClientModes.find(item => item.clientMode === this.clientMode);
          this.clientModeToggleButtonText = (cmEntry ? cmEntry.toggleButtonText : 'Not Set');

          this.toastrService.success(`${this.clientName} has been successfully updated to ${cmEntry.clientModeDisplayText} mode.`,
            'Client Mode Updated');
        }
      },
      (err) => {
        if (err.status === 401) {
          throw err;
        }
        if (err.status === 403) {
          this.errorNotificationService.notifyError("You do not have permission to perform this task.", err);
        } else {
          this.errorNotificationService.notifyError("Error while updating. Please try again.", err);
        }
      }
      );
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
