import { Router } from '@angular/router';
import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import {PermissionService} from '@shared/services/permisssion.service';

@Directive({
  selector: '[ertPermission]'
})
export class PermissionDirective implements OnInit {
  @Input('ertPermission') permissionName: string;

  constructor(
    private el: ElementRef,
    private router: Router,
    private permissionService: PermissionService,
  ) { }

  ngOnInit() {
    const hasPermission = this.permissionService.hasPermission(this.permissionName);
    if (hasPermission) {
        this.el.nativeElement.hidden = false;
    } else {
      this.el.nativeElement.remove();
    }
  }

  search(nameKey, myArray) {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].name === nameKey) {
        return myArray[i];
      }
    }
  }

}
