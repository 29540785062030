import { Store } from '@ngrx/store';
import { ActivatedRoute, Router, Params } from '@angular/router';
import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { StudyService } from '@shared/services/study.service';
import { Study } from '@shared/models/study.model';
import { UpdateToolbar } from '@app/core/store/toolbar/toolbar.actions';
import dateFormat from 'dateformat';

@Component({
  selector: 'ert-metric-study-event-table',
  templateUrl: './metric-study-event-table.component.html',
  styleUrls: ['./metric-study-event-table.component.scss']
})
export class MetricStudyEventTableComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  studyId;
  metricId;
  noData = true;
  // dateformat = require('dateformat');
  thresholdOpCategory = {
    'gte': '>=',
    'lte': '<='
  };

  thresholdCategory = {
    'compliance': 'Compliance',
    'quality': 'Quality'
  };

  displayedColumns = [
    "metricName",
    "siteNo",
    "pi",
    "suggestedAction",
    "category",
    "beginDate"
  ];
  dataSource;
  constructor(
    private studyService: StudyService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<any>
  ) {
    this.route.params.subscribe(params => {
      this.studyId = params.studyId;
      this.metricId = params.metricId;
    });
  }

  ngOnInit() {

    if (this.metricId) {
      this.studyService.studyMetricEvents(this.studyId, this.metricId).subscribe(events => { this.formatData(events) });
    } else {
      this.studyService.studyEvents(this.studyId).subscribe(events => { this.formatData(events) });
    }
  }

  formatData(events) {
    this.noData = (events.length > 0) ? false : true;
    this.dataSource = new MatTableDataSource(events);
    setTimeout(() => {
      this.dataSource.filterPredicate = (data, filter: String) => {
        const lowerFilter = filter.trim().toLowerCase();
        return this.checkDate(data.beginDate, lowerFilter)
          || this.checkFilter(this.displayDescription(data.metricName, data.thresholdOperator, data.thresholdValue, data.units), lowerFilter)
          || this.checkFilter(data.suggestedAction, lowerFilter) || this.checkFilter(data.category, lowerFilter)
          || this.checkFilter(data.siteNo, lowerFilter) || this.checkFilter(data.pi, lowerFilter);
      };
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (datas: any, header: string): any => {
        switch (header) {
          case 'beginDate': return new Date(datas.beginDate);
          case 'siteNo': return parseInt(datas.siteNo, 10) || null;
          default: return datas[header];
        }
      };
    });
  }

  applyFilter(filterValue: string) {
    // remove white space and lowercase filter value
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  checkFilter(item, lowerFilter) {
    if (item)
      return item.toString().trim().toLowerCase().indexOf(lowerFilter) !== -1;
    else
      return false;
  }

  checkDate(item, lowerFilter) {
    console.log(`MetricStudyEventTableComponent checkDate`);
    return dateFormat(item, 'dd-mmm-yyyy', true).toString().trim().toLowerCase().indexOf(lowerFilter) !== -1;
  }

  selectStudyMetric(studyId, metricId) {
    //if this is calling from my study metric page then hyperlink should now display
    if (this.metricId)
      return;
    this.router.navigateByUrl(
      `dashboard/study/${studyId}/metric/${metricId}`
    );
  }

  selectSiteMetric(studyId, siteId, metricId) {
    this.router.navigateByUrl(
      `dashboard/study/${studyId}/site/${siteId}/metric/${metricId}`
    );
  }

  displayDescription(metricName, thresholdOperator, thresholdValue, units) {
    var threshold;
    if (units === '%') {
      threshold = +(thresholdValue * 100).toFixed(2) + units;
    } else {
      threshold = thresholdValue + ' ' + units;
    }
    thresholdOperator = (thresholdOperator) ? thresholdOperator.toLowerCase() : thresholdOperator;
    return String(metricName + " " + this.thresholdOpCategory[thresholdOperator] + " " + threshold);
  }
}

