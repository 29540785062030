import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class EditStudyGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router) { }
  canActivate(): boolean {
    const permCheck = JSON.parse(sessionStorage.getItem('RBM-AUTH')).userAccess.permissions;
    if (permCheck.editStudy) {
      return true;
    }
    this.router.navigate(['/error'], { queryParams: { title: 'PAGE NOT ACCESSIBLE', message: 'Either the page does not exist or you do not have the permissions granted at the moment.', reset: true } });
    return false;
  }
  canActivateChild(): boolean {
    const permCheck = JSON.parse(sessionStorage.getItem('RBM-AUTH')).userAccess.permissions;
    if (permCheck.editStudy) {
      return true;
    }
    this.router.navigate(['/error'], { queryParams: { title: 'PAGE NOT ACCESSIBLE', message: 'Either the page does not exist or you do not have the permissions granted at the moment.', reset: true } });
    return false;
  }
}

@Injectable({
  providedIn: 'root'
})
export class EditUsersGuard implements CanActivate {
  constructor(private router: Router) { }
  canActivate(): boolean {
    const permCheck = JSON.parse(sessionStorage.getItem('RBM-AUTH')).userAccess.permissions;
    if (permCheck.editUsers) {
      return true;
    }
    this.router.navigate(['/error'], { queryParams: { title: 'PAGE NOT ACCESSIBLE', message: 'Either the page does not exist or you do not have the permissions granted at the moment.', reset: true } });
    return false;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ViewAuditTrailGuard implements CanLoad {

  constructor(private router: Router) { }

  canLoad(): boolean {
    const permCheck = JSON.parse(sessionStorage.getItem('RBM-AUTH')).userAccess.permissions;
    if (permCheck.viewAuditTrail && permCheck.viewAuditTrail === 'all') {
      return true;
    }
    this.router.navigate(['/error'], { queryParams: { title: 'PAGE NOT ACCESSIBLE', message: 'Either the page does not exist or you do not have the permissions granted at the moment.', reset: true } });
    return false;
  }
}


@Injectable({
  providedIn: 'root'
})
export class ViewSiteGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const permCheck = JSON.parse(sessionStorage.getItem('RBM-AUTH')).userAccess.permissions;
    if (permCheck.viewSite) {
      return true;
    }
    this.router.navigate(['/error'], { queryParams: { title: 'PAGE NOT ACCESSIBLE', message: 'Either the page does not exist or you do not have the permissions granted at the moment.', reset: true } });
    return false;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ViewStudyGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(): boolean {
    const permCheck = JSON.parse(sessionStorage.getItem('RBM-AUTH')).userAccess.permissions;
    if (permCheck.viewStudy) {
      return true;
    }
    this.router.navigate(['/error'], { queryParams: { title: 'PAGE NOT ACCESSIBLE', message: 'Either the page does not exist or you do not have the permissions granted at the moment.', reset: true } });
    return false;
  }
}

@Injectable({
  providedIn: 'root'
})
export class CrossSiteGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(): boolean {
    if (sessionStorage.getItem('HOMEPAGE') === '/dashboard/sitebenchmark') {
      return true;
    }
    this.router.navigate(['/error'], { queryParams: { title: 'PAGE NOT ACCESSIBLE', message: 'Either the page does not exist or you do not have the permissions granted at the moment.', reset: true } });
    return false;
  }
}


@Injectable({
  providedIn: 'root'
})
export class TOAdminRootGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(): boolean {
    const permCheck = JSON.parse(sessionStorage.getItem('RBM-AUTH')).userAccess.toAdminLevel;
    if (permCheck === 2) {
      return true;
    }
    this.router.navigate(['/error'], { queryParams: { title: 'PAGE NOT ACCESSIBLE', message: 'Either the page does not exist or you do not have the permissions granted at the moment.', reset: true } });
    return false;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AdminLevelCheckGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router) { }

  canActivate(): boolean {
    const permCheck = JSON.parse(sessionStorage.getItem('RBM-AUTH'));
    if (permCheck.userAccess.toAdminLevel > 0 || permCheck.isErtDemo) {
      return true;
    }
    this.router.navigate(['/error'], { queryParams: { title: 'PAGE NOT ACCESSIBLE', message: 'Either the page does not exist or you do not have the permissions granted at the moment.', reset: true } });
    return false;
  }

  canActivateChild(): boolean {
    const permCheck = JSON.parse(sessionStorage.getItem('RBM-AUTH'));
    if (permCheck.userAccess.toAdminLevel > 0 || permCheck.isErtDemo) {
      return true;
    }
    this.router.navigate(['/error'], { queryParams: { title: 'PAGE NOT ACCESSIBLE', message: 'Either the page does not exist or you do not have the permissions granted at the moment.', reset: true } });
    return false;
  }
}

