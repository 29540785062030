import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MetricWizardStepsType } from '@app/metric-studio/metric-library/metric-library.service';
import { Observable } from 'rxjs';

interface CodeMirrorType {
  codeMirrorID?: string;
  data?: object;
  fromChangeEvent?: boolean;
}

@Injectable()
export class CodeMirrorService {
  private codeMirrorData$ = new BehaviorSubject<CodeMirrorType>({});

  constructor() {
  }


  setData(configId: string, formData = {}, fromChangeEvent = false) {
    this.codeMirrorData$.next(<CodeMirrorType>{ [configId]: formData, fromChangeEvent });
  }

  GetData(): Observable<CodeMirrorType> {
    return this.codeMirrorData$.asObservable();
  }
}
