import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayDate'
})
export class DisplayDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: any, display_time?: boolean, args?: any): any {
    let timezone = 'UTC';
    let format = 'dd-MMM-yyyy';

    if (display_time === true) {
      format = 'dd-MMM-yyyy HH:mm:ss UTC';
      timezone = 'UTC';
    }
    return this.datePipe.transform(value, format, timezone);
  }
}
