import { FooterDialogComponent } from './../../footer-dialog/footer-dialog.component';
import { Component, OnInit , Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '@env/environment';
// import { NONE_TYPE } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'ert-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  year: number;
  version = '1.0';
  @Input() color;

  constructor(
    public dialog: MatDialog
  ) { 
    this.version = `${environment.version}`;
  }

  ngOnInit() {
    const now = new Date();
    this.year = now.getFullYear();
  }

  openDialog() {
    const dialogRef = this.dialog.open(FooterDialogComponent, {
      panelClass: 'footer-dialog',
      height: '450px'
    });

    dialogRef.afterClosed().subscribe(res => { });
  }

}
