import { Injectable } from '@angular/core';
import { EndpointService } from '@shared/services/endpoint.service';
import { DataService } from '@shared/services/data.service';
import { map } from 'rxjs/operators';

@Injectable()
export class AdminService {

  constructor(
    private dataService: DataService<any>,
    private endpointService: EndpointService
  ) { }

  getLogDetails(limit: number, pageNo: number, filterQuery = null) {
    const filterQueryString = filterQuery ? `?${filterQuery}` : ``;
    const endpoint = this.endpointService.getEndpoint('logDetails', limit, pageNo, filterQueryString);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  getSingleLogDetails(activityLogId: number) {
    const endpoint = this.endpointService.getEndpoint('singleLogActivity', activityLogId);
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  getLogCount(filterQuery= '') {
    const filterQueryString = filterQuery ? `?${filterQuery}` : ``;
    const endpoint = this.endpointService.getEndpoint('logCount', filterQueryString);
    return this.dataService.getOne(endpoint).pipe(map((response: any) => response.result));
  }
}
