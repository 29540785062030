import { Customer } from '@app/shared/models/customer.model';
import { Action, createFeatureSelector, createSelector } from '@ngrx/store';

export const AUTH_KEY = 'RBM-AUTH';

export enum AuthActionTypes {
  LOGIN = '[Auth] Login',
  LOGIN_SUCCESS = '[Auth] Login Success',
  AUTHENTICATED = '[Auth] Authenticated',
  LOGOUT = '[Auth] Logout',
}

export class Login implements Action {
  readonly type = AuthActionTypes.LOGIN;
  constructor(public payload: any) { }
}

export class LoginSuccess implements Action {
  readonly type = AuthActionTypes.LOGIN_SUCCESS;
  constructor(public payload: any) { }
}

export class Logout implements Action {
  readonly type = AuthActionTypes.LOGOUT;
  constructor(public payload: any) { }
}

export class Authenticated implements Action {
  readonly type = AuthActionTypes.AUTHENTICATED;
}


export type AuthActions
= Login
| Logout
| LoginSuccess
| Authenticated
;

export const initialState: AuthState = loadInitialAuthState();

export function AuthReducer(
  state: AuthState = initialState,
  action: AuthActions
): AuthState {
  switch (action.type) {
    case AuthActionTypes.LOGIN: {
      return state;
    }

    case AuthActionTypes.LOGOUT: {
      return { ...state, isAuthenticated: false };
    }

    case AuthActionTypes.LOGIN_SUCCESS: {
      const { email, firstName, lastName } = action.payload;
      return { ...state, firstName, lastName, email, isAuthenticated: true };
    }

    default:
      return state;
  }
}

export function clearState(reducer) {
  return function (state, action) {

    if (action.type === AuthActionTypes.LOGOUT) {
      state = undefined;
    }

    return reducer(state, action);
  };
}

export interface AuthState {
  isAuthenticated: boolean;
  firstName: string;
  lastName: string;
  email: string;
}

export function loadInitialAuthState() {
  const defaultState: AuthState = {
    isAuthenticated: false,
    firstName: '',
    lastName: '',
    email: '',
  };

  let state: any;
  const storedString: string = sessionStorage.getItem('RBM-AUTH');
  try {
    const sessionStorageObject = JSON.parse(storedString);
    const { isAuthenticated, firstName, lastName, email } = sessionStorageObject;

    state = { ...defaultState, isAuthenticated, firstName, lastName, email };

    if (sessionStorageObject.token) {
      state.isAuthenticated = true;
    }


  } catch (error) {
    sessionStorage.clear();
  }
  return state;
}

export function getUserInfoFromSessionStorage() {

  const storedString: string = sessionStorage.getItem('RBM-AUTH');
  try {
    const sessionStorageObject = JSON.parse(storedString);
    // const {firstName, lastName, email } = sessionStorageObject;

    return sessionStorageObject;
  } catch (error) {
    sessionStorage.clear();
  }

}

