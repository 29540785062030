<button 
  class="button cursor-pointer" 
  [class.active]="active"

  [disabled]="disabled || active">
  {{ buttonText }}
  <span *ngIf="active">
    <svg class="ring" viewBox="25 25 50 50" stroke-width="5"><circle cx="50" cy="50" r="10" /></svg>
  </span>
</button>


