import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn } from '@angular/forms';
import { ToAdminService } from './../to-admin.service';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { SetBreadCrumbs } from '@app/core/store/breadcrumbs/breadcrumbs.actions';
import { ErrorNotificationService } from '@app/core/services/error-notification.service';
import { StudyService } from '@app/shared/services/study.service';

export interface ConfigDefault {
  clientId: number;
  srcStudyId: number;
  numSites: number;
  totalEnroll: number;
  totalCompleted: number;
  length: number;
  visitCount: number;
  visitAvgDays: number;
}

@Component({
  selector: 'ert-add-default-study-config',
  templateUrl: './add-default-study-config.component.html',
  styleUrls: ['./add-default-study-config.component.scss']
})
export class AddDefaultStudyConfigComponent implements OnInit {
  addStudyConfigForm: UntypedFormGroup;

  clientList: [];
  selectedClientId: number;
  sourceUrls: [];
  studies: any[];
  selectedStudy;

  @ViewChild('addStudyConfigFormElem') addStudyConfigFormElem;

  constructor(
    private store: Store<any>,
    private studyService: StudyService,
    private toAdminService: ToAdminService,
    private toastr: ToastrService,
    private errorNotificationService: ErrorNotificationService,
  ) {
    this.store.dispatch(new SetBreadCrumbs({
      crumbs: [
        { text: 'TO Admin' },
        { text: 'Default Config' }
      ]
    }));
   }

  ngOnInit() {
    this.addStudyConfigForm = new UntypedFormGroup({
      clientId: new UntypedFormControl('', [Validators.required]),
      srcStudyId: new UntypedFormControl('', [Validators.required]),
      numSites: new UntypedFormControl('', [Validators.required, Validators.min(0)]),
      totalEnroll: new UntypedFormControl('', [Validators.required, Validators.min(0)]),
      totalCompleted: new UntypedFormControl('', [Validators.required, Validators.min(0)]),
      length: new UntypedFormControl('', [Validators.required, Validators.min(0)]),
      visitCount: new UntypedFormControl('', [Validators.required, Validators.min(0)]),
      visitAvgDays: new UntypedFormControl('', [Validators.required, Validators.min(0)]),
    });

    this.toAdminService.getAllClients().subscribe((allClients) => {
      this.clientList = allClients;
    });
  }

  selectCustomer(_event: any, clientId) {
    if (_event.isUserInput) {
      this.selectedClientId = clientId;
      this.selectedStudy = null;
    }
  }

  updateStudies(event) {
    this.addStudyConfigForm.controls['srcStudyId'].setErrors({ 'noStudies': false });
    if (event && this.selectedClientId) {
      return this.toAdminService.getClientStudies(this.selectedClientId).subscribe((allStudies) => {
        this.studies = allStudies;
        if (!this.studies.length) {
          this.addStudyConfigForm.controls['srcStudyId'].setErrors({ 'noStudies': true });
        }
      });
    }
    return this.studies = [];
  }

  saveDaultStudyConfig() {
    if (this.addStudyConfigForm.valid) {
      const studyConfig: ConfigDefault = {
        clientId: this.selectedClientId,
        srcStudyId: this.addStudyConfigForm.value.srcStudyId,
        numSites: this.addStudyConfigForm.value.numSites,
        totalEnroll: this.addStudyConfigForm.value.totalEnroll,
        totalCompleted: this.addStudyConfigForm.value.totalCompleted,
        length: this.addStudyConfigForm.value.length,
        visitCount: this.addStudyConfigForm.value.visitCount,
        visitAvgDays: this.addStudyConfigForm.value.visitAvgDays,
      };
      this.toAdminService.configDefault(studyConfig).subscribe(data => {
        this.toastr.success('Default Config added successfully.');
        this.addStudyConfigFormElem.resetForm();
      }
        , err => {
          if (err.status === 401) throw err;
          else  this.errorNotificationService.notifyError('Error adding default config.', err);
        }
      );
    }
  }
}
