import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
  OnChanges
} from "@angular/core";
import * as d3 from "d3";
import { UtilsService } from '@app/shared/services/utils.service';
import { CommunicationEventsService } from "@app/shared/services/communication/communication-events.service";
import { takeUntil, catchError, repeatWhen } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { Subject, of } from "rxjs";


@Component({
  selector: "ert-scatter-plot-chart",
  templateUrl: "./scatter-plot-chart.component.html",
  styleUrls: ["./scatter-plot-chart.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class ScatterPlotChartComponent implements OnInit, OnChanges  {
  @ViewChild("scatterplot", { static: true, read: ElementRef })
  private chartContainer: ElementRef;
  firstDraw: boolean = true;
  @Input() data;
  @Input() selectedStudiesData = [];
  @Input() private xAxisLabel: string;
  @Input() private yAxisLabel: string;
  private svg: any = null;
  private margin: any = { top: 5, right: 30, bottom: 45, left: 40 };
  private width: number;
  private height: number;
  private tooltip = null;

  private element;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private store: Store<any>,
    private communicationEvents: CommunicationEventsService,
  ) {}

  ngOnInit() {
    this.firstDraw = false;
    // if (this.data) {
    //   this.element = this.chartContainer.nativeElement;
    //   this.drawChart();
    // }

    // window.addEventListener("resize", this.drawChart.bind(this));
  }

  ngOnChanges(changes) {
    if (this.data){
      this.element = this.chartContainer.nativeElement;
      this.drawChart();

      if (this.selectedStudiesData.length) {
        const studyId = this.selectedStudiesData[0].studyId;
        this.registerEvent();
      }
    }

    window.addEventListener("resize", this.drawChart.bind(this));
  }

  drawChart() {

    if(!this.data || this.data.length == 0) {
      return;
    }

    const element = this.chartContainer.nativeElement;
    this.svg = null;
    this.width = element.offsetWidth - this.margin.left - this.margin.right;
    this.height = 230;

    if (!this.firstDraw) {
      d3.select(element)
        .selectAll("svg")
        .remove();

        d3.select(element)
        .selectAll(".scatter_plot_tooltip")
        .remove();

      this.svg = null;
    }

    // setup x
    var xScale = d3.scaleLinear().range([0, this.width]); // value -> display
    var xAxis = d3.axisBottom(xScale).ticks(5);

    // setup y
    var yScale = d3.scaleLinear().range([this.height, 0]); // value -> display
    var yAxis = d3.axisLeft(yScale).ticks(5);

    // add the tooltip area to the svg and keep the tooltip when mouse over
    var tooltip = null;

    // add the graph canvas to the body of the webpage
    var main_svg = d3
      .select(element)
      .append("svg")
      .attr("width", this.width + this.margin.left + this.margin.right)
      .attr("height", this.height + this.margin.top + this.margin.bottom);

    this.svg = main_svg
      .append("g")
      .attr(
        "transform",
        "translate(" + this.margin.left + "," + this.margin.top + ")"
      );

    //To remove tooltip when mouse moved out of the scotter plot chart
    var isOnTooltip = false;
    d3.select(element).on("mouseleave", function(e) {

      if(d3.event.relatedTarget && d3.event.relatedTarget != null && (d3.event.relatedTarget.id == "scatter_plot_tooltip"
      || d3.event.relatedTarget.class == "tooltip_open") ){
         isOnTooltip =true;
      }

      if(!isOnTooltip){
        d3.select("body")
          .selectAll(".scatter_plot_tooltip")
          .remove();
        tooltip = null;
      }
  }).on("wheel", function(e) {
      d3.select("body")
        .selectAll(".scatter_plot_tooltip")
        .remove();
      tooltip = null;
  });

    // don't want dots overlapping axis, so add in buffer to data domain
    xScale.domain([0, 100]);
    yScale.domain([0, 100]);

    // x-axis
    this.svg
      .append("g")
      .attr("class", "ms-axis")
      .attr("transform", "translate(0," + this.height + ")")
      .call(xAxis);

    // y-axis
    this.svg
      .append("g")
      .attr("class", "ms-axis")
      .call(yAxis);

  //Color the background of the chart
    let areaFunc = d3.area()
    .x1(function(d) { return xScale(d.x) })
    .x0(0)
    .y1(function(d) { return yScale(d.y) })
    .y0(0);


    //Draw red color
    let  portion = [{x:0,y:0}, {x: 0, y: 50}, {x: 50, y: 0}, {x:0, y:0}];
    this.svg.append('path')
      .attr('d', areaFunc(portion))
      .attr('opacity', 0.2)
      .attr('fill', UtilsService.getColorFromScore(24));

    //Draw Orange color
    portion = [{x: 0, y: 50}, {x: 50, y: 0}, {x: 100, y: 0}, {x:0, y:100}];

    this.svg.append('path')
      .attr('d', areaFunc(portion))
      .attr('opacity', 0.2)
      .attr('fill', UtilsService.getColorFromScore(49));

    //Draw Yellow color
    portion = [{x: 0, y: 100}, {x:100, y:0}, {x: 100, y: 50}, {x:50, y:100}];

    this.svg.append('path')
      .attr('d', areaFunc(portion))
      .attr('opacity', 0.2)
      .attr('fill', UtilsService.getColorFromScore(74));

    //Draw Green color
    portion = [{x: 50, y: 100}, {x:100, y:50}, {x: 100, y: 100}, {x:100, y:100}];

    this.svg.append('path')
      .attr('d', areaFunc(portion))
      .attr('opacity', 0.2)
      .attr('fill', UtilsService.getColorFromScore(100));

    // draw dots
    this.svg
      .selectAll(".dot")
      .data(this.data)
      .enter()
      .append("circle")
      .attr("class", "dot")
      .attr("data-study-id", function (d) {
        if (d.study && d.study.length) {
          return d.study[0].id;
        }
      })
      .attr("r", 3.5)
      .attr("cx", function(d) {
        return xScale(d.quality);
      })
      .attr("cy", function(d) {
        return yScale(d.compliance);
      })
      .style('cursor', 'pointer')
      .on('click', (d) => {
        const data = this.registerEvent();
        this.communicationEvents.singleClickEventListener(data);
      })
      .on("mouseover", function(d) {
        var html = "";
        d3.select("body").selectAll(".scatter_plot_tooltip").remove();
        //add the tooltip area to the svg
        tooltip = d3.select("body").append("div")
                    .attr("id", "scatter_plot_tooltip")
                    .attr("class", "scatter_plot_tooltip")
                    .on("mouseover", function(d) {
                      isOnTooltip = true;
                    })
                    .on("mouseleave", function(d) {
                          d3.select("body").selectAll(".scatter_plot_tooltip").remove();
                          tooltip = null;
                          isOnTooltip = false;
                      });

        if (d.study) {  // The below code execute for Study Score and targeted in My Portfolio page
          d.study.forEach(study => {
            html =
              html +
              "<a class='tooltip_open' href='/dashboard/study/" +
              study.id +
              "'>" +
              study.name +
              "</a><br>";
          });
        } else { // The below code execute for Sites Score and targeted in My Study page
          d.site.forEach(site => {
            html =
              html +
              "<a class='tooltip_open' href='dashboard/study/" +
              site.study_id +
              "/site/" +
              site.id +
              "'>" +
              site.no +
              "</a> | " +
              site.pi +
              "<br>";
          });
        }

        //var mouseCoords = d3.mouse(tooltip.node().parentElement);

        tooltip
          .html(
            `<div style="width: 150px;"><span class='study-style'>
              ${html}
              </span>
              <b>Compliance: </b>
              ${d.compliance}
              <br> <b>Quality: </b>
              ${d.quality}
              </div>`
          )
          .style('left', (d3.event.pageX) - 125 + 'px')
          .style('top', (d3.event.pageY) + 25 + 'px');
      });

    // text label for the x axis
    this.svg
      .append("text")
      .attr("class", "axis-label")
      .attr("alignment-baseline", "baseline")
      .attr(
        "transform",
        "translate(" +
          this.width / 2 +
          " ," +
          (this.height + this.margin.top + 35) +
          ")"
      )
      .style("text-anchor", "middle")
      .text(this.xAxisLabel);

    // text label for the y axis
    this.svg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("class", "axis-label")
      .attr("alignment-baseline", "baseline")
      .attr("y", 0 - this.margin.left)
      .attr("x", 0 - this.height / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .text(this.yAxisLabel);
  }

  pulseDot(dot) {
    repeat();
    function repeat() {
      dot.transition()
        .duration(500)
        .attr('stroke-width', 6)
        .attr('r', 4.5)
        .ease(d3.easeSin)
        .transition()
        .duration(500)
        .attr('stroke-width', 0.5)
        .attr('r', 7)
        .ease(d3.easeBounce)

        .on('end', repeat);
    }
  }

  registerEvent() {
    const data = { studyId: null, siteIds: null, metricIds: null };

    if (d3 && d3.event) {
      d3.event.stopPropagation();
      d3.event.preventDefault();
      d3.event.stopImmediatePropagation();
      data.studyId = (d3.event.target && d3.event.target.getAttribute('data-study-id')) || null;
    }

    if (this.selectedStudiesData.length) {
      data.studyId = data.studyId || this.selectedStudiesData[0].studyId;

      const dotSelected = d3.select(this.element).select("[data-study-id='" + data.studyId + "']");
      const isSelected = this.selectedStudiesData.find( (s) => s.studyId === +data.studyId );
      const dotSelectedClasses = dotSelected.size() && dotSelected.attr('class') || [];

      if ( !isSelected || (isSelected && !dotSelectedClasses.includes('selected-study')) ) {
        dotSelected.classed('selected-study', true).attr('r', 4.5);

        d3.selectAll('circle.dot:not(.selected-study)').style('opacity', .25)
        this.pulseDot(dotSelected);

      } else if (dotSelectedClasses.includes('selected-study')) {
        dotSelected.classed('selected-study', false);
      }
    } else { // deselect all dots  and tooltips
      const dots = d3.selectAll('.dot');
      dots.classed('selected-study', false )

    }

    return data;
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.drawChart.bind(this));
  }
}
