import { Router } from '@angular/router';
import { AUTH_KEY, Logout } from '../store/auth/auth.reducer';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { JwtHelperService } from '@auth0/angular-jwt';


@Injectable()
export class AuthService {
  // variable necessary to prevent multiple login calls (RBM-334)
  didHandleLogin = false;
  jwtHelper = new JwtHelperService();

  constructor(
    private store: Store<any>,
    private router: Router,
  ) { }

  getDidHandleLogin() {
    return this.didHandleLogin;
  }
  setDidHandleLogin(didHandleLogin: boolean) {
    this.didHandleLogin = didHandleLogin;
  }


  public isAuthenticated(): boolean {
    const token = sessionStorage.getItem(AUTH_KEY);
    if (token) {
      const tokenObject = JSON.parse(token).token;
      return !this.jwtHelper.isTokenExpired(tokenObject); // fornow, just return true if user has some key
    }
    return false;
  }

  public getUserName(): string {
    const token: any = sessionStorage.getItem(AUTH_KEY);
    if (token) {
      // TODO: Verify key here!
      // return verifyToken(key);
      const tokenObject = JSON.parse(token);
      return tokenObject.firstName; // fornow, just return true if user has some key
    }
  }
  public getEmail(): string {
    const token: any = sessionStorage.getItem(AUTH_KEY);
    if (token) {
      // TODO: Verify key here!
      // return verifyToken(key);
      const tokenObject = JSON.parse(token);
      return tokenObject.email; // fornow, just return true if user has some key
    }
  }

  /** Organization **/
  public getOrganization() {
    const rbm_auth = JSON.parse(sessionStorage.getItem('RBM-AUTH')) || {};
    return  {
      clientId: rbm_auth.clientId,
      clientName: rbm_auth.clientName,
      navigatorOrgName: rbm_auth.navigatorOrgName,
      isUserHasAccessToAnyStudy: rbm_auth.isUserHasAccessToAnyStudy
    };
  }

  public geToken(): string {
    const sessionString: string = sessionStorage.getItem(AUTH_KEY);
    const tokenObject = JSON.parse(sessionString);
    return (tokenObject && tokenObject.token) ? tokenObject.token : null;
  }

  public logout() {
    const tokenString: any = sessionStorage.getItem(AUTH_KEY);
    const tokenObject = JSON.parse(tokenString);
    const token = tokenObject && tokenObject.token;
    return this.store.dispatch(new Logout(token));
  }

}
