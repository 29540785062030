<div class="footer-container">
  <div class="footer-title">
    <header mat-dialog-title><strong>Data Insights System Requirements </strong></header>
  </div>
  <div class="body-container">
<mat-dialog-content class="foo">
  <p>
<strong>Web Browsers</strong>
<br><br>
Data Insights works best with the following browser:</p>
<ul><li><strong> Google Chrome (89.0.4389.114 and above) </strong></li></ul>
<p>Although other browsers may work with the application, we cannot guarantee optimal performance and functionality. <br><br>
<strong>Questions and Support</strong><br><br>
Should you require additional assistance, please call toll-free at 1-800-704-9698. You may also contact Customer Care via email at <a href="mailto:customercare@clario.com">customercare@clario.com</a> or at <a href="https://clario.com/contact/customer-support/" target="_blank">Customer Care</a>. </p>

</mat-dialog-content>
</div>
</div>
