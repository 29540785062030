import { Component, OnInit, ViewChild, ElementRef, Input, ViewEncapsulation, OnDestroy } from '@angular/core';
import * as d3 from 'd3';
import {LineChartOptions, Point} from './line-chart.options';
import { D3UtilsService } from '@app/shared/services/d3/d3-utils.service';
import { monthDiff } from '@app/shared/helpers/util';
import { first } from 'rxjs/operators';

const axisSx  = 40;
const axisSy  = 25;
const legendPadding = 3;
const legendWidthPct = 0.85;
const legendHeightPct = 0.15;
const highlightColor = '#c4ed55';

const CurveType = [
  d3.curveLinear,
  d3.curveMonotoneX,
  d3.curveStepAfter,
  d3.curveStepBefore
];

const OptionDefaults: LineChartOptions = {
    lineWidth: 2,
    dashPattern: '',  // no pattern
    color: 'black',
    curveType: 1,   // see LineType
    dotRadius: 0,  // 0 == no dots
    data: []
};

@Component({
  selector: 'ert-ms-line-chart',
  templateUrl: './ms-line-chart.component.html',
  styleUrls: ['./ms-line-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MsLineChartComponent implements OnInit, OnDestroy {
  static idGen= 0;

  @ViewChild('linechart', {static: true}) private chartContainer: ElementRef;
  @Input() private data: LineChartOptions[];
  @Input() private height: number;
  @Input() private yAxisLabel: string;


  private margin: any = { top: 2, bottom: 2, left: 2, right: 2};
  private width: number;
  private firstDraw: boolean;
  private allPts = [];
  private svg: any;
  private legendSvg: any;
  private tip: any;
  private id: string;
  private xValues: any[] = [];
  private projectedDates = [];
  private units: string;
  private multiLineProjectionCount = 0;
  private lineData;

  constructor() {
    this.firstDraw = true;
    this.id = (MsLineChartComponent.idGen++).toString();
  }

  ngOnInit() {
    if (this.data) {
      this.data.forEach(e => {
        this.loadOptions(e);
      });
      this.data.forEach(e => {
        this.allPts = this.allPts.concat(e.data);
        this.units = e.units;
        this.multiLineProjectionCount += (e.showProjection) ? 1 : 0 ;
        e.data.forEach( d => this.xValues.push(d.x) );
      });

      if (this.multiLineProjectionCount > 0) {
        // Get array with dates spread over 30 days
        this.projectedDates = D3UtilsService.projectedDatesArray(31, this.xValues);
        this.xValues = this.xValues.concat(this.projectedDates);
      }
      this.xValues = [...new Set(this.xValues)];

      this.tip = d3.select('body').append('div')
        .attr('id', 't' + this.id)
        .attr('class', 'chartToolTip')
        .style('opacity', 0);

      this.drawChart();
      this.firstDraw = false;
    }
  }

  ngOnChanges(changes) {
    window.addEventListener("resize", this.drawChart.bind(this));
  }

  loadOptions(opt) {
    for (const key in OptionDefaults) {
      if (opt[key] === undefined) {
        opt[key] = OptionDefaults[key];
      }
    }
  }

  drawGridLines(width, height, xS, yS, numOfXTicks) {

    function make_x_gridlines(values) {
      return d3.axisBottom(xS)
        .ticks(numOfXTicks );
        // .tickValues(values);
    }
    function make_y_gridlines() {
      return d3.axisLeft(yS).ticks(5);
    }
    // add gridlines
    this.svg.append('g')
      .attr('class', 'grid')
      .style('stroke-dasharray', '2,4')
      .attr('transform', 'translate(0,' + (height - axisSy) + ')')
      .call(make_x_gridlines(this.xValues)
        .tickSize(-height + axisSy + 10)
        .tickFormat(d => ''));

    this.svg.append('g')
      .attr('class', 'grid')
      .style('stroke-dasharray', '2,4')
      .attr('transform', `translate(${axisSx}, 0)`)
      .call(make_y_gridlines()
        .ticks()
        // .ticks(d3.timeWeek.every(2))
        .tickSize(-width + 2 * axisSx)
        .tickFormat(d => ''));
  }

  drawAxi(height, xS, yS, numOfXTicks) {
    this.svg
      .append('g')
      .attr('class', 'ms-axis')
      .attr('transform', 'translate(0,' + (height - axisSy) + ')')
      .call(
        d3.axisBottom(xS)
          // .ticks()
          .ticks(numOfXTicks)
          .tickFormat(d => d3.timeFormat('%d-%b-%y')(d))
      )
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('dx', '-.8em')
      .attr('dy', '.15em')
      .attr('transform', function (d) {
        return 'rotate(-45)';
      });

    this.svg.append('g')
      .attr('class', 'ms-axis')
      .attr('transform', `translate(${axisSx}, 0)`)
      .call(d3.axisLeft(yS).ticks(4));

    this.svg.append('text')
      .attr('y', 0)
      .attr('x', 0)
      .attr('class', 'axis-label')
      .attr('alignment-baseline', 'baseline')
      .attr('transform', 'translate(10,' + (height / 2) + '), rotate(-90)')
      .style('text-anchor', 'middle')
      .text(this.yAxisLabel);
  }

  drawLegend(width, height) {
    // parametric legend
    const tWidth = (width - axisSx) * legendWidthPct;
    const bWidth = Math.floor(tWidth / this.data.length);
    const lx = (width - tWidth) / 2;
    const l_height = height - 2 * legendPadding;
    const lSide = l_height - l_height / 2;
    const ox = lx + axisSx + lSide + l_height / 4;

    // background
    this.legendSvg.selectAll('.background')
      .data([0])
      .enter().append('rect')
      .attr('x', lx)
      .attr('y', 0) // legendPadding + 2
      .attr('width', tWidth)
      .attr('height', l_height) // - legendPadding)
      .attr('class', 'legend-box');

    // legend color boxes
    this.legendSvg.selectAll('.legend')
      .data(this.data)
      .enter()
      .append("line")//making a line for legend
      .attr('x1', (d, i) => ox + i * bWidth - lSide/1.25)
      .attr('x2', (d, i) => ox + i * bWidth + (lSide - 2.5))
      .attr('y1', lSide -1)
      .attr('y2', lSide -1)
      .attr('stroke', d => d.color)
      .attr("stroke-width", "3")

      // .append('rect')
      // .attr('class', 'legend-box')
      // .attr('x', (d, i) => ox + i * bWidth - lSide / 2)
      // .attr('y', (d, i) => (l_height - lSide) / 1.5)
      // .attr('width', lSide + 4)
      // .attr('height', 4)
      .style('fill', d => d.color)
      .on('mouseover', (d, i) => {
        d3.select('#s' + this.id + i).transition()
          .style('stroke', d.color)
          .style('stroke-width', 2 * this.data[i].lineWidth);
      })
      .on('mouseout', (d, i) => {
        d3.select('#s' + this.id + i).transition()
          .style('stroke', this.data[i].color)
          .style('stroke-width', this.data[i].lineWidth);
      }).filter(function (d) { return d.legend === "Projected" })
      .attr('stroke-dasharray', '5,5')
      .style("stroke", d => d.color);

    this.legendSvg.selectAll('.legendText')
      .data(this.data)
      .enter().append('text')
      .attr('class', 'legend-text')
      .attr('x', (d, i) => lSide + ox + i * bWidth)
      .attr('y', (d, i) => l_height / 2)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text((d, i) => d.legend);
  }

  drawLines(xS, yS) {
    let projection = [];
    this.data.forEach((e , i) => {
      const line = d3.line()
      .defined(d => d.y >= yS.domain()[0] && d.y <= yS.domain()[1])
      .x(d => xS((d as any).x))
      .y(d => yS((d as any).y))
      .curve(CurveType[e.curveType]);

      if(e.data.length) {
        this.svg.append('path')
        .datum(e.data)
        .attr('fill', 'none')
        .attr('stroke', e.color)
        .attr('stroke-width', 0)
        .attr('d', line)
        .attr('id', 's' + this.id + i)
        .transition()
        .delay((d, k) => k * 100)
        .attr('stroke-width', e.lineWidth)
        .attr('stroke-dasharray', e.dashPattern);

        // ShowProjection is enabled
        if (e.showProjection) {
          // generate future data
          projection = D3UtilsService.getProjectionData(e.data , this.projectedDates);
          this.lineData = e.data.concat(projection);
          // projected line
          this.svg.append('path')
            .datum(projection)
            .attr('fill', 'none')
            .attr('stroke', e.color)
            .attr('stroke-width', 0)
            .attr('d', line)
            .transition()
            .delay((d, k) => k * 100)
            .attr('stroke-width', e.lineWidth)
            .attr('stroke-dasharray', '5,5');
        }


        // tooltip
        this.svg.selectAll('dot')
          .data(this.lineData)
          .enter().append('circle')
          .attr('fill', 'white')
          .attr('stroke', e.color)
          .attr('stroke-width', e.lineWidth)
          .attr('r', 6)
          .attr('id', d => this.uniqueCircleId(e.legend, d.x))
          .attr('cx', d => xS(d.x))
          .attr('cy', d => yS(d.y))
          .attr('opacity', 0)

          .on('mouseover', d => {
            const otherPoint = findDataPointMatchByDate(this.data, d, e.legend);
            this.tooltipShow(d, e.legend, otherPoint);
          })
          .on('mouseout', d => {
            const otherPoint = findDataPointMatchByDate(this.data, d, e.legend);
            this.tooltipOff(d, otherPoint);
          });

        function findDataPointMatchByDate(data, point, legend) {
          let otherLineMatchPoint;
          const otherNotSelectedLine = data.find(d => d.legend !== legend && (d.data && d.data.length));
          if (otherNotSelectedLine.data) {
            otherLineMatchPoint = otherNotSelectedLine.data.find(dataPoint => dataPoint.x === point.x);
            if(otherLineMatchPoint) {
              otherLineMatchPoint.legend = otherNotSelectedLine.legend;
            }
          }

          return otherLineMatchPoint;
        }
      }

    });
  }

  drawChart() {

    if(!this.data || this.data.length == 0)
    return;

    const element = this.chartContainer.nativeElement;
    this.width = element.offsetWidth - this.margin.left - this.margin.right;
    const tHeight = this.height - this.margin.top - this.margin.bottom;
    const lHeight = Math.round(tHeight * legendHeightPct) - 2;
    const height = tHeight - lHeight;
    if (! this.firstDraw) {
      d3.select(element).selectAll('svg').remove();
      this.svg = null;
      this.legendSvg = null;
      this.lineData = null;
    }

    const xS = d3.scaleTime().range([axisSx, this.width - axisSx]);
    const yS = d3.scaleLinear().range([height - axisSy, 10]);

    xS.domain(d3.extent(this.xValues)).nice();

    if (this.units === 'percentage' ) {
      yS.domain(d3.extent( [0, 100] )).nice();
    } else {
      yS.domain(d3.extent(this.allPts, d => +(d as any).y));
    }

    // create legend svg
    this.legendSvg = d3.select(element).append('svg')
      .attr('width', this.width)
      .attr('height', lHeight)
      .append('g')
      // .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);
      .attr('transform', `translate(0, 0)`);
    // create chart svg
    this.svg = d3.select(element).append('svg')
      .attr('width', this.width)
      .attr('height', height + 25)
      .append('g')
      // .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);
      .attr('transform', `translate(0,0)`);


    const numOfXTicks = this.defineXTicks();

    this.drawGridLines(this.width, height, xS, yS, numOfXTicks);
    this.drawAxi(height, xS, yS, numOfXTicks);
    this.drawLegend(this.width, lHeight);
    this.drawLines(xS, yS);
  }

  uniqueCircleId(legend, uniqueID) {
    return `circle_${legend.replace(/ /g, '').toLowerCase()}_${uniqueID}`;
  }

  defineXTicks() {
    const starDate = new Date(this.xValues[0]);
    const endDate = new Date(this.xValues[this.xValues.length - 1]);
    const intervalOfMonths = monthDiff(starDate, endDate);

    if (intervalOfMonths < 3) {
      return d3.timeWeek.every(2);
    }
    if (intervalOfMonths > 3 && intervalOfMonths < 6) {
      return d3.timeWeek.every(3);
    }

    return null;
  }

  tooltipShow(d, legendText, otherPoint = null) {
    let otherPointText = '';
    const c = d3.select(d3.event.srcElement);
    c.attr('opacity', 1);

    if (otherPoint) {
      const otherC = d3.select('#' + this.uniqueCircleId(otherPoint.legend, otherPoint.x));
      otherC.attr('opacity', 1);

      otherPointText = otherPoint ? `<br><b>${otherPoint.legend} </b>: ${otherPoint.y.toFixed(2)}` : '';
    }

    this.tip.transition()
      .duration(200)
      .style('opacity', .9);

    this.tip.html(
      () => {
        const d0 = d3.timeFormat('%d-%b-%Y')(d.x);

        return `<b>Date</b>: ${d0}<br> <b>${legendText}</b>: ${d.y.toFixed(2)}
          ${otherPointText} `;
      })
      .style('display', 'inline-block')
      .style('left', (d3.event.pageX + 10) + 'px')
      .style('top', (d3.event.pageY - 5) + 'px');
    }

  tooltipOff(d, otherPoint = null) {
    if (d3.event && d3.event.srcElement) {
      const c = d3.select(d3.event.srcElement);
      c.attr('opacity', 0);

      if (otherPoint) {
        const otherC = d3.select('#' + this.uniqueCircleId(otherPoint.legend, otherPoint.x));
        otherC.attr('opacity', 0);
      }
      this.tip.transition()
        .duration(500)
        .style('opacity', 0);
    }
  }

  ngOnDestroy() {
    this.tooltipOff(null);
    d3.select('#t' + this.id).remove();
    window.removeEventListener("resize", this.drawChart.bind(this));
  }


}
