<ert-my-study-info *ngIf="studyInfoData" [studyInfoData]="studyInfoData"></ert-my-study-info>
<!-- <ert-stat-block *ngIf = "statData && alertPermCheck" class="study__stat-block" [statData]="statData" [studyStartDate] = "studyStartDate"></ert-stat-block> -->
<div class="overview-score__container" *ngIf="!loadingSites"
  [ngStyle]="{'flex-direction': breakpoint === 1 ? 'column': 'row'}" (window:resize)="onResize($event)">
  <mat-card class="card-half left">
    <ert-score-overview class="study-comp" kritype="" [data]="bucketsScoreHistory"
      yAxisLabel="Score"></ert-score-overview>
  </mat-card>
  <mat-card class="card-half right">
    <div class="chart-title content"><h4> {{'Sites by Score'}}</h4>  </div>
    <ert-scatter-plot-chart class="portfolio-scatterchart" [data]="sitesByScoreData" *ngIf="sitesByScoreData && sitesByScoreData.length > 0; else noDataToShowChart" xAxisLabel="Quality Score"
      yAxisLabel="Compliance Score"></ert-scatter-plot-chart>
  </mat-card>
</div>

<div class="overview-score__container" *ngIf="!loadingSites" [ngStyle]="{'flex-direction': breakpoint === 1 ? 'column': 'row'}">
  <mat-card class="card-half left">
    <div>
      <div class="title-label content"><h4> {{'Metrics By Score'}}</h4>  </div>
      <div class="metric-label">
          <mat-form-field>
            <mat-label>Metric Category</mat-label>
            <mat-select matNativeControl (selectionChange)="onSelectCategory($event.value)">
              <mat-option value="all"> All </mat-option>
              <mat-option *ngFor="let category of metricCategories" value={{category.id}}>
                {{category.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
      </div>
    </div>
    <div class="metric-bar-chart" style="float:center;">
      <ert-metric-by-score [data]="metricbyScoreData" *ngIf="metricbyScoreData && metricbyScoreData.length > 0; else noDataToShowChart">
      </ert-metric-by-score>
    </div>
  </mat-card>


  <mat-card class="card-half right">
    <div class="row map-container" *ngIf="!loadingSites">
      <div class="col-lg-6">
        <h4 class="location-text">Active Events By Location</h4>
          <div class="locations-container">
            <ert-sitelocations [sites]="sites"></ert-sitelocations>
          </div>
      </div>
    </div>
  </mat-card>
</div>

<ng-template #noDataToShowChart>
  <div class="es-status-sign ng-star-inserted">
    <span class="es-icon es-icon-magnifier"></span>
    <span class="text">No Data to Display</span>
  </div>
</ng-template>

<!-- <div layout="row" layout-wrap>
  <mat-grid-list class="grid-container" [cols]="breakpoint" [rowHeight]="metricsGridHeight|| '200px'" [gutterSize]="'10px'"
    (window:resize)="onResize($event)">
    <mat-grid-tile >
      <mat-card class="metric-data-card flex-column-center" *ngIf="!loadingSites">
        <h4 class="metric-data-title">{{metricsConfig.compliance.title}}</h4>
        <ert-bar-chart #barChart1 *ngIf="metricsConfig.compliance.data.length; else noDataToShow"
          [barColor]="metricsConfig.compliance.barColor"
          [data]="metricsConfig.compliance.data">
        </ert-bar-chart>
      </mat-card>
    </mat-grid-tile>
    <mat-grid-tile #barChart2>
      <mat-card class="metric-data-card flex-column-center" *ngIf="!loadingSites">
        <h4 class="metric-data-title">{{metricsConfig.quality.title}}</h4>
        <ert-bar-chart *ngIf="metricsConfig.quality.data.length; else noDataToShow"
          [barColor]="metricsConfig.quality.barColor"
          [data]="metricsConfig.quality.data">
        </ert-bar-chart>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
</div> -->

<ng-template #noDataToShow>
  <div class="stats-item es-status-sign metric-data"
    *ngIf="!metricsConfig.quality.showData || !metricsConfig.compliance.showData">
    <span class="es-icon es-icon-magnifier"></span>
    <span class="text">No Data to Display</span>
  </div>
</ng-template>


<div class="row map-container" *ngIf="!loadingSites">
  <div class="col-lg-6">
    <mat-card>

      <mat-spinner *ngIf="dataCheck === 'unchecked'" diameter="50"></mat-spinner>
      <h4 class="">Site Listing</h4>
      <div class="categories-table">
        <ert-kri-category-table
          [dataSource]="studiesTableSource"
          [displayedColumns]="displayedColumns"
          [detailsDataColumns]="detailsDataColumns"
          [view]="views.STUDY_VIEW">
        </ert-kri-category-table>
      </div>
      <ert-no-data *ngIf="dataCheck === 'invalid'"></ert-no-data>
    </mat-card>
  </div>
</div>

<div *ngIf="!loadingSites">
  <ert-metric-study-event-table></ert-metric-study-event-table>
</div>
