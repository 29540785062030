<div class="dialog-title">
  <span class="es-icon es-icon-close" (click)="closeModal(true)"></span>
  <h4 class="metric-info-title">
    {{data.metricName}}
  </h4>
</div>
<div class="dialog-content">
  <div class="row">
    <div class="col-lg-3 keys">
      <h5 class="metric-key">Metric Description:</h5>
    </div>
    <div class="col-lg-9 values">
      <h5 class="metric-value">{{data.metricDescription}}</h5>
    </div>
  </div>
  <div class="row">
    <!--    threshold category , min, max, operator, weight KEYS-->
    <div class="col-lg-3 keys">
      <h5 class="metric-key" *ngIf="data.hasOwnProperty('category')">Metric Category: </h5>
      <h5 class="metric-key" *ngIf="data.hasOwnProperty('thresholdMin')">Metric Min/Max value: </h5>
      <h5 class="metric-key" *ngIf="data.hasOwnProperty('thresholdValue')">Metric Threshold: </h5>
      <h5 class="metric-key" *ngIf="data.hasOwnProperty('metricWeight')">Metric Weight: </h5>
    </div>
      <!--    threshold category , min, max, operator, weight VALUES-->
    <div class="col-lg-3 values">
      <h5 class="metric-value" *ngIf="data.category">{{data.category | titlecase}} </h5>
      <h5 class="metric-value" *ngIf="data.hasOwnProperty('thresholdMin')">{{formattedData.thresholdMin}}-{{formattedData.thresholdMax}}
        {{data.units}}
      </h5>
      <h5 class="metric-value" *ngIf="data.hasOwnProperty('thresholdValue')">
        {{thresholdOpCategory[data.thresholdOperator]}}
        {{formattedData.thresholdValue}} {{data.units}}</h5>
      <h5 class="metric-value">{{data.metricWeight}} </h5>
    </div>

    <!--  threshold value , score KEYS-->
    <div class="col-lg-3 keys">
      <h5 class="metric-key" *ngIf="data.hasOwnProperty('metricValue')">Metric Value: </h5>
      <h5 class="metric-key" *ngIf="data.hasOwnProperty('score')">Metric Score: </h5>
    </div>
    <!--    threshold value , score VALUES-->
    <div class="col-lg-3">
      <h5 class="metric-values" *ngIf="data.hasOwnProperty('metricValue')">{{data.metricValue}}</h5>
      <h5 class="metric-values" *ngIf="data.hasOwnProperty('score')">
        <span *ngIf="data.score || data.score === 0" class="metric-info-score"
          [ngStyle]="{'background': getScoreColor(data.score)}"> {{data.score}}
        </span>
        <span *ngIf="data.score !== 0 && data.score== null" class="metric-info-score"
          [ngStyle]="{'background': getScoreColor(null), 'color': '#000'}">
        </span>
      </h5>
    </div>
  </div>
</div>
