import { initializeToolbarState, ToolbarState } from './toolbar.state';
import { ToolbarActions, ToolbarActionTypes } from './toolbar.actions';

const defaultState: ToolbarState = initializeToolbarState();

export function ToolbarReducer(
  state: ToolbarState = defaultState,
  action: ToolbarActions
): ToolbarState {
  switch (action.type) {
    case ToolbarActionTypes.UPDATE_TOOLBAR: {
      return {
        ...defaultState,
        ...action.payload
      };
    }

    default: {
      return state;
    }
  }
}
