import { Observable } from 'rxjs';

export interface User {
  userId: number;
  firstName: string;
  lastName: string;
  // convenience field we create
  fullName?: string;
  prefix?: string;
  suffix?: string;
  email: string;
  isApplicationAdmin: boolean;
  active: boolean;
  status: string;
  roles?: any[]; // change from any to Role
}

export function formatUser(user: User): User {
  user.fullName = `${user.firstName} ${user.lastName}`;
  return user;
}

export interface UserInvitation {
  firstName: string;
  lastName: string;
  email: string;
  prefix?: string;
  suffix?: string;
  srcUrl?: string;
}

export type AddUserFunc =  (invitation: UserInvitation) => Observable<any>;
