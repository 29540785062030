import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ert-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss']
})
export class NoDataComponent implements OnInit {
  @Input() message = 'No Data to display'; // Default value. Pass it in for custom message

  constructor() { }

  ngOnInit() {
  }

}
