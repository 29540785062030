import { Component, OnInit, ViewChild, ElementRef, Input, ViewEncapsulation, OnDestroy } from '@angular/core';
import * as d3 from 'd3';
import { LineChartOptions, Point } from './../ms-line-chart/line-chart.options';
import { Color } from '@app/shared/services/utils.service';
import { D3UtilsService } from '@app/shared/services/d3/d3-utils.service';
import { monthDiff } from '@app/shared/helpers/util';
import { parse } from 'querystring';

const axisSx = 40;
const axisSy = 25;
const legendPadding = 3;
const legendWidthPct = 0.85;
const legendHeightPct = 0.15;

const graphColorOptions = {
  'yellowValue': '75',
  'orangeValue': '50',
  'RedValue': '25',
  'Operator': '<',
};

const CurveType = [
  d3.curveLinear,
  d3.curveMonotoneX,
  d3.curveStepAfter,
  d3.curveStepBefore
];

const OptionDefaults: LineChartOptions = {
  lineWidth: 2,
  dashPattern: '',  // no pattern
  color: 'black',
  curveType: 1,   // see LineType
  dotRadius: 0,  // 0 == no dots
  data: []
};

@Component({
  selector: 'ert-line-color-chart',
  templateUrl: './line-color-chart.component.html',
  styleUrls: ['./line-color-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class LineColorChartComponent implements OnInit, OnDestroy {
  static idGen = 0;

  @ViewChild('lineColorChart', { static: true }) private chartContainer: ElementRef;
  @Input() private data: LineChartOptions[];
  @Input() private height: number;
  @Input() private yAxisLabel: string;

  private margin: any = { top: 2, bottom: 2, left: 2, right: 2 };
  private width: number;
  private firstDraw: boolean;
  private allPts = [];
  private svg: any;
  private legendSvg: any;
  private tip: any;
  private id: string;
  private xValues: any[] = [];
  private projectedDates = [];
  private units: string;
  private multiLineProjectionCount = 0;

  constructor() {
    this.firstDraw = true;
    this.id = `line-color-chart-id-${(LineColorChartComponent.idGen++).toString()}`;
  }

  ngOnInit() {
    if (this.data) {
      this.data.forEach(e => {
        this.loadOptions(e);
      });
      this.data.forEach(e => {
        this.allPts = this.allPts.concat(e.data);
        this.units = e.units;
        this.multiLineProjectionCount += (e.showProjection) ? 1 : 0;
        e.data.forEach(d => this.xValues.push(d.x));
      });

      if (this.multiLineProjectionCount > 0) {
        // Get array with dates spread over 30 days
        this.projectedDates = D3UtilsService.projectedDatesArray(31, this.xValues);
        this.xValues = this.xValues.concat(this.projectedDates);
      }
      this.xValues = [...new Set(this.xValues)];

      this.tip = d3.select('body').append('div')
        .attr('id', 't' + this.id)
        .attr('class', 'chartToolTip')
        .style('opacity', 0);
      //
      this.drawChart();
      this.firstDraw = false;
    }
  }

  ngOnChanges(changes) {
    window.addEventListener("resize", this.drawChart.bind(this));
  }


  loadOptions(opt) {
    for (const key in OptionDefaults) {
      if (opt[key] === undefined) {
        opt[key] = OptionDefaults[key];
      }
    }
  }

  drawGridLines(width, height, xS, yS) {
    function pathBandData(yStart, yEnd) {
      // check to see if band falls outside of the y-Axis scale, if so, return nothing
      if (yStart > yS.domain()[1] && yEnd > yS.domain()[1]) {
        return null;
      } else // otherwise, draw the banding box. still check we don't go beyond the y-Axis scale at each point.
      {
        return 'M' + xS(xS.domain()[0]) + ',' + (yStart > yS.domain()[1] ? yS(yS.domain()[1]) : yS(yStart)) +
          'L' + xS(xS.domain()[0]) + ',' + (yEnd > yS.domain()[1] ? yS(yS.domain()[1]) : yS(yEnd)) +
          'L' + xS(xS.domain()[1]) + ',' + (yEnd > yS.domain()[1] ? yS(yS.domain()[1]) : yS(yEnd)) +
          'L' + xS(xS.domain()[1]) + ',' + (yStart > yS.domain()[1] ? yS(yS.domain()[1]) : yS(yStart));
      }
    }

    const GreenMaxValue = yS.domain()[1];
    const EndValue = yS.domain()[0];

    this.svg.append('g').append('path') // GREEN Range
      .attr('d', pathBandData(GreenMaxValue, graphColorOptions.yellowValue))
      .style('opacity', 0.2)
      .style('stroke', Color.Green)
      .style('fill', Color.Green);

    this.svg.append('g').append('path')  // YELLOW range
      .attr('d', pathBandData(graphColorOptions.yellowValue, graphColorOptions.orangeValue))
      .style('opacity', 0.2)
      .style('stroke', Color.Yellow)
      .style('fill', Color.Yellow);

    this.svg.append('g').append('path') // ORANGE range
      .attr('d', pathBandData(graphColorOptions.orangeValue, graphColorOptions.RedValue))
      .style('opacity', 0.2)
      .style('stroke', Color.Orange)
      .style('fill', Color.Orange);

    this.svg.append('g').append('path') // RED range
      .attr('d', pathBandData(graphColorOptions.RedValue, EndValue))
      .style('opacity', 0.2)
      .style('stroke', Color.Red)
      .style('fill', Color.Red);

    this.svg.append('g').append('line') // CENTER LINE
      .attr('x1', xS(xS.domain()[0]))
      .attr('x2', xS(xS.domain()[1]))
      .attr('y1', yS((yS.domain()[1] / 2) - 0.25))
      .attr('y2', yS(yS.domain()[1] / 2) - 0.25)
      .attr('stroke', Color.Orange)
      .attr('stroke-dasharray', '4,5')
      .attr('stroke-width', '2.5');
  }

  drawAxi(height, xS, yS, numOfXTicks) {
    this.svg.append('g')
      .attr('class', 'ms-axis')
      .attr('transform', 'translate(0,' + (height - axisSy) + ')')
      .call(d3.axisBottom(xS)
        .ticks(numOfXTicks)
        .tickFormat(d => d3.timeFormat('%d-%b-%y')(d))
      ).selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", function (d) {
        return "rotate(-45)";
      });

    this.svg.append('g')
      .attr('class', 'ms-axis')
      .attr('transform', `translate(${axisSx}, 0)`)
      .call(d3.axisLeft(yS).ticks(3));

    this.svg.append('text')
      .attr('y', 0)
      .attr('x', 0)
      .attr('class', 'axis-label')
      .attr('alignment-baseline', 'baseline')
      .attr('transform', 'translate(10,' + (height / 2) + '), rotate(-90)')
      .style('text-anchor', 'middle')
      .text(this.yAxisLabel);
  }

  drawLegend(width, height) {
    // parametric legend
    const tWidth = (width - axisSx) * legendWidthPct;
    const bWidth = Math.floor(tWidth / this.data.length);
    const lx = (width - tWidth) / 2;
    const l_height = height - 2 * legendPadding;
    const lSide = l_height - l_height / 2;
    const ox = lx + axisSx + lSide + l_height / 4;

    // background
    this.legendSvg.selectAll('.background')
      .data([0])
      .enter().append('rect')
      .attr('x', lx)
      .attr('y', 0) // legendPadding + 2
      .attr('width', tWidth)
      .attr('height', l_height) // - legendPadding)
      .attr('class', 'legend-box')
      .attr('fill', '#f8f8f8');

    // legend color boxes
    this.legendSvg.selectAll('.legend')
      .data(this.data)
      .enter()
      .append('line') //making a line for legend
      .attr('x1', (d, i) => ox + i * bWidth - (lSide / 2))
      .attr('x2', (d, i) => ox + i * bWidth + (lSide + 1))
      .attr('y1', l_height / 2)
      .attr('y2', l_height / 2)
      .attr('stroke', d => d.color)
      .attr('stroke-width', '3')
      .style('fill', d => d.color)
      .on('mouseover', (d, i) => {
        d3.select('#s' + this.id + i).transition()
          .style('stroke', d.color)
          .style('stroke-width', 2 * this.data[i].lineWidth);
      })
      .on('mouseout', (d, i) => {
        d3.select('#s' + this.id + i).transition()
          .style('stroke', this.data[i].color)
          .style('stroke-width', this.data[i].lineWidth);
      }).filter(d => d.dashPattern)
      .attr('stroke-dasharray', d => d.dashPattern)
      .attr('stroke', d => d.color);

    this.legendSvg.selectAll('.legendText')
      .data(this.data)
      .enter().append('text')
      .attr('class', 'legend-text')
      .attr('x', (d, i) => lSide + ox + i * bWidth + 4)
      .attr('y', (d, i) => l_height / 2)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text((d, i) => d.legend);
  }

  drawLines(xS, yS) {
    let projection = [];
    this.data.forEach((e, i) => {
      const line = d3.line()
        .defined(d => d.y >= yS.domain()[0] && d.y <= yS.domain()[1])
        .x(d => xS((d as any).x))
        .y(d => yS((d as any).y))
        .curve(CurveType[e.curveType]);

      if (e.data.length) {
        this.svg.append('path')
          .datum(e.data)
          .attr('fill', 'none')
          .attr('stroke', e.color)
          .attr('stroke-width', 0)
          .attr('d', line)
          .attr('id', 's' + this.id + i)
          .transition()
          .delay((d, k) => k * 100)
          .attr('stroke-width', e.lineWidth)
          .attr('stroke-dasharray', e.dashPattern);

        if (e.showProjection) {
          // generate future data
          projection = D3UtilsService.getProjectionData(e.data, this.projectedDates);

          // projected line
          this.svg.append('path')
            .datum(projection)
            .attr('fill', 'none')
            .attr('stroke', e.color)
            .attr('stroke-width', 0)
            .attr('d', line)
            .transition()
            .delay((d, k) => k * 100)
            .attr('stroke-width', e.lineWidth)
            .attr('stroke-dasharray', '5,5');
        }

        // tooltip
        this.svg.selectAll('dot')
          .data(e.data.concat(projection))
          .enter().append('circle')
          .attr('fill', 'white')
          .attr('stroke', e.color)
          .attr('stroke-width', e.lineWidth)
          .attr('r', 6)
          .attr('cx', d => xS(d.x))
          .attr('cy', d => yS(d.y))
          .attr('opacity', 0)
          .on('mouseover', d => this.tooltipShow(d, e.legend))
          .on('mouseout', d => this.tooltipOff(d));
      }
    });
  }

  drawChart() {

    if(!this.data || this.data.length == 0)
    return;

    const element = this.chartContainer.nativeElement;
    this.width = element.offsetWidth - this.margin.left - this.margin.right;
    const tHeight = this.height - this.margin.top - this.margin.bottom;
    const lHeight = Math.round(tHeight * legendHeightPct) - 2;
    const height = tHeight - lHeight;
    if (!this.firstDraw) {
      d3.select(element).selectAll('svg').remove();
      this.svg = null;
      this.legendSvg = null;
    }

    const xS = d3.scaleTime().range([axisSx, this.width - axisSx]);
    const yS = d3.scaleLinear().range([height - axisSy, 10]);

    xS.domain(d3.extent(this.xValues)).nice();

    if (this.units === 'percentage') {
      yS.domain(d3.extent([0, 100])).nice();
    } else {
      yS.domain(d3.extent(this.allPts, d => +(d as any).y));
    }

    // create legend svg
    this.legendSvg = d3.select(element).append('svg')
      .attr('width', this.width)
      .attr('height', lHeight)
      .append('g')
      // .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);
      .attr('transform', `translate(0, 0)`);
    // create chart svg
    this.svg = d3.select(element).append('svg')
      .attr('width', this.width)
      .attr('height', height + 25)
      .append('g')
      // .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);
      .attr('transform', `translate(0,0)`);

    const numOfXTicks = this.defineXTicks();

    this.drawGridLines(this.width, height, xS, yS);
    this.drawAxi(height, xS, yS, numOfXTicks);
    this.drawLegend(this.width, lHeight);
    this.drawLines(xS, yS);
  }

  defineXTicks() {
    const starDate = new Date(this.xValues[0]);
    const endDate = new Date(this.xValues[this.xValues.length - 1]);
    const intervalOfMonths = monthDiff(starDate, endDate);

    if (intervalOfMonths < 3) {
      return d3.timeWeek.every(1);
    }
    if (intervalOfMonths > 3 && intervalOfMonths < 6) {
      return d3.timeWeek.every(2);
    }

    return null;
  }

  tooltipShow(d, legendText) {
    const c = d3.select(d3.event.srcElement);
    c.attr('opacity', 1);
    this.tip.transition()
      .duration(200)
      .style('opacity', .9);
    this.tip.html(
      () => {
        const d0 = d3.timeFormat('%d-%b-%Y')(d.x);
        const metricScoreText = `<b>Metric Score: </b>${d.y}`;
        const metricValueText = (d.metricValue !== null || d.metricValue !== undefined) ? `<b>Metric Value: </b>${this.getMetricValue(d.metricValue, d.units)}` : '';
        return `<b>Date</b>: ${d0} <br> ${metricScoreText} <br> ${metricValueText} `;
      })
      .style('display', 'inline-block')
      .style('left', (d3.event.pageX + 15) + 'px')
      .style('top', (d3.event.pageY - 9) + 'px');
  }

  getMetricValue(value, units) {
    if (units === '%') {
      return +(parseFloat(value) * 100).toFixed(2) + units;
    } else {
      return value + ' ' + units;
    }
  }

  tooltipOff(d) {
    if (d3.event && d3.event.srcElement) {
      const c = d3.select(d3.event.srcElement);
      c.attr('opacity', 0);
      this.tip.transition()
        .duration(500)
        .style('opacity', 0);
    }
  }

  ngOnDestroy() {
    this.tooltipOff(null);
    d3.select('#t' + this.id).remove();
    window.removeEventListener("resize", this.drawChart.bind(this));
  }

}
