<mat-card class="stats-container row" *ngIf="dataModel && dataModel?.site">
  <!-- <mat-card-header>
    <div class="card-title">
        <h4>SITE INFO</h4>
    </div>
  </mat-card-header> -->
  <mat-card-content class="site_content">
    <div class="site_info_content">
      <div class="site_info-block">
        <p class="site_block_heading">Study Name: </p>
        <p class="site_block_content"> {{dataModel?.site?.studyName}}</p>
      </div>
      <div class="site_info-block-border">
        <p class="site_block_heading">Site #: </p>
        <p class="site_block_content"> {{dataModel?.site?.siteNo}}</p>
      </div>
      <div class="site_info-block">
        <p class="site_block_heading">Protocol #: </p>
        <p class="site_block_content"> {{dataModel?.site?.protocolNumber}}</p>
      </div>
      <div class="site_info-block-border">
        <p class="site_block_heading">PI Name: </p>
        <p class="site_block_content"> {{dataModel?.site?.investigator}}</p>
      </div>
    </div>
  </mat-card-content>
</mat-card>