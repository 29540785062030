export interface BreadCrumbState {
  crumbs: BreadCrumb[];
}

export interface BreadCrumb {
  text: string;
  url: string;
  shortText?: string;
}

export const initializeBreadCrumbState = function() {
  return {
    crumbs: [],
  };
};
