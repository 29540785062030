<mat-card class="events__table-card">
    <div class="site_events_table__filter">
        <div class="table-heading-label"> <h4 class="">Active Events</h4> </div>
         <mat-form-field class="events__table-control">
             <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
         </mat-form-field>
         <mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[10, 20, 50, 100]"
             class="events__table-control paginator">
         </mat-paginator>
     </div>

     <div class="events-table">
        <!-- Creating the Table -->
        <mat-table #table class="ert-table" [dataSource]="dataSource" matSort>

            <!-- Event Description column -->
            <ng-container matColumnDef="metricName">
                <mat-header-cell style="flex: 0 0 20%" *matHeaderCellDef mat-sort-header>
                    Event Description
                </mat-header-cell>
                <mat-cell style="flex: 0 0 20%" *matCellDef="let event" 
                (click)="selectSiteMetric(event.metricId)"> 
                <p *ngIf="!metricId"><a>{{displayDescription(event.metricName, event.thresholdOperator, event.thresholdValue, event.units)}}</a></p>
                <p *ngIf="metricId">{{displayDescription(event.metricName, event.thresholdOperator, event.thresholdValue, event.units)}}</p>
                </mat-cell>
            </ng-container>

            <!-- suggested Action column -->
            <ng-container matColumnDef="suggestedAction">
                <mat-header-cell style="flex: 0 0 30%" *matHeaderCellDef>
                    Suggested Action
                </mat-header-cell>
                <mat-cell style="flex: 0 0 30%" *matCellDef="let event">
                    {{event.suggestedAction}}
                    <ert-no-value *ngIf="!event.suggestedAction"></ert-no-value>
                </mat-cell>
            </ng-container>  
            
            <!-- Category column -->
            <ng-container matColumnDef="category">
                <mat-header-cell style="flex: 0 0 15%" *matHeaderCellDef mat-sort-header>
                    Category
                </mat-header-cell>
                <mat-cell style="flex: 0 0 15%" *matCellDef="let event">
                    {{thresholdCategory[event.category]}}
                </mat-cell>
            </ng-container>            

            <!-- Event begin Date column -->
            <ng-container matColumnDef="beginDate">
                <mat-header-cell style="flex: 0 0 15%; padding-right: 15px; justify-content: center;" *matHeaderCellDef mat-sort-header>
                    Event Begin Date
                </mat-header-cell>
                <mat-cell style="flex: 0 0 15%; padding-right: 15px; justify-content: center;" *matCellDef="let event"> {{event.beginDate | displayDate}}
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

        </mat-table>
        <ert-no-data *ngIf="noData" message="No data to display"></ert-no-data>
    </div>
</mat-card>