/**
 *
 * ADD REUSABLE UTIL HELPER METHODS HERE
 * @export
 */

 // return if object is empty
export function isEmptyObject(ob) {
  return !(Object.keys(ob).length > 0);
}

export function getNotEmptyKeysObjectArray(obj, field){
  Object.keys(obj).filter(field => {
    if (obj[field].length) { return field; }
  });
}


export function isFloat(num) {
  return typeof num === 'number' && !Number.isInteger(num);
}

export function onlyNumbers(event) {
  const allowedRegex = /[0-9\/]/g;

  if (!event.key.match(allowedRegex)) {
    event.preventDefault();
  }
}

export function isObject(value) {
  return value && typeof value === 'object' && value.constructor === Object;
}

export function isDate(str) {
  if (isNaN(str) && !isNaN(Date.parse(str))) {
    return true;
  }
  return false;
}


export function monthDiff(startDate, endDate) {
  let months;
  months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
  months += endDate.getMonth() - startDate.getMonth();
  return months;
}

export function ArrayEquals (arr1, arr2) {
  arr1 = arr1.sort();
  arr2 = arr2.sort();
  return (
    arr1.length === arr2.length &&
    arr1.every((value, index) => value === arr2[index])
  );
}


export function isPercentageDataType(type: string = ''): boolean {
  return type.toLowerCase() === 'percentage';
}
