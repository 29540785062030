<mat-card class="login__card" [ngClass]="isRefreshSuccess ? 'hide-login-card': null">
  <h3 class="h3">Please select Customer</h3>

  <div style="text-align: center;" *ngIf="!customerList.length || selected || isRefreshStarted">
    <ng-container [ngTemplateOutlet]="loginSelectText"></ng-container>
    <span *ngIf="!this.isJobFailed && !this.isTimeLimitExceeded"><svg class="ring" viewBox="25 25 50 50" stroke-width="5"><circle cx="50" cy="50" r="20" /></svg></span>
  </div>

  <div class="select-site__container" *ngIf="multiSelect && !selected">
  <mat-card-content >
    <ng-container [ngTemplateOutlet]="loginSelectText"></ng-container>
    <div class="fieldset-item">
      <!-- <label for="customer-list-pos-top">Select Customer</label> -->
      <input list="customer-list-pos-top-list" id="customer-list-pos-top" placeholder="Select Customer" (change)="onFilterOptionSelected($event)"
      (input) = "onFilterOptionSelected($event)"
      (key) = "onFilterOptionSelected($event)"
      >
      <datalist id="customer-list-pos-top-list">
        <option *ngFor="let customer of filteredCustomerList | async" [value]="customer.navigatorOrgName">
          {{customer.clientName === null ? customer.navigatorOrgName : customer.clientName}}
       </option>
      </datalist>
    </div>
  </mat-card-content>

    <div class="login__actions select">
      <!-- <button mat-raised-button (click)="onCancelClick()"> Cancel </button> -->
      <button class="button cursor-pointer" (click)="onSelectClick()"
              [disabled]="!selectedCustomer">Select
      </button>
    </div>
  </div>
  <!-- removed until we have forgot password functionality -->
  <!-- <button mat-button>Forgot Password</button> -->
</mat-card>

<ng-template #loginSelectText>
    <span style="margin-top: 30px;">
        <p class="select-multiple-text">
          <span *ngIf = "!isRefreshStarted && customerList.length">
            You have access to multiple customer sites
          </span>
          <span *ngIf = "isRefreshStarted && !this.isTimeLimitExceeded && !this.isJobFailed">
            This first time setup may take up to a minute or two. {{progress}} % completed.
          </span>
          <span *ngIf = "this.isTimeLimitExceeded && !this.isJobFailed">
            Initial setup is taking longer than expected.  Please contact Customer Care.
          </span>
          <span *ngIf = "!this.isTimeLimitExceeded && this.isJobFailed">
            Initial setup has failed.  Please contact Customer Care.
          </span>
        </p>
      </span>
</ng-template>
