import { Action } from '@ngrx/store';

export enum BreadCrumbsActionTypes {
	UPDATE_ADD = '[Breadcrumbs] UPDATE_ADD',
	UPDATE_REMOVE = '[Breadcrumbs] UPDATE_REMOVE',
	UPDATE_SET = '[Breadcrumbs] UPDATE_SET',
}

export class AddBreadCrumbs implements Action {
	readonly type = BreadCrumbsActionTypes.UPDATE_ADD;
	constructor(public payload: any) { }
}

export class RemoveBreadCrumbs implements Action {
	readonly type = BreadCrumbsActionTypes.UPDATE_REMOVE;
	constructor(public payload: any) { }
}

export class SetBreadCrumbs implements Action {
	readonly type = BreadCrumbsActionTypes.UPDATE_SET;
	constructor(public payload: any) { }
}


export type BreadCrumbsActions =
	 AddBreadCrumbs |
	 RemoveBreadCrumbs |
	 SetBreadCrumbs
;
