import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

/**
 * Notification component uses Angular Snackbar material
 *
 * @export
 * @class NotificationComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'ert-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})

export class NotificationComponent implements OnInit {

  constructor(
    public snackBar: MatSnackBar,
    public snackBarRef: MatSnackBarRef<NotificationComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  // openSnackBar(message: string, action: string) {
  //   this.snackBar.open(message, action);
  // }

  public dismissSnackbar(): void {
    this.snackBarRef.dismiss();
  }
}
