import { Injectable } from '@angular/core';

const APP_PREFIX = '';

@Injectable()
export class SessionStorageService {

  constructor() { }

  setItem(key: string, value: any) {
    sessionStorage.setItem(`${APP_PREFIX}${key}`, JSON.stringify(value));
  }

  getItem(key: string) {
    let result;
    try {
      result = JSON.parse(sessionStorage.getItem(`${APP_PREFIX}${key}`));
    } catch(e) {
      console.error(`JSON Parse error for the key ${key} and value ${sessionStorage.getItem(`${APP_PREFIX}${key}`)}`);
    }
    return result;
  }
}
