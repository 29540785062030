import { Action } from '@ngrx/store';

export enum SidebarActionTypes {
  SHOW_SIDEBAR = '[Sidebar] SHOW_SIDEBAR',
  HIDE_SIDEBAR = '[Sidebar] HIDE_SIDEBAR'
}

export class ShowSidebar implements Action {
  readonly type = SidebarActionTypes.SHOW_SIDEBAR;
}

export class HideSidebar implements Action {
  readonly type = SidebarActionTypes.HIDE_SIDEBAR;
}

export type SidebarActions = ShowSidebar | HideSidebar;
