import { KriCategoryTableService } from './../../shared/kri-category-table/kri-category-table.service';
import { PermissionService } from '@shared/services/permisssion.service';
import { isEmptyObject } from '@shared/helpers/util';
import { SetBreadCrumbs } from './../../core/store/breadcrumbs/breadcrumbs.actions';
import { UpdateToolbar } from './../../core/store/toolbar/toolbar.actions';
import { StudyService } from './../../shared/services/study.service';
import { Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { cross } from 'd3-array';
import { KriViews } from '@app/shared/kri-category-table/enum.kri-categories';
@Component({
  selector: 'ert-cross-site-portfolio',
  templateUrl: './cross-site-portfolio.component.html',
  styleUrls: ['./cross-site-portfolio.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CrossSitePortfolioComponent implements OnInit {
  toolbar: any;
  clientName: string;
  displayedColumns: string[] = [];
  dataTableStudies: any[] = [];
  detailsDataColumns = [];
  alertPermCheck: Boolean = false;
  views = KriViews;
  enrollmentLength = 0;
  complianceLength = 0;
  safetyLength = 0;
  qualityLength = 0;
  dataCheck = 'unchecked';

  constructor(
    private router: Router,
    private store: Store<any>,
    private studyService: StudyService,
    private kriCategoryService: KriCategoryTableService,
    private permService: PermissionService,
  ) {
    this.toolbar = {
      title: 'Portfolio for: ',
      infoText: this.clientName,
      dropdownMenu: {
        label: 'Select Study',
        propertyName: 'studyName',
        displayItem(study?) {
          return study ? study.name : undefined;
        },
        onItemSelected(study) {
          const studyId = encodeURIComponent(study.srcStudyId);

          this.router.navigateByUrl(`/dashboard/study/${studyId}`);
        },
      }
    };
  }

  ngOnInit() {
    const { CROSSITE_VIEW } = this.views;
    const dataTable = [];
    this.store.dispatch(new SetBreadCrumbs({
      crumbs: [{ text: 'Portfolio', url: sessionStorage.getItem('HOMEPAGE') }],
    }));
    this.studyService.getCrossSiteBenchmark().subscribe(crossSite => {
      if (crossSite.length > 0) { this.dataCheck = 'valid'; }
      else this.dataCheck = 'invalid';
      // Adding Rank to sites
      var sites = [];
      for(var i in crossSite) {
        sites.push(...crossSite[i].sites)
      }
      sites.sort((a,b) => {return b.compositeScore - a.compositeScore});
      var rank = 1;
      for(var i in sites) {
        sites[i].rank = rank;
        rank++;
      }
      crossSite.forEach(ele => {
        const studyName = ele.studyName;
        ele.sites.forEach(element => {
          dataTable.push((this.kriCategoryService.formatDataTable(CROSSITE_VIEW, element, studyName)));
          this.getKriLength(element);
          dataTable.forEach(element => {
            element.enrollmentLength = this.enrollmentLength;
            element.complianceLength = this.complianceLength;
            element.safetyLength = this.safetyLength;
            element.qualityLength = this.qualityLength;
      });
          // this.studiesTableSource = study[0].sites.map(site => this.kriCategoryService.formatDataTable(KriViews.Crossite, element));
          this.detailsDataColumns = this.kriCategoryService.setDetailsDataColumnsByCat(ele.sites, this.detailsDataColumns);
        });
      });
      this.dataTableStudies = dataTable;
      this.displayedColumns.push(
        'studyName',
        'site',
        'pi',
        'subjects',
        'trend',
        'rank',
      );
      if(this.enrollmentLength > 0) this.displayedColumns.push('enrollment');
      if(this.complianceLength > 0) this.displayedColumns.push('compliance');
      if(this.safetyLength > 0) this.displayedColumns.push('safety');
      if(this.qualityLength > 0) this.displayedColumns.push('quality');
    });
    const editStudyPermission = this.permService.getPermission('editStudy');
    this.alertPermCheck = (editStudyPermission || editStudyPermission === 'all') || this.permService.hasRole('vp');
  }

  getKriLength(data) {
    if(data.calcTypeBucketExtraData) {
      data.calcTypeBucketExtraData.forEach(element => {
        if(element && element.calcTypeBucketName === 'enrollment') {
          this.enrollmentLength = Math.max(this.enrollmentLength, element.calcTypes.length);
        }
        if(element && element.calcTypeBucketName === 'compliance') {
          this.complianceLength = Math.max(this.complianceLength, element.calcTypes.length);
        }
        if(element && element.calcTypeBucketName === 'safety') {
          this.safetyLength = Math.max(this.safetyLength, element.calcTypes.length);
        }
        if(element && element.calcTypeBucketName === 'quality') {
          this.qualityLength = Math.max(this.qualityLength, element.calcTypes.length);
        }
      });
    }
  }
}
