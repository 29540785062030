import { initializeTabswitchState, TabSwitchState } from './tabswitch.state';
import { TabswitchActions, TabswitchActionTypes } from './tabswitch.actions';

const defaultState: TabSwitchState = initializeTabswitchState();
export function TabSwitchReducer(state: TabSwitchState =  defaultState, action: TabswitchActions): TabSwitchState {

  switch (action.type) {

    case TabswitchActionTypes.SWITCH_TAB: {
      return setTabSwitch(state, action.payload);
    }
    default: {
      return state;
    }
  }
  function setTabSwitch(state, activeTab) {
    return { ...state, activeTab };
  }
}
