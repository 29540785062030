import { ToolbarState } from './toolbar/toolbar.state';
import { Customer } from '@app/shared/models/customer.model';
import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { CustomerState } from './customer/customer.state';
import { CustomerReducer } from './customer/customer.reducer';
import { AuthState, AuthReducer } from './auth/auth.reducer';
import { environment } from '@env/environment';
import { SidebarState } from './sidebar/sidebar.state';
import { SidebarReducer } from './sidebar/sidebar.reducer';
import { ToolbarReducer } from './toolbar/toolbar.reducer';
import {  AppState, appReducer } from './app.reducer';
import { BreadCrumbState } from './breadcrumbs/breadcrumbs.state';
import { BreadcrumbsReducer } from './breadcrumbs/breadcrumbs.reducer';
import { VisualInteractionsReducer } from './visual-interactions/visual-interactions.reducer';
import { VisualInteractionsState } from './visual-interactions/visual-interactions.state';
import { VariableReducer } from './variable/variable.reducer';
import { VariableState } from './variable/variable.state';
import { TabSwitchState } from './tabswitch/tabswitch.state';
import { TabSwitchReducer } from './tabswitch/tabswitch.reducer';
import { DataSourceRefreshReducer } from './datasource/datasource.reducer';
import { DataSourceRefreshState } from './datasource/datasource.state';
/// BELOW IMPLEMENTATION
/**
 * Add all new App States here
 */
export interface State {
  customerState: CustomerState;
  authState: AuthState;
  sidebarState: SidebarState;
  toolbarState: ToolbarState;
  appState: AppState;
  breadCrumbState: BreadCrumbState;
  visualInteractionsState: VisualInteractionsState;
  variableStateChanged: VariableState;
  tabSwitchState: TabSwitchState;
  dataSourceState: DataSourceRefreshState;
}

/**
 * Add all new reducers here
 */
export const reducers: ActionReducerMap<State> = {
  customerState: CustomerReducer,
  authState: AuthReducer,
  sidebarState: SidebarReducer,
  toolbarState: ToolbarReducer,
  appState: appReducer,
  breadCrumbState: BreadcrumbsReducer,
  visualInteractionsState: VisualInteractionsReducer,
  variableStateChanged: VariableReducer,
  tabSwitchState: TabSwitchReducer,
  dataSourceState: DataSourceRefreshReducer,
};

// extract state slices
export const getCustomerState = (state: State) => state.customerState;
