import { ButtonOpts } from './button-options.interface';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ert-spinner-button',
  templateUrl: './spinner-button.component.html',
  styleUrls: ['./spinner-button.component.scss']
})
export class SpinnerButtonComponent implements OnInit {
  defaultOptions: ButtonOpts = {
    buttonColor: 'primary',
    raised: true,
    spinnerSize: 18,
    spinnerColor: 'accent',
  };

  @Input() options: ButtonOpts = this.defaultOptions;
  @Input() disabled = false;
  @Input() active = false;
  @Input() buttonText = '';

  constructor() {
  }

  ngOnInit() {
  }

}
