import { VisualInteractionsState, initializeVisualInteractionState, defaultState} from './visual-interactions.state';
import { VisualInteractionsTypes, VisualInteractions } from './visual-interactions.actions';

export const initialState: VisualInteractionsState = initializeVisualInteractionState();

export function VisualInteractionsReducer(state = initialState, action: VisualInteractions): VisualInteractionsState {
  switch (action.type) {
    case VisualInteractionsTypes.SINGLE_SELECT_INTERACTION: {
      return {
        ...state,
        selectedStudiesData: action.payload.studiesData
      };
    }

    case VisualInteractionsTypes.MULTI_SELECT_INTERACTION: {
      return {
        ...state,
        selectedStudiesData: { ...state.selectedStudiesData, ...action.payload.selectedStudiesData},
      };
    }


    case VisualInteractionsTypes.RESET_ALL_INTERACTION: {
      return { ...state, ...defaultState };
    }

    default:
      return state;
  }
}
