import {
  Component, OnInit, OnDestroy, Input, AfterViewInit, ViewChild, ElementRef,
  ViewEncapsulation, EventEmitter, Output
} from '@angular/core';
import * as d3 from 'd3';
import { UtilsService } from '@shared/services/utils.service';

export interface DataItemSpec {
  label?: string;
  key: string;
}

export interface TreemapOptions {
  colors?: string[];
  internalPadding?: number;
  blockLabels?: DataItemSpec[];
  toolTipLabels?: DataItemSpec[];
}

@Component({
  selector: 'ert-treemap-chart',
  templateUrl: './treemap-chart.component.html',
  styleUrls: ['./treemap-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TreemapChartComponent implements OnInit, OnDestroy {
  @ViewChild('chart', {static: true}) chartContainer: ElementRef;
  @Input() private data: any;
  @Input() private height: number;
  @Input() private fontSize: number;
  @Input() private options: TreemapOptions;
  @Input() private valueItem: string;
  @Input() private scoreItem: string;
  @Input() private titleDataItems: DataItemSpec[];
  @Input() private tooltipDataItems: DataItemSpec[];

  @Output() private onClick = new EventEmitter();

  private defaultOptions: TreemapOptions;
  private margin: any = { top: 2, bottom: 2, left: 2, right: 2 };

  private width: number;
  private svg: any;
  private chart: any;
  private firstDraw: boolean;
  private tip: any;
  private fontFactor = 17;
  private textLineSpacing = 14;


  constructor() {
    this.firstDraw = true;
  }

  ngOnInit() {
    if (this.data) {
      // add as children
      this.data = {
        top: 'top',
        children: this.data
      };
      // set minimal height
      if (!this.height) {
        this.height = 100;
      }
      this.draw();
      this.firstDraw = false;
      this.fontSize = +this.fontSize;
      window.addEventListener('resize', this.draw.bind(this));
    }
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.draw.bind(this));
    //this.tooltipOff();
  }

  draw() {
    const element = this.chartContainer.nativeElement;
    this.width = element.offsetWidth - this.margin.left - this.margin.right;
    const height = this.height - this.margin.top - this.margin.bottom;
    if (!this.firstDraw) {
      this.svg.remove();
      this.tip.remove();
    }
    this.tip = d3.select('body').append('div')
      .attr('class', 'treeToolTip')
      .style('opacity', 0);

    const treemapLayout = d3.treemap();
    treemapLayout
      .size([this.width, height])
      .paddingInner(1)
      .tile(d3.treemapBinary);
    // need to sum on block size field
    const root = d3.hierarchy(this.data).sum((d: any) => {return d[this.valueItem]});
    treemapLayout(root);

    this.svg = d3.select(element).append('svg')
      // .attr('class', 'ert-treemap')
      .attr('width', this.width)
      .attr('height', height)
      .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);

    // draw blocks

    const nodes = this.svg.selectAll('.cell')
      .data(root.children);

    nodes
      .enter().append('rect')
      .attr('class', 'cell')
      .attr('x', function (d: any) { return d.x0; })
      .attr('y', function (d: any) { return d.y0; })
      .attr('width', 0)
      .attr('height', 0)
      .style('fill', (d: any) => UtilsService.getColorFromScore(d.data[this.scoreItem]))

      .on('click', d => this.blockClick(d))
      .on('mouseover', d => this.tooltipShow(d))
      .on('mouseout', d => this.tooltipOff())

      .transition()
      .delay((d, i) => i * 5)
      .attr('width', (d: any) => d.x1 - d.x0)
      .attr('height', (d: any) => d.y1 - d.y0);

    // generate text annotations
    const sy = (this.titleDataItems.length * (this.fontSize + this.textLineSpacing)) / 2;
    const dsy = sy / this.titleDataItems.length;
    for (let i = 0; i < this.titleDataItems.length; i++) {
      nodes.enter()
        .append('text')
        .attr('class', 'blockText')
        .attr('x', d => d.x0 + (d.x1 - d.x0) / 2)
        .attr('y', d => d.y0 + (d.y1 - d.y0) / 2 - sy + this.textLineSpacing * this.titleDataItems.length)
        .attr('dy', i * dsy)
        .attr('font-size', this.fontSize)
        .attr('text-anchor', 'middle')
        .text(d => this.getText(d, this.titleDataItems[i].label, this.titleDataItems[i].key, this.fontSize));
    }
  }

  getText(d, label, key, fontSize) {
    // build text
    let str = '';
    if (label) {
      str = label + ': ';
    }
    str += d.data[key];

    const pad = 3;
    const ellipsis = 4;
    const dl = Math.floor((d.x1 - d.x0) / (fontSize / 2)) - pad - ellipsis;
    if (dl < 0) {
      return null;
    } else {
      return (dl < str.length) ? str.slice(0, dl) + ' ...' : str;
    }
  }

  tooltipShow(d) {
    this.svg.style('cursor', 'pointer');
    this.tip.transition()
      .duration(100)
      .style('opacity', .9);
    this.tip
      .html(
        () => {
          // generate tooltip html
          let html = '<table class="toolTip">';
          for (let i = 0; i < this.tooltipDataItems.length; i++) {
            html += '<tr align="left">';
            if (!this.tooltipDataItems[i].label) {
              html += '<td class="toolTip" colspan="2">' + d.data[this.tooltipDataItems[i].key] + '</td>';
            } else {
              if(d.data[this.tooltipDataItems[i].key] === null || d.data[this.tooltipDataItems[i].key] === undefined ) {
                html += '<td class="toolTip">' + this.tooltipDataItems[i].label + ':</td>' +
                '<td class="toolTip">N/D</td>';
              } else {
                html += '<td class="toolTip">' + this.tooltipDataItems[i].label + ':</td>' +
                '<td class="toolTip">' + d.data[this.tooltipDataItems[i].key] + '</td>';
              }

            }
            html += '</tr>';
          }
          html += '</table>';
          return html;
        })
      .style('display', 'inline-block')
      .style('left', getLeft(d3.event.pageX) + 'px')
      .style('top', getTop((d3.event.pageY - 50)) + 'px');

    function getLeft(leftPos) {
      if ((leftPos + 100) > window.innerWidth) {
        return leftPos - 100;
      } else {
        return leftPos;
      }
    }

    function getTop(topPos) {
      if ((topPos + 50) > window.innerHeight) {
        return topPos - 50;
      } else {
        return topPos;
      }
    }
  }

  getTooltipXY(d, event) {
    const ct = this.getCenter(d);
    return { x: ct.x + this.chartContainer.nativeElement.offsetLeft, y: ct.y + this.chartContainer.nativeElement.offsetTop };
  }

  getCenter(d) {
    return { x: Math.floor(d.x0 + (d.x1 - d.x0) / 2), y: Math.floor(d.y0 + (d.y1 - d.y0) / 2) };
  }

  tooltipOff() {
    this.svg.style('cursor', 'default');
    this.tip.transition()
      .duration(300)
      .style('opacity', 0);
  }

  blockClick(d) {
    this.tip.style('opacity', 0);
    this.svg.style('cursor', 'default');
    this.onClick.emit(d.data);
  }
}
