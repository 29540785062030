import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ert-site-metric-info',
  templateUrl: './site-metric-info.component.html',
  styleUrls: ['./site-metric-info.component.scss']
})
export class SiteMetricInfoComponent implements OnInit {

  @Input() protocolNo: string;
  @Input() siteNo: string;
  @Input() metricName: string;
  @Input() metricThreshold: string;
  @Input() studyName: string;
  @Input() piName: string;
  @Input() category: string;
  @Input() valueMinMax: string;
  @Input() metricWeight: string;
  @Input() metricValue: string;

  constructor() { }

  ngOnInit() {
  }

}
