<ert-stat-block *ngIf="siteId" class="site__stat-block"></ert-stat-block>

<div class="overview-score__container" [ngStyle]="{'flex-direction': breakpoint === 1 ? 'column': 'row'}">
  <mat-card class="card-half left">
     <ert-score-overview  class="study-comp" kritype="Compliance"
       [data]="siteBucketsScoreHistory" yAxisLabel="Score">
     </ert-score-overview>
  </mat-card>

  <mat-card class="card-half right">
    <div>
      <div class="title-label content"><h4> {{'Metrics By Score'}}</h4>  </div>
      <div class="metric-label">
          <mat-form-field>
            <mat-label>Metric Category</mat-label>
            <mat-select matNativeControl (selectionChange)="onSelectCategory($event.value)">
              <mat-option value="all"> All </mat-option>
              <mat-option *ngFor="let category of metricCategories" value={{category.id}}>
                {{category.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
      </div>
    </div>
    <div class="metric-bar-chart" style="float:center;">
      <ert-metric-by-score [data]="metricbyScoreData" *ngIf="metricbyScoreData && metricbyScoreData.length > 0; else noDataToShow">
      </ert-metric-by-score>
    </div>
  </mat-card>
</div>


<ng-template #noDataToShow>
  <div class="stats-item es-status-sign metric-data">
    <span class="es-icon es-icon-magnifier"></span>
    <span class="text">No Data to Display</span>
  </div>
</ng-template>

<div class="site-metric-info">
  <ert-metric-site-event-table></ert-metric-site-event-table>
</div>
