import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

export interface StatBlockDataStructure {
  name: string;
  value: string;
  color?: string;
}

export interface MilestoneTooltip {
  name: string;
  targetDate: string;
  actualDate: string;
  projectedDate?: string;
  targetDateColor?: string;
  actualDateColor?: string;
  projectedDateColor?: string;
}

@Component({
  selector: 'ert-stat-block',
  templateUrl: './stat-block.component.html',
  styleUrls: ['./stat-block.component.scss']
})
export class StatBlockComponent implements OnInit {
  studyId: number;
  siteId: number;
  @Input() statData: any;
  @Input() topRowTitle: string;
  @Input() bottomRowTitle: string;
  @Input() topRowData: StatBlockDataStructure[];
  @Input() studyStartDate: Date;
  @ViewChild('tooltip') tooltip: ElementRef;
  keys = ['FPFV', 'FPI', 'LPI', 'LPLV', 'LPO', 'DB Lock', 'eTMF'];
  colorKeys = ['#3193b9','#4f87b1','#627ba9','#706ea1','#7c6199', '#875191', '#903d8a'];
  data = [];
  milestoneTooltip =[];
  lastUpdatedDate;
  orderCheck: boolean = false;
  alertPermCheck = false;
  constructor(private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.studyId = +params.studyId;
      this.siteId = +params.siteId;
    });
  }

  ngOnInit() {
    const token = JSON.parse(sessionStorage.getItem('RBM-AUTH'));
    // check if user doesn't have access to view observation component for studyView
    if ((token.userAccess.roles && token.userAccess.roles[0] === 'studyView')
    || (token.userAccess.permissions && token.userAccess.permissions.editStudy)
    && (token.userAccess.permissions.editStudy.includes(this.studyId)
    !== token.userAccess.permissions.viewStudy.includes(this.studyId))) {
      this.alertPermCheck = true;
    }
    if (this.statData && this.statData.bottomRowData && this.statData.bottomRowData.length) {
      let targetDate = [];
      let act_projDate = [];
      this.lastUpdatedDate = this.statData.bottomRowData[0].lastUpdatedDate;
      for (var element in this.statData.bottomRowData) {
        targetDate[
          this.statData.bottomRowData[element].shortName
        ] = this.statData.bottomRowData[element].targetDate;
        if (this.statData.bottomRowData[element].actualDate) {
          this.milestoneTooltip[this.statData.bottomRowData[element].shortName] = 'Actual Date';
          act_projDate[
            this.statData.bottomRowData[element].shortName
          ] = this.statData.bottomRowData[element].actualDate;
        } else {
          this.milestoneTooltip[this.statData.bottomRowData[element].shortName] = 'Projected Date';
          act_projDate[
            this.statData.bottomRowData[element].shortName
          ] = this.statData.bottomRowData[element].projectedDate;
        }
      }
      let mappedTargetDate = [];
      let mappedActDate = [];
      for (var ele in this.keys) {
        mappedTargetDate[this.keys[ele]] = targetDate[this.keys[ele]];
        mappedActDate[this.keys[ele]] = act_projDate[this.keys[ele]];
      }
      var orderCheckArray = Object.keys(mappedTargetDate).sort(function(a,b){ return mappedTargetDate[a] - mappedTargetDate[b] })
      if(JSON.stringify(orderCheckArray) === JSON.stringify(this.keys))
      this.orderCheck = true;
      mappedTargetDate['milestoneDateType'] = 'Target Date';
      mappedActDate['milestoneDateType'] = 'Actual/Projected Date';
      this.data.push(mappedActDate);
      this.data.push(mappedTargetDate);
    }
  }

  toolTipGoalData(goalValue, thresholdValue, unit) {
    let goal = goalValue != null ? goalValue : thresholdValue;
    if(goal !== undefined) {
      if(unit === '%') {
        return `Target: ${parseFloat(goal) * 100} ${unit}`;
      } else return `Target: ${goal} ${unit.toLowerCase()}`
    } else return 'Target Not Defined';
  }

  wrapWidth() {
    if (this.statData.topRowData.length <= 8) {
      return `${100/(this.statData.topRowData.length)}%`;
    } else {
      if (this.statData.topRowData.length % 2 === 0) {
        return `${100/(this.statData.topRowData.length/2)}%`;
      } else return `${100/((this.statData.topRowData.length/2) + 1)}%`;
    }
  }
}
