import { ActionAppPreloginUrl } from './../app.actions';
import {CustomerActionTypes, SelectCustomerSuccess, LoadCustomers, ResetCustomer, SelectCustomer, SelectCustomerWithNoDB} from './../customer/customer.actions';
import { saveCustomerState  } from './../customer/customer.state';
import { HttpClient } from '@angular/common/http';
import { mergeMap, tap, switchMap, catchError, map, withLatestFrom, take } from 'rxjs/operators';
import { AuthService } from '@core/services/auth.service';
import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { EndpointService, DataService } from '@shared/services';
import { AUTH_KEY, AuthActionTypes, Login, LoginSuccess, Authenticated, getUserInfoFromSessionStorage} from './auth.reducer';
import { SessionStorageService } from '@core/services/session-storage.service';
import { Router } from '@angular/router';
import { DataSourceRefresh } from '../datasource/datasource.actions';

const home: string = 'HOMEPAGE';
const error: string = '/error';
const dashboardURL: string = '/dashboard/kpi/portfolio';
const diAdminURL: string = '/dashboard/settings/admin/organization';
// const crossSiteURL: string = '/dashboard/sitebenchmark';
const noPermTitle: string = 'NO PERMISSIONS GRANTED';
const noPermMessage: string = 'Please contact your administrator. You do not have any permissions assigned at the moment';

@Injectable()
export class AuthEffects {
  constructor(
    private http: HttpClient,
    private actions$: Actions<Action>,
    private authService: AuthService,
    private store: Store<any>,
    private sessionStorageService: SessionStorageService,
    private endpointService: EndpointService,
    private dataService: DataService<any>,
    private router: Router,
  ) {
  }

  /**
   * Get List of Customers
   * If  single customer dispatch login sucecss
   */
  @Effect({ dispatch: false })
  login(): Observable<Action> {
    return this.actions$.pipe(ofType(AuthActionTypes.LOGIN),
        switchMap((action: any) => {
          const loginCompleteUrl = this.endpointService.getEndpoint('gssoLoginComplete');
          return this.dataService.post(loginCompleteUrl, action).pipe(
            map((response: any) => {
              const { result: userData,  result: { clientId, clientName, custList } } = response;
              const customers = custList || (clientId && clientName ? [{clientId, clientName}] : []);
              let isCustomerSelected = false;

              if (customers.length === 1) {
                userData.select = false;
                isCustomerSelected = true;
              }
              this.store.dispatch(new LoadCustomers({ customers, select: userData.select, isCustomerSelected}));
              userData.multiSelect = userData.select;
              this.sessionStorageService.setItem(AUTH_KEY, userData);

              if (!userData.select) {
                //this.store.dispatch(new LoginSuccess(userData));
                //Dispatch an event to do an DataSource Refresh
                this.store.dispatch(new DataSourceRefresh({
                  selectedCustomerId: null,
                  selectedCustomer: userData
                }))
              }
            }),
            catchError((err => of(err)))
          );
        })
    );
  }

  /**
   * After login is successfully set sessionStorageService and localStorage
   */
  @Effect()
  LoginSuccess(): Observable<Action> {
    return this.actions$.pipe(ofType(AuthActionTypes.LOGIN_SUCCESS),
        map((action: any) => {
          const userData = { ...action.payload };
          // const permissionsEndpoint = this.endpointService.getEndpoint('userPermissions');

          userData.isCustomerSelected = true;
          var custList = this.sessionStorageService.getItem('RBM-AUTH').custList;
          const tableauDomain = this.sessionStorageService.getItem('RBM-AUTH').tableauDomain;
          const tableauClientSite = this.sessionStorageService.getItem('RBM-AUTH').tableauClientSite;
          userData.custList = custList;
          userData.tableauDomain = tableauDomain;
          userData.tableauClientSite = tableauClientSite;
          this.sessionStorageService.setItem(AUTH_KEY, userData);

          // return this.dataService.getAll(permissionsEndpoint).pipe(
          //   map((data: any) => {
          //     localStorage.setItem('permissions', JSON.stringify(data.result));
          //   }),
          //   catchError((err => of(err)))
          //   );
            return new Authenticated();
        })
      );
  }

  /**
 * After login is successfully set sessionStorageService and localStorage
 */
  @Effect({ dispatch: false })
  SelectCustomerWithNoDB(): Observable<Action> {
    return this.actions$.pipe(ofType(CustomerActionTypes.SELECT_CUSTOMER_WITH_NO_DB),
      withLatestFrom(this.store.select('customerState'), (action, state) => {
        const storageInfo = getUserInfoFromSessionStorage();
        const { customers, isCustomerSelected, ...customerSelect } = state;
        const infoNew = { ...storageInfo, ...customerSelect, selectedCustomerId: customers[0], isCustomerSelected: isCustomerSelected};
        return infoNew;
      }),
      tap((storageInfo) => {
        const selectCustomerUrl = this.endpointService.getEndpoint('select');
        const selectCustomerPayload = {
          clientId: storageInfo.selectedCustomerId.clientId,
          orgId: storageInfo.selectedCustomerId.orgId,
          navigatorOrgName: storageInfo.selectedCustomerId.navigatorOrgName
        };

        this.dataService.post(selectCustomerUrl, selectCustomerPayload).toPromise()
          .then((response) => {
            const selectedCustomerId = storageInfo.selectedCustomerId;
            const { result: selectedCustomer } = response;

            this.store.dispatch(new SelectCustomerSuccess(selectedCustomerId));
            this.store.dispatch(new LoginSuccess(selectedCustomer));
          });

        // this.store.dispatch(new LoginSuccess(customers));
        // return customers;
      })
    );
  }

  /**
   *  Navigate to Landing page when aunthenticated
   */
  @Effect({ dispatch: false })
  authenticated(): Observable<Action> {
    return this.actions$.pipe(ofType(AuthActionTypes.AUTHENTICATED),
        withLatestFrom(this.store.select('appState'), (action, state) => state.preLoginUrl),
        tap((redirectUrl) => {
          // const landingPage = dashboardURL;
          // Setting the landing page as a part of Session Storage
          const permission = JSON.parse(sessionStorage.getItem('RBM-AUTH'));
          sessionStorage.setItem(home, dashboardURL);
          if (redirectUrl) {
            return this.router.navigate([redirectUrl])
              .then(() => this.store.dispatch(new ActionAppPreloginUrl('')));
          }
          if (permission.userAccess.toAdminLevel === 0 && !permission.isErtDemo) {
              if (sessionStorage.getItem(home) !== null) {
                this.router.navigateByUrl(dashboardURL);
                return;
              } else {
                this.router.navigate([error], { queryParams: { title: noPermTitle, message: noPermMessage, reset: true } });
                return;
              }
          } else if (permission.userAccess.toAdminLevel > 0 || permission.isErtDemo) {
            this.router.navigateByUrl(diAdminURL);
          }
        })
      );
  }

  /**
   * Clear session storage, reload app and navigate to login
   */
  @Effect({ dispatch: false })
  logout(): Observable<Action> {
    return this.actions$.pipe(ofType(AuthActionTypes.LOGOUT),
        tap((action: any) => {
          const logout = this.endpointService.getEndpoint('logoff');
          this.dataService.post(logout, { id_token: action.payload }).toPromise()
            .then(() => {
              this.resetApp();
            })
            .catch((err) => {
              if (err.code = 'InvalidToken') {
                this.resetApp();
              }
            });
        })
      );
  }

  resetApp() {
    sessionStorage.clear();
    // location.reload();
    this.store.dispatch(new ResetCustomer());
    this.router.navigate(['login']);
  }

  /**
   * Select a customer from multiple customers (MULTI LOGIN)
   */
  @Effect({ dispatch: false })
  SelectCustomer(): Observable<Action> {
    return this.actions$.pipe(ofType(CustomerActionTypes.SELECT_CUSTOMER),
      switchMap((action: any) => {
        //Filters to reset if selectOrg has other org selected
        if(JSON.parse(sessionStorage.getItem('RBM-AUTH')).clientId) {
          if (action.payload.clientId != JSON.parse(sessionStorage.getItem('RBM-AUTH')).clientId) {
            sessionStorage.removeItem('FILTERS');
          }
        }
        if (!action.payload.clientId) {
         return  of(action.payload).pipe(
            map((action: any) => {
              this.store.dispatch(new SelectCustomerWithNoDB(action));
              // this.store.dispatch(new LoginSuccess(userInfo));

            }),
          );
        }
        const selectCustomerUrl = this.endpointService.getEndpoint('select');
        return this.dataService.post(selectCustomerUrl, action.payload)
          .pipe(
            map((response: any) => {
              const selectedCustomerId = action.payload;
              const { result: selectedCustomer } = response;
              //Dispatch an event to do an DataSource Refresh
              this.store.dispatch(new DataSourceRefresh({
                selectedCustomerId: selectedCustomerId,
                selectedCustomer: selectedCustomer
              }))
            }),
            catchError((err => of(err)))
          );

      })
    );
  }


  /**
   * Create a customer
   * select created customer
   * save customer info to sessionStorage
   */
  @Effect({dispatch: false})
  CreateCustomer(): Observable<Action> {
    return this.actions$.pipe(ofType(CustomerActionTypes.CREATE_CUSTOMER),
      withLatestFrom(this.store.select('customerState'), (action, state) => state.customers),
      tap((customers: any) => {
        if (customers.length) {
          saveCustomerState(customers[0]);
          const { clientId, clientName, orgId, navigatorOrgName, clientMode } = customers[0];
          this.store.dispatch(new SelectCustomer({
            clientId, clientName, orgId, navigatorOrgName, clientMode
          }));
        }
      })
    );
  }
}
