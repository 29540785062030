import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbConfigService {

  constructor(private router: Router,
    private ngZone: NgZone) { }

  public getHomeBrdCrmb() {
    var authData = JSON.parse(sessionStorage.getItem('RBM-AUTH-CONFIG'));
    // on click of 'Change Org' breadcrumb.
    // check if a user has any customers, if not then redirect them to default landing page
    // otherwise redirect to the customer selection dropdown
    var orgObject = JSON.parse(sessionStorage.getItem('RBM-AUTH'));
    const {custList} = orgObject
    const redirectUrl = `/authcallback/code/${authData.code}/state/${authData.state}`;
    const handler = () => {
      this.ngZone.run(() => {
        this.router.navigate([redirectUrl]);
      });
    }
    if(custList.length > 1){ //Change Org only when user has access to more than one org
      return { label: 'Change Org', handler: handler };
    }
    else {
      return { label: 'Change Org' } 
    }
  }

  public getPortfolioBrdCrmb() {
    var orgObject = JSON.parse(sessionStorage.getItem('RBM-AUTH'));
    var orgName = orgObject.clientName ? orgObject.clientName : orgObject.navigatorOrgName;
    const handler = () => {
      this.ngZone.run(() => {
        this.router.navigate(['/dashboard/portfolio'])
      });
    }
    return { label: orgName, handler: handler };
  }

  public getStudyBrdCrmb(studyId, studyName) {
    const handler = () => {
      this.ngZone.run(() => {
        this.router.navigate([`/dashboard/study/${studyId}`])
      });
    }
    return { label: studyName, handler: handler };
  }

  public getSiteBrdCrmb(studyId, siteId, siteNo) {
    const handler = () => {
      this.ngZone.run(() => {
        this.router.navigate([`/dashboard/study/${studyId}/site/${siteId}`])
      });
    }
    return { label: `Site: ${siteNo}`, handler: handler };
  }

  public getStudySiteMetricBrdCrmb(studyId, siteId, metricId, metricName) {
    const handler = () => {
      this.ngZone.run(() => {
        if (siteId) this.router.navigate([`/dashboard/study/${studyId}/site/${siteId}/metric/${metricId}`]);
        else this.router.navigate([`/dashboard/study/${studyId}/metric/${metricId}`]);
      });
    }
    return { label: metricName, handler: handler };
  }
  public getOrgSettingBrdCrmb(orgPageName = 'Settings') {
    const client = JSON.parse(sessionStorage.getItem('RBM-AUTH'));
    const clientName = client.clientName ? client.clientName : client.navigatorOrgName;
    const handler = () => {
      this.ngZone.run(() => {
        this.router.navigate([`/dashboard/settings/admin/organization/studies`]);
      });
    }
    return { label: `${clientName} ${orgPageName}`, handler: handler };
  }

  public getStudySettingBrdCrmb(studyId, studyName) {
    const handler = () => {
      this.ngZone.run(() => {
        this.router.navigate([`/dashboard/settings/admin/studies/${studyId}`]);
      });
    }
    return { label: studyName, handler: handler };
  }

  public getDataMonitoringBrdCrmb( ) {
    const handler = () => {
      this.ngZone.run(() => {
        this.router.navigate([`/dashboard/kpi/data-monitoring`]);
      });
    }
    return { label: 'Data Quality Monitoring', handler: handler };
  }

  public getTableauParentBrdCrmb(tableauParentPath, viewParentTitle) {
    viewParentTitle = viewParentTitle[0].toUpperCase() + viewParentTitle.slice(1);
    const handler = () => {
      this.ngZone.run(() => {
        this.router.navigate([`/dashboard/kpi/${tableauParentPath}`]);
      });
    }
    return { label: viewParentTitle, handler: handler };
  }


  public getTableauDashboardBrdCrmb(tableauViewPath, viewTitle) {
    const handler = () => {
      this.ngZone.run(() => {
        this.router.navigate([`/dashboard/kpi/${tableauViewPath}`]);
      });
    }
    return { label: viewTitle, handler: handler };
  }

}
