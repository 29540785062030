import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn } from '@angular/forms';
import { ToAdminService } from './../to-admin.service';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { SetBreadCrumbs } from '@app/core/store/breadcrumbs/breadcrumbs.actions';
import { ErrorNotificationService } from '@app/core/services/error-notification.service';

@Component({
  selector: 'ert-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss']
})
export class AddCustomerComponent implements OnInit {
  getClientKey;

  addCustomerForm: UntypedFormGroup;
  clientTypeList = [
    'CRO',
    'sponsor'
  ];

  @ViewChild('addCustomerFormElem') addCustomerFormElem;

  constructor(
    private store: Store<any>,
    private toAdminService: ToAdminService,
    private toastr: ToastrService,
    private errorNotificationService: ErrorNotificationService,
  ) {
    this.store.dispatch(new SetBreadCrumbs({
      crumbs: [
        {text: 'TO Admin'},
        {text: 'Customer'}
      ]
    }));
  }

  ngOnInit() {
    const permissions = JSON.parse(sessionStorage.getItem('RBM-AUTH'));
    const userEmail = permissions && permissions.email;

    this.addCustomerForm = new UntypedFormGroup({
      clientName: new UntypedFormControl('', [Validators.required]),
      clientType: new UntypedFormControl('', [Validators.required]),
      clientAdminEmail: new UntypedFormControl(userEmail, [Validators.required, this.emailERTValidator]),
    });
  }

  emailERTValidator(control: UntypedFormGroup) {
    const email = control.value;
    if (!email || !email.includes('@ert.com')) {
      return { 'emailError': 'Admin email must be an @ert.com email address.' };
    }
    return null;
  }

  saveCustomer() {
    /* if (this.addCustomerForm.valid) {
      const customer = {
        clientName: this.addCustomerForm.value.clientName,
        clientType: this.addCustomerForm.value.clientType,
        adminEmail: this.addCustomerForm.value.clientAdminEmail,
      };
      this.toAdminService.addCustomer(customer).subscribe(data => {
        this.getClientKey = data;

        this.toastr.success(`Customer added successfully.
        Please note the following Client API Key ${this.getClientKey.result.client_key}`, '', {timeOut: 10000} );
        this.addCustomerFormElem.resetForm();
      }, err => {
        if (err.status === 401) throw err;
        else this.errorNotificationService.notifyError('Error adding customer.', err);
      });
    } */
  }
}
