<h2 class="add-alert__header">Generate Alert</h2>
<form class="add-alert__form" [formGroup]="createAlertForm" (ngSubmit)="onFormSubmit()">
  <div class="add-alert__formsection">
    <mat-form-field>
      <input matInput onkeyup="this.value = this.value[0].toUpperCase() + this.value.substr(1)"
        placeholder="Title" value="" formControlName="title">
    </mat-form-field>
  </div>
  <div class="add-alert__formsection">
    <mat-form-field>
      <textarea matInput onkeyup="this.value = this.value[0].toUpperCase() + this.value.substr(1)"
        placeholder="Description" formControlName="description"></textarea>
    </mat-form-field>
  </div>
  <button class="add-alert__button-row es-button" type="button" matDialogClose>Cancel</button>
  <div class="add-alert__create">
    <ert-spinner-button [disabled]="createAlertForm.invalid" [active]="buttonActive" [buttonText]="buttonText"></ert-spinner-button>
  </div>
</form>
