import { SessionStorageService } from './services/session-storage.service';
import { AuthService } from '@core/services/auth.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { SharedModule } from '@app/shared/shared.module';
import {DBLoggerService } from '@core/services/db-logger.service';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
  ],
  providers: [
    AuthService,
    SessionStorageService,
    DBLoggerService,
  ]
})
export class CoreModule { }
